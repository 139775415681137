import React from "react";

const ActionComponent = (props) => {
  
  let { value } = props;
  let hoverText = "";
  let iconClass = "";
  let functionType = "";
  let iconColor = "";
  let duelFuelTypeShow = "d-none"

  if (value === "no_gps") {
    iconClass = "icofont-social-google-map";
    hoverText = "GPS Data Not Available";
    functionType = props.context.componentParent.onShowNoGPSKpi;
    iconColor = "redfont";
  }

  if (value === "enroute_stoppage") {
    iconClass = "icofont-pause";
    hoverText = "Enroute Stoppages";
    functionType = props.context.componentParent.onShowEnrouteStoppagesKpi;
    iconColor = "maroon";
  }

  if (value === "slow_trucks") {
    iconClass = "icofont-fast-delivery";
    hoverText = "Slow Truck";
    functionType = props.context.componentParent.onShowTransitKpi;
    iconColor = "txt-info";
  }

  if (value === "food_stoppage") {
    iconClass = "icofont-fast-food";
    hoverText = "Food Stopage";
    functionType = props.context.componentParent.onShowFoodStoppageKpi;
    iconColor = "txt-google-plus";
  }

  if (value === "fuel_stoppage") {
    iconClass = "icofont-water-drop";
    hoverText = "Fuel Stopage";
    functionType = props.context.componentParent.onShowFuelStoppageKpi;
    iconColor = "txt-warning";
  }

  if (value === "duel_fuel_stoppage") {
    iconClass = "icofont-water-drop";
    hoverText = "Duel Fuel Stopage";
    functionType = props.context.componentParent.onShowFuelStoppageKpi;
    iconColor = "txt-warning";
    duelFuelTypeShow = "";
  }

  if (value === "e_lock") {
    iconClass = "icofont-lock";
    hoverText = "E-Lock";
    functionType = props.context.componentParent.onShowElockKpi;
    iconColor = "txt-black";
  }

  if (value === "loading_delay") {
    iconClass = "icofont-truck-loaded";
    hoverText = "Loading delay";
    functionType = props.context.componentParent.onShowLoadingUnloadingKpi;
    iconColor = "txt-dark";
  }

  if (value === "unloading_delay") {
    iconClass = "icofont-delivery-time";
    hoverText = "Unloading delay";
    functionType = props.context.componentParent.onShowLoadingUnloadingKpi;
    iconColor = "txt-pink-medium";
  }

  if (value === "detour") {
    iconClass = "icofont-zigzag";
    hoverText = "Detour";
    functionType = props.context.componentParent.onShowDetourKpi;
    iconColor = "txt-info";
  }

  if (value === "toll_stoppage") {
    iconClass = "icofont-bill";
    hoverText = "Toll stoppage";
    functionType = props.context.componentParent.onShowTollStoppageKpi;
    iconColor = "txt-pink-dark";
  }

  const handleEventClick = (e, functionType) => {
    e.stopPropagation();
    functionType(props);
  };

  return (
    <div>
      <button
        onClick={(e) => {
          handleEventClick(e, functionType);
        }}
        className="custom-btn f22 label label-success"
        title={`${hoverText}`}
      >
        <i className={`icofont ${iconClass} ${iconColor}`}></i> 
        <i class={`${duelFuelTypeShow} icofont icofont-water-drop txt-warning`} style={{position:"relative",right:"14px"}}></i>
        &nbsp;
      </button>
    </div>
  );
};

export default ActionComponent;
