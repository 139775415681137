import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { TabContent, TabPane } from 'reactstrap';
import CKEditor from "react-ckeditor-component";
import CountUp from "react-countup";
import * as Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import TicketsMap from "./ticketsMapcomponent";
import DashboardGrid from "../dashboard/DashboardGrid";
import ActionComponent from "../manage/actionComponent";
import ManualActionComponent from "../manage/manualActionComponent";

import { AgGridReact } from "@ag-grid-community/react";
import Tickethistory from "./tickethistory";
import Select from "react-select";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import Modal from "react-responsive-modal";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import InvalidTicket from "./invalidTicket";
import Ticketmailview from "./ticketmailview";
import GridButton from "./gridButton";
import DrawMap from "../common/drawmap";

import {
  getDDMMYYYY,
  getDDMMYYYYHHMMSS,
  getYYYYMMDD,
  getYYYYMMDDHHMMSS,
  getHyphenYYYYMMDDHHMMSS,
  getHyphenDDMMMYYYYHHMM,
  getHyphenDDMMMYYYY,
  getHyphenYYYYMMDD,
  getHyphenDDMMYYYYHHMMSS,
  getHyphenDDMMYYYY,
} from "../common/utils";
import RoutePlayPause from "./routeAnimate";
// import { getConsigementTicketTollStoppage } from "../../../../adibirla_node/routes/controller/tmsController";

var moment = require("moment");
var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require("../redirectURL");

export default class ticketmanagementsystemComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fromDate: moment().subtract(7, "days"),
      toDate: moment(),
      gridDataTitle: "",
      inBoundBtn:"btn-info",
			inPlantBtn:"btn-default",
      show: false,
      basicType: "default",
      basicTitle: "",
      loadshow: "show-n",
      overly: "show-n",
      modules: AllModules,
      paginationPageSize: 50,
      rowSelection: "multiple",
      ticketHistoryLog: "",
      detailCellRendererParams: {},
      confirmShow: false,
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      defaultsdate: "",
      defaultedate: "",
      mapToggle:false,
      rowData: null,
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      usergridstate: [],
			emaillist:[],
      isclosetickts: "",
      isinvalidtickets: "",
      opens: 0,
      workinprogresses: 0,
      onholds: 0,
      totaltypes: [],
      totalTickets: 0,
      invalid_reason: "",
      invalidticketinfo: [],
			mailresponse:[],
      closeds: 0,
      autocloseds: 0,
      closenoresponse: 0,
      forceclosed: 0,
      ticket_status_code: "",
      ticketStatusOptions: [
        { value: "1", label: "Open" },
        { value: "2", label: "Work in progress" },
        { value: "3", label: "Closed" },
        { value: "4", label: "On hold" },
        { value: "6", label: "Invalid" },
      ],
      ticket_comment: "",
      commentTickets: [],
      commentTicketsCategory: [],
      invalids: 0,
      overspeed: 0,
      nightdrive: 0,
      shortrest: 0,
      enroute: 0,
			dept_code:'all',
      harshBrakingCount: 0,
      detourCount: 0,
      breakdownCount: 0,
      idlingCount: 0,
      rownode: "",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      overlayNoRowsTemplate: "",
      sliderTicketMapRoute: "",
      sliderTicketTranslate: "",
      frameworkComponents: {
        // customLoadingCellRenderer: CustomLoadingCellRenderer,
        // sendemailticket:Sendemailticket,
        // kpiActions:KpiActions,
        // kpiTransitDelayActions:KpiTransitDelayActions,
        // kpiNightDriveActions:KpiNightDriveActions,
        // ticketloadingaction:Ticketloadingaction,
        // ticketunloadingaction:Ticketunloadingaction,
        automaticActionComponent: ActionComponent,
        manualActionComponent: ManualActionComponent,
        // RoutemapAction:RoutemapAction,
        // RoutemapTransitAction:RoutemapTransitAction,
        // RoutemapenrouteAction:RoutemapenrouteAction,
        // RouteNightDrivemap:RouteNightDrivemap,
        tickethistory: Tickethistory,
        invalidTicket: InvalidTicket,
        ticketmailview: Ticketmailview,
        TicketsMap: TicketsMap,
        GridButton: GridButton,
        // EscalateButton: EscalateButton,
        // changeTicketCategoryButton: ChangeTicketCategoryButton,
        // Slowtrucks:Slowtrucks,
        // detourAction: DetourAction,
        // fuelStoppage: FuelStoppage,
        // eLock: ELock,
        // foodStoppage: FoodStoppage,
        // loadingDelay: LoadingDelay,
        // tollStoppage: TollStoppage,
        // unloadingDelay: UnloadingDelay,
        // nogps:NoGps,
        // ticketkpienroute:Ticketkpienroute,
        // duelFuelStoppage: DuelFuelStoppage,

        // delayActionComponent: DelayActionComponent,
        // breakdownAction:BreakdownAction,
      },
    };
    this.cipher = this.cipher.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.onShowTicketDetails = this.onShowTicketDetails.bind(this);
    this.onShowTicketHistory = this.onShowTicketHistory.bind(this);
    this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		this.onShowTicketSentEmail = this.onShowTicketSentEmail.bind(this);
    this.onShowTicketsMap = this.onShowTicketsMap.bind(this);
    this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this);
  }

  cipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);
    try {
      return (text) => {
        if (text) {
          return text
            .split("")
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join("");
        } else {
          return "";
        }
      };
    } catch (e) {}
  };

  componentDidMount() {
    var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
    var edate = moment.parseZone().format("YYYY-MM-DD");
    var sdate = moment
      .parseZone()
      .subtract(1, "days")
      .format("YYYY-MM-DD");

    this.setState({
      fromDate: sdate,
      toDate: edate,
      defaultsdate: sdate,
      defaultedate: edate
    });
    var urlpath = "";
    var dpt = "";
    var title = "";
    var isclosetickts = "";
    var isinvalidtickets = "";
    console.log("tms ", this.props.match.path);

    if (this.props.match.path == "/ticketmanagementsystem") {
      urlpath = "/tms";
      title = "Ticket Management System";
      isclosetickts = "";
      isinvalidtickets = "";
    }

    if (this.props.match.path == "/closetickets") {
      urlpath = "/tms/closetickets";

      title = "Closed Tickets";
      isclosetickts = 1;
      isinvalidtickets = "";
    }
    if (this.props.match.path == "/invalidtickets") {
      urlpath = "/tms/invalidtickets";

      title = "Invalid Tickets";
      isclosetickts = "";
      isinvalidtickets = 1;
    }
    //console.log(title);
    this.setState({
      title: title,
      isclosetickts: isclosetickts,
      isinvalidtickets: isinvalidtickets,
      pagetitle: title,
    });
    var params = {
      startDate: sdate,
      endDate: edate,
      dept_code: encode("all"),
    };

    // redirectURL
    //   .post("/consignments/usergridstates", {
    //     userId: localStorage.getItem("userid"),
    //     screenurl: window.location.pathname,
    //   })
    //   .then((resp) => {
    //     	console.log("resp user grid ", resp)
    //     this.setState({
    //       usergridstate: resp.data,
    //     },()=>{
    //       console.log(this.state.usergridstate, "usergridState")
    //     });
    //     this.restoreGridStates();
    //   });

    this.onLoadShowTMSData(urlpath, params);
  }

  onLoadShowTMSData(urlpath, parameters) {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    redirectURL
      .post(urlpath, parameters)
      .then((response) => {
        console.log("REsponse ", response.data);
        var records = response.data.records;
        console.log("records from ticketLogs", records);

        var openarr = [];
        var workinprogressarr = [];
        var closedarr = [];
        var onholdarr = [];
        var autoclosedarr = [];
        var invalidarr = [];
        var closenoresponsearr = [];
        var forceclosearr = [];
        var transitarr = [];
        var overspeedarr = [];
        var nightdrivearr = [];
        var transporterarr = [];
        var enroutearr = [];
        var detour = [];
        var shortrestarr = [];
        var idlingarr = [];

        if (records.length > 0) {
          records.map((item) => {
            if (item.ticket_status == 1) {
              openarr.push(item);
            }
            if (item.ticket_status == 2) {
              workinprogressarr.push(item);
            }
            if (item.ticket_status == 2 && item.transporter_commented == 1) {
              transporterarr.push(item);
            }
            if (item.ticket_status == 3) {
              closedarr.push(item);
            }
            if (item.ticket_status == 4) {
              onholdarr.push(item);
            }
            if (item.ticket_status == 5) {
              autoclosedarr.push(item);
            }
            if (item.ticket_status == 6) {
              invalidarr.push(item);
            }
            if (item.ticket_status == 7) {
              closenoresponsearr.push(item);
            }
            if (item.ticket_status == 8) {
              forceclosearr.push(item);
            }
            if (item.ticket_category == "enroute_stoppage") {
              enroutearr.push(item);
            }
            if (item.ticket_category == "detour") {
              detour.push(item);
            }
            if (item.ticket_category == "over_speeding") {
              overspeedarr.push(item);
              console.log("oversped");
            }
            if (item.ticket_category == "night_driving") {
              nightdrivearr.push(item);
            }
            if (item.ticket_category == "short_rest") {
              shortrestarr.push(item);
            }
            if (item.ticket_category == "idling") {
              idlingarr.push(item);
            }
          });

          this.setState({
            originalData: records,
            rowData: records,
            opens: openarr.length,
            workinprogresses: workinprogressarr.length,
            totalTickets: records.length,
            transporter: transporterarr.length,
            closeds: closedarr.length,
            onholds: onholdarr.length,
            autocloseds: autoclosedarr.length,
            invalids: invalidarr.length,
            closenoresponse: closenoresponsearr.length,
            forceclosed: forceclosearr.length,
            transit: transitarr.length,
            enroute: enroutearr.length,
            detourCount: detour.length,
            shortrest: shortrestarr.length,
            overspeed: overspeedarr.length,
            nightdrive: nightdrivearr.length,
            idlingCount: idlingarr.length
          });
        }
        this.setState({
          loadshow: "show-n",
          overly: "show-n"
        })
      })
      .catch(function(e) {
        console.log(e);
      });
  }
  onBlur = async (evt) => {
    console.log("onBlur event called with event info: ", evt.editor.getData());
    await this.setState({
      ticketEmailContent: evt.editor.getData(),
    });
  };

  afterPaste(evt){
		console.log("afterPaste event called with event info: ", evt);
		this.setState({
            ticketEmailContent:  evt.editor.getData()
        })
  }
  toggle = () => {
      const currentState = this.state.mapToggle;
      this.setState({ mapToggle: !currentState });
  }

  updateContent(newContent) {
		console.log("newContent ",newContent)
      this.setState({
          ticketEmailContent:  newContent.editor.getData()
      })
  }

  onShowTicketSentEmail = async(ticketdata) =>{
		console.log("onShowTicketSentEmail ", ticketdata)

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Show Ticket Sent E-Mails",
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var rqparams = {
			ticket_number:ticketdata.data.ticket_number
		}
		redirectURL.post("/tms/viewticketmails", rqparams)
		.then((response) => {
			//console.log("Draft ", response.data)
			var drafts = response.data
			if(drafts.length > 0)
			{
				this.setState({
					viewmailSlider:'slider-translate-75p',
					overly:'show-m',
					showleftmailside:'show-m',
					sendmaildiv:"col-xl-9",
					emaillist:drafts,
					toEmail:'',
					ccEmail:'',
					ticketEmailContent:'',
					ticketSubject:'',
					ticketMailID:''
				});
			}
		})
		.catch(function(e){
			console.log("Error ",e)
		})
	}

  onClickInvalidTicket = async (params) => {
    if (googleAnalytics.page.enableGA) {
      let pageTitle = this.state.pagetitle;
      let eventOptions = {
        category: pageTitle,
        action: this.state.eventGridAction,
        label: "Invalid Ticket",
      };
      googleAnalytics.logEvent(eventOptions);
    }
    console.log("Props ", params);

    var selectedData = [params.data];
    this.setState({
      invalidticketinfo: selectedData,
    });
    this.onOpenModal();
    //var res = this.gridApi.updateRowData({ remove: selectedData });
    //printResult(res);
  };
  onOpenModal = () => {
    this.setState({ open: true });
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };
  inBoundData = (params) => {
    this.setState({
      inBoundBtn:"btn-info",
      inPlantBtn:"btn-default",
    })
    var urlpath;
    if(params ==="closeInBound"){
      console.log("closed inbound data")
      urlpath="/tms/closetickets";
    } else {
      urlpath="/tms"
    } 
    if(this.state.fromDate == '' || this.state.toDate == '')
    {
      var actiontype = 0;
    }
    else
    {
      actiontype=1;
    }
    if(this.state.dept_code == 'all')
    {
      var selectdept = 'all';
    }
    else
    {
      var selectdept = this.state.dept_code.value;
    }
    if(this.state.fromDate == '' || this.state.toDate == "NaN-NaN-NaN")
    {
      var sdate = this.state.defaultsdate;
    }
    else{
      var sdate = this.state.fromDate;
    }

    if(this.state.toDate == '' || this.state.toDate == "NaN-NaN-NaN")
    {
      var edate = this.state.defaultedate;
    }
    else{
      var edate = this.state.toDate;
    }
    var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
    var reqparms = {
      startDate:sdate,
      endDate:edate,
      dept_code:encode(selectdept),
      // in_plant_vehicle: 1
      //actiontype:actiontype
    }
    this.onLoadShowTMSData(urlpath, reqparms)
  }
  inPlantData = (params) => {
    this.setState({
      inBoundBtn:"btn-default",
      inPlantBtn:"btn-info",
    })
    var urlpath;
    if(params === "closeInPlant"){
      console.log("closed inplant")
      urlpath="/tms/closetickets"
    } else {
     urlpath="/tms";
    }
    var edate = moment.parseZone().format("YYYY-MM-DD");
    if(this.state.fromDate == '' || this.state.toDate == '')
    {
      var actiontype = 0;
    }
    else
    {
      actiontype=1;
    }
    if(this.state.dept_code == 'all')
    {
      var selectdept = 'all';
    }
    else
    {
      var selectdept = this.state.dept_code.value;
    }
    if(this.state.fromDate == '' || this.state.toDate == "NaN-NaN-NaN")
    {
      var sdate = this.state.defaultsdate;
    }
    else{
      var sdate = this.state.fromDate;
    }

    if(this.state.toDate == '' || this.state.toDate == "NaN-NaN-NaN")
    {
      var edate = this.state.defaultedate;
    }
    else{
      var edate = this.state.toDate;
    }
    var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
    var reqparms = {
      startDate:sdate,
      endDate:edate,
      dept_code:encode(selectdept),
      in_plant_vehicle: 1
      //actiontype:actiontype
    }
    console.log("selecteddeptcode",reqparms)
    this.onLoadShowTMSData(urlpath, reqparms)
  }
  onClickSetTicketAsInvalid = async () => {
    if (googleAnalytics.page.enableGA) {
      let pageTitle = this.state.pagetitle;
      let eventOptions = {
        category: pageTitle,
        action: this.state.eventGridAction,
        label: "Set Ticket As Invalid",
      };
      googleAnalytics.logEvent(eventOptions);
    }

    var check = CheckForHtmlTag(this.state.invalid_reason);
    if (check == 1) {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Html Tags or Script tags are not acceptable",
      });
    } else {
      var reason = this.state.invalid_reason;
      reason = reason.trim();
      if (reason != "") {
        let ticket_category = this.state.invalidticketinfo[0].ticket_category;
        if (ticket_category != "Manual") {
          var reqparm = {
            ticket_id: this.state.invalidticketinfo[0].ticket_number,
            reason: this.state.invalid_reason,
            userId: localStorage.getItem("userid"),
          };
          redirectURL
            .post("/tms/makeTicketInvalid", reqparm)
            .then((response) => {
              this.onCloseModal();
            })
            .catch(function(e) {
              console.log("Error ", e);
            });
          var selectedData = this.state.invalidticketinfo;
          var res = this.gridApi.updateRowData({ remove: selectedData });
          //var res = this.gridApi.updateRowData({ remove: params });
          printResult(res);
        } else {
          var reqparam = {
            ticket_id: this.state.invalidticketinfo[0].ticket_number,
            reason: this.state.invalid_reason,
            userId: localStorage.getItem("userid"),
          };

          redirectURL
            .post("/tms/makemanualticketinvalid", reqparam)
            .then((response) => {
              this.onCloseModal();
            })
            .catch((e) => {
              console.log("error", e);
            });
          var selectedData = this.state.invalidticketinfo;
          var res = this.gridApi.updateRowData({ remove: selectedData });
          //var res = this.gridApi.updateRowData({ remove: params });
          printResult(res);
        }
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Field should not be empty",
        });
      }
    }
  };
  showCloseTicketModal = (propData) => {
    console.log(propData, "483");
    this.setState({
      ticketRowData: propData.data,
      confirmShow: true,
    });
  };

  onShowTicketHistory = async (rowdata) => {
    console.log("rowdata ", rowdata);

    var transitedelaydata = [];

    await this.setState({
      detailCellRendererParams: {
        suppressRefresh: true,
        detailGridOptions: {
          columnDefs: [
            {
              headerName: "Ticket No.",
              field: "tickets",
              width: 150,
              resizable: true,
              valueGetter: (param) => {
                return param.data.tickets[0];
              },
            },
            {
              headerName: "Communication Type",
              field: "communication_type",
              width: 150,
              resizable: true,
            },
            {
              headerName: "Comment",
              field: "ticket_comment",
              width: 400,
              resizable: false,
              cellClass: ["wraptext"],
              autoHeight: true,
            },
            {
              headerName: "Satisfactory Status",
              field: "satisfactory",
              width: 120,
              resizable: false,
            },
            {
              headerName: "Comment By",
              field: "username",
              width: 150,
              cellClass: ["wraptext"],
              resizable: true,
            },
            {
              headerName: "Transporter Response",
              field: "reponsecomment",
              //field: "transporter_response",
              width: 400,
              cellClass: ["wraptext"],
              resizable: true,
            },
            // {
            // 	headerName: "Transporter Response",
            // 	field: "pasteResponseEmail",
            // 	width:150,
            // 	resizable: true,
            // },
            {
              headerName: "Created Date",
              field: "created_date",
              width: 150,
              resizable: true,
              valueGetter: function(params) {
                return getHyphenDDMMMYYYYHHMM(params.data.created_date);
              },
            },
            {
              headerName: "Status",
              field: "ticket_status",
              width: 150,
              resizable: true,
              valueGetter: function(params) {
                if (params.data.ticket_status == 1) {
                  return "Open";
                }
                if (params.data.ticket_status == 2) {
                  return "Work in progress";
                }
                if (params.data.ticket_status == 3) {
                  return "Closed";
                }
                if (params.data.ticket_status == 4) {
                  return "On Hold";
                }
                if (params.data.ticket_status == 5) {
                  return "Auto Closed";
                }
                if (params.data.ticket_status == 6) {
                  return "Invalid";
                }
                if (params.data.ticket_status == 7) {
                  return "Closed - No response";
                }
              },
            },
          ],
          overlayNoRowsTemplate: "No ticket history available",
        },
        getDetailRowData: function(params) {
          //console.log("Step 4 ",transitedelaydata)
          console.log("Step 4 ", params);
          var qu = {
            // ticket_id:params.data.ticket_id,
            ticket_no: params.data.ticket_number,
          };
          redirectURL
            .post("/tms/tickethistorybyid", qu)
            .then(async (response) => {
              console.log("Step 1 ", response.data);

              params.successCallback(response.data);
              //console.log("Step 2 ",response.data)
            });
        },
      },
    });

    //if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
    if (rowdata.column.colDef.field == "_id") {
      rowdata.node.setExpanded(!rowdata.node.expanded);
      //console.log("expanded ",params.node.expanded)
      if (rowdata.node.expanded == false) {
        transitedelaydata = [];
      }
    } else {
      rowdata.node.setExpanded(false);
    }
    //console.log("detailCellRendererParams ", this.state.detailCellRendererParams);
  };

  closeTicketConfiguration = (closureResponse) => {
    var check = CheckForHtmlTag(closureResponse);
    if (check == 1) {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Html Tags or Script tags are not acceptable",
      });
    } else {
      var reason = closureResponse;
      reason = reason.trim();
      if (reason != "") {
        this.setState({
          confirmShow: false,
        });
        let data = this.state.ticketRowData;
        var user_id = localStorage.getItem("userid");

        let params = {
          ticket_id: data.ticket_number,
          closure_response: closureResponse,
          user_id: user_id,
        };
        console.log(params, "511");
        redirectURL.post("/tms/closemanualticket", params).then((response) => {
          console.log(response, "513");

          if (response.data.status == "success") {
            this.setState({
              show: true,
              basicTitle: "Ticket Closed successfully!",
              basicType: "success",
            });
            this.setState((prevState) => {
              return {
                opens: prevState.opens - 1,
                totalManualTickets: prevState.totalManualTickets - 1,
              };
            });
            // let arrayName = "manual" + data.ticket_type
            // arrayName = arrayName.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();
            // let filteredArray = this.state[arrayName].filter( e => e._id != data._id)
            // this.setState({
            // 	[arrayName]:filteredArray
            // })
            var res = this.gridApi.updateRowData({ remove: [data] });
          } else {
            this.setState({
              show: true,
              basicTitle: "Failed to delete the data",
              basicType: "danger",
            });
          }
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Field should not be empty",
        });
      }
    }
  };
  restoreGridStates() {
    if (this.state.usergridstate.length > 0) {
      try {
        var windowstates = this.state.usergridstate;
        this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
        this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
        this.gridApi.setSortModel(windowstates[0].gridcolsort);
        this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
      } catch (e) {}
    }
  }

  closeAlert = () => {
    this.setState({
      show: false,
      confirmShow: false,
    });
  };

  // onClickHideAll = () => {
  //   this.setState({
  //     loadshow: "show-n",
  //     overly: "show-n",
  //   });
  // };

  onShowRouteDiv = (e) => {
    console.log("Rute params ", e);
    this.setState({
      loadshow: "show-m",
      maptruckno: e.truck_no,
    });

    // If gateouttime is current time - It shows "No Route data available"
    var gateouttime = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
    // if(e.ticket_type == "slow_trucks")
    // {
    gateouttime = e.exception_start_time
      .replace("T", " ")
      .replace("Z", " ")
      .replace(".000", " ")
      .trim();
    // }
    // else
    // {
    // 	if (e.gate_out_time) {
    // 		gateouttime = e.gate_out_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
    // 	}
    // }

    var edate = "";
    var end_date;
    if (e.status == 2) {
      //var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1) * 86400000);
      //var edatesecond = new Date(ms);
      end_date = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");

      edate = getDDMMYYYYHHMMSS(end_date);
    } else if (e.status == 3 || e.status == 4) {
      if (e.recent_dealer_reported) {
        end_date = e.recent_dealer_reported
          .replace("T", " ")
          .replace("Z", " ")
          .replace(".000", " ")
          .trim();
        edate = getDDMMYYYYHHMMSS(e.recent_dealer_reported);
      }
    } else if (e.status == 5) {
      if (e.left_dealer_marked) {
        end_date = e.left_dealer_marked
          .replace("T", " ")
          .replace("Z", " ")
          .replace(".000", " ")
          .trim();
        edate = getDDMMYYYYHHMMSS(e.left_dealer_marked);
      }
    } else {
      if (e.created_date) {
        end_date = e.created_date
          .replace("T", " ")
          .replace("Z", " ")
          .replace(".000", " ")
          .trim();
        edate = getDDMMYYYYHHMMSS(e.created_date);
      }
    }

    //console.log("end date",end_date);
    //console.log("checking e",e)

    if (e.transit_time > 0) {
      var seconddate = new Date(
        new Date(e.gate_out_time).getTime() +
          (e.transit_time + 1) * 24 * 60 * 60 * 1000
      );
    } else {
      var seconddate = new Date(
        new Date(e.exception_start_time).getTime() + 2 * 24 * 60 * 60 * 1000
      );
    }

    var sdate =
      seconddate.getFullYear() +
      "-" +
      (seconddate.getMonth() + 1) +
      "-" +
      seconddate.getDate() +
      " " +
      seconddate.getHours() +
      ":" +
      seconddate.getMinutes() +
      ":" +
      seconddate.getSeconds();

    if (!end_date) {
      // When end date is unavailable then current date is considered as end date.
      end_date = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
      //console.log("Defining end_date ", end_date);
    }
    var reqData = {
      truck_no: e.truck_no,
      consignment_code: e.consignment_code,
      consignee_code: e.consignee_code,
      gate_out_time: gateouttime,
      seconddate: end_date,
      screen: "consignment",
    };
    console.log("reqData: ", reqData);
    redirectURL
      .post("/consignments/maproutes", reqData, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((response) => {
        var records = response.data;
        //console.log("Props data ", e)

        this.setState({
          loadshow: "show-m",
        });
        if (records.coords.length == 0) {
          //console.log("records", records.coords);
          this.setState({
            show: true,
            basicTitle: "No Route data available",
            basicType: "danger",
            loadshow: "show-n",
          });
        } else {
          if (records != "") {
            console.log("records",records)
            var sdate = e.gate_out_time;
            var edate = "";
            if (e.status == 2) {
              //var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1)*86400000);
              //var edatesecond = new Date(ms);
              end_date = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
              edate = getDDMMYYYYHHMMSS(end_date);
            } else if (e.status == 3 || e.status == 4) {
              edate = getDDMMYYYYHHMMSS(e.recent_dealer_reported);
            } else if (e.status == 5) {
              edate = getDDMMYYYYHHMMSS(e.left_dealer_marked);
            } else {
              edate = getDDMMYYYYHHMMSS(e.created_date);
            }
            //console.log("End Date ", edate)
            this.setState({
              sliderRouteTranslate: "slider-translate-50p",
              showDiv: "show-m",
              mapinfo: records,
              dealer: e.consignee_code,
              consignment_code: "Consignment : " + e.consignment_code,
              maptruckno: e.truck_no,
              routeTruck: {
                truck_no: e.truck_no,
                startTime: sdate,
                endTime: edate,
              },
              loadshow: "show-n",
              sidebarSubHeader: "Consignment Information",
              overly: "show-m",
              rownode: e,
              leg_no: 0,
            });
            this.onShowGoogleRoute(
              e.consignment_code,
              e.consignee_code,
              0,
              e.truck_no
            );
            //this.renderMap();
          }
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  onShowNoGPSRouteMap(rownode) {
    console.log("rownode", rownode);
    this.setState({
      loadshow: "show-m",
    });
    // var fintance = rownode.last_packet_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
    // var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();

    var fintance = getHyphenYYYYMMDDHHMMSS(rownode.last_packet_datetime);
    var eintance = getHyphenYYYYMMDDHHMMSS(rownode.packet_received_on);

    var params = {
      truck_no: rownode.truck_no,
      consignment_code: rownode.consignment_code,
      //consignee_code:rownode.consignee_code,
      first_instance: fintance,
      recent_instance: eintance,
      screen: "consignment",
    };
    redirectURL
      .post("/tms/nightdriveroutes", params)
      .then((response) => {
        //var records = JSON.parse(JSON.stringify(response)).data;
        //console.log("Inner Grid ",response.data);
        if (response.data.coords.length == 0) {
          this.setState({
            show: true,
            basicTitle: "No Route data available",
            basicType: "danger",
            loadshow: "show-n",
          });
        } else {
          var sdate = "";
          var edate = "";

          if (
            rownode.last_packet_datetime != "" &&
            rownode.last_packet_datetime != undefined
          ) {
            sdate = getHyphenYYYYMMDDHHMMSS(rownode.last_packet_datetime);
          }
          if (
            rownode.packet_received_on != "" &&
            rownode.packet_received_on != undefined
          ) {
            edate = getHyphenYYYYMMDDHHMMSS(rownode.packet_received_on);
          }
          this.setState({
            mapinfo: response.data,
            dealer: rownode.consignee_code,
            sliderRouteTranslate: "slider-translate-50p",
            defTransitCoords: rownode.consignee_coordinates,
            consignment_code: "Consignment : " + rownode.truck_no,
            maptruckno: rownode.truck_no,
            routeTruck: {
              truck_no: rownode.truck_no,
              startTime: sdate,
              endTime: edate,
            },
            loadshow: "show-n",
            sidebarSubHeader: "Consignment Leg Information",
            overly: "show-m",
            rownode: rownode,
            leg_no: rownode.leg_no,
          });

          this.onShowGoogleRoute(
            rownode.consignment_code,
            rownode.consignee_code,
            rownode.leg_no,
            rownode.truck_no
          );
          //this.renderMap();
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  onClickSendEmail(data) {
    console.log("Ticket Data ", data);
    this.setState({
      sliderTicketTranslate: "slider-translate-40p",
      showDiv: "show-m",
      loadshow: "show-n",
      sidebarSubHeader: "Consignment Ticket Details",
      overly: "show-m",
      rownode: data,
      commentTickets: [data.data.ticket_id],
    });
    //console.log("EMail ",data)
  }

  onShowTicketsMap(rowdata) {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    var truck_no = rowdata.truck_no;
    // if(rowdata.ticket_category == "slow_trucks")
    // {
    var gate_out_time = getHyphenYYYYMMDDHHMMSS(rowdata.exception_start_time);
    // }
    // else
    // {
    // 	var gate_out_time = getHyphenYYYYMMDDHHMMSS(rowdata.gate_out_invoice_time)
    // }
    // var current_time = moment.parseZone().format("YYYY-MM-DD");
    // current_time = current_time.toString() + " 23:59:59";
    var end_time = getHyphenYYYYMMDDHHMMSS(rowdata.exception_end_time)
    var formdata = {
      truck_no: truck_no,
      gate_out_time: gate_out_time,
      seconddate: end_time,
    };
    console.log(formdata,"formdata")
    console.log(
      rowdata.consignment_code,
      rowdata.consignee_code,
      0,
      rowdata.truck_no
    );
    this.onShowGoogleRoute(
      rowdata.consignment_code,
      rowdata.consignee_code,
      0,
      rowdata.truck_no
    );
    redirectURL.post("/consignments/maproutes", formdata).then((response) => {
      try {
        if (response.data.coords.length) {
          this.setState({
            sliderTicketMapRoute: "slider-translate-60p",
            mapinfo: response.data,
            rownode: rowdata,
            loadshow: "show-n",
            overly: "show-m",
          });
        } else {
          this.setState({
            basicTitle: "No Route Data Found",
            basicType: "warning",
            loadshow: "show-n",
            overly: "show-n",
            show: true,
          });
        }
      } catch (e) {
        this.setState({
          basicTitle: "No Route Data Found",
          basicType: "warning",
          loadshow: "show-n",
          overly: "show-n",
          show: true,
        });
      }
    });
  }

  onShowTicketDetails = (e) => {
    if (googleAnalytics.page.enableGA) {
      let pageTitle = this.state.pagetitle;
      let eventOptions = {
        category: pageTitle,
        action: this.state.eventGridAction,
        label: googleAnalytics.page.action.viewRoute,
      };
      googleAnalytics.logEvent(eventOptions);
    }
    console.log("IN  onShowTicketDetails, e = ", e);
    if (e.transit_time > 0) {
      var seconddate = new Date(
        new Date(e.exception_start_time).getTime() +
          (e.transit_time + 1) * 24 * 60 * 60 * 1000
      );
    } else {
      var seconddate = new Date(
        new Date(e.exception_start_time).getTime() + 2 * 24 * 60 * 60 * 1000
      );
    }
    var sdate =
      seconddate.getFullYear() +
      "-" +
      (seconddate.getMonth() + 1) +
      "-" +
      seconddate.getDate() +
      " " +
      seconddate.getHours() +
      ":" +
      seconddate.getMinutes() +
      ":" +
      seconddate.getSeconds();
    var edate =
      seconddate.getFullYear() +
      "-" +
      (seconddate.getMonth() + 1) +
      "-" +
      seconddate.getDate() +
      " " +
      seconddate.getHours() +
      ":" +
      seconddate.getMinutes() +
      ":" +
      seconddate.getSeconds();

    //sliderTicketDetails
    this.setState({
      sliderTicketDetails: "slider-translate-60p",
      showDiv: "show-m",
      //mapinfo:records,
      mapinfo: [],
      dealer: e.consignee_code,
      consignment_code: "Consignment : " + e.consignment_code,
      maptruckno: e.truck_no,
      routeTruck: { truck_no: e.truck_no, startTime: sdate, endTime: edate },
      loadshow: "show-n",
      sidebarSubHeader: "Consignment Ticket Details",
      overly: "show-m",
      rownode: e,
      leg_no: 0,
    });
  };

  onShowGoogleRoute(consignmentcode, consigneecode, legno, truck_no) {
    //console.log("GOogle params ", consignmentcode+", "+ consigneecode+", "+legno)
    var reqData = {
      consignment_code: consignmentcode,
      consignee_code: consigneecode,
      leg_no: legno,
      truck_no: truck_no,
    };
    redirectURL
      .post("/consignments/googlemaproutes", reqData, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((response) => {
        //console.log("Google ", response)
        //var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]'
        this.setState({
          googelRoutes: response.data.googleroute,
        });
      })
      .catch(function(e) {
        console.log(e);
      });
  }

  // getFilterData = (e) => {
  //   e.preventDefault();
  //   if (this.state.fromDate && this.state.toDate) {
  //   } else {
  //     this.setState({
  //       show: true,
  //       basicType: "danger",
  //       basicTitle: "Dates shouldn't be empty",
  //     });
  //   }
  // };

  onShowDeptData = async () => {
		var records=[];
		this.setState({
			loadshow:'show-m'
		});
		//console.log("this.state.dept_code.value ", this.state.dept_code.value);
		// if(this.state.dept_code.value == 'all')
		// {
		// 	this.gridApi.setRowData(this.state.originalData);
		// 	this.gridApi.onFilterChanged();

		// 	records=this.state.originalData;
		// 	console.log("records ", records)
		// 	var openarr=[];
		// 		var workinprogressarr=[];
		// 		var closedarr=[];
		// 		var onholdarr=[];
		// 		var autoclosedarr=[];
		// 		var invalidarr=[];
				

		// 		var transitarr=[];
		// 		var gpsarr=[];
		// 		var overspeedarr=[];
		// 		var nightdrivearr=[];
		// 		var enroutearr=[];
		// 		var loadingarr=[];
		// 		var unloadingarr=[];
		// 		if(records.length > 0)
		// 		{
		// 			records.map((item) => {
		// 				if(item.ticket_status == 1)
		// 				{
		// 					openarr.push(item)
		// 				}
		// 				if(item.ticket_status == 2)
		// 				{
		// 					workinprogressarr.push(item)
		// 				}
		// 				if(item.ticket_status == 3)
		// 				{
		// 					closedarr.push(item)
		// 				}
		// 				if(item.ticket_status == 4)
		// 				{
		// 					onholdarr.push(item)
		// 				}
		// 				if(item.ticket_status == 5)
		// 				{
		// 					autoclosedarr.push(item)
		// 				}
		// 				if(item.ticket_status == 6)
		// 				{
		// 					invalidarr.push(item)
		// 				}
						
		// 				if(item.ticket_type == 'transit_delay')
		// 				{
		// 					transitarr.push(item)
		// 				}
						
		// 				if(item.ticket_type == 'no_gps')
		// 				{
		// 					gpsarr.push(item)
		// 				}
						
		// 				if(item.ticket_type == 'overspeeding')
		// 				{
		// 					overspeedarr.push(item)
		// 				}
		// 				if(item.ticket_type == 'enroute_stoppage')
		// 				{
		// 					enroutearr.push(item)
		// 				}
		// 				if(item.ticket_type == 'night_driving')
		// 				{
		// 					nightdrivearr.push(item)
		// 				}
		// 				if(item.ticket_type == 'loading_delay')
		// 				{
		// 					loadingarr.push(item)
		// 				}
		// 				if(item.ticket_type == 'unloading_delay')
		// 				{
		// 					unloadingarr.push(item)
		// 				}
		// 			});
		// 		}

		// 		this.setState({
		// 			rowData:records,
		// 			totaltypes:records.length,
		// 			opens:openarr.length,
		// 			workinprogresses:workinprogressarr.length,
		// 			closeds:closedarr.length,
		// 			onholds:onholdarr.length,
		// 			autocloseds:autoclosedarr.length,
		// 			invalids:invalidarr.length,
		// 			transit:transitarr.length,
		// 			gps:gpsarr.length,
		// 			overspeed:overspeedarr.length,
		// 			enroute:enroutearr.length,
		// 			nightdrive:nightdrivearr.length,
		// 			loading:loadingarr.length,
		// 			unloading:unloadingarr.length
		// 		});

		// }
		// else{
			
			if(this.state.fromDate == '' || this.state.toDate == '')
			{
				var actiontype = 0;
			}
			else
			{
				actiontype=1;
			}
			if(this.state.dept_code == 'all')
			{
				var selectdept = 'all';
			}
			else
			{
				var selectdept = this.state.dept_code.value;
			}
			if(this.state.fromDate == '' || this.state.toDate == "NaN-NaN-NaN")
			{
				var sdate = this.state.defaultsdate;
			}
			else{
				var sdate = this.state.fromDate;
			}

			if(this.state.toDate == '' || this.state.toDate == "NaN-NaN-NaN")
			{
				var edate = this.state.defaultedate;
			}
			else{
				var edate = this.state.toDate;
			}
			var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
      var reqparms = {};
      if(this.state.inBoundBtn === "btn-info"){
          reqparms = {
          startDate:sdate,
          endDate:edate,
          dept_code:encode(selectdept),
          //actiontype:actiontype
        }
      } else if(this.state.inPlantBtn === "btn-info"){
          reqparms = {
          startDate:sdate,
          endDate:edate,
          dept_code:encode(selectdept),
          in_plant_vehicle: 1
          //actiontype:actiontype
        }
      }
			
			var urlpath='';
			// if(this.props.match.path == "/ticketmanagementsystem")
			// {
			// 	urlpath = '/tms/departementtickets';
				
			// }
			
			// if(this.props.match.path == "/closetickets")
			// {
			// 	urlpath = '/tms/departmentclosetickets';
				
			// }
			// if(this.props.match.path == "/invalidtickets")
			// {
			// 	urlpath = '/tms/departmentinvalidtickets';
				
			// }

			if(this.props.match.path == "/ticketmanagementsystem")
			{
        // console.log("this.props.match.path for getDate",this.props.match.path)
				urlpath = '/tms';
        console.log(reqparms, "reqparms 1502")
			}
			
			if(this.props.match.path == "/closetickets")
			{
				urlpath = '/tms/closetickets';
				
			}
			if(this.props.match.path == "/invalidtickets")
			{
				urlpath = '/tms/invalidtickets';
				
			}
			
			this.onLoadShowTMSData(urlpath, reqparms)



			
			// redirectURL.post(urlpath, reqparms)
			// .then((response) => {
			// 	console.log("departementtickets ", response.data)
			// 	var records = response.data.records;

			// 	console.log("records dept ", records)

			// 	var openarr=[];
			// 	var workinprogressarr=[];
			// 	var closedarr=[];
			// 	var onholdarr=[];
			// 	var autoclosedarr=[];
			// 	var invalidarr=[];
			// 	var closenoresponsearr=[];
			// 	var forceclosearr=[];
				

			// 	var transitarr=[];
			// 	var gpsarr=[];
			// 	var overspeedarr=[];
			// 	var nightdrivearr=[];
			// 	var enroutearr=[];
			// 	var loadingarr=[];
			// 	var unloadingarr=[];
			// 	var transporterarr = [];
			// 	if(records.length > 0)
			// 	{
			// 		records.map((item) => {
			// 			if(item.ticket_status == 1)
			// 			{
			// 				openarr.push(item)
			// 			}
			// 			if(item.ticket_status == 2)
			// 			{
			// 				workinprogressarr.push(item)
			// 			}

			// 			if(item.ticket_status == 2 && item.transporter_commented == 1)
			// 			{
			// 				transporterarr.push(item)
			// 			}
			// 			if(item.ticket_status == 3)
			// 			{
			// 				closedarr.push(item)
			// 			}
			// 			if(item.ticket_status == 4)
			// 			{
			// 				onholdarr.push(item)
			// 			}
			// 			if(item.ticket_status == 5)
			// 			{
			// 				autoclosedarr.push(item)
			// 			}
			// 			if(item.ticket_status == 6)
			// 			{
			// 				invalidarr.push(item)
			// 			}
			// 			if(item.ticket_status == 7)
			// 			{
			// 				closenoresponsearr.push(item)
			// 			}

			// 			if(item.ticket_status == 8)
			// 			{
			// 				forceclosearr.push(item)
			// 			}
			// 			if(item.ticket_type == 'transit_delay')
			// 			{
			// 				transitarr.push(item)
			// 			}
						
			// 			if(item.ticket_type == 'no_gps')
			// 			{
			// 				gpsarr.push(item)
			// 			}
						
			// 			if(item.ticket_type == 'overspeeding')
			// 			{
			// 				overspeedarr.push(item)
			// 			}
			// 			if(item.ticket_type == 'enroute_stoppage')
			// 			{
			// 				enroutearr.push(item)
			// 			}
			// 			if(item.ticket_type == 'night_driving')
			// 			{
			// 				nightdrivearr.push(item)
			// 			}
			// 			if(item.ticket_type == 'loading_delay')
			// 			{
			// 				loadingarr.push(item)
			// 			}
			// 			if(item.ticket_type == 'unloading_delay')
			// 			{
			// 				unloadingarr.push(item)
			// 			}
			// 		});
			// 	}

			// 	this.setState({
			// 		rowData:records,
			// 		totaltypes:records.length,
			// 		opens:openarr.length,
			// 		workinprogresses:workinprogressarr.length,
			// 		transporter:transporterarr.length,
			// 		closeds:closedarr.length,
			// 		onholds:onholdarr.length,
			// 		autocloseds:autoclosedarr.length,
			// 		invalids:invalidarr.length,
			// 		closenoresponse:closenoresponsearr.length,
			// 		forceclosed:forceclosearr.length,
			// 		transit:transitarr.length,
			// 		gps:gpsarr.length,
			// 		overspeed:overspeedarr.length,
			// 		enroute:enroutearr.length,
			// 		nightdrive:nightdrivearr.length,
			// 		loading:loadingarr.length,
			// 		unloading:unloadingarr.length,
			// 		loadshow:'show-n'
			// 	});

			// })
			// .catch(function(e){
			// 	console.log("Error ",e)
			// });
	
			// console.log("this.state.dept_code.value ", this.state.dept_code.value);
			// var filterComponent = this.gridApi.getFilterInstance("dept_code");
			// filterComponent.selectNothing();
			// filterComponent.selectValue(this.state.dept_code.value);
			// filterComponent.applyModel();
			// let isFilterDataAvailable = this.state.originalData.filter(rec => {
			// 	if (rec["dept_code"]) {
			// 		return rec["dept_code"] == this.state.dept_code.value;
			// 	}
			// });
			// console.log("isFilterDataAvailable ", isFilterDataAvailable)
			// this.gridApi.setRowData(isFilterDataAvailable);
			// this.gridApi.onFilterChanged();
			
			// records=isFilterDataAvailable;
		//}
	
	}

  handleFromDate = (event) => {
    let changedDate = moment(event._d).format("YYYY-MM-DD");
    if (this.state.toDate) {
      if (moment(changedDate).isAfter(moment(this.state.toDate))) {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "From Date shouldn't be lesser than To Date",
          fromDate: moment(this.state.toDate)
            .subtract(1, "days")
            .format("YYYY-MM-DD"),
        });
      } else {
        this.setState({
          fromDate: changedDate,
        });
      }
    } else {
      this.setState({
        fromDate: changedDate,
      });
    }
  };

  handleToDate = (event) => {
    let changedDate = moment(event._d).format("YYYY-MM-DD");
    if (this.state.fromDate) {
      if (moment(changedDate).isBefore(moment(this.state.fromDate))) {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "To Date shouldn't be lesser than From Date",
          toDate: moment(this.state.fromDate)
            .add(1, "days")
            .format("YYYY-MM-DD"),
        });
      } else {
        this.setState({
          toDate: changedDate,
        });
      }
    } else {
      this.setState({
        toDate: changedDate,
      });
    }
  };

  newFilter = (filterType, selOption) => {
    console.log("filterType", filterType);
    // console.log("manualEscalationsArray", this.state.manualEscalationsArray);
    console.log("selOption", selOption);
    this.setState({ [filterType]: selOption }, () => {
      if (filterType == "changedTicketType") {
        console.log("openChangeTicketModal", this.state.selectedRowData);
        var escalatinOptions = [];
        // for(let )
        this.state.manualEscalationsArray.forEach((each) => {
          let {
            l1_group,
            l2_group,
            l3_group,
            ticket_type,
            team_category,
          } = each;
          var regexPattern = new RegExp("\\b" + team_category + "\\b", "i");

          if (
            ticket_type === selOption.value &&
            this.state.teamType.match(regexPattern)
          ) {
            if (l1_group.length > 0) {
              escalatinOptions.push({ label: "L1", value: "L1" });
            }

            if (l2_group.length > 0) {
              escalatinOptions.push({ label: "L2", value: "L2" });
            }

            if (l3_group.length > 0) {
              escalatinOptions.push({ label: "L3", value: "L3" });
            }
          }
        });

        this.setState({
          escalationLevels: escalatinOptions,
          changedEscalationLevel: "",
        });
      }
    });
  };

  onClickViewMailItem = (params) => {
    console.log("params ", params);

    var reqparams = {
      ticketemailid: params._id,
      ticketnumbers: params.ticket_numbers,
    };
    redirectURL.post("/tms/viewticketemaildata", reqparams).then((response) => {
      // console.log("Draft ", response.data)
      var records = response.data.mails;
      let toEmails = records[0].toEmail;
      toEmails = toEmails.filter(Boolean);

      let ccEmails = records[0].ccEMail;
      ccEmails = ccEmails.filter(Boolean);

      this.setState(
        {
          emailformat: records,
          // toEmail: records[0].toEmail.includes('')? "" :records[0].toEmail.join(' ; ') ,
          toEmail: toEmails.join(" ; "),
          // ccEmail: records[0].ccEMail.includes('') ? "": records[0].ccEMail.join(' ; '),
          ccEmail: ccEmails.join(" ; "),
          ticketEmailContent: records[0].body,
          ticketSubject: records[0].subject,
          ticketMailID: records[0]._id,
          ticketslist: records[0].ticket_numbers,
          showdiscard: "show-m",
          viewcols: "col-xl-3 col-md-6",
          viewshow: "show-m",
          mailresponse: response.data.mailresponse,
        },
        () => {
          console.log("cc", this.state.ccEMail);
          console.log("to", this.state.toEmail);
        }
      );
    });
  };

  onClickHideAll() {
    console.log("sliderTicket", this.state.sliderTicketTranslate);
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      sliderRouteTranslate: "",
      sliderTicketMapRoute: "",
      sliderTicketTranslate: "",
      tabsliderTranslate: "",
      inboxSlider: "",
      bulkslide: "",
      viewmailSlider: "",
      viewcols: "col-xl-12 col-lg-12",
      viewshow: "show-n",
      transportcont: "",
    });
  }

  // onClickCounterShowData = (params) => {
  //   console.log("params", params);
  //   this.setState({
  //     gridDataTitle: `${params.slice(0, 1).toUpperCase()}${params.slice(1)}`,
  //   });
  // };

  onCloseRouteDiv = () => {
    console.log("sliderroutetranslate", this.state.sliderTicketMapRoute);
    this.setState({
      uploadDivWidth: "0%",
      sliderRouteTranslate: "",
      sliderTicketMapRoute: "",
      showDiv: "show-n",
      uploadFile: "",
      file: "",
      csvmessage: "",
      csverrmessage: "",
      overly: "show-n",
    });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;

    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;

    //    ChartMenu.prototype.saveChart = function (e) {
    // 	   var event = {
    // 	      data: this.gridOptionsWrapper.getApi(),
    // 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
    // 	    };
    // 	   //console.log("Testing", event);
    // 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
    // 	   this.dispatchEvent(event);
    // 	   var chrtdata = event.data.chartService.rangeController.cellRanges;

    //    };
  };

  onGridState = () => {
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns,
     * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();

    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
  };

  formHandler(event) {
    event.preventDefault();
    //console.log("event.target = ", event.target);
    //console.log("event = ", event);

    var data = new FormData();
    // data.append('com_code',this.state.com_code)
    if (this.state.ticket_status_code) {
      data.append("ticket_status_code", this.state.ticket_status_code.label);
      data.append("ticket_comment", this.state.ticket_comment);

      var formdata = {
        ticket_id: this.state.commentTickets,
        ticket_category:
          this.state.commentTicketsCategory == "Manual"
            ? "Manual"
            : "Automatic",
        userId: localStorage.getItem("userid"),
        // communication_type:this.state.com_code,
        ticket_status: this.state.ticket_status_code,
        // satisfactory:this.state.satisfactory,
        // reponsecomment:this.state.reponsecomment,
        ticket_comment: this.state.ticket_comment,
        // transporterresponse:transporterresponse
      };
      //console.log("data = ", data);
      // console.log("Form data ",data);
      redirectURL
        .post("/tms/saveticketcomment", formdata)
        .then((response) => {
          this.componentDidMount();
          this.setState({
            formMessage: response.data.message,
            formStatus: response.data.status,
            alertFade: false,
            com_code: "",
            ticket_status_code: "",
            ticket_comment: "",
            commentTickets: "",
            commentTicketsCategory: "",
            reponsecomment: "",
            satisfactory: "",
            transporterresponse: "",
            sliderTicketTranslate: "",
            overly: "show-n",
            loadshow: "show-n",
          });
          // this.gridApi.deselectAll();
        })
        .catch((error) => {
          console.log(error);
        });
      // if(this.state.ticket_status_code.label == 'Closed' && this.state.satisfactory == '')
      // {
      // 	this.setState({
      // 		show: true,
      // 		basicTitle:"Satisfactory field should not be empty when status is close or closed no response",
      // 		basicType:"danger"
      // 	});
      // }
      // else{
      // 	var transporterresponse="No";
      // 	if(this.state.transporterresponse == '')
      // 	{
      // 		transporterresponse="No";
      // 	}
      // 	else
      // 	{
      // 		transporterresponse="Yes";
      // 	}

      // }
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Status option is not selected",
      });
    }
  }

  onClickCounterShowData(params) {
    console.log("Counter Params: ", params);
    let filterInstance = null;
    let filterInstance2 = null;
    let filterActivated = false;
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();

    filterInstance = this.gridApi.getFilterInstance("ticket_status");
    filterActivated = true;

    //console.log("filterActivated ",filterActivated)
    if (filterActivated) {
      let isFilterDataAvailable = this.state.rowData.filter((rec) => {
        if (rec["ticket_status"]) {
          if (params == "open") {
            return rec["ticket_status"] == 1;
          }
          if (params == "workinprogress") {
            return rec["ticket_status"] == 2;
          }

          if (params == "transporterreponse") {
            return (
              rec["ticket_status"] == 2 && rec["transporter_commented"] == 1
            );
          }
          if (params == "closed") {
            return rec["ticket_status"] == 3;
          }
          if (params == "onhold") {
            return rec["ticket_status"] == 4;
          }
          if (params == "autoclosed") {
            return rec["ticket_status"] == 5;
          }
          if (params == "invalid") {
            return rec["ticket_status"] == 6;
          }
          if (params == "closenoresponse") {
            return rec["ticket_status"] == 7;
          }
          if (params == "forceclosed") {
            return rec["ticket_status"] == 8;
          }
          if (params == "all") {
            return rec["ticket_status"] == 1 || rec["ticket_status"] == 2 || rec["ticket_status"] == 4;
          }
        }
      });
      if (isFilterDataAvailable.length) {
        //filterInstance.selectNothing();
        // if(params == 'open')
        // {
        //     filterInstance.selectValue(1);
        // }
        // if(params == 'workinprogress')
        // {
        //     filterInstance.selectValue(2);
        // }
        // if(params == 'closed')
        // {
        //     filterInstance.selectValue(3);
        // }
        // if(params == 'onhold')
        // {
        //     filterInstance.selectValue(4);
        // }
        // if(params == 'autoclosed')
        // {
        //     filterInstance.selectValue(5);
        // }
        // if(params == 'invalid')
        // {
        //     filterInstance.selectValue(6);
        // }
        // if(params == 'closenoresponse')
        // {
        //     filterInstance.selectValue(7);
        // }
        // if(params == 'forceclosed')
        // {
        //     filterInstance.selectValue(8);
        // }
        //filterInstance.applyModel();
        this.gridApi.setRowData(isFilterDataAvailable);
        this.gridApi.onFilterChanged();
      } else {
        // filterInstance.selectNothing();
        //filterInstance.selectValue("");
        // filterInstance.applyModel();
        this.gridApi.setRowData([]);
        this.gridApi.onFilterChanged();
      }
    } else {
      this.gridApi.setRowData(this.state.rowData);
      this.gridApi.onFilterChanged();
    }
  }

  onClickTicketTypeCounterShowData(params, ticketCategory) {
    //console.log("Counter Params: ", params);
    let filterInstance = null;
    let filterInstance2 = null;
    let filterActivated = false;
    let gridTitle = "Active";
    try {
      this.gridApi.setFilterModel(null);
      this.gridApi.onFilterChanged();
      this.setState({
        showMailBtn: "show-n",
        showCommentBtn: "show-n",
      });
      filterInstance = this.gridApi.getFilterInstance("ticket_category");
      filterActivated = true;
      // if(params == 'all')
      // {
      // 	filterActivated = false;
      // }
      try {
        //console.log("filterActivated ",filterActivated)
        var isFilterDataAvailable = [];
        if (filterActivated) {
          // if(params != "slow_trucks_data" && params != "ready_to_close_data" && params != "ready_to_close_manual_data")
          if (
            params != "ready_to_close_data" &&
            params != "ready_to_close_manual_data"
          ) {
            console.log(params, "counter type");

            // here now params can be slow_trucks_data, food_stoppages, fuel_stoppage, duel_fuel_stoppage

            isFilterDataAvailable = this.state.rowData.filter((rec) => {
              // if(params == 'transitdelay')
              // {
              // 	return rec["ticket_type"] == "transit_delay";
              // }
              if (params == "overspeeding") {
                gridTitle = "Overspeeding";
                return rec["ticket_category"] == "over_speeding";
              }
              if (params == "enroute") {
                gridTitle = "Enroute Stoppage";
                return rec["ticket_category"] == "enroute_stoppage";
              }
              if (params == "nightdrive") {
                gridTitle = "Night Driving";
                return rec["ticket_category"] == "night_driving";
              }
              if (params == "detour") {
                gridTitle = "Deviation";
                return rec["ticket_category"] == "detour";
              }
              if (params == "shortrest") {
                gridTitle = "No Short Rest";
                return rec["ticket_category"] == "short_rest";
              }
              if (params == "idling") {
                gridTitle = "Idling";
                return rec["ticket_category"] == "idling";
              }
              if (params == "all") {
                gridTitle = "Active";
                return rec["ticket_type"] != "Manual";
              }
            });
            console.log(isFilterDataAvailable, "isFilterDataAvailable");
          } else {
            isFilterDataAvailable = this.state[params];
          }
          console.log(isFilterDataAvailable, "isFilterDataAvailable");
          if (isFilterDataAvailable.length) {
            // filterInstance.selectNothing();

            // if(params == 'transitdelay')
            // {
            // 	filterInstance.selectValue("transit_delay");
            // }
            // if(params == 'nogps')
            // {
            // 	filterInstance.selectValue("no_gps");
            // }
            // if(params == 'overspeeding')
            // {
            // 	filterInstance.selectValue("overspeeding");
            // }
            // if(params == 'enroute')
            // {
            // 	filterInstance.selectValue("enroute_stoppage");
            // }
            // if(params == 'nightdrive')
            // {
            // 	filterInstance.selectValue("night_driving");

            // }
            // if(params == 'loadingdelay')
            // {
            // 	filterInstance.selectValue("loading_delay");
            // }
            // if(params == 'unloadingdelay')
            // {
            // 	filterInstance.selectValue("unloading_delay");
            // }

            // filterInstance.applyModel();
            this.gridApi.setRowData(isFilterDataAvailable);
            this.gridApi.onFilterChanged();
          } else {
            // filterInstance.selectNothing();
            //filterInstance.selectValue("");
            // filterInstance.applyModel();
            this.gridApi.setRowData([]);
            this.gridApi.onFilterChanged();
          }
        } else {
          console.log("Here");
          this.gridApi.setRowData(this.state.rowData);
          this.gridApi.onFilterChanged();
        }
        this.setState({
          gridDataTitle: gridTitle,
        })
      } catch (e) {
        console.log("Counter Filter Error ", e);
      }
    } catch (e) {
      console.log("Counter Filter Error ", e);
    }
  }

  onClickCommentBtn() {
    if (googleAnalytics.page.enableGA) {
      let pageTitle = this.state.pagetitle;
      let eventOptions = {
        category: pageTitle,
        action: this.state.eventGridAction,
        label: "Comment Button",
      };
      googleAnalytics.logEvent(eventOptions);
    }
    var rowCount = this.gridApi.getSelectedNodes();
    //console.log("etype", etype)
    console.log("rowCount ", rowCount);
    if (rowCount.length > 0) {
      let containerslist = [];
      var existdept = [];
      let ticketCategory = [];
      rowCount.map((item) => {
        containerslist.push(item.data.ticket_number);
        ticketCategory.push(item.data.ticket_category);
      });
      console.log("containerslist ", containerslist.length);
      if (containerslist.length == 1) {
        var fdata = {
          tickets: containerslist,
        };
        redirectURL
          .post("/tms/getTicketsHistory", fdata)
          .then((response) => {
            var datahistory = response.data;
            //console.log("response ", datahistory)
            this.setState({
              ticketHistoryLog: datahistory,
            });
            //window.location.reload();
          })
          .catch(function(e) {
            console.log("Error ", e);
          });
        this.setState({
          sliderTicketTranslate: "slider-translate-40p",
          showDiv: "show-m",
          loadshow: "show-n",
          sidebarSubHeader: "Ticket Details",
          overly: "show-m",
          commentTickets: containerslist,
          commentTicketsCategory: ticketCategory,
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Please select one item only",
        });
      }
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Please select item",
      });
    }
  }

  changeHandler(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({
      [name]: value,
    });
  }

  render() {
    const modalStyles = {
      width: "500px !important",
    };
    const { open } = this.state;
    let hideonlyInvlid =
      this.props.match.path == "/invalidtickets" ? true : false;
    let showonlytms = this.state.isinvalidtickets == "" ? false : true;
    var tktHistory = [];
    if (this.state.ticketHistoryLog.length > 0) {
      this.state.ticketHistoryLog.forEach((item) => {
        tktHistory.push({
          username: item.username,
          ticket_comment: item.ticket_comment,
          communication_type: item.communication_type,
          ticket_status: item.ticket_status,
          created_date: item.created_date,
        });
      });
    }
    const columnwithDefs = [
      {
        headerName: "",
        field: "ticket_category",
        width: 40,
        pinned: "left",
        cellRendererSelector: function(params) {
          if (
            params.data.hasOwnProperty("ticket_type") &&
            params.data.ticket_type === "Manual"
          ) {
            var rendComponent = {
              component: "manualActionComponent",
            };
            return rendComponent;
          } else {
            var rendComponent = {
              component: "automaticActionComponent",
            };
            return rendComponent;
          }
        },

        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
      },
      {
        headerName: "",
        field: "",
        width: 50,
        filter: false,
        pinned: "left",
        cellRendererSelector: function(params) {
          var rendComponent = {
            component: "TicketsMap",
          };
          return rendComponent;
        },
      },
      {
        headerName: "",
        field: "ticket_id",
        width: 50,
        pinned: "left",
        filter: false,
        hide: hideonlyInvlid,
        cellRendererSelector: function(params) {
          var rendComponent = {
            component: "invalidTicket",
          };
          return rendComponent;
        },
      },
      {
        headerName: "",
        field: "_id",
        width: 50,
        pinned: "left",
        filter: false,
        cellRendererSelector: function(params) {
          var rendComponent = {
            component: "tickethistory",
          };
          return rendComponent;
        },
      },
      // {
      //     headerName:"",
      //     field:"truck_no",
      //     width:50,
      //     cellRendererSelector:function(params){
      //         var rendComponent = {
      //             component: 'sendemailticket'
      //         };
      //         return rendComponent
      //     }
      // },
      // {
      //   headerName: "",
      //   field: "",
      //   width: 50,
      //   pinned: "left",
      //   filter: false,
      //   hide: showonlytms,
      //   cellRendererSelector: function(params) {
      //     var rendComponent = {
      //       component: "ticketmailview",
      //     };
      //     return rendComponent;
      //   },
      // },
      {
        headerName: "",
        field: "",
        width: "40",
        pinned: "left",
        params: {
          buttonName: "Close Ticket",
          iconName: "fa fa-close",
          gButton: "custom-btn f22 label",
          title: "Close Ticket",
          onClickFunction: this.showCloseTicketModal,
        },
        cellRendererSelector: function(params) {
          // if (params.data.ticket_category == "Manual") {
          var rendComponent = {
            component: "GridButton",
          };
          // } else {
          // var rendComponent = "";
          // }
          return rendComponent;
        },
      },
      //Escalate
      // {
      //   headerName: "",
      //   field: "",
      //   width: "40",
      //   pinned: "left",
      //   params: {
      //     buttonName: "Change Escalation level",
      //     iconName: "fa fa-arrow-up",
      //     gButton: "custom-btn f22 label",
      //     title: "Change Escalation level",
      //     // onClickFunction:this.openEscalateModal.bind(this,params)
      //     onClickFunction: this.openEscalateModal,
      //   },
      //   cellRendererSelector: function(params) {
      //     if (params.data.ticket_category == "Manual") {
      //       var rendComponent = {
      //         component: "EscalateButton",
      //       };
      //     } else {
      //       var rendComponent = "";
      //     }
      //     return rendComponent;
      //   },
      // },

      //change Ticket category
      // {
      //   headerName: "Change Ticket Type",
      //   field: "",
      //   width: "40",
      //   pinned: "left",
      //   params: {
      //     buttonName: "Change Ticket Type",
      //     iconName: "fa fa-toggle-up",
      //     gButton: "custom-btn f22 label",
      //     title: "Change Ticket Type",
      //     // onClickFunction:this.openEscalateModal.bind(this,params)
      //     onClickFunction: this.openChangeTicketModal,
      //   },
      //   cellRendererSelector: function(params) {
      //     var rendComponent = {
      //       component: "changeTicketCategoryButton",
      //     };
      //     return rendComponent;
      //   },
      // },

      // {
      // 	headerName: "Escalate",
      // 	field: "",
      // 	width: 150,
      // 	filter:false,
      // 	floatingFilter:false,
      // 	cellRendererFramework: (params)=>{
      // 		if(params.data.ticket_category == "Manual"){
      // 			return <button style={{backgroundColor:"#00c292",border:"1px solid white",color:"white",cursor:"pointer" ,width:"120px"}} onClick={this.openEscalateModal.bind(this,params)}>Escalate</button>
      // 		}else{
      // 			return ""
      // 		}
      // 	}
      // },

      // escalation level
      // {
      //   headerName: "Escalation",
      //   field: "escalation_level",
      //   width: 120,
      //   sortable: true,
      //   sort: "desc",
      //   valueGetter: (param) => {
      //     if (
      //       param.data.escalation_level == "l1_group" ||
      //       param.data.escalation_level == "L1"
      //     ) {
      //       return "L1";
      //     } else if (
      //       param.data.escalation_level == "l2_group" ||
      //       param.data.escalation_level == "L2"
      //     ) {
      //       return "L2";
      //     } else {
      //       return "L3";
      //     }
      //   },
      // },
      // {
      //   headerName: "Responsible Team",
      //   field: "responsible_team",
      //   width: 120,
      // },
      // coloumn ticket_type
      // {
      //   headerName: "Ticket Type",
      //   field: "ticket_type",
      //   width: 120,
      //   valueGetter: function(params) {
      //     let ticketCat = params.data.ticket_type;
      //     let str = "";
      //     ticketCat == "Manual" ? (str = "Manual") : (str = "Automatic");
      //     return str;
      //   },
      //   cellClass: "capitlize",
      // },
      // Ticket
      {
        headerName: "Ticket",
        field: "ticket_category",
        width: 120,
        valueGetter: function(params) {
          var tickettype = params.data.ticket_category;
          var str = tickettype.replace(/_/g, " ");
          return str;
        },
        cellClass: "capitlize",
      },

      // {
      //     headerName:"Ticket ID",
      //     field:"ticket_id",
      //     width:180,
      // },

      // Ticket No
      {
        headerName: "Ticket No",
        field: "ticket_number",
        width: 120,
      },
      // {
      // 	headerName:"Ready To Close",
      //     field:"ready_to_close",
      // 	width:120,
      // 	valueGetter : function(params)
      // 	{
      // 		if(params.data.ready_to_close == 1)
      // 		{
      // 			return "Yes";
      // 		}
      // 		else
      // 		{
      // 			return "";
      // 		}
      // 	}
      // },

      // Truck No
      {
        headerName: "Truck No",
        field: "truck_no",
        width: 120,
      },
      // Consignment Code
      {
        headerName: "Consignment Code",
        field: "consignment_code",
        width: 120,
      },
      {
        headerName: "Exception Start Time",
        field: "exception_start_time",
        width: 180,
        valueGetter: function(params) {
          if (params.data.hasOwnProperty("exception_start_time")) {
            return getHyphenDDMMMYYYYHHMM(params.data.exception_start_time);
          } else {
            return "";
          }
        },
        comparator: dateComparator,
      },
      {
        headerName: "Exception End Time",
        field: "exception_end_time",
        width: 180,
        valueGetter: function(params) {
          if (params.data.hasOwnProperty("exception_end_time")) {
            return getHyphenDDMMMYYYYHHMM(params.data.exception_end_time);
          } else {
            return "";
          }
        },
        comparator: dateComparator,
      },
      {
        headerName: "Exception Duration (min)",
        field: "total_exception_duration",
        width: 120,
        valueGetter: (param) => {
          return Math.round(param.data.total_exception_duration / 60);
        },
      },
      {
        headerName: "Port In Time",
        field: "port_in_timestamp",
        width: 180,
        valueGetter: function(params) {
          if (params.data.hasOwnProperty("port_in_timestamp")) {
            return getHyphenDDMMMYYYYHHMM(params.data.port_in_timestamp);
          } else {
            return "";
          }
        },
        comparator: dateComparator,
      },
      {
        headerName: "Consignee Code",
        field: "consignee_code",
        width: 120,
      },
      {
        headerName: "Consignee Name",
        field: "consignee_name",
        width: 120,
      },
      {
        headerName: "Transporter",
        field: "transporter_name",
        width: 120,
      },
      {
        headerName: "Driver",
        field: "driver_name",
        width: 120,
      },
      {
        headerName: "Driver Mobile No",
        field: "driver_mobile_no",
        width: 120,
      },
      // {
      //   headerName: "Department",
      //   field: "dept_code",
      //   width: 150,
      // },
      // {
      //   headerName: "Actual LSP User",
      //   field: "actual_lspuser",
      //   width: 150,
      // },
      {
        headerName: "Status",
        field: "ticket_status",
        width: 130,
        valueGetter: function(params) {
          if (params.data.ticket_status == 1) {
            return "Open";
          }
          if (params.data.ticket_status == 2) {
            return "Work in progress";
          }
          if (params.data.ticket_status == 3) {
            return "Closed";
          }
          if (params.data.ticket_status == 4) {
            return "On hold";
          }
          if (params.data.ticket_status == 5) {
            return "Auto closed";
          }
          if (params.data.ticket_status == 6) {
            return "Invalid";
          }
          if (params.data.ticket_status == 7) {
            return "Closed - No response";
          }
          if (params.data.ticket_status == 8) {
            return "Force Closed";
          }
        },
      },
      {
        headerName: "Ticket Details",
        field: "ticket_text",
        width: 320,
      },
      // {
      //   headerName: "Consignee City",
      //   field: "consignee_city",
      //   width: 180,
      //   hide: showonlytms,
      //   valueGetter: function(params) {
      //     if (typeof params.data.consignee_city == undefined) {
      //       return "";
      //     } else {
      //       return params.data.consignee_city;
      //     }
      //   },
      // },
      // {
      //   headerName: "Transit Delay Hours",
      //   field: "transit_delay_hours",
      //   width: 180,
      //   hide: showonlytms,
      //   valueGetter: function(params) {
      //     if (typeof params.data.transit_delay_hours == undefined) {
      //       return "";
      //     } else {
      //       return params.data.transit_delay_hours;
      //     }
      //   },
      // },
      // {
      //   headerName: "Exception Location",
      //   field: "exception_location",
      //   width: 170,
      //   resizable: true,
      // },
      // {
      // 	headerName : "Exception Longitude",
      // 	field : "exception_lng",
      // 	width : 140,
      // 	resizable : true
      // },
      // {
      //   headerName: "Last Known City/Area",
      //   field: "area",
      //   width: 180,
      //   valueGetter: function(params) {
      //     if (params.data.hasOwnProperty("area")) {
      //       return params.data.area;
      //     } else {
      //       return "";
      //     }
      //   },
      // },
      // {
      //   headerName: "Last Known State",
      //   field: "area",
      //   width: 180,
      //   valueGetter: function(params) {
      //     return params.data.hasOwnProperty("state") ? params.data.state : "";
      //   },
      // },
      {
        headerName: "Last Packet Time",
        field: "timestamp",
        width: 180,
        valueGetter: function(params) {
          if (params.data.hasOwnProperty("timestamp")) {
            return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
          } else {
            return "";
          }
        },
        comparator: dateComparator,
      },

      // {
      //   headerName: "Gate Out / Invoice Time",
      //   field: "gate_out_invoice_time",
      //   width: 180,
      //   valueGetter: function(params) {
      //     return getHyphenDDMMMYYYYHHMM(params.data.gate_out_invoice_time);
      //   },
      //   //filter: "agDateColumnFilter",
      //   comparator: dateComparator,
      //   // filterParams: {
      //   // 	browserDatePicker: true,
      //   // 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
      //   // 	// provide comparator function
      //   // 	comparator: function(filteredDate,cellValue,secondDate) {
      //   // 		cellValue = cellValue.replace(/\//g,"-")
      //   // 		if(cellValue != "" && cellValue != " "){
      //   // 			cellValue = cellValue.split(" ")[0].split("-");
      //   // 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
      //   // 			var cellDate = new Date(cellValue);
      //   // 			if(filteredDate.getMonth() < 10){
      //   // 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
      //   // 			}
      //   // 			else{
      //   // 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
      //   // 			}
      //   // 			if(filterValue.split("-")[2].length < 2)
      //   // 			{
      //   // 				var temp = filterValue
      //   // 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
      //   // 			}
      //   // 			var filteredDate = new Date(filterValue);
      //   // 			cellDate = cellDate.getTime();
      //   // 			filteredDate = filteredDate.getTime();
      //   // 			// console.log(cellDate,filteredDate);
      //   // 			if(cellDate === filteredDate)
      //   // 			{
      //   // 				return 0;
      //   // 			}
      //   // 			if(cellDate < filteredDate){
      //   // 				return -1;
      //   // 			}
      //   // 			if(cellDate > filteredDate)
      //   // 			{
      //   // 				return 1;
      //   // 			}
      //   // 		}
      //   // 	}
      //   // },
      // },
      {
        headerName: "Created Date",
        field: "ticket_creation_date",
        width: 180,
        valueGetter: function(params) {
          return getHyphenDDMMMYYYYHHMM(params.data.ticket_creation_date);
        },
        //filter: "agDateColumnFilter",
        comparator: dateComparator,
        // filterParams: {
        // 	browserDatePicker: true,
        // 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
        // 	// provide comparator function
        // 	comparator: function(filteredDate,cellValue,secondDate) {
        // 		cellValue = cellValue.replace(/\//g,"-")
        // 		if(cellValue != "" && cellValue != " "){
        // 			cellValue = cellValue.split(" ")[0].split("-");
        // 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
        // 			var cellDate = new Date(cellValue);
        // 			if(filteredDate.getMonth() < 10){
        // 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
        // 			}
        // 			else{
        // 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
        // 			}
        // 			if(filterValue.split("-")[2].length < 2)
        // 			{
        // 				var temp = filterValue
        // 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
        // 			}
        // 			var filteredDate = new Date(filterValue);
        // 			cellDate = cellDate.getTime();
        // 			filteredDate = filteredDate.getTime();
        // 			// console.log(cellDate,filteredDate);
        // 			if(cellDate === filteredDate)
        // 			{
        // 				return 0;
        // 			}
        // 			if(cellDate < filteredDate){
        // 				return -1;
        // 			}
        // 			if(cellDate > filteredDate)
        // 			{
        // 				return 1;
        // 			}
        // 		}
        // 	}
        // },
      },
      // {
      //   headerName: "Modified Date",
      //   field: "ticket_modification_date",
      //   width: 180,
      //   valueGetter: function(params) {
      //     return getHyphenDDMMMYYYYHHMM(params.data.ticket_modification_date);
      //   },
      //   //filter: "agDateColumnFilter",
      //   comparator: dateComparator,
      //   // filterParams: {
      //   // 	browserDatePicker: true,
      //   // 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
      //   // 	// provide comparator function
      //   // 	comparator: function(filteredDate,cellValue,secondDate) {
      //   // 		cellValue = cellValue.replace(/\//g,"-")
      //   // 		if(cellValue != "" && cellValue != " "){
      //   // 			cellValue = cellValue.split(" ")[0].split("-");
      //   // 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
      //   // 			var cellDate = new Date(cellValue);
      //   // 			if(filteredDate.getMonth() < 10){
      //   // 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
      //   // 			}
      //   // 			else{
      //   // 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
      //   // 			}
      //   // 			if(filterValue.split("-")[2].length < 2)
      //   // 			{
      //   // 				var temp = filterValue
      //   // 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
      //   // 			}
      //   // 			var filteredDate = new Date(filterValue);
      //   // 			cellDate = cellDate.getTime();
      //   // 			filteredDate = filteredDate.getTime();
      //   // 			// console.log(cellDate,filteredDate);
      //   // 			if(cellDate === filteredDate)
      //   // 			{
      //   // 				return 0;
      //   // 			}
      //   // 			if(cellDate < filteredDate){
      //   // 				return -1;
      //   // 			}
      //   // 			if(cellDate > filteredDate)
      //   // 			{
      //   // 				return 1;
      //   // 			}
      //   // 		}
      //   // 	}
      //   // },
      // },
      {
        headerName: "Department Code",
        field: "dept_code",
        width: 140,
        filter: true,
        Routeresizable: true,
    },
    {
        headerName: "Vehicle Type",
        field: "vehicle_type",
        width: 140,
        filter: true,
        Routeresizable: true,
    },
    {
        headerName: "Vehicle Tracking Type",
        field: "vehicle_tracking_type",
        width: 140,
        filter: true,
        Routeresizable: true,
    },
    ];
    return (
      <div className="container-fluid">
        <h5 className="mt-4" style={{ marginBottom:"15px" }}>
          <i className="icofont icofont-ticket cus-i"></i>Ticket
            Management System
        </h5>
        <div className={"dataLoadpage " + this.state.loadshow}></div>
        <div className={"dataLoadpageimg " + this.state.loadshow}>
          <div className="loader-box">
            <div className="loader-box">
              <div className="rotate dashed colored"></div>
            </div>
          </div>
        </div>
        <div
          className={"overlay-part " + this.state.overly}
          onClick={this.onClickHideAll.bind(this)}
        ></div>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        />
        <SweetAlert
          input
          placeHolder="Write something"
          show={this.state.confirmShow}
          showCancel
          confirmBtnText="Yes, Close it!"
          confirmBtnBsStyle="danger"
          title="Enter the closure reason!"
          onConfirm={(response) => this.closeTicketConfiguration(response)}
          onCancel={this.closeAlert}
          focusCancelBtn
        ></SweetAlert>
        { this.props.match.path == "/ticketmanagementsystem" ?  <div className="row" style={{marginBottom:"1%"}}>
				   <div className="col-xl-12 col-lg-12">
              <button onClick={this.inBoundData.bind(this,"inBound")} className={"btn "+this.state.inBoundBtn}>In Bound</button>
              <button onClick={this.inPlantData.bind(this, "inPlant")} className={"btn "+this.state.inPlantBtn}>In Plant</button>
					</div>
				</div> :
          <div className="row" style={{marginBottom:"1%"}}>
            <div className="col-xl-12 col-lg-12">
              <button onClick={this.inBoundData.bind(this,"closeInBound")} className={"btn "+this.state.inBoundBtn}>In Bound</button>
              <button onClick={this.inPlantData.bind(this, "closeInPlant")} className={"btn "+this.state.inPlantBtn}>In Plant</button>
            </div>
          </div>
        } 
       
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-sm-6 mt-3" style={{marginBottom: "-30px"}}>
            <div className="p-3 card shadow">
              {/* Filter card */}

              <div className="col-lg-12 col-xl-12 justify-content-between mx-0 mt-2 row">
                <div className="col-xl-3 col-lg-3 form-group">
                  <label>From Date</label>
                  <Datetime
                    timeFormat={false}
                    value={this.state.fromDate}
                    disableCloseOnClickOutside={false}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "From",
                      name: "fromDate",
                      autoComplete: "off",
                      readOnly: 'true'
                    }}
                    dateFormat="YYYY-MM-DD"
                    name="fromDate"
                    onChange={this.handleFromDate.bind(this)}
                    className="card shadow"
                    required
                  />
                </div>

                <div className="col-xl-3 col-lg-3 form-group">
                  <label>To Date</label>
                  <Datetime
                    timeFormat={false}
                    value={this.state.toDate}
                    disableCloseOnClickOutside={false}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "To",
                      name: "toDate",
                      autoComplete: "off",
                      readOnly: 'true'
                    }}
                    dateFormat="YYYY-MM-DD"
                    name="toDate"
                    onChange={this.handleToDate.bind(this)}
                    className="card shadow"
                    required
                  />
                </div>
                <div className={"col-xl-6 col-lg-6 mt-2 form-group"}>
                  <label htmlFor=""></label>
                  <br />
                  <button
                    type="button"
                    className="btn btn-info shadow"
                    onClick={this.onShowDeptData.bind(this)}
                    disabled={
                      this.state.fromDate && this.state.toDate
                        ? null
                        : "disabled"
                    }
                  >
                    Get Data
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/*Ticket Status*/}
          <div className="col-xl-12 col-lg-12 col-sm-6 " style={{marginBottom: "-30px"}}>
            <div className="card mt-4 shadow">
              <div
                className={
                  "card-body " + (this.state.isclosetickts === 1)
                    ? "p-14px"
                    : ""
                }
                style={{ margiTop: "35px" }}
              >
                <div className="crm-numbers" style={{ padding: "0px" }}>
                  {this.state.isclosetickts === "" &&
                  this.state.isinvalidtickets === "" ? (
                    <div className="row mx-0">
                      <div
                        className="col cpointer pt-26px counterBorder"
                        onClick={this.onClickCounterShowData.bind(this, "open")}
                      >
                        <span className="f13 countClass">Open</span>
                        <h4 className="txt-pink-medium f30 txt-center">
                          <span className="counter">
                            <CountUp end={this.state.opens} />
                          </span>
                        </h4>
                      </div>

                      <div
                        className="col cpointer pt-26px counterBorder"
                        onClick={this.onClickCounterShowData.bind(
                          this,
                          "workinprogress"
                        )}
                      >
                        <span className="f13 countClass">Work in progress</span>
                        <h4 className="f30 txt-center txt-secondary txt-center">
                          <span className="counter">
                            <CountUp end={this.state.workinprogresses} />
                          </span>
                        </h4>
                      </div>

                      <div
                        className="col cirlce-d cpointer pt-26px counterBorder"
                        onClick={this.onClickCounterShowData.bind(
                          this,
                          "onhold"
                        )}
                      >
                        <span className="f13 countClass">On Hold</span>
                        <h4 className="txt-info f30 txt-center">
                          <span className="counter">
                            <CountUp end={this.state.onholds} />
                          </span>
                        </h4>
                      </div>

                      <div
                        className="col cirlce-d cpointer pt-26px"
                        onClick={this.onClickCounterShowData.bind(this, "all")}
                      >
                        <span className="f13 countClass">Total</span>
                        <h4 className="txt-pink-medium f30 txt-center">
                          <span className="counter">
                            <CountUp end={this.state.totalTickets} />
                          </span>
                        </h4>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.isclosetickts === 1 ? (
                    <div className="row pt-15px pl-10px">
                      <div
                        className="col cpointer"
                        onClick={this.onClickCounterShowData.bind(
                          this,
                          "closed"
                        )}
                      >
                        <span className="f13">
                          Closed
                          <br />
                          <br />{" "}
                        </span>
                        <h4 className="txt-pink-medium f30 txt-center">
                          <span className="counter">
                            <CountUp end={this.state.closeds} />
                          </span>
                        </h4>
                      </div>

                      <div
                        className="col cpointer"
                        onClick={this.onClickCounterShowData.bind(
                          this,
                          "autoclosed"
                        )}
                      >
                        <span className="f13">
                          Auto Closed
                          <br />
                          <br />
                        </span>
                        <h4 className="txt-primary f30 txt-center">
                          <span className="counter">
                            <CountUp end={this.state.autocloseds} />
                          </span>
                        </h4>
                      </div>

                      {/* <div
                        className="col cpointer"
                        onClick={this.onClickCounterShowData.bind(
                          this,
                          "closenoresponse"
                        )}
                      >
                        <span className="f12">Closed No Response</span>
                        <h4 className="txt-primary f30 txt-center">
                          <span className="counter">
                            <CountUp end={this.state.closenoresponse} />
                          </span>
                        </h4>
                      </div> */}

                      <div
                        className="col cpointer"
                        onClick={this.onClickCounterShowData.bind(
                          this,
                          "forceclosed"
                        )}
                      >
                        <span className="f13">
                          Force Closed
                          <br />
                          <br />
                        </span>
                        <h4 className="txt-primary f30 txt-center">
                          <span className="counter">
                            <CountUp end={this.state.forceclosed} />
                          </span>
                        </h4>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/*  */}
                  {this.state.isinvalidtickets === 1 ? (
                    <div className="row pt-15px">
                      <div
                        className="col cpointer"
                        onClick={this.onClickCounterShowData.bind(
                          this,
                          "invalid"
                        )}
                      >
                        <span className="f13">Invalid</span>
                        <h4 className="f30 txt-center txt-secondary txt-center">
                          <span className="counter">
                            <CountUp end={this.state.invalids} />
                          </span>
                        </h4>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Counters display */}
          <div className="col-xl-12 col-lg-12 col-sm-6 ">
            <div className="card mt-4 shadow">
              <div className="card-body p-10px">
                <div className="crm-numbers pb-0">
                  <div className="row mx-0">
                    {/* <div className="col-12">
                      <h6>Tickets: </h6>
                    </div> */}

                    {/* Overspeed */}
                    <div
                      className="col cpointer counterBorder"
                      onClick={this.onClickTicketTypeCounterShowData.bind(
                        this,
                        "overspeeding",
                        "automatic"
                      )}
                    >
                      <span className="f13 countClass">Overspeed</span>
                      <h4 className="f30 txt-center txt-danger">
                        <span className="counter">
                          <CountUp end={this.state.overspeed} />
                        </span>
                      </h4>
                    </div>

                    {/* Night Driving */}
                    <div
                      className="col cpointer counterBorder"
                      onClick={this.onClickTicketTypeCounterShowData.bind(
                        this,
                        "nightdrive",
                        "automatic"
                      )}
                    >
                      <span className="f13 countClass">Night Driving</span>
                      <h4 className="f30 txt-center txt-fb">
                        <span className="counter">
                          <CountUp end={this.state.nightdrive} />
                        </span>
                      </h4>
                    </div>

                    {/* Short Rest */}
                    {this.state.inBoundBtn === "btn-info" ?
                      <div
                      className="col cpointer counterBorder"
                      onClick={this.onClickTicketTypeCounterShowData.bind(
                        this,
                        "shortrest",
                        "automatic"
                      )}
                    >
                      <span className="f13 countClass">No Short Rest</span>
                      <h4 className="f30 txt-center txt-primary-dark">
                        <span className="counter">
                          <CountUp end={this.state.shortrest} />
                        </span>
                      </h4>
                      </div>
                    :""}

                    {/* On-Route Stoppage */}
                    {this.state.inBoundBtn === "btn-info" ?<div
                      className="col cpointer counterBorder"
                      onClick={this.onClickTicketTypeCounterShowData.bind(
                        this,
                        "enroute",
                        "automatic"
                      )}
                    >
                      <span className="f13 countClass">Enroute Stoppage</span>
                      <h4 className="f30 txt-center txt-black">
                        <span className="counter">
                          <CountUp end={this.state.enroute} />
                        </span>
                      </h4>
                    </div>
                    :""}

                    {/* Harsh Braking */}
                    {this.state.inBoundBtn === "btn-info" ?<div
                      className="col cpointer counterBorder"
                      onClick={this.onClickCounterShowData.bind(
                        this,
                        "harshBraking"
                      )}
                    >
                      <span className="f13 countClass">Harsh Braking</span>
                      <h4 className="f30 txt-center txt-danger-dark">
                        <span className="counter">
                          <CountUp end={0} />
                        </span>
                      </h4>
                    </div>
                    :""}

                    {/* Detour */}
                    {this.state.inBoundBtn === "btn-info" ?<div
                      className="col cpointer counterBorder"
                      onClick={this.onClickTicketTypeCounterShowData.bind(
                        this,
                        "detour",
                        "automatic"
                      )}
                    >
                      <span className="f13 countClass">Deviation</span>
                      <h4 className="f30 txt-center txt-secondary">
                        <span className="counter">
                          <CountUp end={this.state.detourCount} />
                        </span>
                      </h4>
                    </div>
                    :""}

                    {/* Breakdown */}
                    {/* <div
                      className="col cpointer counterBorder"
                      onClick={this.onClickCounterShowData.bind(
                        this,
                        "breakdown"
                      )}
                    >
                      <span className="f13 countClass">Breakdown</span>
                      <h4 className="f30 txt-center txt-google-plus">
                        <span className="counter">
                          <CountUp end={this.state.breakdownCount} />
                        </span>
                      </h4>
                    </div> */}

                    {/* Idling */}
                    <div
                      className="col cpointer"
                      onClick={this.onClickTicketTypeCounterShowData.bind(this, "idling")}
                    >
                      <span className="f13 countClass">Idling</span>
                      <h4 className="f30 txt-center txt-warning">
                        <span className="counter">
                          <CountUp end={this.state.idlingCount} />
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* AG-Grid */}
          <div className="col-xl-12 col-lg-12 ">
            <div className="card">
              <div className="row card-body pt-15px">
                {this.state.isclosetickts == 1 ? (
                  ""
                ) : (
                  <div className="col-xl-12 col-lg-12">
                    {/* <div className="float-right">
                      <button
                        type="button" 
                        title="Bulk Upload"
                        onClick={this.onShowBulkUpload.bind(this)}
                        className={"btn se-ico btn-success ml-5px "}
                        >
                        Bulk Update
                      </button>
                    </div> */}
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <label style={{ fontSize: "18px", fontWeight: "bold", marginBottom: 0 }}>{`${this.state.gridDataTitle} Tickets`}</label>
                      <button
                        type="button"
                        title="Write Comment"
                        onClick={this.onClickCommentBtn.bind(this)}
                        className={
                          "btn se-ico btn-info ml-5px " +
                          this.state.showCommentBtnb
                        }
                      >
                        Change Status
                      </button>
                    </div>
                    {/* <div className="float-right">
                      <div className="dropdown">
                        <button
                          type="button" 
                          title="Send EMail"
                          onClick={() => this.HandleToggleMore()}
                          className={"btn se-ico btn-warning dropdown-toggle text-muted "+(this.state.showMailBtnb)}
                          id="dropdownMenuButton"
                          data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="false">
                          Send Mail
                        </button>
                        <div className={`dropdown-menu ${this.state.toggleMore?'show':''}`}
                          aria-labelledby="dropdownMenuButton">
                          <a className="dropdown-item" href="#" onClick={this.onClickSendEMails.bind(this,1)}>Level 1</a>
                          <a className="dropdown-item" href="#" onClick={this.onClickSendEMails.bind(this,2)}>Level 2</a>
                          <a className="dropdown-item" href="#" onClick={this.onClickSendEMails.bind(this,3)}>Level 3</a>
                        </div>
                      </div>
                    </div> */}
                    {/* <span className="layoutbtns float-right">
                      <button className="float-right btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                        <i className="icofont icofont-save"></i> Save Grid Layout
                      </button>
                      <button className="float-right btn white btn-info" onClick={this.resetState.bind(this)}>
                        <i className="icofont icofont-refresh"></i> Reset Default Layout
                      </button>
                    </span> */}
                  </div>
                )}
                <div
                  id="myGrid"
                  style={{ height: "550px", width: "100%" }}
                  className="col-xl-12 col-lg-12 ag-theme-balham"
                >
                  <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={true}
                    enableRangeSelection={true}
                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    frameworkComponents={this.state.frameworkComponents}
                    detailCellRendererParams={
                      this.state.detailCellRendererParams
                    }
                    //editType={this.state.editType}
                    stopEditingWhenGridLosesFocus={true}
                    //floatingFilter={true}
                    enableCellChangeFlash={true}
                    suppressCellFlash={true}
                    // rowClassRules={this.state.rowClassRules}
                    //onCellClicked={this.onRowClicked.bind(this)}
                    gridOptions={{
                      context: { componentParent: this },
                    }}
                    masterDetail={true}
                    rowSelection={this.state.rowSelection}
                    //onRowSelected={this.onRowSelected.bind(this)}
                    suppressRowClickSelection={true}
                  />
                </div>
              </div>

              {/* <DashboardGrid 
                onGridState={this.onGridState}
                gridOptions={{
                  context: { componentParent: this }
                }}
                frameworkComponents={this.state.frameworkComponents}
                columnDefs={columnwithDefs}
                rowData={this.state.rowData}
              /> */}
            </div>
          </div>
        </div>
        <Modal open={open} onClose={this.onCloseModal} styles={modalStyles}>
          <div
            className={"row"}
            style={{
              padding: "5px 0px",
              width: "400px",
              overflow: "hidden",
              zIndex: "9999",
            }}
          >
            <form method="post">
              <div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
                <div className="form-group">
                  <label className="col-form-label">Reason:</label>
                  <textarea
                    required
                    id="invalid_reason"
                    className="form-control"
                    rows="6"
                    name="invalid_reason"
                    value={this.state.invalid_reason}
                    onChange={this.changeHandler}
                    placeholder="Enter Reason"
                  />
                </div>
                {/* onBlur="javascript:return CheckForHtmlTag()" */}
              </div>
              <div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-success"
                    name="submit"
                    onClick={this.onClickSetTicketAsInvalid.bind(this)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal>

        {this.state.sliderTicketMapRoute != "" ? (
          <div
            className={"slide-r " + this.state.sliderTicketMapRoute}
            style={{ overflow: "auto", top: "48px" , height: "95vh"}}
          >
            <div className="slide-r-title">
              <h4 style={{ zIndex: 100 }}>
                Map Route
                <span
                  className="float-right closebtn"
                  style={{ marginRight: "25px" }}
                  onClick={this.onCloseRouteDiv.bind(this)}
                >
                  X
                </span>
              </h4>
            </div>
            <div className="slide-r-body" style={{ position: "relative",overflow: "auto" }}>
              {/* <div className="row col-xl-12 col-lg-12 ml-0p n-p-0" style={{ marginBottom:"10px"}}> */}
              <div className="row col-xl-12 col-lg-12" style={{ marginBottom:"10px"}}>
                <span className="layoutbtns pull-right" style={{paddingRight:"5px"}}>
                    {(this.state.mapfor !="show_location") ?
                      <button className="btn btn-warning float-right" style={{marginRight: "10px",padding: "9px 9px",fontSize: "14px"}} onClick={this.toggle}>{(this.state.mapToggle) ? "Back To Map" : "Replay"}</button>
                    :""}
                </span>
              </div>
              {/* <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"> */}
              <div className="row col-xl-12 col-lg-12">
                {(this.state.mapToggle) ? 
                  <RoutePlayPause
                    coords={this.state.mapinfo}
                  />
                  :	<DrawMap
                    context={this}
                    rownode={this.state.rownode}
                    mapFor={"ticket"}
                    googleroutes={this.state.googelRoutes}
                    truckno={this.state.maptruckno}
                    mapinfo={this.state.mapinfo}
                  />
                }
                
                <div className="col-xl-12 col-lg-12 n-p-0">
                  <div className="crm-numbers pb-0">
                    <h3 className="subH">{this.state.sidebarSubHeader}</h3>
                    <div className="col-xl-12 col-lg-12 row">
                      <div className="col route-block">
                        <label className="sidebar-label">Truck No</label>
                        <div>{this.state.rownode.truck_no}</div>
                      </div>
                      <div className="col route-block">
                        <label className="sidebar-label">Start Time</label>
                        <div>
                          {this.state.rownode.exception_start_time != ""
                            ? getHyphenDDMMMYYYYHHMM(
                                this.state.rownode.exception_start_time
                              )
                            : "NA"}
                        </div>
                      </div>
                      <div className="col route-block">
                        <label className="sidebar-label">End Time</label>
                        <div>
                          {moment.parseZone().format("DD-MMM-YYYY HH:MM")}
                        </div>
                      </div>

                      {/* <div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(this.state.mapinfo.route_details != '')?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
											</div>
											{(this.state.leg_no == 0 || this.state.leg_no == null)?	""
											:
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
											</div>
											} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {this.state.sliderTicketTranslate != "" ? (
          <div
            className={"slide-r " + this.state.sliderTicketTranslate}
            style={{ overflow: "auto", top: "48px" }}
          >
            <div className="slide-r-title">
              <h4 style={{ zIndex: 100 }}>
                {this.state.sidebarSubHeader}
                <span
                  className="float-right closebtn"
                  style={{ marginRight: "25px" }}
                  onClick={this.onClickHideAll.bind(this)}
                >
                  X
                </span>
              </h4>
            </div>
            <div className="slide-r-body" style={{ position: "relative" }}>
              <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                {/*Ticket form start*/}

                <div className="crm-numbers pb-0">
                  <div className="col-xl-12 col-lg-12 row">
                    {/* "FORM START" */}
                    <form
                      className="row theme-form"
                      onSubmit={this.formHandler.bind(this)}
                    >
                      {/* <div className="col-md-12 col-sm-6 col-xl-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Communication Type</label>
                                                    <select required className="form-control mb-1" name="com_code" value={this.state.com_code} onChange={this.changeHandler}>
                                                        <option value=''>Select</option>
                                                        {this.communicationTypesDropdownOptions()}
                                                    </select>
                                                </div>
                                            </div> */}

                      {/* <div className="col-md-6 col-sm-6 col-xl-6 col-lg-6 ">
                                                <div className="form-group">
                                                    <label className="col-form-label">Status</label>
                                                    <select required className="form-control mb-1" name="ticket_status_code" value={this.state.ticket_status_code} onChange={this.changeHandler}>
                                                        <option value=''>Select</option>
                                                        {this.ticketStatusTypesDropdownOptions()}
                                                    </select>
                                                </div>
                                            </div> */}

                      <div className="col-md-6 col-sm-6 col-xl-6 col-lg-6 ">
                        <div className="form-group">
                          <label className="col-form-label">Status</label>
                          <Select
                            closeMenuOnSelect={true}
                            name="ticket_status_code"
                            value={this.state.ticket_status_code}
                            className="border-radius-0"
                            onChange={this.newFilter.bind(
                              this,
                              "ticket_status_code"
                            )}
                            style={{ borderRadius: "0px" }}
                            options={this.state.ticketStatusOptions}
                            placeholder={"Select"}
                            required
                          />
                        </div>
                      </div>

                      {/* <div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
                                                <div className="form-group">
                                                    <label className="col-form-label">Quality of Response</label>
													<div>
                                                    	<input type="radio" name="satisfactory" onChange={this.changeHandler} value="Yes" /> Satisfactory
                                                    	&nbsp;&nbsp;<input type="radio" name="satisfactory" onChange={this.changeHandler} value="NO" /> Not Satisfactory
													</div>
												</div>
                                            </div> */}

                      {/* <div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
                                                <div className="form-group">
                                                    <label className="col-form-label">Transporter response</label>
													<div>
                                                    	<input type="radio" name="transporterresponse" onChange={this.changeHandler} value="Yes" /> Yes
                                                    	&nbsp;&nbsp;<input type="radio" name="transporterresponse" onChange={this.changeHandler} value="NO" /> No
													</div>
												</div>
                                            </div> */}
                      {tktHistory.length ? (
                        <div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
                          <div className="form-group">
                            <label className="col-form-label">History:</label>
                            <ul
                              class="crm-activity ch300"
                              style={{ overflowY: "auto" }}
                            >
                              {tktHistory.map((item) => (
                                <li class="media">
                                  <span class="mr-3 font-primary">
                                    {item.username[0]}
                                  </span>
                                  <div class="align-self-center media-body">
                                    <h6 class="mt-0">{item.ticket_comment}</h6>
                                    <ul class="dates">
                                      <li class="digits">
                                        Communication: {item.communication_type}
                                      </li>
                                      <li class="digits">
                                        Created at:{" "}
                                        {getDDMMYYYY(item.created_date)}
                                      </li>
                                      <li class="digits">
                                        By: {item.username}
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
                        <div className="form-group">
                          <label className="col-form-label">Comments</label>
                          <textarea
                            required
                            className="form-control"
                            rows="6"
                            column="10"
                            name="ticket_comment"
                            value={this.state.ticket_comment}
                            onChange={this.changeHandler}
                            placeholder="Please provide details"
                          />
                        </div>
                      </div>

                      {/* <div className="col-md-12 col-sm-12 col-xl-12 col-lg-12 ">
                                                <div className="form-group">
                                                    <label className="col-form-label">Paste Response Email</label>
                                                    <textarea required className="form-control" rows="6" column="10" name="reponsecomment" value={this.state.reponsecomment} onChange={this.changeHandler} placeholder="Please provide details"/>
                                                </div>
                                            </div> */}
                      <div className="form-row col-xl-12 col-sm-12 col-lg-12">
                        <div className="col-sm-6">
                          <button type="submit" className="btn btn-secondary">
                            Save
                          </button>
                        </div>
                      </div>
                    </form>

                    {/* "FORM END" */}
                  </div>
                </div>

                {/*End*/}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div
          className={"slide-r " + this.state.viewmailSlider}
          style={{ overflow: "hidden" }}
        >
          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="container-fluid">
              {/* Inbox Start  */}
              <div className="email-wrap">
                <div className="row">
                  <div
                    className={
                      this.state.viewcols + " " + this.state.showleftmailside
                    }
                  >
                    <div className="email-right-aside">
                      <div className="card email-body">
                        <div className="pr-0 b-r-light">
                          <div className="email-top n-p-0">
                            <div className="row">
                              <div className="col">
                                <h5>View Mails</h5>
                              </div>
                            </div>
                          </div>

                          <div className="inbox">
                            {this.state.emaillist.map((item, index) => (
                              <div
                                className={
                                  "media cpointer brd-top " +
                                  (index % 2 == 0 ? "" : "maileven")
                                }
                                onClick={this.onClickViewMailItem.bind(
                                  this,
                                  item
                                )}
                              >
                                <div className="media-body">
                                  <h6
                                    className="mt-0"
                                    style={{ textAlign: "left" }}
                                  >
                                    {item.subject}
                                  </h6>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      " col-md-12 " +
                      this.state.sendmaildiv +
                      " " +
                      this.state.viewshow
                    }
                  >
                    <div className="email-right-aside">
                      <div className="card email-body radius-left">
                        <div className="pl-0">
                          <TabContent
                            className="tab-content"
                            activeTab={this.state.SelectedTab}
                          >
                            <TabPane tabId="1">
                              <div className="email-top compose-border n-p-0">
                                <div className="row">
                                  <div className="col-sm-12 xl-100">
                                    <h4 className="mb-0">
                                      {this.state.ticketSubject != ""
                                        ? this.state.ticketSubject
                                        : "NA"}
                                    </h4>
                                  </div>
                                </div>
                              </div>
                              <div className="email-compose mb-70p">
                                <div className="email-wrapper mb-60p">
                                  <form className="theme-form">
                                    <div className=" row form-group">
                                      <label
                                        htmlFor="exampleInputEmail1"
                                        className="col-form-label pt-0 col-xl-2 col-lg-2"
                                      >
                                        To
                                      </label>
                                      <div className="col-xl-10 col-lg-10">
                                        {this.state.toEmail != ""
                                          ? this.state.toEmail
                                          : "-"}
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label
                                        htmlFor="exampleInputEmail1"
                                        className="col-form-label pt-0 col-xl-2 col-lg-2"
                                      >
                                        Cc
                                      </label>
                                      <div className="col-xl-10 col-lg-10">
                                        {this.state.ccEmail != ""
                                          ? this.state.ccEmail
                                          : "-"}
                                      </div>
                                    </div>

                                    <div className="form-group mb-0">
                                      <label className="text-muted">
                                        Message
                                      </label>
                                      <div className="col-xl-12 col-lg-12">
                                        <CKEditor
                                          min-height="500px"
                                          config={{
                                            height: "500px",
                                            width: "100%",
                                          }}
                                          activeClass="p10"
                                          content={
                                            this.state.ticketEmailContent
                                          }
                                          events={{
                                            blur: this.onBlur.bind(this),
                                            afterPaste: this.afterPaste.bind(
                                              this
                                            ),
                                            updateContent: this.updateContent.bind(
                                              this
                                            ),
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="form-group mb-0 mt-20p mb-60p">
                                      <label className="text-muted">
                                        Transporter Response:
                                      </label>
                                      {this.state.mailresponse.length > 0
                                        ? this.state.mailresponse.map(
                                            (item, index) => (
                                              <div className="col-xl-12 col-lg-12">
                                                {item.reponsecomment}
                                              </div>
                                            )
                                          )
                                        : "NA"}
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </TabPane>
                          </TabContent>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Inbox End  */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function dateComparator(date1, date2) {
  // console.log("dateComparator");
  // console.log(date1.length);
  if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
    // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
  }
}

function monthToComparableNumber(date) {
  // console.log(date,date.length);
  if (date === undefined || date === null || date.length !== 16) {
    return null;
  }
  var yearNumber = date.substring(6, 10);
  var monthNumber = date.substring(3, 5);
  var dayNumber = date.substring(0, 2);
  // console.log(yearNumber,monthNumber,dayNumber);
  var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
  return result;
}

function getMonthNumber(monthName) {
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var monthNum = months.indexOf(monthName) + 1;
  if (monthNum < 10) {
    monthNum = "0" + monthNum.toString();
  }
  return monthNum;
}
function printResult(res) {
  console.log(res);
  if (res.remove) {
    res.remove.forEach(function(rowNode) {
      console.log("Removed Row Node", rowNode);
    });
  }
}
// funciton checkforhtmltag
function CheckForHtmlTag(reason) {
  var rs = reason;
  var reg = /<(.|\n)*?>/g;
  if (reg.test(rs.value)) {
    var ErrorText = "Oops! HTML or Script is not allowed.";
    rs.value = "";
    //alert();
    return 1;
  }
}
