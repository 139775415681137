/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ComponentIndex from '../common/appendComponents';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import KpiActions from '../manage/kpiActionsComponent';
import KpiDeviationActions from '../manage/kpiDeviationActionsComponent';
import KpiTransitDelayActions from '../manage/kpitrucktransitdelayaction';
import kpiDayWiseDistanceActions from '../manage/kpiDayWiseDistanceActionsComponent';
import RouteMapForDayWiseDistanceTrip from '../manage/routeMapForDayWiseDistanceTrip';
import Nooverspeed from '../manage/nooverspeedComponent.js';
import Notransit from '../manage/notransitComponent.js';
import RoutemapAction from '../manage/routemapActionComponent';
import CommentActions from "../layouts/commentsComponent";
import RoutemapTransitAction from '../manage/routemaptransit';
import RouteNightDrivemap from '../manage/routenightdrivemap';
import KpiNightDriveActions from '../manage/kpiNightDriveActionsComponent';
import Nonightdrive from '../manage/nonightdriveComponent.js';
import KpiEnrouteStoppagesActions from '../manage/kpiEnrouteStoppagesActionsComponent';
import KpiLoadingDelayActions from '../manage/kpiLoadingDelayActions';
import KpiUnLoadingDelayActions from '../manage/kpiUnLoadingDelayActions';
import TruckConsignmentLegs from '../manage/truckConsignmentLegs';
import Noenroutes from '../manage/noenroutesComponent.js';
import Gps from '../manage/gpsActionComponent.js';
import NoGps from '../manage/nogpsActionComponent.js';
import $ from "jquery";
import NoGpsLifetime from '../manage/nogpslifetimeActionComponent.js';

import Modal from 'react-responsive-modal';
import Select from 'react-select';
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import AppendComponentIndex from '../common/appendComponents';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import Counters from './consignmenttruckscountersComponent';
import DateFormater from './dateComponent';
import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenDDMMYYYY
} from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ConsignmentDrawMap from '../common/consignmentdrawmap';
import e from 'jquery-datetimepicker';
var infoBox = require('../common/google-infowindow');

var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
var geoFencingArea;

const decipher = salt => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
	return encoded => encoded.match(/.{1,2}/g)
		.map(hex => parseInt(hex, 16))
		.map(applySaltToChar)
		.map(charCode => String.fromCharCode(charCode))
		.join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class ManageTruckConsignments extends Component {
	constructor(props) {
		super(props);
		this.displayData = [];
		this.state = {
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			eventAction: null,
			allRowData: [],
			originalData: [],
			uploadDivWidth: '0%',
			sliderTranslate: '',
			sliderRouteTranslate: '',
			tabsliderTranslate: '',
			timelineinfo: '',
			consignment_code: '',
			mapinfo: '',
			dealer: '',
			maptruckno: '',
			loadshow: 'show-n',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			overly: 'show-n',
			alerterrshow: 'fade',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			modules: AllModules,
			filterstatess: {},
			columnsdatas: {},
			rowgroupdcols: [],
			valuecolumns: [],
			activeconsignemnt: [],
			activetrucks: [],
			transitdelays: [],
			gpsdatana: [],
			overspeedtrucks: [],
			nightdrive: [],
			pivotmodeis: false,
			sliderCommentTranslate: "",
			commentsRowData: [],
			pivotcols: [],
			defTransitCoords: '',
			routeTruck: {},
			sidebarSubHeader: '',
			slideBar: "",
			truckInfo: "",
			consignments: "",
			currenttrucklocation: "",
			defaultColDef: {
				sortable: true,
				filter: true,
				resizable: true

			},
			rownode: [],
			rowData: null,
			maprowData: null,
			rowSelection: 'multiple',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				consignmentActions: ConsignmentActions,
				commentActions: CommentActions,
				kpiActions: KpiActions,
				kpiDeviationActions: KpiDeviationActions,
				kpiTransitDelayActions: KpiTransitDelayActions,
				nooverspeed: Nooverspeed,
				notransit: Notransit,
				kpiNightDriveActions: KpiNightDriveActions,
				nonightdrive: Nonightdrive,
				kpiEnrouteStoppagesActions: KpiEnrouteStoppagesActions,
				noenroutes: Noenroutes,
				kpiLoadingDelayActions: KpiLoadingDelayActions,
				kpiUnLoadingDelayActions: KpiUnLoadingDelayActions,
				truckConsignmentLegs: TruckConsignmentLegs,
				dateFormater: DateFormater,
				gps: Gps,
				nogps: NoGps,
				noGpsLifetime: NoGpsLifetime,
				kpiDayWiseDistanceActions: kpiDayWiseDistanceActions,
				customLoadingCellRenderer: CustomLoadingCellRenderer
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			triptype: '',
			tripvalue: '',
			consigner: '',
			consigner_coordinates: [],
			consignee_coordinates: [],
			pagecounters: '',
			startDate: '',
			containerslist: [],
			endDate: '',
			defaultsdate: '',
			defaultedate: '',
			tolls: [],
			loginplant: "",
			filterCities: [],
			filterStates: [],
			filterZones: [],
			filterTransporters: [],
			flCity: "",
			flState: "",
			flZone: "",
			flTransporter: "",
			movementtype: { "value": "all", "label": "All" },
			plants: [],
			selectedFilter: []
		}
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		this.onShowTimelineDiv = this.onShowTimelineDiv.bind(this);
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
		this.onShowTransitRouteMap = this.onShowTransitRouteMap.bind(this);
		this.onShowNoGPSRouteMap = this.onShowNoGPSRouteMap.bind(this);
		this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this);
		this.selectConsignerOptionsItems = this.selectConsignerOptionsItems.bind(this);
		this.onClickShowMapView = this.onClickShowMapView.bind(this);
		this.onShowCommentsDiv = this.onShowCommentsDiv.bind(this);
		// var checkerData;
		// if(this.props.data)
		// {
		// 	checkerData="Yes";
		// 	this.onPropsApply(this.props.data);
		// }
		// else{
		// 	checkerData="No";
		// 	this.onPropsApply("testing success");
		// }
	}
	cipher = (salt) => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
		try {
			return text => text.split('')
				.map(textToChars)
				.map(applySaltToChar)
				.map(byteHex)
				.join('');
		}
		// eslint-disable-next-line no-unreachable
		catch (e) { }
	}
	componentDidMount() {
		loadDateTimeScript();
		var loguser = decode(localStorage.getItem("m"));
		// console.log("LOGUSER ", JSON.parse(loguser));
		var loginplant = "";
		try {
			var luser = JSON.parse(loguser);
			loginplant = luser.is_plant_code;
			// console.log("logplant", loginplant)
			this.setState({
				loginplant: loginplant,
				// consigner:[{"value":luser.is_plant_code,"label":luser.is_plant_name}]

			})
		}
		catch (e) {

		}
		var urlpath;
		var dpt;
		var sdate = moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD');
		if (this.props.match.path == "/sndtrucks") {
			urlpath = '/trucks/sndtrucks';
			dpt = 'SNDG';
		}
		else if (this.props.match.path == "/prttrucks") {
			urlpath = '/trucks/prttrucks';
			dpt = 'LOG-PRT';
		}
		else if (this.props.match.path == "/tnptrucks") {
			urlpath = '/trucks/tnptrucks';
			dpt = 'LOG-TNP';
		}
		else {
			urlpath = '/trucks';
			dpt = 'all';
		}
		var edate = moment.parseZone().format('YYYY-MM-DD');
		//var sdate = moment.parseZone().subtract(30, 'days').format('YYYY-MM-DD');

		//console.log("Props ", this.props.match)
		//filters data
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		redirectURL.post("/consignments/plants", { dept_code: encode(dpt) })
			.then((resp) => {
				this.setState({
					plants: resp.data
				});
			})
		redirectURL.post("/consignments/getConsignmentsNewFilterKeysForSndTrucks", { dept_code: encode(dpt) })
			.then((resp) => {
				// console.log("responseforDropDown", resp.data)
				var transporters = resp.data.transportersList;
				var data = resp.data.data
				let tlist = [];
				tlist.push({
					"value": "",
					"label": "All",
				});
				transporters.map((t) => {
					tlist.push({
						"value": t.transporter_code,
						"label": t.transporter_name,
					});
				});
				var cities = data.cities;
				var filterCities = [];
				if (cities.length > 0) {
					filterCities.push({
						label: "All",
						value: "",
					});
					cities.map(function (c) {
						filterCities.push({
							label: c,
							value: c,
						});
					});
				}
				var states = data.states;
				var filterStates = [];
				if (states.length > 0) {
					filterStates.push({
						label: "All",
						value: "",
					});
					states.map(function (s) {
						filterStates.push({
							label: s,
							value: s,
						});
					});
				}
				var zones = data.zones;
				var filterZones = [];
				if (zones.length > 0) {
					filterZones.push({
						label: "All",
						value: "",
					});
					zones.map(function (cl) {
						filterZones.push({
							label: cl,
							value: cl,
						});
					});
				}
				var transporters = data.transporters;
				var filterTransporters = [];
				if (transporters.length > 0) {
					filterTransporters.push({
						label: "All",
						value: ""
					});
					transporters.map(function (t) {
						filterTransporters.push({
							label: t,
							value: t,
						});
					});
				}
				this.setState({
					filterCities: filterCities,
					filterStates: filterStates,
					filterZones: filterZones,
					filterTransporters: tlist,
				});
			})



		this.setState({
			loadshow: 'show-m',
			// startDate: sdate,
			// endDate: edate,
			deptcode: dpt,
			defaultsdate: sdate,
			defaultedate: edate
		});

		var reqparams = {
			dept_code: dpt,
			consigner_code: ["ALL"],
			from_date: "",
			to_date: ""
		}
		var activeconsignemntarr = [];
		var activetrucksarr = [];
		var transitdelaysarr = [];
		var gpsdatanaarr = [];
		var overspeedtrucksarr = [];
		var nightdrivearr = [];
		var alltrucksarr = [];

		try {
			var userrols = localStorage.getItem("roles");
			var splitrole = userrols.split(",")
			if (splitrole.length > 0) {
				if (splitrole.includes("tpt_sndg") == true || splitrole.includes("tpt_prt") == true) {
					reqparams.transporter_codes = localStorage.getItem("transportercode")
				}
			}
		}
		catch (e) {

		}
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({ eventAction: eventAction });
		redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
			.then(async (resp) => {
				await this.setState({
					usergridstate: resp.data
				});
				await this.restoreGridStates();
			})
		this.onLoadShowTrucksList(urlpath, reqparams)
	};

	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}


	onLoadShowTrucksList = (urlpath, parameters) => {
		// console.log("parameters", parameters, "urlpath", urlpath)
		redirectURL.post(urlpath, parameters)
			.then((response) => {
				//console.log(response);
				// console.log("response", response.data);
				let trucksArr = response.data.active_trucks
				if (typeof response.data == 'object') {
					var recordsarr = response.data;
				}
				else {
					var dd = response.data;
					//console.log(JSON.parse(dd))
					var recordsarr = JSON.parse(response.data);
				}
				//console.log("Total records ", recordsarr)
				//console.log("records", typeof recordsarr.active_trucks);
				var records = [];
				// recordsarr.active_trucks.map((item) => {
				// 	//console.log("Item ", item)
				// 	Object.entries(item).forEach(
				// 		([key, value]) => {
				// 			// console.log("item ",value)
				// 			if (value.header_details.transit_delay != undefined && value.header_details.transit_delay != "undefined") {
				// 				var transit_delay = value.header_details.transit_delay;
				// 			}
				// 			else {
				// 				var transit_delay = "";
				// 			}
				// 			if (this.state.loginplant != "" && this.state.loginplant != undefined) {
				// 				if (this.state.loginplant == value.header_details.consigner_code) {
				// 					var row = {
				// 						"truck_no": key,
				// 						"transit_delay": transit_delay,
				// 						"no_gps_data": value.header_details.no_gps_data,
				// 						"no_gps_data_lifetime": value.header_details.no_gps_data_lifetime,
				// 						"unloading_delay_exception": value.header_details.unloading_delay_exception,
				// 						"loading_delay_exception": value.header_details.loading_delay_exception,
				// 						"nightdriving_exception": value.header_details.nightdriving_exception,
				// 						"overspeeding_exception": value.header_details.overspeeding_exception,
				// 						"enroute_exception": value.header_details.enroute_exception,
				// 						"status": value.header_details.status,
				// 						"cluster": value.header_details.cluster,
				// 						"dept_code": value.header_details.dept_code,
				// 						"gate_in_time": value.header_details.gate_in_time,
				// 						"gate_out_time": value.header_details.gate_out_time,
				// 						"invoice_time": value.header_details.invoice_time,
				// 						"consigner_code": value.header_details.consigner_code,
				// 						"vehicle_mode": value.header_details.vehicle_mode,
				// 						"timestamp": value.header_details.timestamp,
				// 						"area": value.header_details.area,
				// 						"state": value.header_details.state,
				// 						"actual_lspuser": value.header_details.actual_lspuser,
				// 						"transporter_name": value.header_details.transporter_name,
				// 						"consigner_coordinates": value.header_details.consigner_coordinates,
				// 						"consignee_coordinates": value.header_details.consignee_coordinates,
				// 						"expected_trip_end": value.header_details.expected_trip_end,
				// 						"revised_trip_end": value.header_details.revised_trip_end,
				// 						"transit_time": value.header_details.transit_time,
				// 						"inside_fence": value.header_details.inside_fence,
				// 						"outside_fence": value.header_details.outside_fence,
				// 						"spoc_contact_name": value.header_details.spoc_contact_name,
				// 						"spoc_contact_number": value.header_details.spoc_contact_number,
				// 						"consignee_code": value.header_details.consignee_code,
				// 						"consignee_city": value.header_details.consignee_city,
				// 						"distance_from_dealer_location": value.header_details.distance_from_dealer_location,
				// 						"invoice_nos": value.header_details.invoice_nos,
				// 						"consignment_codes": value.header_details.consignment_codes,
				// 						"travel_status": value.header_details.travel_status,
				// 						"speed": value.header_details.speed,
				// 						"trip_distance": value.header_details.aprox_dist_travelled_from_start,
				// 						"address": value.header_details.address,
				// 						"leg_details": value.leg_details,
				// 						"zone": value.header_details.zone,
				// 						"distance_in_km": value.header_details.distance_in_km
				// 					}
				// 					records.push(row);
				// 				}
				// 			}
				// 			else {
				// 				var row = {
				// 					"truck_no": key,
				// 					"transit_delay": transit_delay,
				// 					"no_gps_data": value.header_details.no_gps_data,
				// 					"no_gps_data_lifetime": value.header_details.no_gps_data_lifetime,
				// 					"unloading_delay_exception": value.header_details.unloading_delay_exception,
				// 					"loading_delay_exception": value.header_details.loading_delay_exception,
				// 					"nightdriving_exception": value.header_details.nightdriving_exception,
				// 					"overspeeding_exception": value.header_details.overspeeding_exception,
				// 					"enroute_exception": value.header_details.enroute_exception,
				// 					"status": value.header_details.status,
				// 					"cluster": value.header_details.cluster,
				// 					"dept_code": value.header_details.dept_code,
				// 					"gate_in_time": value.header_details.gate_in_time,
				// 					"gate_out_time": value.header_details.gate_out_time,
				// 					"invoice_time": value.header_details.invoice_time,
				// 					"consigner_code": value.header_details.consigner_code,
				// 					"vehicle_mode": value.header_details.vehicle_mode,
				// 					"timestamp": value.header_details.timestamp,
				// 					"area": value.header_details.area,
				// 					"state": value.header_details.state,
				// 					"actual_lspuser": value.header_details.actual_lspuser,
				// 					"transporter_name": value.header_details.transporter_name,
				// 					"consigner_coordinates": value.header_details.consigner_coordinates,
				// 					"consignee_coordinates": value.header_details.consignee_coordinates,
				// 					"expected_trip_end": value.header_details.expected_trip_end,
				// 					"revised_trip_end": value.header_details.revised_trip_end,
				// 					"transit_time": value.header_details.transit_time,
				// 					"inside_fence": value.header_details.inside_fence,
				// 					"outside_fence": value.header_details.outside_fence,
				// 					"spoc_contact_name": value.header_details.spoc_contact_name,
				// 					"spoc_contact_number": value.header_details.spoc_contact_number,
				// 					"consignee_code": value.header_details.consignee_code,
				// 					"consignee_city": value.header_details.consignee_city,
				// 					"distance_from_dealer_location": value.header_details.distance_from_dealer_location,
				// 					"invoice_nos": value.header_details.invoice_nos,
				// 					"consignment_codes": value.header_details.consignment_codes,
				// 					"travel_status": value.header_details.travel_status,
				// 					"speed": value.header_details.speed,
				// 					"trip_distance": value.header_details.aprox_dist_travelled_from_start,
				// 					"address": value.header_details.address,
				// 					"leg_details": value.leg_details,
				// 					"zone": value.header_details.zone,
				// 					"distance_in_km": value.header_details.distance_in_km
				// 				}
				// 				records.push(row);
				// 			}

				// 		})
				// })
				var counters = recordsarr.counters_data;
				// console.log("records ", records)

				records = records.sort(GetSortDescOrder("invoice_time"));
				this.setState({
					rowData: trucksArr,
					allRowData: trucksArr,
					originalData: trucksArr,
					loadshow: 'show-n',
					pagecounters: counters
				});


			})
			.catch(function (error) {
				console.log(error);
			});
	}


	// onPropsApply = (params) =>{
	// 	console.log("Props Params ", params)
	// }
	onShowTimelineDiv = (e) => {
		//console.log("Props data ", e)
		this.setState({
			loadshow: 'show-m'
		});
		var reqData = {
			consignment_code: e.consignment_code
		}
		//console.log("reqData: ", reqData);
		redirectURL.post('/consignments/timeline', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;
				//console.log("records", records);

				this.setState({
					uploadDivWidth: '30%',
					sliderTranslate: "slider-translate-50p",
					showDiv: 'show-m',
					timelinedata: records,
					loadshow: 'show-n',
					overly: 'show-m'
				});
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onCloseUploadDiv = () => {
		// console.log("function call");
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			sideBar: "",
			tabsliderTranslate: '',
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n'
		});
	}
	onShowCommentsDiv = (e) => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Show Comments",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var reqparams = {
			truck_no: e.truck_no,
			dept_code: this.state.deptcode,
			screen: "Active Trucks"
		}
		redirectURL.post("/consignments/getCommentLogs", reqparams).then((response) => {
			// console.log(response.data);
			this.setState({
				commentsRowData: response.data.data
			})
		}).catch(function (error) {
			console.log(error);
		})
		this.setState({
			sliderCommentTranslate: "slider-translate-60p",
			showDiv: 'show-m',
			loadshow: 'show-n',
			overly: 'show-m',
		});
	}
	onShowRouteDiv = (e) => {
		// console.log("e ", e)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var consignmentcodes = []
		//console.log("leg_details ", JSON.parse(e.leg_details))
		var legs = JSON.parse(e.leg_details);
		legs.map((item) => {
			consignmentcodes.push(item.consignment_code)
		})
		// console.log("consignmentcodes ", consignmentcodes)
		this.setState({
			loadshow: 'show-m'
		});
		var edate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss")
		if (e.dept_code == "LOG-PRT") {
			var startdate = getYYYYMMDDHHMMSS(e.gate_out_time)
			let sD = startdate.split(" ")
			let date = sD[0].replaceAll("/", "-")
			startdate = date + " " + sD[1]
			// console.log("startD",startdate)
		}
		else {
			var startdate = getYYYYMMDDHHMMSS(e.invoice_time)
			let sD = startdate.split(" ")
			let date = sD[0].replaceAll("/", "-")
			startdate = date + " " + sD[1]
			// console.log("startD",startdate)

		}
		var reqData = {
			truck_no: e.truck_no,
			consignment_codes: consignmentcodes,
			fromdate: startdate,
			todate: edate
		}
		// console.log("reqData: ", reqData);
		redirectURL.post('/trucks/truckmap', reqData)
			.then((response) => {
				var records = response.data;
				console.log("check ",Array.isArray(records))
				// console.log("Props data ", response.data)
				try{
					if(Array.isArray(records) == false)
					{
						var recs = records.replace(/NaN/g,"0")
						records = JSON.parse(recs)
					}
				}
				catch(e){

				}
				
				// console.log("records list ", records);
				this.setState({
					loadshow: 'show-m'
				});
				// console.log("S1")
				try {
					// console.log("S2")
					// console.log("records.coords ", records.coords)
					if (records.coords.length == 0) {
						// console.log("S3")
						//console.log("records", records.coords);
						this.setState({
							show: true,
							basicTitle: 'No Route data available',
							basicType: "danger",
							loadshow: 'show-n'
						});
					}
					else {
						// console.log("S4")
						// console.log("recordss ", records)
						// if (records != '') {

							this.setState({
								sliderRouteTranslate: "slider-translate-60p",
								showDiv: 'show-m',
								mapinfo: records,
								dealer: records.consignee_coordinates,
								consignment_code: "Truck NO : " + e.truck_no,
								maptruckno: e.truck_no,
								routeTruck: { "truck_no": e.truck_no, "startTime": records.route_details.start_time, "endTime": records.route_details.end_time },
								loadshow: 'show-n',
								sidebarSubHeader: "Truck Route Details",
								overly: 'show-m',
								rownode: e,
								consigner_coordinates: records.consigner_coordinates,
								consignee_coordinates: records.consignee_coordinates,
								tolls: records.tolls

							});
							console.log("s5 ")
							this.renderMap();
						// }
					}
				}
				catch (e) {
					if (records.message == "no data found") {
						this.setState({
							show: true,
							basicTitle: 'No Route data available',
							basicType: "danger",
							loadshow: 'show-n'
						});
					}
				}



			})
			.catch(function (error) {
				console.log(error);
			});
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			showDiv: 'show-n',
			sliderCommentTranslate: "",
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n'
		});
	}

	/*Truck Sidebar Component Starts*/
	async onShowSidebar(e) {
		this.setState({
			tabsliderTranslate: ""
		});
		//console.log(e);
		if (e.colDef.field == 'truck_no') {
			let ComponentInfo = AppendComponentIndex['truck'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				truck_no: e.data[e.colDef.field]
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/masters/truckByNo', layoutdata1)
				.then((response) => {
					//console.log("trucks response", response);
					var truckrecords = JSON.parse(JSON.stringify(response)).data;

					this.setState({
						truckInfo: truckrecords
					});
				})

				.catch(function (error) {
					console.log(error);
				});

			/*Trucks details by Truck No*/
			await redirectURL.post('/masters/latestConsignmentsByTruckNo', layoutdata1)
				.then((response) => {
					//console.log(response);
					var consignrecords = JSON.parse(JSON.stringify(response)).data;

					this.setState({
						consignments: consignrecords
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Trucks details by Truck No*/
			await redirectURL.post('/masters/latestTruckLocation', layoutdata1)
				.then((response) => {
					//console.log("HR55W4880",response);
					var currenttrucklocation = JSON.parse(JSON.stringify(response)).data;

					this.setState({
						currenttrucklocation: currenttrucklocation
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			//console.log("currenttrucklocation",this.state.currenttrucklocation);
			//setTimeout(function(){})

			this.displayData = await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} truckInfo={this.state.truckInfo} consignments={this.state.consignments} currenttrucklocation={this.state.currenttrucklocation}></ComponentInfo>);

			this.setState({
				showdata: this.displayData,
				tabsliderTranslate: "slider-translate"
			});
		}

		/*Transporters Sidebar*/

		if (e.colDef.field == 'transporter_name') {
			let ComponentInfo = AppendComponentIndex['transporter'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				transporter_name: e.data[e.colDef.field]
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/masters/transporterByName', layoutdata1)
				.then((response) => {
					// console.log("transporterByName response", response);
					var transporterrecords = JSON.parse(JSON.stringify(response)).data;
					var deptcodes = [];
					var transportName = '';
					var transportCode = '';
					transporterrecords.map(item => {
						transportName = item.transporter_name;
						transportCode = item.transporter_code;
						deptcodes.push(item.dept_code + ", ");
					});
					//console.log(transportName+","+transportCode+","+deptcodes)
					var transportData = [{
						transporter_name: transportName,
						transporter_code: transportCode,
						dept_code: deptcodes
					}]
					this.setState({
						transporterInfo: transportData
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Total Trucks Operating*/
			await redirectURL.post('/masters/totalTrucksOperating', layoutdata1)
				.then((response) => {
					//console.log("Total Trucks Operating response", response);
					this.setState({
						truckoperates: response
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Total Trucks With GPS Integration Lifetime*/
			await redirectURL.post('/masters/totalTrucksGPSLifetime', layoutdata1)
				.then((response) => {
					// console.log("Total Trucks Operating response", response);
					this.setState({
						truckgpslifetime: response
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Total Trucks With GPS Integration Today*/
			await redirectURL.post('/masters/totalTrucksGPSToday', layoutdata1)
				.then((response) => {
					//console.log("Total Trucks Operating response", response);
					this.setState({
						gpstoday: response
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Transporters Consignment details*/
			await redirectURL.post('/masters/latestConsignmentsByTransporterName', layoutdata1)
				.then((response) => {
					//console.log(response);
					var consignrecords = JSON.parse(JSON.stringify(response)).data;
					var consignrecordslist = [];
					consignrecords.map(item => {
						if (item.consigns.length > 0) {
							item.consigns.map(cons => {
								consignrecordslist.push(cons)
							})

						}
					});

					// console.log("consignrecords list", consignrecordslist);
					this.setState({
						consignments: consignrecordslist
					});
				})
				.catch(function (error) {
					console.log(error);
				});


			this.displayData = await (<ComponentInfo context={this} gpstoday={this.state.gpstoday} gpsLifetime={this.state.truckgpslifetime} transportName={e.data[e.colDef.field]} transporterInfo={this.state.transporterInfo} totalTruckOperating={this.state.truckoperates} consignments={this.state.consignments} ></ComponentInfo>);

			this.setState({
				showdata: this.displayData,
				tabsliderTranslate: "slider-translate"
			});
		}
		/*End*/

		/*LSP Sidebar*/

		if (e.colDef.field == 'actual_lspuser') {
			let ComponentInfo = AppendComponentIndex['lsp'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata2 = {
				actual_lspuser: e.data[e.colDef.field]
			};
			// console.log("layoutdata2", layoutdata2);
			//Total Trucks Integrate;
			await redirectURL.post('/masters/totalTruckIntegrate', layoutdata2)
				.then((response) => {
					var lsprecords = JSON.parse(JSON.stringify(response)).data.count;
					//console.log("LSP response serveeeee", lsprecords);

					var result = lsprecords.reduce(function (sum, item) {
						return sum = sum + item.count;
					}, 0);
					// console.log("result", result);
					this.setState({
						lspInfo: result
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Department Serving*/
			await redirectURL.post('/masters/totalDeptServe', layoutdata2)
				.then((response) => {
					var deprecords = JSON.parse(JSON.stringify(response)).data;
					//console.log("LSP DeptServe response", deprecords);

					var deptcnt = [];
					deprecords.map(item => {
						deptcnt.push(item._id)
					});
					this.setState({
						deptServes: deptcnt.length
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*No Of Transporters Serving*/
			await redirectURL.post('/masters/totalTransporterServe', layoutdata2)
				.then((response) => {
					var transrecords = JSON.parse(JSON.stringify(response)).data;
					//console.log("Transporter serve response", transrecords);
					var trasportcnt = [];
					transrecords.map(item => {
						trasportcnt.push(item._id)
					});
					this.setState({
						transrecordserves: trasportcnt.length
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*No Of GPS DATA Send Today*/
			await redirectURL.post('/masters/totalGPSSendToday', layoutdata2)
				.then((response) => {
					var gpssendrecords = JSON.parse(JSON.stringify(response)).data;
					//console.log("GPS Send Data response", gpssendrecords);

					this.setState({
						gpssendtoday: gpssendrecords.length
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			/*Total Trucks with no transporter data*/
			await redirectURL.post('/masters/trucksWithNoTransporter', layoutdata2)
				.then((response) => {
					var notransrecords = JSON.parse(JSON.stringify(response)).data;
					//console.log("No Trasnporter Data response", notransrecords);

					this.setState({
						nopetransporters: notransrecords.length
					});
				})
				.catch(function (error) {
					console.log(error);
				});

			this.displayData = await (<ComponentInfo context={this} nopetransporters={this.state.nopetransporters} gpssendtoday={this.state.gpssendtoday} transportersServes={this.state.transrecordserves} deptServes={this.state.deptServes} lspName={e.data[e.colDef.field]} lspInfo={this.state.lspInfo} ></ComponentInfo>);

			this.setState({
				showdata: this.displayData,
				tabsliderTranslate: "slider-translate"
			});
		}
		/*End*/
	}


	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

		//    ChartMenu.prototype.saveChart = function (e) {
		// 	   var event = {
		// 	      data: this.gridOptionsWrapper.getApi(),
		// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
		// 	    };
		// 	   //console.log("Testing", event);
		// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
		// 	   this.dispatchEvent(event);
		// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;

		//    };
		//console.log("Deptcode " ,this.state.deptcode)
		if (this.state.deptcode == 'LOG-PRT') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], false);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], false);
		}
		if (this.state.deptcode == 'SNDG') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
		}


	};


	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns,
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);


	}

	renderMap = () => {
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBI9tCwOL8bO4iIFRCWucbzeWfJOEX-y34&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}

	initMap = () => {
		// console.log("mapinfo ",this.state.mapinfo);
		var currentwindow;

		var jsondata = this.state.mapinfo;
		var locations = jsondata.breaks;
		var arr = jsondata.coords;


		//console.log("Here",arr);
		if (arr.length > 0) {
			var lt = arr[0].lat;
			var ln = arr[0].lng;
		}
		else {
			if (this.state.defTransitCoords == '') {
				var lt = 28.4519751;
				var ln = 77.0310713;
			}
			else {
				var lt = JSON.parse(this.state.defTransitCoords).lat;
				var ln = JSON.parse(this.state.defTransitCoords).lng;
			}

		}
		var routeinfo = jsondata.route_details;
		var mapOptions = {
			zoom: 7,
			zoomControl: true,
			mapTypeControl: true,
			scaleControl: true,
			streetViewControl: true,
			rotateControl: true,
			fullscreenControl: true,
			labels: true,
			mapTypeControlOptions: {
				mapTypeIds: ['hybrid', 'roadmap'],
			},
			center: new window.google.maps.LatLng(lt, ln),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP
		};

		var map = new window.google.maps.Map(document.getElementById('map'),
			mapOptions);

		var bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(lt, ln));
		// Create our info window content
		var currentinfowindow = null;
		var line = new window.google.maps.Polyline(
			{
				map: map,
				strokeColor: '#157254',
				strokeOpacity: 1.0,
				strokeWeight: 2.5,
				icons: [{
					icon: {
						path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						strokeColor: '#ff8c52',
						fillColor: '#ff8c52',
						fillOpacity: 1,
						strokeWeight: 2
					},
					repeat: '100px',
					path: []
				}]
			});

		if (arr.length > 0) {
			for (let i = 0; i < arr.length; i++) {
				var path = line.getPath().getArray();

				let latLng = new window.google.maps.LatLng(arr[i].lat, arr[i].lng);
				path.push(latLng);
				line.setPath(path);




				//Change line color based on map type
				window.google.maps.event.addListener(map, 'maptypeid_changed', function () {
					if (map.getMapTypeId() == 'hybrid' || map.getMapTypeId() == 'satellite') {
						var line = new window.google.maps.Polyline(
							{
								map: map,
								strokeColor: '#FFFFFF',
								strokeOpacity: 1.0,
								strokeWeight: 2.5,
								icons: [{
									icon: {
										path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
										strokeColor: '#ff8c52',
										fillColor: '#ff8c52',
										fillOpacity: 1,
										strokeWeight: 2
									},
									repeat: '100px',
									path: []
								}]
							});
					}
					else {
						var line = new window.google.maps.Polyline(
							{
								map: map,
								strokeColor: '#157254',
								strokeOpacity: 1.0,
								strokeWeight: 2.5,
								icons: [{
									icon: {
										path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
										strokeColor: '#ff8c52',
										fillColor: '#ff8c52',
										fillOpacity: 1,
										strokeWeight: 2
									},
									repeat: '100px',
									path: []
								}]
							});

					}

					for (i = 0; i < arr.length; i++) {
						var path = line.getPath().getArray();
						let latLng = new window.google.maps.LatLng(arr[i].lat, arr[i].lng);
						path.push(latLng);
						line.setPath(path);
						//map.setCenter(latLng);
					}

					var x = map.getZoom();
					var c = map.getCenter();
					window.google.maps.event.trigger(map, 'resize');
					map.setZoom(x);
					map.setCenter(c);
				});

				bounds.extend(new window.google.maps.LatLng(arr[i].lat, arr[i].lng));
			}

			var infowindow = new window.google.maps.InfoWindow();
			var marker, l;

			for (l = 0; l < locations.length; l++) {
				marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
					icon: require('../../assets/icons/cf.png'),
					map: map,
				});

				window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
					return function () {
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
						var contentarr = []
						var header = "Break - " + (l + 1)
						contentarr.push({ "key": "Start time", "value": getDDMMYYYYHHMMSS(locations[l].break_start) })
						contentarr.push({ "key": "End time", "value": getDDMMYYYYHHMMSS(locations[l].break_end) + " (" + secondsToString(locations[l].break_time_seconds) + ")" })
						var contentString = infoBox(marker.icon, header, contentarr)
						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, marker);
						//console.log(marker.position.toJSON());
						//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
					}
				})(marker, l));
			}
			marker = new window.google.maps.Marker({
				position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
				icon: require('../../assets/icons/track_start.png'),
				map: map,
			});
			window.google.maps.event.addListener(marker, 'click', (function (marker) {
				return function () {
					//	console.log(clusters[0])
					var contentarr = []
					//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
					var header = "Starting Point"
					contentarr.push({ "key": "Started at ", "value": getDDMMYYYYHHMMSS(routeinfo.start_time) })
					var contentString = infoBox(marker.icon, header, contentarr, '')


					infowindow.setContent(contentString);
					currentwindow = infowindow;
					infowindow.open(map, marker);
					//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
				}
			})(marker));
			marker = new window.google.maps.Marker({
				position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
				icon: require('../../assets/icons/truck-end.png'),
				map: map,
			});
			window.google.maps.event.addListener(marker, 'click', (function (marker) {
				return function () {
					//	console.log(clusters[0])
					var contentarr = []
					//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
					var header = "End Point"
					contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) })
					var contentString = infoBox(marker.icon, header, contentarr)

					infowindow.setContent(contentString);
					currentwindow = infowindow;
					infowindow.open(map, marker);
					// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
				}
			})(marker));
		}
		try {
			// console.log("consigner_coordinates ", this.state.consigner_coordinates)
			if (this.state.consigner_coordinates != undefined) {
				if ((this.state.consigner_coordinates[0].lat != '' || this.state.consigner_coordinates[0].lat != 0)
					&& (this.state.consigner_coordinates[0].lng != '' || this.props.consigner_coordinates[0].lng != 0)) {
					// console.log(this.state.consigner_coordinates[0].lat+" ==   "+ this.state.consigner_coordinates[0].lng);
					marker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(this.state.consigner_coordinates[0].lat, this.state.consigner_coordinates[0].lng),
						icon: require('../../assets/icons/business.png'),
						map: map,
					});
					var nplantname = this.state.consigner_coordinates[0].name;
					window.google.maps.event.addListener(marker, 'click', (function (marker) {
						console.log(marker)
						return function () {
							//	console.log(clusters[0])
							var contentarr = []
							//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
							var header = "Plant"
							// contentarr.push({ "key": "End at ", "value": nplantname })
							contentarr.push({ "key": "Start at ", "value": nplantname })
							var contentString = infoBox(marker.icon, header, contentarr)

							infowindow.setContent(contentString);
							currentwindow = infowindow;
							infowindow.open(map, marker);
							// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
						}
					})(marker));

				}
			}
		}
		catch (e) {

		}
		try {

			if (this.state.consignee_coordinates != undefined) {
				if (this.state.consignee_coordinates.length > 0) {
					var consigneecrds = this.state.consignee_coordinates;
					consigneecrds.map((item) => {
						//console.log("Coords itm ", item)
						try {
							//console.log(typeof(item))
							var coordinate = JSON.parse(item);
						}
						catch (e) {
							var coordinate = item;
						}
						if (coordinate.lat != 0 && coordinate.lng != 0) {
							marker = new window.google.maps.Marker({
								position: new window.google.maps.LatLng(coordinate.lat, coordinate.lng),
								icon: require('../../assets/icons/customer_pin_38.png'),
								map: map,
							});
							window.google.maps.event.addListener(marker, 'click', (function (marker) {
								return function () {
									//	console.log(clusters[0])
									var contentarr = []
									//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
									var header = "Dealer"
									contentarr.push({ "key": "End at ", "value": coordinate.name })
									var contentString = infoBox(marker.icon, header, contentarr)

									infowindow.setContent(contentString);
									currentwindow = infowindow;
									infowindow.open(map, marker);
									// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
								}
							})(marker));

						}

					});

				}
			}
		}
		catch (e) {

		}
		map.fitBounds(bounds)
	}



	onShowOverspeedKpi = async (params) => {
		//console.log(e);
		// console.log("Consign Overspeed ",params);
		let eventLabel = googleAnalytics.page.action.overspeed;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		if (params != 0) {

			var overspeedData = [];

			//console.log("Step 3 ",overspeedData)
			var propsdata = params;
			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "", field: "_id",
								width: 50,
								cellRendererFramework: RoutemapAction,
								resizable: true
							},
							{ headerName: "Truck No", width: 140, field: "truck_no", filter: true, resizable: true },
							{ headerName: "Speed (km/h)", width: 100, field: "speed", filter: true, resizable: true },
							{
								headerName: "Reported From Date", field: "first_instance", filter: true, resizable: true,
								valueGetter: function (params) {
									// return getDDMMYYYYHHMMSS(params.data.first_instance);
									try {
										if (params.data.first_instance != "" && params.data.first_instance != undefined) {
											return getDDMMYYYYHHMMSS(params.data.first_instance);
										}
										else {
											return "";
										}
									}
									catch (e) { }
								}
							},

							{
								headerName: "Reported End Date", field: "recent_instance", filter: true, resizable: true,
								valueGetter: function (params) {
									try {
										if (params.data.recent_instance != "" && params.data.recent_instance != undefined) {
											return getDDMMYYYYHHMMSS(params.data.recent_instance);
										}
										else {
											return "";
										}
									}
									catch (e) { }

								}
							},
						],

					},
					getDetailRowData: function (param) {
						// console.log("Step 4 ",param)
						var row = JSON.parse(params.data.leg_details)
						//console.log("Row ",row);
						var consignments = []
						var consignees = []
						row.map((item) => {
							consignments.push(item.consignment_code)
							consignees.push(item.consignee_code)
						})
						redirectURL.post('/consignments/truckoverspeed', {
							consignment_code: consignments,
							truck_no: param.data.truck_no
						})
							.then(async (response) => {
								// console.log("Step 1 ",response.data)
								overspeedData = response.data;
								param.successCallback(overspeedData);
								//console.log("Step 2 ",response.data)

							});

					},
					masterDetail: true
				}
			});

			if (propsdata.column.colDef.field == 'overspeeding_exception') {

				propsdata.node.setExpanded(!propsdata.node.expanded);
			}
			else {

				propsdata.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}

	onShowTransitKpi = async (params) => {
		// console.log("params ", params);
		//console.log("Transit ",params);

		if (params != 0) {
			let eventLabel = googleAnalytics.page.action.transitDelay;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
			var row = JSON.parse(params.data.leg_details)
			//console.log("Row ",row);
			var consignments = []
			var consignees = []
			row.map((item) => {
				consignments.push(item.consignment_code)
				consignees.push(item.consignee_code)
			})
			var transitedelaydata = [];
			/*
			await this.setState({

				detailCellRendererParams:{
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							{ headerName: "",field: "_id",

								cellRendererFramework:RoutemapTransitAction,
								width:100,
								suppressSizeToFit: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}

								}
							},
							{ headerName: "Truck No",field: "truck_no", width:100,resizable: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}

								}
							},
							{ headerName: "Leg Start Time",field: "leg_start", width:120,resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.leg_start);
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}

								}
							},
							{ headerName: "Leg End Time",field: "leg_end_eta",
							width:120,resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.leg_end_eta);
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}

								}
							  },
							{ headerName: "Exp. Leg Distance (KM)",
							field: "leg_expected_distance", width:140,resizable: true,
								valueGetter:function(params){
									return Math.round(params.data.leg_expected_distance);
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}

								}
							},
							{ headerName: "Actual Leg Distance Covered (KM)",
							field: "actual_leg_distance", width:140,resizable: true,
								valueGetter:function(params){
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if(params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined)
									{
										return Math.round(params.data.actual_leg_distance);
									}
									else{
										return 0;
									}

								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}

								}
							},
							{ headerName: "Exp. Distance from Trip Start(KM)",
							field: "expected_distance_start_to_leg", width:140,resizable: true,
								valueGetter:function(params){
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if(params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined)
									{
										return Math.round(params.data.expected_distance_start_to_leg);
									}
									else{
										return 0;
									}

								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}

								}

							},
							{ headerName: "Actual Distance from Trip Start (KM)",
							field: "actual_distance_traveled_start_to_leg", width:140,resizable: true,
								valueGetter:function(params){
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if(params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined)
									{
										return Math.round(params.data.actual_distance_traveled_start_to_leg);
									}
									else{
										return 0;
									}

								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}

								}


							},

							{
								headerName: "GPS Data Available",
								field: "no_gps_data",
								width:140,
								resizable: true,
								valueGetter:function(params){
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if(params.data.no_gps_data == 1)
									{
										return "Not Available";
									}
									if(params.data.no_gps_data == 0)
									{
										return "Available";
									}

								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}

								}


							},


							{
								headerName: "Trip Completed",
								field: "trip_completed",
								width:140,
								resizable: true,
								valueGetter:function(params){
									//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
									if(params.data.trip_completed == 1)
									{
										return "Yes";
									}
									if(params.data.trip_completed == 2)
									{
										return "";
									}
									if(params.data.trip_completed == 0)
									{
										return "";
									}

								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}

								}


							}
						],
						overlayNoRowsTemplate: 'No transit time available for this Consignee',

					},
					getDetailRowData: function(params) {
						//console.log("Step 4 ",transitedelaydata)
						//console.log("Step 4 ",params)
						redirectURL.post('/consignments/transitdelay', {
							consignment_code:consignments,
							consignee_code:consignees,
							truck_no:params.data.truck_no
						})
						.then(async (response) =>{
							console.log("Step 1 ",response.data)
							transitedelaydata=response.data;
							var legsarr=[]
							transitedelaydata.map((item) => {
								//if(item.trip_completed < 2)
								//{
								legsarr.push(item)
								//}
							})
							console.log('transitedelaydata ', transitedelaydata);

							params.successCallback(legsarr);
							//console.log("Step 2 ",response.data)

						});

					}
				}
			});
			*/


			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							// {
							// 	headerName: "Invoice No",
							// 	field: "invoice_no",
							// 	width: 140,
							// 	cellClass: function (params) {
							// 		if (params.data.transit_delay == 1) {
							// 			return 'lightred';
							// 		}
							// 		else {
							// 			return '';
							// 		}

							// 	}
							// }, 
							{
								headerName: "Consignment",
								field: "consignment_code",
								width: 140,
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Original ETA",
								field: "expected_trip_end",
								width: 140,
								valueGetter: function (params) {
									return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end)
								},
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}
								},

							},
							{
								headerName: "Revised ETA",
								field: "revised_trip_end",
								width: 140,
								valueGetter: function (params) {
									return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end)
								},
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Trip Start Time",
								field: "invoice_time"
							},
							// {
							// 	headerName: "Gate In Time",
							// 	field: "gate_in_time"
							// },
							// {
							// 	headerName: "Gate Out Time",
							// 	field: "gate_out_time"
							// },
							{
								headerName: "Status",
								field: "status",
								width: 140,
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								},
								valueGetter: function (params) {
									//console.log("GPS ", params.data.gps_data_available);
									if (params.data.status == 1) {
										return "Inside Plant"
									}
									else if (params.data.status == 2) {
										return "In Transit"
									}
									else if (params.data.status == 3) {
										return "Near Destination"
									}
									else if (params.data.status == 4) {
										return "Reached Destination"
									}

									else if (params.data.status == 5) {
										return "Left Destination"
									}
									else {
										return ""
									}

								}
							},
							{
								headerName: "Zone",
								field: "zone",
								width: 100,
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Consigner Code",
								field: "consigner_code",
								width: 100,
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Dealer Code",
								field: "consignee_code",
								width: 100,
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Dealer City",
								field: "consignee_city",
								width: 120,
								resizable: true,
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},

							{
								headerName: "Dealer State",
								field: "consignee_state",
								width: 120,
								resizable: true,
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Trip Distance (KM)",
								field: "distance_in_km",
								width: 120,
								resizable: true,
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Distance from Dealer (KM)",
								field: "distance_from_dealer_location",
								width: 160,
								valueGetter: function (params) {
									if (params.data.distance_from_dealer_location != '' && params.data.distance_from_dealer_location != null) {
										return params.data.distance_from_dealer_location;
									}
									else {
										return "";
									}
								},
								cellClass: function (params) {
									if (params.data.transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},

							// {
							// 	headerName: "Cluster",
							// 	field: "cluster",
							// 	cellClass: function (params) {
							// 		if (params.data.transit_delay == 1) {
							// 			return 'lightred';
							// 		}
							// 		else {
							// 			return '';
							// 		}

							// 	}
							// },

							// {
							// 	headerName: "Transit Time",
							// 	field: "transit_time"
							// },

						]
					},
					getDetailRowData: async function (params) {
						var data = params.data.leg_details;
						//console.log("Data ", params)
						var legs = JSON.parse(data);
						//console.log("Data ", legs)
						await params.successCallback(legs);
					}
				}
			});


			//if( params.column.colId == 'transit_delay' ||  params.column.colId == 'no_gps_data')
			if (params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'no_gps_data') {
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if (params.node.expanded == false) {
					transitedelaydata = []
				}
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}


	onShowNightDriveKpi = async (params) => {
		//console.log(e);
		//console.log("Consign Overspeed ",params);

		if (params != 0) {
			let eventLabel = googleAnalytics.page.action.nightDrive;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "", field: "_id",

								cellRendererFramework: RouteNightDrivemap,
								width: 100,
								suppressSizeToFit: true
							},
							{ headerName: "Truck No", field: "truck_no", width: 150, resizable: true },
							{
								headerName: "Traveled From", field: "first_instance", width: 180, resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.first_instance);
								}
							},
							{
								headerName: "Traveled Until", field: "recent_instance", resizable: true,
								width: 140,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.recent_instance);
								}
							},
							{
								headerName: "Distance Traveled (KM)", field: "distance_from_first_instance", width: 180,
								resizable: true,
								valueGetter: function (params) {
									return Math.round(params.data.distance_from_first_instance)
								}
							}
						],

					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",overspeedData)
						var row = JSON.parse(params.data.leg_details)
						//console.log("Row ",row);
						var consignments = []
						var consignees = []
						row.map((item) => {
							consignments.push(item.consignment_code)
							consignees.push(item.consignee_code)
						})
						redirectURL.post('/consignments/trucknightdriving', {
							consignment_code: consignments,
							gate_out_time: params.data.gate_out_time,
							truck_no: params.data.truck_no
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								overspeedData = response.data;
								params.successCallback(overspeedData);
								//console.log("Step 2 ",response.data)

							});

					}

				}
			});

			if (params.column.colDef.field == 'nightdriving_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}

	/*NO GPS DATA*/


	onShowNoGPSKpi = async (params) => {
		//console.log(params);
		//console.log("Consign onShowNoGPSKpi params = ",params);
		//console.log(params.column.colId);

		if (params != 0) {
			let eventLabel = googleAnalytics.page.action.gpsAvailable;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
			var transitedelaydata = [];

			await this.setState({

				detailCellRendererParams: {
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							/*{ headerName: "",field: "_id",

								cellRendererFramework:RoutemapNoGPSAction,
								width:50,
								suppressSizeToFit: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}

								}
							},
							*/
							{
								headerName: "Truck No", field: "truck_no", width: 100, resizable: true,
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Last Data Receieved",
								field: "last_packet_datetime",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Data Received on",
								field: "data_received_on",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.packet_received_on);
								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							}
						],
						overlayNoRowsTemplate: 'No transit time available for this Consignee',

					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",transitedelaydata)
						//console.log("Step 4 ",params)
						var row = JSON.parse(params.data.leg_details)
						//console.log("Row ",row);
						var consignments = []
						var consignees = []
						row.map((item) => {
							consignments.push(item.consignment_code)
							consignees.push(item.consignee_code)
						})

						redirectURL.post('/consignments/trucknogpsdata', {
							consignment_code: consignments,
							truck_no: params.data.truck_no
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								transitedelaydata = response.data;
								var legsarr = []
								transitedelaydata.map((item) => {
									//if(item.trip_completed < 2)
									//{
									legsarr.push(item)
									//}
								})
								// console.log('transitedelaydata ', transitedelaydata);

								params.successCallback(legsarr);
								//console.log("Step 2 ",response.data)

							});

					}
				}
			});

			//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
			if (params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'no_gps_data') {
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if (params.node.expanded == false) {
					transitedelaydata = []
				}
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}

	onShowEnrouteStoppagesKpi = async (params) => {
		//console.log(e);
		// console.log("Consign Overspeed ",params);

		if (params != 0) {
			let eventLabel = googleAnalytics.page.action.enrouteStoppages;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: "Break Start",
								field: "stoppage_start_time",
								width: 150,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.stoppage_start_time != undefined) {
										return getDDMMYYYYHHMMSS(params.data.stoppage_start_time)
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Break End",
								field: "reset_datetime",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.reset_datetime != undefined) {
										return getDDMMYYYYHHMMSS(params.data.reset_datetime)
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Break Time",
								field: "elaped_time_from_last_moved_seconds",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.elaped_time_from_last_moved_seconds != undefined) {
										var seconds = params.data.elaped_time_from_last_moved_seconds;
										var hours = parseInt(seconds / 3600);
										var minutes = Math.round((seconds - (hours * 3600)) / 60)
										//return hours+" Hr(s) "+minutes+" Min(s)";
										return secondsToString(seconds);
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Last Update on",
								field: "modified_date",
								width: 220,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.modified_date != undefined) {
										return getDDMMYYYYHHMMSS(params.data.modified_date)
									}
									else {
										return '';
									}

								}
							},
						]
					},
					getDetailRowData: async function (params) {
						//console.log("Step 4 ",overspeedData)
						var row = JSON.parse(params.data.leg_details)
						//console.log("Row ",row);
						var consignments = []
						var consignees = []
						row.map((item) => {
							consignments.push(item.consignment_code)
							consignees.push(item.consignee_code)
						})
						redirectURL.post('/consignments/truckenroutes', {
							consignment_code: consignments,
							truck_no: params.data.truck_no
						})
							.then(async (response) => {
								// console.log("Step 1 ",response.data)
								overspeedData = response.data;
								params.successCallback(overspeedData);
								//console.log("Step 2 ",response.data)

							});

					}
				}
			});

			if (params.column.colDef.field == 'enroute_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}


	onShowLoadingDelayKpi = async (params) => {
		//console.log(e);
		// console.log("Consign Overspeed ", params);
		//console.log(params.column.colId);
		if (params != 0) {
			let eventLabel = googleAnalytics.page.action.loadingDelay;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: "Gate In Time",
								field: "gate_in_time",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.gate_in_time != undefined) {
										return params.data.gate_in_time;
									}
									else {
										return "NA"
									}

								}
							},
							{
								headerName: "Gate Out Time",
								field: "gate_out_time",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.gate_out_time != undefined) {
										return params.data.gate_out_time;
									}
									else {
										return "NA"
									}
								}
							},
							{
								headerName: "Loading Time",
								field: "loading_time",
								width: 180,
								valueGetter: function (params) {
									if (params.data.loading_time != "" && params.data.loading_time != undefined) {
										return params.data.loading_time;
									}
									else {
										return "";
									}
								}
							}

						],

					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",params)
						// if (params.data.gate_in_time != undefined) {
						// 	var g1 = getDDMMYYYYHHMMSS(params.data.gate_in_time);
						// 	//var g1 = (params.data.gate_in_time) ? moment.parseZone(params.data.gate_in_time).format('DD-MM-YYYY') : "";
						// 	var gdate1 = new Date(params.data.gate_in_time);
						// 	var fromDate = g1;
						// }
						// else {

						var g1 = getDDMMYYYYHHMMSS(params.data.inside_fence);
						var gdate1 = new Date(params.data.inside_fence);
						var fromDate = g1;
						// }
						//console.log("g1 ", g1)//console.log("Step 4 ",params)
						if (params.data.gate_out_time != undefined) {
							var g2 = getDDMMYYYYHHMMSS(params.data.gate_out_time);
							var gdate2 = new Date(params.data.gate_out_time);
							var toField = g2;
							//console.log("gatein ", gatein)
							//console.log("gateout ", gateout)
							//var total =0 ;
						}
						else {
							var c = new Date();
							var todaydate = new Date(moment(c).format("YYYY-MM-DD HH:mm:ss"));
							var g2 = getDDMMYYYYHHMMSS(todaydate);
							var gdate2 = new Date(todaydate);
							var toField = g2;
						}
						//var g2 = getDDMMYYYY(params.data.gate_out_time);

						//console.log("g1 ", g2)
						var s1 = g1.split("-");
						var s2 = g2.split("-");


						var gatein = gdate1.getTime();
						var gateout = gdate2.getTime();
						var total = gateout - gatein;
						//console.log("total ", total)
						var diffMs = total;
						var diffDays = Math.ceil(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);

						let loadingTimeDifference = null;

						// let timeDiffereceDays = null;
						// let timeDiffereceHours = null;
						// let timeDiffereceMinutes = null;
						// let totalDifferece = null;

						// gdate1 = g1;
						// gdate2 = g2;
						// console.log("gdate1 && gdate2 =", gdate1, gdate2);
						// if(gdate1 && gdate2) {
						// 	console.log("IN IF gdate1 && gdate2");
						// 	timeDiffereceDays = gdate2.diff(gdate1, 'days');  // calculating time difference in days between two dates
						// 	timeDiffereceHours = gdate2.diff(gdate1, 'hours');  // calculating time difference in days between two dates
						// 	timeDiffereceMinutes = gdate2.diff(gdate1, 'minutes');  // calculating time difference in days between two dates
						// 	totalDifferece = gdate2.diff(gdate1);  // calculating time difference in days between two dates

						// } else {
						// 	console.log("IN ELSE  gdate1 && gdate2");
						// }
						// console.log("timeDiffereceDays = ", timeDiffereceDays);
						// console.log("timeDiffereceHours = ", timeDiffereceHours);
						// console.log("timeDiffereceMinutes = ", timeDiffereceMinutes);
						// console.log("totalDifferece = ", totalDifferece);

						var dataarr = []
						// dataarr.push({
						// 	"gate_in_time":fromDate,
						// 	"gate_out_time": toField,
						// 	"loading_time":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
						// })

						// if (diffDays) {
						// 	loadingTimeDifference = diffDays+" Day(s) " + diffHrs + " Hr(s) "+" "+diffMins+" Min(s)";
						// } else {
						// 	loadingTimeDifference = diffHrs+" Hr(s) "+" "+diffMins+" Min(s)";
						// }

						// eslint-disable-next-line use-isnan
						if (fromDate != "" && toField != "") {
							if (diffHrs != "" && diffHrs != undefined && diffHrs != "NaN" && diffHrs != NaN) {
								var adiffHrs = diffHrs;
							}
							else {
								var adiffHrs = 0;
							}
							// eslint-disable-next-line use-isnan
							if (diffMins != "" && diffMins != undefined && diffMins != "NaN" && diffMins != NaN) {
								var adiffMins = diffMins;
							}
							else {
								var adiffMins = 0;
							}
							var ltime = adiffHrs + " Hr(s) " + adiffMins + " Min(s)";
						}
						else {
							var ltime = "";
						}

						// console.log("diffMins ", adiffMins)
						dataarr.push({
							"gate_in_time": fromDate,
							"gate_out_time": toField,
							"loading_time": ltime
						})
						params.successCallback(dataarr);
					}

				}
			});

			if (params.column.colId == 'loading_delay_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {

		}

	}



	onShowUnLoadingDelayKpi = async (params) => {
		//console.log(e);
		//console.log("Consign Overspeed ",params);
		//console.log(params.column.colId);
		if (params != 0) {
			let eventLabel = googleAnalytics.page.action.unloadingDelay;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);

			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: "From Date",
								field: "from_date",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									// console.log("child params ", params)
									if (params.data.from_date != undefined) {
										return getDDMMYYYYHHMMSS(params.data.from_date);
									}
									else {
										return "NA"
									}

								}
							},
							{
								headerName: "To Date",
								field: "to_date",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.to_date != undefined) {
										return getDDMMYYYYHHMMSS(params.data.to_date);
									}
									else {
										return "NA"
									}
								}
							},
							{
								headerName: "Unloading Time",
								field: "unloading_time",
								width: 180,

							}

						],

					},
					getDetailRowData: function (params) {
						if (params.data.locked_dealer_reported == undefined) {
							var g1 = getYYYYMMDDHHMMSS(params.data.first_dealer_reported);
							var fromField = g1;
						}
						else {

							var g1 = getYYYYMMDDHHMMSS(params.data.locked_dealer_reported);
							var fromField = g1;
						}
						//console.log("params.data.left_dealer_marked ", params.data.left_dealer_marked)
						if (params.data.left_dealer_marked == undefined) {
							var c = new Date();
							var todaydate = c;
							var g2 = getYYYYMMDDHHMMSS(todaydate);
							var toField = g2;
						}
						else {
							var g2 = getYYYYMMDDHHMMSS(params.data.left_dealer_marked);
							var toField = g2;
							//console.log("toField stee " ,toField);
						}
						var fstr = fromField.split("-");
						//console.log(fstr);
						var gdate1 = new Date(fromField);
						var gdate2 = new Date(toField);
						var frmd = gdate1.getTime();
						var tod = gdate2.getTime();
						var total = tod - frmd;
						//console.log("From Date ", fromField)
						//console.log("To Date ", toField)
						//var total =0 ;
						//console.log("gdate1 ", frmd)
						//console.log("gdate2 ", tod)
						//console.log("Total ", total)
						var diffMs = total;
						var diffDays = Math.floor(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);

						// var sr = params.data.unloading_time_in_hrs;
						// var hrs=sr.toString().split(".");
						// var hours = parseInt(hrs[0]);
						// var mins = Math.round((hrs[1]/100)*60)
						var dataarr = []
						// dataarr.push({
						// 	"from_date":fromField,
						// 	"to_date": toField,
						// 	"unloading_time":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
						// 	//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
						// })
						let loadingTimeDifference = null;
						if (diffDays) {
							loadingTimeDifference = diffDays + " Day(s) " + diffHrs + " Hr(s) " + diffMins + " Min(s)";
						} else {
							loadingTimeDifference = diffHrs + " Hr(s) " + diffMins + " Min(s)";
						}
						dataarr.push({
							"from_date": fromField,
							"to_date": toField,
							"unloading_time": loadingTimeDifference
							//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
						})
						params.successCallback(dataarr);

					}

				}
			});

			if (params.column.colDef.field == 'unloading_delay_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {

		}

	}


	onShowDeviate = async (params) => {
		//console.log(e);
		//console.log("Consign Overspeed ",params);

		if (params != 0) {
			let eventLabel = googleAnalytics.page.action.deviation;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
			var overspeedData = [];

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]
					},
					getDetailRowData: async function (params) {
						// console.log("Step 4 ", overspeedData)
						await params.successCallback(overspeedData);
					}
				}
			});

			if (params.column.colId == '_id_1' || params.column.colId == '_id_2') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}
	onRowClicked(params) {
		//console.log("row click ", params)
		if (params.column.colId == '_id_1' || params.column.colId == '_id_2') {

			params.node.setExpanded(!params.node.expanded);
		}
		else {

			params.node.setExpanded(false);
		}

	}

	onShowRouteMap(rownode) {
		//console.log("rownode", rownode);
		//console.log("rownode.consignment_code ", rownode.consignment_code)

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		this.setState({
			loadshow: 'show-m'
		})
		var d = new Date(rownode.first_instance);
		var r = new Date(rownode.recent_instance);
		var finstance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var rinstance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			consignee_code: rownode.consignee_code,
			first_instance: finstance,
			recent_instance: rinstance,
			screen: "consignment"
		}

		redirectURL.post('/reports/overspeedgpsroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log(response);
				this.setState({
					mapinfo: response.data,
					dealer: rownode.consignee_code,
					sliderRouteTranslate: "slider-translate-50p",
					consignment_code: rownode.truck_no,
					maptruckno: rownode.truck_no,
					routeTruck: {
						"truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
						"endTime": response.data['route_details']['end_time']
					},
					overly: 'show-m',
					loadshow: 'show-n',
					tolls: response.data.tolls
				});
				this.renderMap();


			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onShowTransitRouteMap(rownode) {
		//console.log("rownode", rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow: 'show-m'
		})
		var d = new Date(rownode.leg_start);
		var r = new Date(rownode.leg_end_eta);
		var fintance = rownode.leg_start.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var eintance = rownode.leg_end_eta.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			consignee_code: rownode.consignee_code,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment"
		}
		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				//console.log("Inner Grid ",response.data);
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					if (rownode.leg_start != '' && rownode.leg_start != undefined) {
						sdate = rownode.leg_start;
					}
					if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
						edate = getDDMMYYYYHHMMSS(rownode.leg_end_eta)
					}
					this.setState({
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-60p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Consignment : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						overly: 'show-m',
						tolls: response.data.tolls

					});
					this.renderMap();

				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}
	onShowGoogleRoute(consignmentcode, consigneecode, legno, truck_no) {
		//console.log("GOogle params ", consignmentcode+", "+ consigneecode+", "+legno)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		var reqData = {
			consignment_code: consignmentcode,
			consignee_code: consigneecode,
			leg_no: legno,
			truck_no: truck_no
		}
		redirectURL.post('/consignments/googlemaproutes', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				// console.log("Google ", response)
				//var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]'
				this.setState({
					googelRoutes: response.data.googleroute
				})
			})
			.catch(function (e) {
				console.log(e)
			})
	}
	onShowNoGPSRouteMap(rownode) {
		//console.log("rownode", rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		this.setState({
			loadshow: 'show-m'
		})
		var fintance = rownode.last_packet_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment"
		}
		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				//console.log("Inner Grid ",response.data);
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					if (rownode.leg_start != '' && rownode.leg_start != undefined) {
						sdate = rownode.leg_start;
					}
					if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
						edate = getDDMMYYYYHHMMSS(rownode.leg_end_eta)
					}
					this.setState({
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-60p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Consignment : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls

					});

					this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
					this.renderMap();

				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onShowNightDriveRouteMap(rownode) {
		//console.log("nightdrivenode", rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow: 'show-m'
		});
		var d = new Date(rownode.first_instance);
		var r = new Date(rownode.recent_instance);
		//var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		//var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();

		var fintance = getHyphenYYYYMMDDHHMMSS(rownode.first_instance);
		var eintance = getHyphenYYYYMMDDHHMMSS(rownode.recent_instance);

		var params = {
			truck_no: rownode.truck_no,
			first_instance: fintance,
			recent_instance: eintance,
		}

		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log("nightroutedata", response.data);

				this.setState({
					mapinfo: response.data,
					sliderRouteTranslate: "slider-translate-50p",
					consignment_code: rownode.truck_no,
					defTransitCoords: { "lat": rownode.first_lat, "lng": rownode.first_lng },
					maptruckno: rownode.truck_no,
					routeTruck: {
						"truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
						"endTime": response.data['route_details']['end_time']
					},
					loadshow: 'show-n',
					overly: 'show-m',
					tolls: response.data.tolls
				});
				this.renderMap();


			})
			.catch(function (error) {
				console.log(error);
			});


	}
	onClickHideAll() {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderRouteTranslate: '',
			tabsliderTranslate: '',
			sliderCommentTranslate: "",
			slideBar: ''
		});

	}
	closeAlert = () => {
		this.setState({
			show: false
		});
	}



	onClickCounterShowData(params) {
		//console.log("Counter Params Here ", params);
		this.setState({
			loadshow: 'show-m'
		});
		var filterInstance;
		let filterActivated = false;
		this.gridApi.setFilterModel(null);
		this.gridApi.onFilterChanged();
		//console.log("params ", params)
		switch (params) {
			case "transit_delay":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "no_gps_data":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "no_gps_data_lifetime":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "overspeeding_exception":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "nightdriving_exception":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "loading_delay_exception":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "unloading_delay_exception":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			case "enroute_exception":
				filterInstance = this.gridApi.getFilterInstance(params);
				filterActivated = true;
				break;
			default:
				//console.log("filterDeavtivated params = ", params);
				filterActivated = false;
		}
		// console.log("filterActivated ", filterActivated)
		// if (filterActivated) {
		// 	filterInstance.selectNothing();
		// 	let isFilterDataAvailable = this.state.allRowData.filter(rec => {
		// 		if (rec[params]) {
		// 			return rec[params] == 1;
		// 		}
		// 	});
		// 	//console.log("isFilterDataAvailable ", isFilterDataAvailable)
		// 	if (isFilterDataAvailable.length) {
		// 		filterInstance.selectValue("1");
		// 	}
		// 	//console.log("filterInstance ", filterInstance)
		// 	filterInstance.applyModel();
		// 	this.gridApi.onFilterChanged();
		// 	this.setState({
		// 		activeFilter: params
		// 	});
		// }

		if (filterActivated) {

			let isFilterDataAvailable = this.state.allRowData.filter(rec => {
				// if(rec['no_gps_data_lifetime'] == 1)
				// {
				// 	return (rec['no_gps_data_lifetime'] == 1);
				// }
				// else{
				if (rec[params]) {
					return rec[params] == 1;
				}
				//}
			});
			// console.log("filterInstance ", this.gridApi.getFilterInstance(params))
			// console.log("isFilterDataAvailable ", isFilterDataAvailable)
			if (isFilterDataAvailable.length) {
				//this.gridApi.getFilterInstance(params).selectNothing();
				//filterInstance.selectValue("1");
				//filterInstance.applyModel();
				this.gridApi.setRowData(isFilterDataAvailable);
				this.gridApi.onFilterChanged();
				this.setState({
					loadshow: 'show-n'
				});
			}
			else {
				// filterInstance.selectNothing();
				//filterInstance.selectValue("");
				// filterInstance.applyModel();
				this.gridApi.setRowData([]);
				this.gridApi.onFilterChanged();
				this.setState({
					loadshow: 'show-n'
				});
			}
		}
		else {
			this.gridApi.setRowData(this.state.allRowData);
			this.gridApi.onFilterChanged();
			this.setState({
				loadshow: 'show-n'
			});
		}


	}



	onSelectTrip = triptype => {
		this.setState(
			{ triptype },
			// () => console.log(`Trip Option selected:`, this.state.triptype)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	onClickTripButton = async () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Filter Button",
			}
			googleAnalytics.logEvent(eventOptions);
		}

		this.setState({
			loadshow: 'show-m'
		});
		//console.log("Buton this.state.triptype ", this.state.triptype)
		var selectcons = []
		if (this.state.consigner.length > 0) {
			this.state.consigner.forEach(item => {
				selectcons.push(item.value);
			});
		}

		if (this.state.triptype.value != "0") {
			var tdata = {
				triptype: this.state.triptype.value,
				consigner_code: selectcons
			}
			await redirectURL.post("/trucks/triptrucks", tdata)
				.then((response) => {
					// console.log("Trip array ", response.data)
					this.setState({
						tripvalue: this.state.triptype.value,
						rowData: response.data,
						allRowData: response.data,
						loadshow: 'show-n',
						//consigner:''
					});
				})
				.catch(function (e) {
					console.log("Counters Grid Error ", e)
				})

		}
		else {
			// console.log("this.state.allRowData ", this.state.originalData)
			this.setState({
				rowData: this.state.originalData,
				allRowData: this.state.originalData,
				tripvalue: this.state.triptype.value,
				loadshow: 'show-n'
			});
		}



	}

	changeConsignerArrayHandler = consigner => {
		this.setState(
			{ consigner },
			//   () => console.log(`Consigner Option selected:`, this.state.consigner)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	selectConsignerOptionsItems() {
		let items = [];

		var plants = this.state.plants;

		items.push({ value: 'all', label: 'All' });
		if (plants.length > 0) {
			plants.forEach(item => {
				if (this.state.loginplant != "" && this.state.loginplant != undefined) {
					if (this.state.loginplant == item.plant_code) {
						items.push({ value: item.plant_code, label: item.plant_name });
					}

				}
				else {
					items.push({ value: item.plant_code, label: item.plant_name });
				}

			});
		}


		return items;
	}

	newFilter = (filterType, selOption) => {
		// console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });
	}

	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		this.setState({
			startDate: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
		this.setState({
			endDate: edate
		});
	}

	async onShowSidebarComponent(e) {
		if (e.colDef.field == "truck_no") {
			this.setState({
				slideBar: "",
				loadshow: "show-m"
			});
			let ComponentInfo = ComponentIndex['activeTrucks'];
			var layoutdata = {
				truck_no: e.data['truck_no'],
			};
			// console.log(layoutdata);
			await redirectURL.post("/consignments/activeTruckDetails", layoutdata)
				.then(async (response) => {
					var records = JSON.parse(JSON.stringify(response)).data;
					var truckrecords = records.truck;
					var consignrecords = records.consignments;
					// console.log("Current Location",currenttrucklocation);
					this.setState({
						truckInfo: truckrecords,
						consignments: consignrecords,
					});
					let obj = this.state.rowData.find(o => o.truck_no === e.data[e.colDef.field]);
					var objArray = [];
					objArray.push(obj);
					// console.log("truckInfo",this.state.truckInfo);
					// console.log("consignRecords",this.state.consignments);
					this.displayData = await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} truckInfo={truckrecords} consignments={consignrecords} truckData={objArray}></ComponentInfo>);

				}).catch(function (error) {
					console.log("ERROR");
				});
			await this.setState({
				showdata: this.displayData,
				slideBar: "slider-translate",
				loadshow: 'show-n',
				overly: 'show-m'
			});

		}
	}

	onGetConsigners = async (event) => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Filters",
			}
			googleAnalytics.logEvent(eventOptions);
		}


		// var sDate = document.getElementById("from_date").value;
		// var eDate = document.getElementById("to_date").value;
		var sDate = this.state.startDate
		var eDate = this.state.endDate
		//console.log(sDate,eDate);
		// if(sDate != '')
		// {
		// 	if(sDate.split("-")[2].length == 4){
		// 		sDate = sDate.split("-")[2]+"-"+sDate.split("-")[1]+"-"+sDate.split("-")[0]
		// 	}
		// 	sDate = moment(new Date(sDate)).format("Y-MM-DD");
		// }
		// else
		// {
		// 	sDate = this.state.defaultsdate
		// }
		// if(eDate != '')
		// {
		// 	if(eDate.split("-")[2].length == 4){
		// 		eDate = eDate.split("-")[2]+"-"+eDate.split("-")[1]+"-"+eDate.split("-")[0]
		// 	}
		// 	eDate = moment(new Date(eDate)).format("Y-MM-DD");

		// }
		// else{
		// 	eDate = this.state.defaultedate
		// }

		//console.log(sDate,eDate);

		this.setState({
			// startDate:moment(sDate).format("DD-MM-Y"),
			// endDate:moment(eDate).format("DD-MM-Y"),
			loadshow: 'show-m'
		});

		var selectcons = []
		//console.log("this.state.consigner.length ", this.state.consigner.length)
		if (this.state.consigner != null) {
			if (this.state.consigner.length > 0) {
				this.state.consigner.forEach(item => {
					selectcons.push(item.value);
				});
			}
			else {
				selectcons.push("ALL");
			}
		}
		else {
			selectcons.push("ALL");
		}

		var parameters = {
			consigner_code: selectcons,
			dept_code: this.state.deptcode,
			from_date: sDate,
			to_date: eDate
		}
		if (this.state.deptcode == 'SNDG') {
			let zone = this.state.flZone
			if (zone !== "" && zone !== undefined && zone !== null) {
				parameters['zone'] = [zone.value]
			}
		}

		let transporter = this.state.flTransporter
		// console.log("transporter type", typeof (transporter.value))
		if (transporter !== "" && transporter !== undefined && transporter !== null) {
			if (transporter.value != "") {
				// parameters['transporter'] = [transporter.label]
				parameters['transporter'] = [transporter.value]
			}

		}

		if (this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') {
			let state = this.state.flState
			let city = this.state.flCity
			if ((state) !== "" && (state) !== undefined && state !== null) {
				if (state.value != "") {
					parameters['state'] = [state.value]
				}

			}
			if (city !== "" && (city) !== undefined && city !== null) {
				if (city.value != "") {
					parameters['city'] = [city.value]
				}
			}
		}
		var urlpath;
		if (this.props.match.path == "/sndtrucks") {
			urlpath = '/trucks/sndtrucks';
		}
		else if (this.props.match.path == "/prttrucks") {
			urlpath = '/trucks/prttrucks';
		}
		else if (this.props.match.path == "/tnptrucks") {
			urlpath = '/trucks/tnptrucks';
		}
		else {
			urlpath = '/trucks';
		}

		this.onLoadShowTrucksList(urlpath, parameters)

	}

	onShowTruckConsignments = async (legData) => {
		//console.log("legData", legData)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Show Legs",
			}
			googleAnalytics.logEvent(eventOptions);
		}

		await this.setState({

			detailCellRendererParams: {
				detailGridOptions: {
					columnDefs: [
						{
							headerName: "Consignment",
							field: "consignment_code"
						},
						{
							headerName: "Expected Trip End",
							field: "expected_trip_end"
						},
						{
							headerName: "Revised Trip End",
							field: "revised_trip_end"
						},
						{
							headerName: "Trip Start Time",
							field: "invoice_time"
						},
						{
							headerName: "Gate In Time",
							field: "gate_in_time"
						},
						{
							headerName: "Gate Out Time",
							field: "gate_out_time"
						},
						{
							headerName: "Status",
							field: "status"
						},
						{
							headerName: "Consigner Code",
							field: "consigner_code"
						},
						{
							headerName: "Dealer Code",
							field: "consignee_code"
						},

						// {
						// 	headerName: "Cluster",
						// 	field: "cluster"
						// },

						{
							headerName: "Transit Time",
							field: "transit_time"
						},

					]
				},
				getDetailRowData: async function (params) {
					var data = params.data.leg_details;
					//console.log("Data ", params)
					var legs = JSON.parse(data);
					//console.log("Data ", legs)
					await params.successCallback(legs);
				}
			}
		});
		if (legData.column.colDef.field == 'truck_no') {

			legData.node.setExpanded(!legData.node.expanded);
		}
		else {

			legData.node.setExpanded(false);
		}
	}

	onClickShowMapView = async () => {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		//this.gridApi.deselectAll();
		if (this.state.containerslist.length > 0) {
			var reqparms = {
				truck_no: this.state.containerslist,
				dept_code: this.state.deptcode
			}
		}
		else {
			var reqparms = {
				truck_no: [],
				dept_code: this.state.deptcode
			}
		}

		await redirectURL.post("/consignments/mapview", reqparms)
			.then((response) => {
				//console.log("Res ",response.data.message)
				var records = response.data.message;

				var truck = JSON.parse(records.truck_info);
				if (truck.length > 0) {
					if (truck.length > 1) {
						var consinees = this.state.plants;
					}
					else {
						var consinees = records.consignee_coordinates;
					}
				}
				else {
					var consinees = [];
				}
				// console.log("truckinfo,", truck)
				//console.log("truck.consignee_coordinates,",JSON.parse(records.consignee_coordinates))
				this.setState({
					open: true,
					maprowData: truck,
					rownode: truck,
					maptruckno: records.truck_no,
					consigneecoords: consinees,
					consigner_coordinates: records.consigner_coordinates
				});


				//containerslist=[];
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}
	onRowSelection(event) {
		var containerslist = [];
		//console.log("rowselect", event)
		var rwCount = event.api.getSelectedNodes();
		// console.log("rwCount ", rwCount)
		//var containerlist=[];
		// console.log("rowselect", event.data)
		var rowCount = event.data
		//console.log("rowCount ", rowCount)

		rwCount.map((item) => {
			containerslist.push(item.data.truck_no)
		});

		this.setState({
			containerslist: containerslist
		})
		//console.log("checkedlist ", containerslist)
	}

	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ open: false });
	};


	onOpenForceCloseModal = () => {
		this.setState({ openforceclose: true });
	};

	onCloseForceCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ openforceclose: false });
	};


	onClickSaveGridState() {
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Save Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		try {
			window.colState = this.gridColumnApi.getColumnState();
			window.groupState = this.gridColumnApi.getColumnGroupState();
			window.sortState = this.gridApi.getSortModel();
			window.filterState = this.gridApi.getFilterModel();

			let screenpage = '';

			if (this.props.match.path == "/sndtrucks") {
				screenpage = 'snd trucks';
			}
			else if (this.props.match.path == "/prttrucks") {
				screenpage = 'prt trucks';
			}
			else if (this.props.match.path == "/tnptrucks") {
				screenpage = 'tnp trucks';
			}
			else {
				screenpage = 'all trucks';
			}

			let reqparams = {
				gridcolumns: window.colState,
				gridgroup: window.groupState,
				gridcolsort: window.sortState,
				gridcolfilter: window.filterState,
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: screenpage
			}
			//console.log("reqparams ", reqparams)
			redirectURL.post("/consignments/saveGridStates", reqparams)
				.then((response) => {
					//console.log("State response ",response.data)
					this.setState({
						show: true,
						basicTitle: "Successfully saved grid layout",
						basicType: "success",
						screenurl: window.location.pathname,
						screentitle: screenpage
					})
				})
				.catch(function (e) {
					console.log("Error ", e)
				})
		}
		catch (e) { }
	}

	restoreGridStates() {
		if (this.state.usergridstate.length !== 0) {
			try {
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

			}
			catch (e) {

			}
		}
	}

	resetState = () => {

		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": "Reset Grid Layout",
			}
			googleAnalytics.logEvent(eventOptions);
		}
		try {

			this.gridColumnApi.resetColumnState();
			this.gridColumnApi.resetColumnGroupState();
			this.gridApi.setSortModel(null);
			this.gridApi.setFilterModel(null);
			//console.log('column state reset');
			var reqparams = {
				userId: localStorage.getItem("userid"),
				screenurl: window.location.pathname,
				screentitle: this.state.screenpage
			}

			redirectURL.post("/consignments/removeUserGridState", reqparams)
				.then((response) => {
					this.setState({
						show: true,
						basicTitle: "Successfully reset default grid layout",
						basicType: "success"
					})
				})
		}
		catch (e) { }

	};
	selectMovementTypeOptionsItems() {
		let items = [];
		items.push({ value: "all", label: "All" });
		items.push({ value: true, label: "Local Movement" });
		items.push({ value: false, label: "Non Local Movement" });
		return items;
	}

	getTruckConsignments = (event) => {
		this.setState({
			loadshow: "show-m",
			overly: "show-m"
		})
		event.preventDefault();
		// var selectedFilter = $("#selectedFilter").text();
		let selectedFilter = this.state.selectedFilter
		// console.log("selectedFilter", selectedFilter)
		var inputTruckConsignment = $("#inputTruckConsignment").val();
		// console.log("selFilter",inputTruckConsignment)
		if (selectedFilter == "Select..." || inputTruckConsignment == "") {
			this.setState({
				basicTitle: "Fields Should not be Empty",
				basicType: "danger",
				show: true,
				loadshow: "show-n",
				overly: "show-n"
			})
		}
		else {
			const { allRowData, pagecounters } = this.state
			// console.log("allRowData",allRowData,"pagecounters",pagecounters )	
			if (selectedFilter == "Truck") {
				let filteredData = allRowData.filter(item => item.truck_no.includes(inputTruckConsignment))
				// console.log("filteredData", filteredData)
				// this.gridApi.setRowData(filteredData);
				// this.gridApi.onFilterChanged();
				this.setState({
					rowData: filteredData,
					allRowData: filteredData,
					loadshow: 'show-n',
					overly: "show-n"
				});

			}
			if (selectedFilter == "GR No") {
				let filteredData = allRowData.filter(item => item.consignment_codes.includes(inputTruckConsignment))

				// console.log("Consingments", filteredData)
				this.setState({
					rowData: filteredData,
					allRowData: filteredData,
					loadshow: 'show-n',
					overly: "show-n"

				});
			}
			if (selectedFilter == "Invoice") {
				let filteredData = allRowData.filter(item => item.invoice_nos.includes(inputTruckConsignment))
				// console.log("Invoice", filteredData)
				this.setState({
					rowData: filteredData,
					allRowData: filteredData,
					loadshow: 'show-n',
					overly: "show-n"
				});
			}
		}
	}

	reloadPageData = () => {
		window.location.reload();
	}

	onShowDayWiseDistanceTrip = async (params) => {
		// console.log("params", params)
		// let eventLabel = googleAnalytics.page.action.nightDrive;
		// let eventOptions = {
		// 	"category": this.state.pagetitle,
		// 	"action": this.state.eventAction,
		// 	"label": eventLabel,
		// }
		// googleAnalytics.logEvent(eventOptions);
		if (params != 0) {
			var transitedelaydata = [];

			await this.setState({

				detailCellRendererParams: {
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "",
								field: "_id",
								cellRendererFramework: RouteMapForDayWiseDistanceTrip,
								width: 50,
								suppressSizeToFit: true,
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},

							{
								headerName: "Truck No", field: "truck_no", width: 100, resizable: true,
								// cellClass:function(params){
								// 	if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
								// 	{
								// 		return 'green';
								// 	}
								// 	else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
								// 	{
								// 		return 'lightred';
								// 	}
								// 	else{
								// 		return '';
								// 	}

								// }
							},
							{
								headerName: "Distance Travelled(Km)",
								field: "distance_travelled_km",
								width: 180,
								resizable: true,
								// cellClass:function(params){
								// 	if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
								// 	{
								// 		return 'green';
								// 	}
								// 	else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
								// 	{
								// 		return 'lightred';
								// 	}
								// 	else{
								// 		return '';
								// 	}

								// }
							},
							{
								headerName: "Day",
								field: "day",
								width: 180,
								resizable: true,
								// cellClass:function(params){
								// 	if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
								// 	{
								// 		return 'green';
								// 	}
								// 	else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
								// 	{
								// 		return 'lightred';
								// 	}
								// 	else{
								// 		return '';
								// 	}

								// }
							},
							{
								headerName: "Start time",
								field: "start_time",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.start_time);
								},
								// cellClass:function(params){
								// 	if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
								// 	{
								// 		return 'green';
								// 	}
								// 	else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
								// 	{
								// 		return 'lightred';
								// 	}
								// 	else{
								// 		return '';
								// 	}

								// }
							},
							{
								headerName: "End time",
								field: "end_time",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.end_time);
								},
								// cellClass:function(params){
								// 	if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
								// 	{
								// 		return 'green';
								// 	}
								// 	else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
								// 	{
								// 		return 'lightred';
								// 	}
								// 	else{
								// 		return '';
								// 	}

								// }
							}
						],
						overlayNoRowsTemplate: 'No rows to show',
					},
					getDetailRowData: function (param) {
						//console.log("Step 4 ",transitedelaydata)
						// console.log("Step 4 ",param)
						redirectURL.post('/consignments/getDayWiseDistanceTravelled', {
							consignment_codes: param.data.consignment_codes,
							invoice_time: getHyphenYYYYMMDDHHMMSS(params.data.invoice_time)
						})
							.then(async (response) => {
								// console.log("Step 1 ", response.data)
								transitedelaydata = response.data;
								var legsarr = []
								if (transitedelaydata.length !== 0)
									transitedelaydata.map((item) => {
										//if(item.trip_completed < 2)
										//{
										legsarr.push(item)
										//}
									})
								// console.log('transitedelaydata ', transitedelaydata);

								param.successCallback(legsarr);
								//console.log("Step 2 ",response.data)

							});

					}
				}
			});

			//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
			if (params.column.colDef.field == 'day_wise_distance_trip' || params.column.colDef.field == 'day_wise_distance_trip') {
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if (params.node.expanded == false) {
					transitedelaydata = []
				}
			}
			else {
				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			// console.log("elseData")
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Distance Travelled(Km)", field: "distance_travelled_km" },
							{ headerName: "Day", field: "day" },
							{ headerName: "Start Time", field: "start_time" },
							{ headerName: "End Time", field: "end_time" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}
	}

	onShowDayWiseRouteMap = (rownode) => {
		//console.log("IN onShowNoGPSRouteMap, params = ",rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log("rownode", rownode);
		this.setState({
			loadshow: 'show-m'
		})
		var fintance = rownode.start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		//var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		var eintance = rownode.end_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			// consignment_code:rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
			startDate: fintance,
			endDate: eintance,
			screen: "consignment"
		}
		redirectURL.post('/gmap', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log("Inner Grid ",JSON.parse(response.data.data).coords);
				var records = JSON.parse(response.data.data);
				// console.log("records ", records)
				if (records.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					// if (records.route_details.start_time != '' && records.route_details.start_time != undefined) {
					// 	sdate = records.route_details.start_time;
					// }
					// if (records.route_details.end_time != '' && records.route_details.end_time != undefined) {
					// 	edate = getDDMMYYYYHHMMSS(records.route_details.end_time)
					// }
					this.setState({
						mapinfo: records,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-60p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Truck No : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": fintance, "endTime": eintance },
						loadshow: 'show-n',
						sidebarSubHeader: "View Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls

					});

					// this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
					this.renderMap();

				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}


	render() {
		const modalStyles = {
			width: '1300px !important',
		}
		const { open } = this.state;
		const { openforceclose } = this.state;

		var maptransporter = '';
		if (this.state.rownode.transporter) {
			///console.log("map rownode ",this.state.rownode.transporter[0])
			maptransporter = this.state.rownode.transporter[0].transporter_name;
		}
		let hideonlyPRT = (this.state.deptcode === "LOG-PRT") ? true : false;
		//console.log("Rendere ",this.state.deptcode)
		const columnwithDefs = [

			{
				headerName: "Actions",
				field: "_id",
				width: 40,
				pinned: 'left',
				//cellRenderer:'consignmentActions',
				filter: false, resizable: true,
				headerCheckboxSelection: false,
				//headerCheckboxSelectionFilteredOnly: true,
				checkboxSelection: true

			},
			{
				headerName: "View Route",
				field: "_id",
				width: 40,
				pinned: 'left',
				cellRenderer: 'consignmentActions'

			},


			{
				headerName: "Transit Delay",
				field: "transit_delay",
				width: 40,
				pinned: 'left',
				pivot: false,
				cellRendererSelector: function (params) {
					if (params.data.transit_delay == 1) {
						var rendComponent = {
							component: 'kpiTransitDelayActions'
							// component: 'notransit'
						};
						return rendComponent
					}
					else {
						var rendComponent = {
							component: 'notransit'
						};
						return rendComponent
					}
				},
				filter: true, resizable: true
			},
			{
				headerName: "Gps Data",
				field: "no_gps_data",
				width: 40,
				pinned: 'left',
				pivot: false,
				cellRendererSelector: function (params) {
					//console.log(params.data)
					var comp = 'gps';
					if (params.data.no_gps_data == 0 && params.data.no_gps_data_lifetime == 1) {
						comp = 'noGpsLifetime'

					}
					else if (params.data.no_gps_data == 1) {
						comp = 'nogps'
					}

					else if (params.data.no_gps_data == 0) {
						comp = 'gps'

					}
					var rendComponent = {
						component: comp
					};
					return rendComponent
				},
				filter: true, resizable: true
			},
			{
				headerName: "Overspeed",
				field: "overspeeding_exception",
				width: 40,
				pinned: 'left',
				pivot: false,
				cellRendererSelector: function (params) {
					if (params.data.overspeeding_exception == 1) {
						var rendComponent = {
							component: 'kpiActions'
						};
						return rendComponent
					}
					else {
						var rendComponent = {
							component: 'nooverspeed'
						};
						return rendComponent
					}
				},
				filter: true, resizable: true
			},
			{
				headerName: "Night Drive",
				field: "nightdriving_exception",
				width: 40,
				pinned: 'left',
				pivot: true,
				hide: hideonlyPRT,
				cellRendererSelector: function (params) {
					//console.log("Except ",params.data.nightdriving_exception)
					//console.log("Consignemnt code ", params.data.consignment_code)
					if (params.data.nightdriving_exception != undefined && params.data.nightdriving_exception == 1) {
						var rendComponent = {
							component: 'kpiNightDriveActions'
						};
						return rendComponent
					}
					else {
						var rendComponent = {
							component: 'nonightdrive'
							//component: 'kpiNightDriveActions'
						};
						return rendComponent
					}
				},
				filter: true, resizable: true
			},
			{
				headerName: "Loading Delay",
				field: "loading_delay_exception",
				width: 40,
				pinned: 'left',
				pivot: false,
				hide: hideonlyPRT,
				cellRendererSelector: function (params) {
					var rendComponent = {
						component: 'kpiLoadingDelayActions'
					};
					return rendComponent;
				},
				filter: true, resizable: true
			},
			// {
			// 	headerName: "Unloading Delay",
			// 	field: "unloading_delay_exception",
			// 	width: 40,
			// 	pinned: 'left',
			// 	pivot: false,
			// 	hide:hideonlyPRT,
			// 	cellRendererSelector:function(params){
			// 		if(params.data.unloading_delay_exception != undefined && params.data.unloading_delay_exception == 1)
			// 		{
			// 			var rendComponent = {
			// 				component: 'kpiUnLoadingDelayActions'
			// 			};
			// 			return rendComponent;
			// 		}
			// 		else{
			// 			var rendComponent = {
			// 				component: 'kpiUnLoadingDelayActions'
			// 			};
			// 			return rendComponent;
			// 		}
			//
			// 	},
			// 	filter: true,resizable: true
			// },

			{
				headerName: "Enroute Stoppages",
				field: "enroute_exception",
				width: 40,
				pinned: 'left',
				pivot: false,
				cellRendererSelector: function (params) {

					var rendComponent = {
						component: 'kpiEnrouteStoppagesActions'
					};
					return rendComponent

				},
				filter: true, resizable: true
			},
			{
				headerName: "Day Wise Distance Trip",
				field: "day_wise_distance_trip",
				colId: "day_wise_distance_trip",
				width: 40,
				pinned: 'left',
				pivot: false,
				hide: hideonlyPRT,
				cellRenderer: 'kpiDayWiseDistanceActions',
				filter: true, resizable: true
			},
			// {
			// 	headerName: "",
			// 	field: "truck_no",
			// 	width: 40,
			// 	pinned: 'left',
			//     pivot: false,
			// 	cellRendererSelector:function(params){

			// 		var rendComponent = {
			// 			component: 'truckConsignmentLegs'
			// 		};
			// 		return rendComponent

			// 	},
			// 	filter: true,resizable: true
			// },
			/*
			{
				headerName: "Consignment Code",
				field: "consignment_code",
				width: 120,
				pinned: 'left',
				editable:false,
				filter: "agSetColumnFilter",resizable: true
				//cellRenderer: "agGroupCellRenderer"
			},
			*/
			// {
			// 	headerName: "Actions",
			// 	field: "_id",
			// 	colId: "_id",
			// 	pinned: 'left',
			// 	width: 40,
			// 	cellRenderer:'commentActions',
			// 	filter: false,resizable: true
			//
			// },
			{
				headerName: "Truck No",
				field: "truck_no",
				width: 100,
				pinned: 'left',
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true,
				//rowGroup: true
			},

			{
				headerName: "Original ETA",
				field: "expected_trip_end",
				width: 120,
				resizable: true,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
				},
				// filter: "agDateColumnFilter",
				comparator: dateComparator,
				// filterParams: {
				// 	browserDatePicker: true,
				// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	// provide comparator function
				// 	comparator: function(filteredDate,cellValue,secondDate) {
				// 		cellValue = cellValue.replace(/\//g,"-")
				// 		if(cellValue != "" && cellValue != " "){
				// 			cellValue = cellValue.split(" ")[0].split("-");
				// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
				// 			var cellDate = new Date(cellValue);
				// 			if(filteredDate.getMonth() < 10){
				// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			else{
				// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			if(filterValue.split("-")[2].length < 2)
				// 			{
				// 				var temp = filterValue
				// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
				// 			}
				// 			var filteredDate = new Date(filterValue);
				// 			cellDate = cellDate.getTime();
				// 			filteredDate = filteredDate.getTime();
				// 			// console.log(cellDate,filteredDate);
				// 			if(cellDate === filteredDate)
				// 			{
				// 				return 0;
				// 			}
				// 			if(cellDate < filteredDate){
				// 				return -1;
				// 			}
				// 			if(cellDate > filteredDate)
				// 			{
				// 				return 1;
				// 			}
				// 		}
				// 	}
				// },
			},
			{
				headerName: "Revised ETA",
				field: "revised_trip_end",
				width: 120,
				resizable: true,
				valueGetter: function (params) {
					//console.log(" ETA ", params.data)
					if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
						return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
					}
					else {
						return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
					}
				},
				// filter: "agDateColumnFilter",
				comparator: dateComparator,
				// filterParams: {
				// 	browserDatePicker: true,
				// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	// provide comparator function
				// 	comparator: function(filteredDate,cellValue,secondDate) {
				// 		cellValue = cellValue.replace(/\//g,"-")
				// 		if(cellValue != "" && cellValue != " "){
				// 			cellValue = cellValue.split(" ")[0].split("-");
				// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
				// 			var cellDate = new Date(cellValue);
				// 			if(filteredDate.getMonth() < 10){
				// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			else{
				// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 			}
				// 			if(filterValue.split("-")[2].length < 2)
				// 			{
				// 				var temp = filterValue
				// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
				// 			}
				// 			var filteredDate = new Date(filterValue);
				// 			cellDate = cellDate.getTime();
				// 			filteredDate = filteredDate.getTime();
				// 			// console.log(cellDate,filteredDate);
				// 			if(cellDate === filteredDate)
				// 			{
				// 				return 0;
				// 			}
				// 			if(cellDate < filteredDate){
				// 				return -1;
				// 			}
				// 			if(cellDate > filteredDate)
				// 			{
				// 				return 1;
				// 			}
				// 		}
				// 	}
				// },

				cellClass: function (params) {
					let hourDifference = 0;
					if (params.data.revised_trip_end && params.data.expected_trip_end) {
						let revisedDateOnlyMoment = moment(params.data.revised_trip_end);
						let expectedDateOnlyMoment = moment(params.data.expected_trip_end);
						hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours');
						//console.log("diff= ", params.data.consignment_code, hourDifference);
					} else {
						//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
					}

					if (hourDifference >= 1) {
						return 'bgColorDangerMedium'
					}

					if (hourDifference <= -1) {
						return 'bgColorSuccessMedium'
					}
					else {
						return ''
					}
				}

			},

			{
				headerName: "Status",
				field: "status",
				width: 120,
				pinned: 'left',
				filter: "agSetColumnFilter",
				resizable: true,
				valueGetter: function (params) {
					//console.log("GPS ", params.data.gps_data_available);
					if (params.data.status == 1) {
						// return "Inside Plant"
						return "New Trip"
					}
					else if (params.data.status == 2) {
						return "In Transit"
					}
					else if (params.data.status == 3) {
						// return "Near Destination"
						return "Arriving"
					}
					else if (params.data.status == 4) {
						// return "Reached Destination"
						return "At Destination"
					}

					else if (params.data.status == 5) {
						// return "Left Destination"
						return "Closed"
					}
					else {
						return ""
					}

				}
			},
			// {
			//   headerName: "Transporter Name",
			//   field: "transporter",
			//   valueGetter:function(params){
			// 	  //console.log(params.data.transporter);
			// 	  if (params.data.hasOwnProperty('transporter'))
			// 	  {
			// 		  if (params.data.transporter.length > 0)
			// 		  {
			// 			return params.data.transporter[0].transporter_name;
			// 		  }
			// 	  }

			//   },
			//   width: 150,
			//   filter: "agSetColumnFilter",
			//   cellRenderer:'',resizable: true
			// },
			// {
			// 	headerName: "GPS Provider",
			// 	field: "truck",
			// 	width: 150,
			// 	filter: "agSetColumnFilter",
			// 	cellRenderer:'',resizable: true,
			// 	valueGetter:function(params){
			// 		//console.log(params.data.transporter);
			// 		if (params.data.hasOwnProperty('truck'))
			// 		{
			// 			if (params.data.truck.length > 0)
			// 			{
			// 			  return params.data.truck[0].actual_lspuser;
			// 			}
			// 		}

			// 	},
			// },
			// {
			// 	headerName: "Invoice Nos",
			// 	field: "invoice_nos",
			// 	width: 150,
			// 	filter: "agSetColumnFilter",
			// 	cellRenderer: '', resizable: true
			// },

			{
				headerName: "GR Nos",
				field: "consignment_codes",
				width: 150,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},

			{
				headerName: "Invoice Date",
				field: "invoice_time",
				width: 150,
				cellRenderer: '', resizable: true,
				valueGetter: function (params) {
					if (params.data.invoice_time != '' || params.data.invoice_time != undefined) {
						return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
					}
					else {
						return "NA";
					}
				},
				// filter: "agDateColumnFilter",
				comparator: dateComparator,

			},
			{
				headerName: "Transporter Name",
				field: "transporter_name",
				width: 150,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},
			// {
			// 	headerName: "Zone",
			// 	field: "zone",
			// 	width: 150,
			// 	filter: "agSetColumnFilter",
			// 	cellRenderer: '', resizable: true
			// },
			{
				headerName: "Consigner Code",
				field: "consigner_code",
				width: 150,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},

			{
				headerName: "Dealer Code",
				field: "consignee_code",
				width: 120,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},
			{
				headerName: "Dealer City",
				field: "consignee_city",
				width: 120,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},

			{
				headerName: "Travel Status",
				field: "travel_status",
				width: 150,
				filter: "agSetColumnFilter"
			},

			{
				headerName: "Speed (kmph)",
				field: "speed",
				width: 150,
				filter: "agSetColumnFilter"
			},
			{
				headerName: "Last Location",
				field: "address",
				width: 150,
				resizable: true
			},
			{
				headerName: "Travelled Distance (KM)",
				field: "aprox_dist_travelled_from_start",
				width: 150,
				filter: "agSetColumnFilter",
				valueGetter: param =>{
					try{
						const data = parseFloat(param.data.aprox_dist_travelled_from_start)
						let otpt = Math.ceil(data)
						if( isNaN(otpt) == false ){
							return otpt
						}else{
							return ''
						}
						
					}catch(e){	}
				}
			},

			{
				headerName: "Remaining Distance from Dealers (KM)",
				field: "distance_from_dealer_location",
				width: 160,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},

			{
				headerName: "Total Distance (KM)",
				field: "total_distance_km",
				width: 200,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},

			{
				headerName: "HMCL Distance (KM)",
				field: "distance_in_km",
				width: 200,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},

			{
				headerName: "Transit Time (Days)",
				field: "transit_time",
				width: 200,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},
			/*  {
				headerName: "Department Code",
				field: "dept_code",
				width: 120,
				filter: "agSetColumnFilter",
				cellRenderer:''  ,resizable: true
			  },   */

			//         {
			//             headerName: "Gate in Time",
			//             field: "gate_in_time",
			//             width: 180,
			// 	//cellRenderer:'dateFormater',
			// 	valueGetter:function(params){
			// 		return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
			// 	},
			// 	resizable: true,
			// 	// filter: "agDateColumnFilter",
			// 	comparator: dateComparator,
			// 	// filterParams: {
			// 	// 	browserDatePicker: true,
			// 	// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	// 	// provide comparator function
			// 	// 	comparator: function(filteredDate,cellValue,secondDate) {
			// 	// 		cellValue = cellValue.replace(/\//g,"-")
			// 	// 		if(cellValue != "" && cellValue != " "){
			// 	// 			cellValue = cellValue.split(" ")[0].split("-");
			// 	// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
			// 	// 			var cellDate = new Date(cellValue);
			// 	// 			if(filteredDate.getMonth() < 10){
			// 	// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 	// 			}
			// 	// 			else{
			// 	// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 	// 			}
			// 	// 			if(filterValue.split("-")[2].length < 2)
			// 	// 			{
			// 	// 				var temp = filterValue
			// 	// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 	// 			}
			// 	// 			var filteredDate = new Date(filterValue);
			// 	// 			cellDate = cellDate.getTime();
			// 	// 			filteredDate = filteredDate.getTime();
			// 	// 			// console.log(cellDate,filteredDate);
			// 	// 			if(cellDate === filteredDate)
			// 	// 			{
			// 	// 				return 0;
			// 	// 			}
			// 	// 			if(cellDate < filteredDate){
			// 	// 				return -1;
			// 	// 			}
			// 	// 			if(cellDate > filteredDate)
			// 	// 			{
			// 	// 				return 1;
			// 	// 			}
			// 	// 		}
			// 	// 	}
			// 	// },
			// },
			// {
			// 	headerName: "GPS Gate in Time",
			// 	field: "inside_fence",
			// 	width: 180,
			// 	filter: "agSetColumnFilter",
			// 	//cellRenderer:'dateFormater',
			// 	valueGetter: function (params) {
			// 		return getHyphenDDMMMYYYYHHMM(params.data.inside_fence);
			// 	},
			// 	resizable: true,
			// 	// filter: "agDateColumnFilter",
			// 	comparator: dateComparator,

			// },
			//       {
			//           headerName: "Gate out Time",
			//           field: "gate_out_time",
			//           width: 180,
			//           filter: "agSetColumnFilter",
			// //cellRenderer:'dateFormater',
			// valueGetter:function(params){
			// 	return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
			// },
			// resizable: true,
			// // filter: "agDateColumnFilter",
			// comparator: dateComparator,
			// // filterParams: {
			// // 	browserDatePicker: true,
			// // 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// // 	// provide comparator function
			// // 	comparator: function(filteredDate,cellValue,secondDate) {
			// // 		cellValue = cellValue.replace(/\//g,"-")
			// // 		if(cellValue != "" && cellValue != " "){
			// // 			cellValue = cellValue.split(" ")[0].split("-");
			// // 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
			// // 			var cellDate = new Date(cellValue);
			// // 			if(filteredDate.getMonth() < 10){
			// // 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// // 			}
			// // 			else{
			// // 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// // 			}
			// // 			if(filterValue.split("-")[2].length < 2)
			// // 			{
			// // 				var temp = filterValue
			// // 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// // 			}
			// // 			var filteredDate = new Date(filterValue);
			// // 			cellDate = cellDate.getTime();
			// // 			filteredDate = filteredDate.getTime();
			// // 			// console.log(cellDate,filteredDate);
			// // 			if(cellDate === filteredDate)
			// // 			{
			// // 				return 0;
			// // 			}
			// // 			if(cellDate < filteredDate){
			// // 				return -1;
			// // 			}
			// // 			if(cellDate > filteredDate)
			// // 			{
			// // 				return 1;
			// // 			}
			// // 		}
			// // 	}
			// // },
			//       },
			// {
			// 	headerName: "GPS Gate out Time",
			// 	field: "outside_fence",
			// 	width: 180,
			// 	filter: "agSetColumnFilter",
			// 	//cellRenderer:'dateFormater',
			// 	valueGetter: function (params) {
			// 		return getHyphenDDMMMYYYYHHMM(params.data.outside_fence);
			// 	},
			// 	resizable: true,
			// 	// filter: "agDateColumnFilter",
			// 	comparator: dateComparator,

			// },
			/*
			{
				headerName: "Reached Destination At",
				field: "first_dealer_reported",
				width: 180,
				filter: "agSetColumnFilter",
				//cellRenderer:'dateFormater',
				valueGetter:function(params){
					return getDDMMYYYYHHMMSS(params.data.recent_dealer_reported);
				},
				resizable: true
			},

			{
				headerName: "Left Destination At",
				field: "left_dealer_marked",
				width: 180,
				filter: "agSetColumnFilter",
				//cellRenderer:'dateFormater',
				valueGetter:function(params){
					return getDDMMYYYYHHMMSS(params.data.left_dealer_marked);
				},
				resizable: true
			},
			{
				headerName:"Local Movement(Yes/No)",
				field:"local_movement",
				width:200,
				filter:"agSetColumnFilter",
				cellRenderer:'',resizable: true
			},
			*/
			{
				headerName: "Last Packet Time",
				field: "timestamp",
				width: 150,
				valueGetter: function (params) {
					return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
				},
				resizable: true,
				// filter: "agDateColumnFilter",
				comparator: dateComparator,

			},
			{
				headerName: "GPS Provider",
				field: "actual_lspuser",
				width: 150,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},
			// {
			// 	headerName: "Last City/Town",
			// 	field: "area",
			// 	width:150,
			// 	resizable: true
			// },
			//
			// {
			// 	headerName: "Last State",
			// 	field: "state",
			// 	width:150,
			// 	resizable: true
			// },
			// {
			// 	headerName: "SPOC Contact Name",
			// 	field: "spoc_contact_name",
			// 	width:180,
			// 	resizable: true
			// },
			// {
			// 	headerName: "SPOC Contact Number",
			// 	field: "spoc_contact_number",
			// 	width:180,
			// 	resizable: true
			// },
			//         {
			//             headerName:"Cluster",
			//             field:"cluster",
			//             width:100,
			//             filter:"agSetColumnFilter",
			// 	cellRenderer:'',resizable: true
			//         },
			// {
			//     headerName:"Vehicle Mode",
			//     field:"vehicle_mode",
			//     widht:100,
			//     filter:"agSetColumnFilter",
			// 	cellRenderer:'',resizable: true
			// },
			// {
			//     headerName:"Trip Type",
			//     field:"trip_type",
			//     widht:80,
			//     filter:"agSetColumnFilter",
			// 	cellRenderer:'',resizable: true
			// },
			// {
			//     headerName: "Items",
			//     field: "items",
			//     width: 200,
			//     filter: "agSetColumnFilter",
			// 	cellRenderer:'' ,resizable: true
			// },
			// {
			// 	headerName: "Critical",
			// 	field: "is_critical",
			// 	width: 90,
			// 	cellRenderer: function(params) {
			// 		var input = document.createElement('input');
			// 		input.type="checkbox";
			// 		params.value=0;
			// 		var status = false;
			// 		if(params.data.is_critical == true)
			// 		{
			// 			status=true;
			// 			input.checked=true;
			// 			params.value=1;
			// 		}
			// 		input.addEventListener('click', function (event) {
			// 			console.log(event);
			// 			params.value=!params.value;
			// 			console.log(params);
			// 			onSetCritical(params.data, params.value)
			// 		});
			// 		return input;
			// 	},
			// 	filter: false,resizable: true

			// }

		];
		const commentColumns = [
			{
				headerName: "Truck No",
				field: "truck_no",
				resizable: true,
				width: 100,
				filter: true
			},
			{
				headerName: "GR No",
				field: "consignment_code",
				resizable: true,
				width: 100,
				filter: true
			},
			{
				headerName: "User Type",
				field: "user_type",
				width: 100,
				resizable: true,
				filter: true,
				valueGetter: function (params) {
					if (params.data.user_type == "ORGUSER") {
						return "MSIL"
					}
					if (params.data.user_type == "DCTUSER") {
						return "DCT"
					}
					if (params.data.user_type == "TRANSPORTER") {
						return "TRANSPORTER"
					}
				}
			},
			{
				headerName: "Comment",
				field: "comment",
				cellClass: ['wraptext'],
				resizable: true,
				filter: true
			},
			{
				headerName: "Commented By",
				field: "username",
				cellClass: ['wraptext'],
				resizable: true,
				width: 100,
				filter: true
			},

			{
				headerName: "Commented Date",
				field: "comment_date",
				resizable: true,
				filter: true,
				valueGetter: function (params) {
					if (params.data.comment_date != "") {
						return getYYYYMMDDHHMMSS(params.data.comment_date);
					}
				}
			}

		]
		const dStyles = {
			width: this.props.width,
			height: '500px',
			marginLeft: this.props.marginLeft,

		}
		var hText = 'All';
		var filterClassName = "form-group col-xl-12 col-lg-12";
		var filterCardStyle1 = { minHeight: "234px" };
		var filterCardStyle2 = { padding: '10px' };
		var mnFilterClass = { marginLeft: "0px", padding: "0px" };
		if (this.state.deptcode == 'SNDG') {
			hText = 'Sales and Dispatch'
			filterClassName = "form-group col-xl-12 col-lg-12";
			filterCardStyle1 = { minHeight: "234px" };
			filterCardStyle2 = { padding: '10px' };
		}

		if (this.state.deptcode == 'LOG-PRT') {
			hText = 'Spare Parts'
			mnFilterClass = { marginLeft: "0px" };
		}
		if (this.state.deptcode == 'LOG-TNP') {
			hText = 'Train and Production'
			filterCardStyle1 = { minHeight: "240px" };
		}
		const tripoptoins = [
			{ "value": "0", label: "All" },
			{ "value": "S", label: "Short Haul" },
			{ "value": "L", label: "Long Haul" }
		]
		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.alert}
				{/* {(this.state.deptcode == 'LOG-TNP')?
				<div className="row col-xl-12 col-lg-12">
					<div className="row col-xl-12 col-lg-12">
						<div className="form-group col-xl-4 col-lg-4">
							<label>Trip Type: </label>
							<Select value={this.state.triptype} className="border-radius-0" onChange={this.onSelectTrip.bind(this)} style={{borderRadius:"0px"}} options={tripoptoins} required />

						</div>

						<div className="form-group col-xl-4 col-lg-4">
							<label>Select Consigner: </label>Transporter :
							<Select closeMenuOnSelect={false} value={this.state.consigner} isMulti="true" className="border-radius-0" onChange={this.changeConsignerArrayHandler.bind(this)} style={{borderRadius:"0px"}} options={this.selectConsignerOptionsItems()} required />

						</div>
						<div className="form-group col-xl-4 col-lg-4">
							<label>&nbsp;</label><br />
							<button type="button" className="btn btn-success" onClick={this.onClickTripButton.bind(this)}>Submit</button>
						</div>

					</div>
				</div>
				:""} */}

				{/* {(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG')? */}
				<div className="row" style={{ marginBottom: "1%" }}>
					<div className="col-xl-12 col-lg-12">
						<button onClick={this.reloadPageData} className="btn btn-info" style={{ float: "right" }}>Reset Filters</button>
					</div>
				</div>
				<div className="row">
					{/* <div className="form-group col-xl-4 col-lg-4">
						<label>Select Consigner: </label>
						<Select
						 closeMenuOnSelect={false}
						value={this.state.consigner}
						isMulti="true"
						className="border-radius-0"
						onChange={this.changeConsignerArrayHandler.bind(this)}
						style={{borderRadius:"0px"}}
						options={this.selectConsignerOptionsItems()}
						required />

					</div> 
					 <>
						<div className="col-xl-2 col-lg-2 form-group">
							<label>From Date</label>
							<input type="text" placeholder="From Date" id="from_date" autoComplete="off" value={this.state.startDate}  className="datetimepicker_date form-control"  required />
							
						</div>

						<div className="col-xl-2 col-lg-2 form-group">
							<label>To Date</label>
							<input type="text" placeholder="To Date" id="to_date" value={this.state.endDate}  autoComplete="off" className="datetimepicker_date form-control"  required />
							
						</div>
						<div className="form-group col-xl-2 col-lg-2">
							<label>&nbsp;</label><br />
							<button type="button" className="btn btn-success" onClick={this.onGetConsigners.bind(this)}>Submit</button>
						</div>
					</> */}
					<div className="col-xl-4 col-lg-4">
						<div className="card" style={filterCardStyle1}>
							<div className="row card-body" style={{ padding: "10px 30px" }}>
								<form className="row" onSubmit={this.getTruckConsignments}>
									<div className={filterClassName}>
										<label>Select Type :  </label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedFilter"
											style={{ borderRadius: "0px" }}
											options={[{ label: "Truck", value: "Truck" }, { label: "GR No", value: "Consingments" }]}
											onChange={e => { this.setState({ selectedFilter: e.label }) }}
											required />
									</div>
									<div className={filterClassName}>
										<label className="reason">Truck / GR No</label>
										<input type="text" name="reason" autoComplete="off" class="form-control" id="inputTruckConsignment" />
									</div>
									<div className="form-group col-xl-12 col-lg-12" style={{ textAlign: "left" }}>
										<button type="submit" className="btn btn-success">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className="col-xl-8 col-lg-8">
						<div className="card" style={filterCardStyle1}>
							<div className="row card-body" style={filterCardStyle2}>
								{/* {(this.state.deptcode == 'LOG-TNP')?
							<div className="row col-xl-12 col-lg-12">
								<div className="row col-xl-12 col-lg-12">
									<div className="form-group col-xl-3 col-lg-3">
										<label>Trip Type: </label>
										<Select placeholder={"All"} value={this.state.triptype} className="border-radius-0" onChange={this.onSelectTrip} style={{borderRadius:"0px"}} options={tripoptoins} required />
									</div>

									<div className="form-group col-xl-3 col-lg-3">
										<label>Select Consigner: </label>
										<Select placeholder={"All"} closeMenuOnSelect={false} value={this.state.consigner} isMulti="true" className="border-radius-0" onChange={this.changeConsignerArrayHandler} style={{borderRadius:"0px"}} options={this.selectConsignerOptionsItems()} required />

									</div>
									<div className="col-xl-3 col-lg-3 form-group">
										<label>From Date</label>
										<Datetime value={this.state.startDate}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off' }}
										dateFormat="YYYY-MM-DD"
										name="startDate"
										onChange={this.handlerStartDateTime} />
									</div>

									<div className="col-xl-3 col-lg-3 form-group">
										<label>To Date</label>								
										<Datetime value={this.state.endDate}
										disableCloseOnClickOutside={false}
										closeOnSelect={true}
										inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off'}}
										dateFormat="YYYY-MM-DD"
										onChange={this.handlerEndDateTime} />

									</div>
								</div>
							</div>
							:""} */}

								{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') ?
									<div className="row col-xl-12 col-lg-12" style={mnFilterClass}>
										{/* {(this.state.deptcode == 'LOG-PRT') ?
											<div className="form-group col-xl-2 col-lg-2 n-p-0">
												<label>Select Type: </label>
												<Select placeholder={"All"} closeMenuOnSelect={true} value={this.state.movementtype} className="border-radius-0" onChange={this.changeMovementArrayHandler} style={{ borderRadius: "0px" }} options={this.selectMovementTypeOptionsItems()} required />

											</div>
											: ""} */}
										<div className="form-group col-xl-4 col-lg-4">
											<label>Select Consigner: </label>
											<Select
												placeholder={"All"} closeMenuOnSelect={false}
												value={this.state.consigner} isMulti="true"
												className="border-radius-0"
												onChange={this.changeConsignerArrayHandler}
												style={{ borderRadius: "0px" }}
												options={this.selectConsignerOptionsItems()} required />

										</div>
										<div className="col-xl-3 col-lg-3 form-group">
											<label>From Date</label>
											{/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}
											<Datetime
												value={this.state.startDate}
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off' }}
												dateFormat="YYYY-MM-DD"
												name="startDate"
												onChange={this.handlerStartDateTime} />
										</div>

										<div className="col-xl-3 col-lg-3 form-group">
											<label>To Date</label>
											{/* <input type="text" placeholder="To Date" id="to_date" value={this.state.endDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}
											<Datetime
												value={this.state.endDate}
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off' }}
												dateFormat="YYYY-MM-DD"
												onChange={this.handlerEndDateTime} />
										</div>
										{/* {(this.state.deptcode == 'LOG-PRT')?
								<div className="form-group col-xl-12 col-lg-12 n-p-0">
									<button type="button" className="btn btn-success" onClick={this.onGetConsigners.bind(this)}>Submit</button>
								</div>:""} */}

									</div>
									: ""}

								<div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px", padding: "0px" }}>
									{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>Consignment City :  </label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedCity"
												style={{ borderRadius: "0px" }}
												value={this.state.flCity}
												onChange={this.newFilter.bind(this, "flCity")}
												options={this.state.filterCities}
											/>
										</div>
										: ""}
									{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>Consignment State :  </label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedState"
												style={{ borderRadius: "0px" }}
												value={this.state.flState}
												onChange={this.newFilter.bind(this, "flState")}
												options={this.state.filterStates}
											/>
										</div>
										: ""}
									{/* {(this.state.deptcode == 'SNDG') ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>Zone :  </label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedCluster"
												style={{ borderRadius: "0px" }}
												value={this.state.flZone}
												onChange={this.newFilter.bind(this, "flZone")}
												options={this.state.filterZones}
											/>
										</div>
										: ""}
									<div className="form-group col-xl-3 col-lg-3">
										<label>Transporter :  </label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedTransporter"
											style={{ borderRadius: "0px" }}
											value={this.state.flTransporter}
											onChange={this.newFilter.bind(this, "flTransporter")}
											options={this.state.filterTransporters}
										/>
									</div> */}
									{/* <div className="form-group col-xl-12 col-lg-12" style={{textAlign:"left"}}>
										<button type="submit"  className="btn btn-success">Submit</button> &nbsp;&nbsp;
										<button type="button" onClick={this.ClearNewFilter.bind(this)} className="btn btn-danger">Clear</button>
									</div> */}
								</div>
								{(this.state.deptcode == "LOG-TNP") ?
									<div className="form-group col-xl-12 col-lg-12">
										<button type="button" style={{ margin: "0 auto" }} className="btn btn-success" onClick={this.onClickTripButton}>Submit</button>
									</div>
									:
									<div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px" }}>
										<button type="button" className="btn btn-success" onClick={this.onGetConsigners}>Submit</button>
									</div>
								}
							</div>
						</div>
					</div>

				</div>
				{/*  :""} */}

				<Counters
					context={this}
					gridData={this.state.allRowData}
					deptcode={this.state.deptcode}
				/>
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Ontrip Trucks ({hText}) </span>

								</h5>
							</div>
							<div className="card-body pt-10px" >
								<div className="row">
									<div className="col-xl-12 col-lg-12 mb-10p">

										<span className="float-right" style={{ fontSize: "13px", fontWeight: "100" }}>
											<i className="icofont icofont-map-pins f20" style={{ fontSize: "22px" }}></i> - View Route
											&nbsp;
											<i className="icofont icofont-clock-time" style={{ color: "#ff0000", fontSize: "22px" }}></i> - Transit Delay
											&nbsp;<i className="icofont icofont-social-google-map txt-danger" style={{ fontSize: "22px" }}></i> - GPS Data Not Available
											&nbsp;<i className="icofont icofont-social-google-map txt-secondary" style={{ fontSize: "22px" }}></i> - GPS Data Not Adequate
											&nbsp;<i className="fa fa-tachometer txt-success" style={{ fontSize: "22px	" }}></i> - Overspeed
											{!(this.state.deptcode == 'LOG-PRT') ?
												<span>
													<span>&nbsp;<i className="icofont icofont-full-night txt-primary" style={{ fontSize: "22px" }}></i> - Night Driving
													</span>
													<span>&nbsp;<i className="icofont icofont-truck-alt darkorange" style={{ fontSize: "22px" }}></i> - Loading Delay
													</span>

												</span>
												: ""}
											<span>&nbsp;<i className="icofont icofont-pause maroon" style={{ fontSize: "22px" }}></i> - Enroute Stoppages
											</span>
											<span>&nbsp;<i className="icofont icofont-list" style={{ fontSize: "22px" }}></i> - Day Wise Distance Trip
											</span>
											{/*&nbsp;<i className="icofont icofont-pause" style={{color:"rgb(174, 70, 239)", fontSize:"22px	"}}></i> - Enroute Stoppages*/}


										</span>

									</div>
									<div className="col-xl-12 mb-10p">
										<button className="float-left custom-btn btn-warning" onClick={this.onClickShowMapView.bind(this)}>
											<i className="icofont icofont-social-google-map"></i> Map View
										</button>

										<span className="layoutbtns">

											<button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
												<i className="icofont icofont-save"></i> Save Grid Layout
											</button>
											<button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
												<i className="icofont icofont-refresh"></i> Reset Default Layout
											</button>
											<a href={"/sndconsignments"} className="float-right custom-btn white btn-success" style={{ marginRight: "10px" }}>
												Go To Consignments
											</a>
										</span>
									</div>
								</div>
								{/*
				   				   <Grid
								   ashow={this.state.show}
								   atype={this.state.basicType}
								   atitle={this.state.basicTitle}
								   actionCall={"manage"}
									actionMethod={"updateTruckRow"}
									table={"trucklocation"}
									columnyaxis={this.state.rowData}
									columnaxis={columnwithDefs}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									childconfs={this.state.childconfs}
									childrow={this.state.childrow}
									context={this }

									/>
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
								*/}

								<div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										//floatingFilter={true}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										//onCellClicked={this.onRowClicked.bind(this)}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										groupMultiAutoColumn={true}
										rowSelection={this.state.rowSelection}
										onRowSelected={this.onRowSelection.bind(this)}
										suppressRowClickSelection={true}
										overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
									/>


								</div>
							</div>
						</div>
					</div>

				</div>
				{this.state.sliderCommentTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderCommentTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								Consignment Comments
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>
							<div id="myGrid1" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
								<AgGridReact
									modules={this.state.modules}
									columnDefs={commentColumns}
									defaultColDef={this.state.defaultColDef}
									rowData={this.state.commentsRowData}
									// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
									enableCharts={true}
									enableRangeSelection={true}
									autoGroupColumnDef={this.state.autoGroupColumnDef}
									onGridReady={this.onGridReady}
									onGridState={this.onGridState}
									statusBar={this.state.statusBar}
									// sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}

								/>



							</div>
						</div>

					</div>
					: ""
				}
				{this.state.sliderTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								Timeline #{this.state.timelinedata[0].consignment_code}
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseUploadDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>

							<div className="row col-xl-12 col-lg-12">
								<VerticalTimeline>
									<VerticalTimelineElement
										className="vertical-timeline-element--work"
										contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', borderTopLeftRadius: "20%", borderBottomRightRadius: "20%" }}
										contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
										date="Basic Information"
										iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}

									>
										<h3 className="vertical-timeline-element-title">Truck No</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].truck_no}</h4>
										<h3 className="vertical-timeline-element-title">Transporter Code</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].transporter_code}</h4>
										<h3 className="vertical-timeline-element-title">Department Code</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].dept_code}</h4>
										<h3 className="vertical-timeline-element-title">Vehicle Mode</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].vehicle_mode}</h4>
										<h3 className="vertical-timeline-element-title">Items</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].items}</h4>
										<h3 className="vertical-timeline-element-title">Distance (Kms)</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].distance_in_km}</h4>

									</VerticalTimelineElement>


									<VerticalTimelineElement
										className="vertical-timeline-element--work"
										date="Gate in time"
										contentStyle={{ background: 'rgb(234, 68, 99)', color: '#fff', borderTopRightRadius: "20%", borderBottomLeftRadius: "20%" }}
										contentArrowStyle={{ borderRight: '7px solid  rgb(243, 33, 33)' }}
										iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}

									>
										<h3 className="vertical-timeline-element-title">MSIL Gate intime</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].gate_in_time}</h4>

										<h3 className="vertical-timeline-element-title">GPS Gate intime</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].inside_fence}</h4>

										<h3 className="vertical-timeline-element-title">Trip Start time</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].invoice_time}</h4>

									</VerticalTimelineElement>

									<VerticalTimelineElement
										className="vertical-timeline-element--work"
										date="MSIL Gate out time"
										contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', borderTopLeftRadius: "20%", borderBottomRightRadius: "20%" }}
										contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
										iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}

									>
										<h3 className="vertical-timeline-element-title">MSIL Gate out time</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].gate_out_time}</h4>

										<h3 className="vertical-timeline-element-title">GPS Gate out time</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].outside_fence}</h4>

									</VerticalTimelineElement>

									<VerticalTimelineElement
										className="vertical-timeline-element--work"
										date="Consignee Code"
										contentStyle={{ background: 'rgb(234, 68, 99)', color: '#fff', borderTopRightRadius: "20%", borderBottomLeftRadius: "20%" }}
										contentArrowStyle={{ borderRight: '7px solid  rgb(243, 33, 33)' }}
										iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}

									>
										<h3 className="vertical-timeline-element-title">Consignee Code</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].consignee_code}</h4>

									</VerticalTimelineElement>

								</VerticalTimeline>

							</div>
						</div>
					</div>
					: ""
				}

				{this.state.sliderRouteTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>

							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								{console.log("this.state.mapinfo ", this.state.mapinfo)}
								<DrawMap
									mapFor={"truck"}
									consignercords={this.state.consigner_coordinates}
									tolls={this.state.tolls}
									consigneecords={this.state.consignee_coordinates}
									rownode={this.state.rownode}
									truckno={this.state.maptruckno}
									dealer={this.state.dealer}
									mapinfo={this.state.mapinfo}
									defTransitCoords={this.state.defTransitCoords} />
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{/* <div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div> */}
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.startTime) : "NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.endTime) : "NA"}</div>
											</div>

											<div className="col route-block">
												<label className="sidebar-label">Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details)) ? Math.round(this.state.mapinfo.route_details.distance / 1000) : "0"} Kms</div>
											</div>
											{/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}

										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
					: ""
				}
				<Modal open={open} onClose={this.onCloseModal} styles={modalStyles} classNames={{ overlay: 'customOverlay', modal: 'customModal' }}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', }} >
						{(this.state.maprowData != '') ?
							<ConsignmentDrawMap
								context={this} griddata={this.state.maprowData}
								consigner_coordinates={this.state.consigner_coordinates}
								consigneecoords={this.state.consigneecoords}
								rownode={this.state.rownode}
								mapFor={"consignment"} />
							: ""}

					</div>
				</Modal>

				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				<div className={"slide-r " + (this.state.slideBar)} >
					{this.displayData}
				</div>
			</div>

		);
	}
}
function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}
function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}
function timeConvert(n) {
	// var num = n/1000;
	// var hours = (num / (3600*24));
	// var rhours = Math.floor(hours);
	// var minutes = (n) / (60*60);
	// var rminutes = Math.round(minutes);

	var diffMs = n;
	var diffDays = Math.floor(diffMs / 86400000); // days
	var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


	return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
	var timeinfo = new window.google.maps.InfoWindow({
		content: "Time at this location: " + timedet
	});

	marker.addListener('click', function () {

		if (currentinfowindow != null) {
			currentinfowindow.close();
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}
		else {
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}

	});
}

function onSetCritical(params, status) {
	// console.log("onSetCritical ", params);
	// console.log("onSetCritical value ", status);
	redirectURL.post('/consignments/setCriticalStatus', {
		params: params,
		is_critical: status
	})
		.then((response) => {

			//var records = JSON.parse(JSON.stringify(response)).data;
			// console.log(response.data);
		});

}


function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	// console.log(yearNumber, monthNumber, dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}

function loadDateTimeScript() {
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask: '39-19-9999',
		format: 'd-m-Y',
		timepicker: false
	});
	var index = window.document.getElementsByTagName("script")[1]
	var script = window.document.createElement("script")
	script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

function GetSortDescOrder(prop) {
	return function (a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		} else if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	}
}
