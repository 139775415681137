
import React, { Component } from 'react';

 
const GridButton = (props) => {
    
   const handleOnClick = () => {
        props.colDef.params.onClickFunction(props,true);
    };
    var iconName = "fa fa-edit";
    var gButton = "btn btn-outline-success";
    var title = "Action Button"
    if(props.colDef.params.iconName !=undefined && props.colDef.params.iconName !="")
    {
        iconName = props.colDef.params.iconName;
        gButton = props.colDef.params.gButton;
    }
    if(props.colDef.params.buttonCls !=undefined && props.colDef.params.buttonCls !="")
    {
        gButton = props.colDef.params.buttonCls;
    }
    if(props.colDef.params.title){
        title = props.colDef.params.title
    }
    return (
        <div style={{"textAlign":"center"}}>
           
            <button className={gButton+" grid-btn"} onClick={handleOnClick} style={{border:"0px"}} title={title}>
                <i className={iconName}></i> {/*props.colDef.params.buttonName*/}
            </button>
            
        </div>
    );
};

export default GridButton;
