import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import ComponentIndex from '../common/appendComponents';
var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`)

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var doOnce = true;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;
export default class VicinityScreen extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: null,

			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
			columnDefs: [
				{
				  headerName: "Truck No",
				  field: "truck_no",
				  width: 200,
				  
				  
				},
				{
				  headerName: "LSP Code",
				  field: "transporter_code",
				  width: 200,
					  
			  },  	        
			  {
				headerName: "LSP Name",
				field: "transporter_name",
				width: 200,
					
			  },
				{
				headerName: "Department Code",
				field: "dept_code",
				width: 200,
					
			  },
				{
				headerName: "Actual GPS Provider",
				field: "actual_lspuser",
				width: 200,
					
			  },
			  {
				headerName: "Address",
				field: "address",
				width: 200,
				  
			 },
			 {
				headerName: "Distance",
				field: "distance",
				width: 200,
					
			  },
				{
				headerName: "Last Packet Time",
				field: "timestamp",
				width: 200,
					
			  },
			  {
				headerName: "Data Received On",
				field: "last_packet_timestamp",
				width: 200
			  }
				 
				 
			  ],
		};
		this.popmarker=this.popmarker.bind(this);
		this.goBack = this.goBack.bind(this);
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	
    componentDidMount(){
		console.log("path ", this.props.match.path)
		var upath = this.props.match.path.split("/")
		console.log("upath ", upath)
		var deptcode = upath[2];
		this.setState({
			dept_code:deptcode
		});
		this.renderMapDefault();
		
    }
	renderMapDefault = () => {
    	
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBI9tCwOL8bO4iIFRCWucbzeWfJOEX-y34&libraries=places,drawing&callback=initMapDefault");
		window.initMapDefault = this.initMapDefault
 	}
	renderMap = () => {		
		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBI9tCwOL8bO4iIFRCWucbzeWfJOEX-y34&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}
	initMapDefault = () => {
		
		var lt=28.4519751;
		var ln=77.0310713;
	
    	map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 13,
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			/*mapTypeControl: true,
			gestureHandling: 'greedy',
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
              },            
            disableDefaultUI: true,
            zoomControl: true*/
		});

		var input = document.getElementById('search');
		var searchBox = new window.google.maps.places.SearchBox(input);
		map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

		// Bias the SearchBox results towards current map's viewport.
		map.addListener('bounds_changed', function() {

		  searchBox.setBounds(map.getBounds());
		});
		
		var markers = [];
		//console.log("searchBox",searchBox);
		 searchBox.addListener('places_changed', function() {
		  var places = searchBox.getPlaces();
			
		  if (places.length == 0) {
			return;
		  }

		  // Clear out the old markers.
		  markers.forEach(function(marker) {
			  console.log("Search Marker ", marker)
			marker.setMap(null);
		  });
		  markers = [];
// For each place, get the icon, name and location.
		  var bounds = new window.google.maps.LatLngBounds();
		  places.forEach(function(place) {
			if (!place.geometry) {
			  console.log("Returned place contains no geometry");
			  return;
			}
			var icon = {
			  url: place.icon,
			  size: new window.google.maps.Size(71, 71),
			  origin: new window.google.maps.Point(0, 0),
			  anchor: new window.google.maps.Point(17, 34),
			  scaledSize: new window.google.maps.Size(25, 25)
			};

			// Create a marker for each place.
			console.log("Plae Lat", place.geometry.location.lat());
			document.getElementById("latitude").value =place.geometry.location.lat();

			document.getElementById("longitude").value =place.geometry.location.lng();
			
			markers.push(new window.google.maps.Marker({
			  map: map,
			  icon: icon,
			  title: place.name,
			  position: place.geometry.location
			}));

			if (place.geometry.viewport) {
			  // Only geocodes have viewport.
			  bounds.union(place.geometry.viewport);
			} else {
			  bounds.extend(place.geometry.location);
			}
		  });
		  map.fitBounds(bounds);
		});	

		circle = new window.google.maps.Circle({
            map: map
		});
		
		marker = new window.google.maps.Marker({
			map: map,
			draggable: true,
			animation: window.google.maps.Animation.DROP,
			//position: {lat: 28.48, lng: 77.06}
		});
		
		marker.addListener('click', function(){
			marker.setAnimation(window.google.maps.Animation.BOUNCE)			
		});
		
  		window.google.maps.event.addListener(map, 'click', function(event) {
			
			document.getElementById("latitude").value = event.latLng.lat();
			document.getElementById("longitude").value = event.latLng.lng();
			marker.setPosition(event.latLng);
			
		});
		circle.bindTo('center', marker, 'position');
		
		
		
		var eventtrigger = document.getElementsByClassName('truck');

		  // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
		  //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
		 
	}
	 initMap = () => {
		var contentdata = this.state.contentString;
		if(this.state.rlatitude == '' && this.state.rlongitude == '')
		{
			var lt=28.4519751;
			var ln=77.0310713;
		}
		else{
			var lt=this.state.rlatitude;
			var ln=this.state.rlongitude;
		}
    	map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 13,
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
			/*mapTypeControl: true,
			gestureHandling: 'greedy',
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
              },            
            disableDefaultUI: true,
            zoomControl: true*/
		});

		var input = document.getElementById('search');
		var searchBox = new window.google.maps.places.SearchBox(input);
		map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

		// Bias the SearchBox results towards current map's viewport.
		map.addListener('bounds_changed', function() {

		  searchBox.setBounds(map.getBounds());
		});
		
		var markers = [];
		//console.log("searchBox",searchBox);
		 searchBox.addListener('places_changed', function() {
		  var places = searchBox.getPlaces();
			
		  if (places.length == 0) {
			return;
		  }

		  // Clear out the old markers.
		  markers.forEach(function(marker) {
			  console.log("Search Marker ", marker)
			marker.setMap(null);
		  });
		  markers = [];
// For each place, get the icon, name and location.
		  var bounds = new window.google.maps.LatLngBounds();
		  places.forEach(function(place) {
			if (!place.geometry) {
			  console.log("Returned place contains no geometry");
			  return;
			}
			var icon = {
			  url: place.icon,
			  size: new window.google.maps.Size(71, 71),
			  origin: new window.google.maps.Point(0, 0),
			  anchor: new window.google.maps.Point(17, 34),
			  scaledSize: new window.google.maps.Size(25, 25)
			};

			// Create a marker for each place.
			console.log("Plae Lat", place.geometry.location.lat());
			document.getElementById("latitude").value =place.geometry.location.lat();

			document.getElementById("longitude").value =place.geometry.location.lng();
			
			markers.push(new window.google.maps.Marker({
			  map: map,
			  icon: icon,
			  title: place.name,
			  position: place.geometry.location
			}));

			if (place.geometry.viewport) {
			  // Only geocodes have viewport.
			  bounds.union(place.geometry.viewport);
			} else {
			  bounds.extend(place.geometry.location);
			}
		  });
		  map.fitBounds(bounds);
		});	

		circle = new window.google.maps.Circle({
            map: map
		});
		
		marker = new window.google.maps.Marker({
			map: map,
			draggable: true,
			animation: window.google.maps.Animation.DROP,
			//position: {lat: 28.48, lng: 77.06}
		});
		
		marker.addListener('click', function(){
			marker.setAnimation(window.google.maps.Animation.BOUNCE)			
		});
		
  		window.google.maps.event.addListener(map, 'click', function(event) {
			
			document.getElementById("latitude").value = event.latLng.lat();
			document.getElementById("longitude").value = event.latLng.lng();
			marker.setPosition(event.latLng);
			
		});
		circle.bindTo('center', marker, 'position');
		var markerLatlng1;
		//console.log("Radius ", this.state.radius)
		//console.log("state Lat ",this.state.rlatitude)
		if(this.state.rlatitude != '' && this.state.rlongitude != '')
		{
			//var geoFencingObj = allcords;
			//console.log(parseFloat(this.state.rlatitude),parseFloat(this.state.rlongitude));
			markerLatlng1 = new window.google.maps.LatLng(this.state.rlatitude,this.state.rlongitude);
			//console.log("markerLatlng", markerLatlng1)
			var circle = new window.google.maps.Circle({
				strokeColor: '#71caab',
				strokeOpacity: 0.7,
				strokeWeight: 1,
				fillColor: '#71caab',
				fillOpacity: 0.25,
				map: map,
				center: markerLatlng1,
				radius: Math.sqrt(this.state.radius) * 10000
			});

		}
		var mainmark = new window.google.maps.Marker({
			position: new window.google.maps.LatLng(parseFloat(this.state.rlatitude), parseFloat(this.state.rlongitude)),
			map: map
		});
		mainmark.setMap(map);
		//console.log("allcords initmap ", allcords)
		if(doOnce){
        	initOMS();
          	doOnce = false;
        }
		allcords.map((marker) => {
			//console.log("marker ", marker)
			if(marker.status == 1)
			{
				var truckText =  "Inside Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_inside_24.png');
			}
			else if(marker.status == 2)
			{
				var truckText =  "Going to Destination";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_going_24.png');
			}
			else if(marker.status == 3 || marker.status == 4) 
			{
				var truckText =  "At Destination Location";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_atdealer_24.png');
			}
			else if(marker.status == 5)
			{
				var truckText =  "Left Destination Location";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_leftdealer_24.png');
			}
			else if(marker.status == 6)
			{
				var truckText =  "Return to Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_return_24.png');
			}
			else if(marker.status == 7)
			{
				var truckText =  "Empty Truck Outside Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_empty_24.png');
			}
			else{
				var truckText =  "N/A";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_na_24.png');
			}
			var image =  require('../../assets/icons/truck_na_24.png');
			var contentarr = []
			var headerTitle = truckText
			contentarr.push({"key":"Truck No", "value":marker.truck_no});
			contentarr.push({"key":"Transporter Name", "value":marker.transporter_name});
			contentarr.push({"key":"Distance from geofence", "value":marker.distance});
			contentarr.push({"key":"LSP Name", "value":marker.lsp_name});
			contentarr.push({"key":"Address", "value":marker.address});
			
			var contentString = infoBox(image, headerTitle, contentarr, '')


			//var contentString = "Here "+marker.truck_no;
			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});
			
			var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));
			var image =  require('../../assets/icons/truck_na_24.png');
			
			/*if(marker.overspeeding_exception == 1)
			{
				var image =  require('../../assets/icons/truck_overspeed.png');
			}
			if(marker.nightdriving_exception == 1)
			{
				var image =  require('../../assets/icons/truck-end.png');
			}*/

			if(marker.status == 1)
			{
				var image =  require('../../assets/icons/truck_inside_24.png');
			}
			else if(marker.status == 2)
			{
				var image =  require('../../assets/icons/truck_going_24.png');
			}
			else if(marker.status == 3 || marker.status == 4) 
			{
				var image =  require('../../assets/icons/truck_atdealer_24.png');
			}
			else if(marker.status == 5)
			{
				var image =  require('../../assets/icons/truck_leftdealer_24.png');
			}
			else if(marker.status == 6)
			{
				var image =  require('../../assets/icons/truck_return_24.png');
			}
			else if(marker.status == 7)
			{
				var image =  require('../../assets/icons/truck_empty_24.png');
			}
			else{
				var image =  require('../../assets/icons/truck_na_24.png');
			}

			var image =  require('../../assets/icons/truck_na_24.png');
			var mark = new window.google.maps.Marker({
                position: markerLatlng,
				map: map,
				title:marker.truck_no,
				icon: image
            });
			mark.addListener('click', function() {
				infowindow.open(map, mark);
			  });
            mark.setMap(map);
		});
		var eventtrigger = document.getElementsByClassName('truck');

		  // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
		  //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
		 
	}
	toggleBounce() {
		if (marker.getAnimation() !== null) {
		  marker.setAnimation(null);
		} else {
		  marker.setAnimation(window.google.maps.Animation.BOUNCE);
		}
		
	  }
	
	changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    
	changeRadiusHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
		this.setState({[name]:value});
		circle.setRadius(value);
    }
    
	formHandler = (event) =>{
		event.preventDefault();
		this.setState({
			loadshow:'show-m',
		});
		var formdata = {
			radius:this.state.radius,
			latitude:document.getElementById("latitude").value,
			longitude:document.getElementById("longitude").value,
			trip_type: 1
		}
		rlatitude=document.getElementById("latitude").value;
		rlongitude=document.getElementById("longitude").value;
		//http://autometrics.in:5000/getNearByTrucks
		redirectURL.post('/getRadiusCoordinates',formdata,{
			headers:{
				'Content-Type':'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
				'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

			}
		})
		.then(
			(response) =>{
			// console.log("response", JSON.parse(response.data.apiData.body));
			allcords=[];
			var allmarkers=JSON.parse(response.data.apiData.body);
			allmarkers = JSON.parse(allmarkers.trucks_data);
			//console.log("Dept ", this.state.dept_code);
			// console.log("allmarkers", allmarkers)
			var dCode = "IBL";
			if(allmarkers.length > 0)
			{
				//console.log("UPpcase Dept ", dCode);
				var markers = [];
				allmarkers.map((item) => {
					if(item.dept_code == dCode.toUpperCase())
					{
						markers.push(item);
					}
				});
				console.log("Markers ", markers);
				this.setState({
					defaultradius:formdata.radius,
					coordinates:markers,
					mwidth:"col-xl-7 col-lg-7",
					rowData:markers,
					radius:formdata.radius,
					loadshow:'show-n',
					showform:'show-n',
					showrefresh:'show-m',
					rlatitude:formdata.latitude,
					rlongitude:formdata.longitude,
				});
				
				
				var coords = markers;
				/*oms = new oms.OverlappingMarkerSpiderfier(map, {
					markersWontMove: true,
					markersWontHide: true,
					keepSpiderfied: true,
					basicFormatEvents: true
				});*/
				var p1 = {lat:parseFloat(formdata.latitude), lng:parseFloat(formdata.longitude)};
				coords.map((marker) => {
					//console.log("marker", marker);
					var p2 = {lat:marker.latitude, lng:marker.longitude};
					//console.log("Distace is ", this.getDistance(p1,p2));
					//console.log(marker.distance +"<= "+ response.data.radius)
					var distance =  this.getDistance(p1,p2);
					//console.log(window.google.maps.geometry.spherical.computeDistanceBetween (p1, p2))
					if (distance <= formdata.radius)
					{
						allcords.push(marker);
					}
					
				});
				window.initMap = this.initMap
				this.renderMap();
			}
			else{
				this.setState({
					show: true, basicType:'success', basicTitle:"No Data found.",
					loadshow:'show-n',
					showform:'show-m',
				});
				
			}
		})
		.catch(function(error){
			console.log(error);
		});
	}
	goBack(){
		window.location.reload();
	}
	getDistance = (p1, p2) => {
		//console.log("p1",p1);
		//console.log("p2",p2);
		var R = 6378137; // Earth’s mean radius in meter
		var dLat = rad(p2.lat - p1.lat);
		var dLong = rad(p2.lng - p1.lng);
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d/1000; // returns the distance in meter
	  };
	onShowInfo = (e) =>{
		console.log(JSON.parse(e.target.id));
		this.setState({
			contentString:"Here Success"
		});
		var ourMarker = e.target.id;
 			window.google.maps.event.trigger(ourMarker, 'click');
		var infowindow = new window.google.maps.InfoWindow({
			content: "Here Success"
		  });
		infowindow.open(map, marker);

	}
	popmarker(e)
	{
		console.log("OnClick Table ",e.data)
		var lat = parseFloat(e.data.latitude);
		var lng =parseFloat(e.data.longitude);
		var data =e.data;
		map.setCenter(new window.google.maps.LatLng(lat,lng));
		map.setZoom(22);
		var markerLatlng = new window.google.maps.LatLng(lat, lng);
		//var image =  require('../../assets/icons/truckblue.png');
		var image =  require('../../assets/icons/truck_na_24.png');
		/*if(data.overspeeding_exception == 1)
		{
			var image =  require('../../assets/icons/truck_overspeed.png');
		}
		
		if(data.nightdriving_exception == 1)
		{
			var image =  require('../../assets/icons/truck-end.png');
		}*/

		if(data.status == 1)
		{
			var truckText =  "Inside Plant";
			var color =  "#333333";
			var image =  require('../../assets/icons/truck_inside_24.png');
		}
		else if(data.status == 2)
		{
			var truckText =  "Going to Destination";
			var color =  "#333333";
			var image =  require('../../assets/icons/truck_going_24.png');
		}
		else if(data.status == 3 || data.status == 4) 
		{
			var truckText =  "At Destination Location";
			var color =  "#333333";
			var image =  require('../../assets/icons/truck_atdealer_24.png');
		}
		else if(data.status == 5)
		{
			var truckText =  "Left Destination Location";
			var color =  "#333333";
			var image =  require('../../assets/icons/truck_leftdealer_24.png');
		}
		else if(data.status == 6)
		{
			var truckText =  "Return to Plant";
			var color =  "#333333";
			var image =  require('../../assets/icons/truck_return_24.png');
		}
		else if(data.status == 7)
		{
			var truckText =  "Empty Truck Outside Plant";
			var color =  "#333333";var image =  require('../../assets/icons/truck_na_24.png');
			var image =  require('../../assets/icons/truck_empty_24.png');
		}
		else{
			var truckText =  "N/A";
			var color =  "#333333";
			var image =  require('../../assets/icons/truck_na_24.png');
		}
		var image =  require('../../assets/icons/truck_na_24.png');
		var marker = new window.google.maps.Marker({
			position: markerLatlng,
			map: map,
			title:data.truck_no,
			icon:image
		});


		var contentarr = []
		var headerTitle = truckText
		contentarr.push({"key":"Truck No", "value":data.truck_no});
		contentarr.push({"key":"Transporter Name", "value":data.transporter_name});
		contentarr.push({"key":"Distance from geofence", "value":data.distance});
		contentarr.push({"key":"LSP Name", "value":data.lsp_name});
		contentarr.push({"key":"Address", "value":data.address});
		
		var contentString = infoBox(image, headerTitle, contentarr, '')
		/*
		var contentString = "Truck No: "+data.truck_no+"<br />Name: "+data.transporter_name+" LSP Name:"+data.lsp_name+"<br />Department:" + data.dept_code+"<br />Address:"+data.address;
		var contentString = "<table><tr><th>Status: </th><td style='text-align: right'>"+truckText+"</td></tr><tr><th>Distance from Geofence: </th><td style='text-align: right'>"+data.distancel
		contentString = contentString + " (KM)</td></tr><th>Truck No: </th><td style='text-align: right'>"+data.truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
		contentString = contentString + data.transporter_name + "</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+data.lsp_name+"</td></tr><tr><th>Department:</th>";
		contentString = contentString + "<td style='text-align: right'>"+data.dept_code+"</td></tr><tr><th>Address:</th><td style='text-align: right'>"+data.address+"</td></tr></table>";
			*/	
		var infowindow = new window.google.maps.InfoWindow({
			content: contentString
		});
		
		marker.setAnimation(window.google.maps.Animation.DROP)
		marker.addListener('click', function() {
			infowindow.open(map, marker);
		});
		
		marker.setMap(map);
		
		
	}
	onShowUploadDiv = () => {
		//console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	 /*Truck Sidebar Component Starts*/
	 async onShowSidebarAquireComponent(e){
	   this.setState({
		sliderTranslate:'slider-translate',
		loadshow:'show-m'
	   });
	   //console.log(e);
	   if(e.colDef.field == 'truck_no')
	   {
		   let ComponentInfo = ComponentIndex['truck'];
		   //console.log("ComponentInfo", ComponentInfo);
		   var layoutdata1 = {
			   truck_no:e.data[e.colDef.field]
		   };
		   //console.log("layoutdata",layoutdata);
		   await redirectURL.post('/masters/truckByNo', layoutdata1)
			   .then((response) => {
				   //console.log("trucks response", response);
				   var truckrecords = JSON.parse(JSON.stringify(response)).data;
				   
				   this.setState({
					   truckInfo:truckrecords
				   });
			   })
			   .catch(function (error) {
				   console.log(error);
			   });

			   /*Trucks details by Truck No*/
		   await redirectURL.post('/masters/latestConsignmentsByTruckNo', layoutdata1)
			   .then((response) => {
				   //console.log(response);
				   var consignrecords = JSON.parse(JSON.stringify(response)).data;
				   
				   this.setState({
					   consignments:consignrecords
				   });
			   })
			   .catch(function (error) {
				   console.log(error);
			   });
		   
		   /*Trucks details by Truck No*/
		   await redirectURL.post('/masters/latestTruckLocation', layoutdata1)
			   .then((response) => {
				   //console.log("HR55W4880",response);
				   var currenttrucklocation = JSON.parse(JSON.stringify(response)).data;
				   
				   this.setState({
					   currenttrucklocation:currenttrucklocation
				   });
			   })
			   .catch(function (error) {
				   console.log(error);
			   });
		   //console.log("currenttrucklocation",this.state.currenttrucklocation);
		   //setTimeout(function(){})
		   
		   this.displayData=await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} truckInfo={this.state.truckInfo}  consignments={this.state.consignments} currenttrucklocation={this.state.currenttrucklocation}></ComponentInfo>);
		   /*		
		   this.setState({
			   showdata : this.displayData,
			   sliderTranslate:"slider-translate"
		   });
		   */

		  this.setState({
			showdata:this.displayData,
			loadshow:'show-n'
		  });
	   }

	   /*Transporters Sidebar*/
	   
	   if(e.colDef.field == 'transporter_name')
	   {
		   let ComponentInfo = ComponentIndex['transporter'];
		   //console.log("ComponentInfo", ComponentInfo);
		   var layoutdata1 = {
			   transporter_name:e.data[e.colDef.field]
		   };
		   //console.log("layoutdata",layoutdata);
		   await redirectURL.post('/masters/transporterByName', layoutdata1)
			   .then((response) => {
				   console.log("transporterByName response", response);
				   var transporterrecords = JSON.parse(JSON.stringify(response)).data;
				   var deptcodes=[];
				   var transportName='';
				   var transportCode='';
				   transporterrecords.map(item=>{
					   transportName=item.transporter_name;
					   transportCode=item.transporter_code;
					   deptcodes.push(item.dept_code+", ");
				   });
				   //console.log(transportName+","+transportCode+","+deptcodes)
				   var transportData = [{
					   transporter_name:transportName,
					   transporter_code:transportCode,
					   dept_code:deptcodes
				   }]
				   this.setState({
					   transporterInfo:transportData
				   });
			   })
			   .catch(function (error) {
				   console.log(error);
			   });

		   /*Total Trucks Operating*/
		   await redirectURL.post('/masters/totalTrucksOperating', layoutdata1)
		   .then((response) => {
			   //console.log("Total Trucks Operating response", response);
			   this.setState({
				   truckoperates:response
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });

		   /*Total Trucks With GPS Integration Lifetime*/
		   await redirectURL.post('/masters/totalTrucksGPSLifetime', layoutdata1)
		   .then((response) => {
			   console.log("Total Trucks Operating response", response);
			   this.setState({
				   truckgpslifetime:response
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });
			   
		   /*Total Trucks With GPS Integration Today*/
		   await redirectURL.post('/masters/totalTrucksGPSToday', layoutdata1)
		   .then((response) => {
			   //console.log("Total Trucks Operating response", response);
			   this.setState({
				   gpstoday:response
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });

			   /*Transporters Consignment details*/
		   await redirectURL.post('/masters/latestConsignmentsByTransporterName', layoutdata1)
			   .then((response) => {
				   //console.log(response);
				   var consignrecords = JSON.parse(JSON.stringify(response)).data;
				   var consignrecordslist=[];
				   consignrecords.map(item=>{
					   if(item.consigns.length > 0)
					   {
						   item.consigns.map(cons => {
							   consignrecordslist.push(cons)
						   })
						   
					   }
				   });
				   
				   console.log("consignrecords list", consignrecordslist);
				   this.setState({
					   consignments:consignrecordslist
				   });
			   })
			   .catch(function (error) {
				   console.log(error);
			   });
		   
		   
		   this.displayData=await (<ComponentInfo context={this} gpstoday={this.state.gpstoday} gpsLifetime={this.state.truckgpslifetime} transportName={e.data[e.colDef.field]} transporterInfo={this.state.transporterInfo} totalTruckOperating={this.state.truckoperates} consignments={this.state.consignments} ></ComponentInfo>);
		   
		   this.setState({
			showdata:this.displayData,
			loadshow:'show-n'
		  });
	   }
	   /*End*/
	   
	   /*LSP Sidebar*/
	   
	   if(e.colDef.field == 'actual_lspuser')
	   {
		   let ComponentInfo = ComponentIndex['lsp'];
		   //console.log("ComponentInfo", ComponentInfo);
		   var layoutdata2 = {
			   actual_lspuser:e.data[e.colDef.field]
		   };
		   console.log("layoutdata2", layoutdata2);
		   //Total Trucks Integrate;
		   await redirectURL.post('/masters/totalTruckIntegrate', layoutdata2)
			   .then((response) => {
				   var lsprecords = JSON.parse(JSON.stringify(response)).data.count;
				   //console.log("LSP response serveeeee", lsprecords);
				   
				   var result = lsprecords.reduce(function(sum, item){
					   return sum = sum+item.count;
				   },0);
					   console.log("result", result);
				   this.setState({
					   lspInfo:result
				   });
			   })
			   .catch(function (error) {
				   console.log(error);
			   });

		   /*Department Serving*/
		   await redirectURL.post('/masters/totalDeptServe', layoutdata2)
		   .then((response) => {
			   var deprecords = JSON.parse(JSON.stringify(response)).data;
			   //console.log("LSP DeptServe response", deprecords);
			   
			   var deptcnt=[];
			   deprecords.map(item => {
				   deptcnt.push(item._id)
			   });
			   this.setState({
				   deptServes:deptcnt.length
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });

		   /*No Of Transporters Serving*/
		   await redirectURL.post('/masters/totalTransporterServe', layoutdata2)
		   .then((response) => {
			   var transrecords = JSON.parse(JSON.stringify(response)).data;
			   //console.log("Transporter serve response", transrecords);
			   var trasportcnt=[];
			   transrecords.map(item => {
				   trasportcnt.push(item._id)
			   });
			   this.setState({
				   transrecordserves:trasportcnt.length
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });

		   /*No Of GPS DATA Send Today*/
		   await redirectURL.post('/masters/totalGPSSendToday', layoutdata2)
		   .then((response) => {
			   var gpssendrecords = JSON.parse(JSON.stringify(response)).data;
			   //console.log("GPS Send Data response", gpssendrecords);
			   
			   this.setState({
				   gpssendtoday:gpssendrecords.length
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });

		   /*Total Trucks with no transporter data*/
		   await redirectURL.post('/masters/trucksWithNoTransporter', layoutdata2)
		   .then((response) => {
			   var notransrecords = JSON.parse(JSON.stringify(response)).data;
			   //console.log("No Trasnporter Data response", notransrecords);
			   
			   this.setState({
				   nopetransporters:notransrecords.length
			   });
		   })
		   .catch(function (error) {
			   console.log(error);
		   });
		   
		   this.displayData=await (<ComponentInfo context={this} nopetransporters={this.state.nopetransporters} gpssendtoday={this.state.gpssendtoday} transportersServes={this.state.transrecordserves} deptServes={this.state.deptServes} lspName={e.data[e.colDef.field]} lspInfo={this.state.lspInfo} ></ComponentInfo>);
		   /*		
		   this.setState({
			   showdata : this.displayData,
			   sliderTranslate:"slider-translate"
		   });
		   */

		  this.setState({
			showdata:this.displayData,
			loadshow:'show-n'
		  });

	   }
	   /*End*/
   }

    render(){
		var hText = 'All';
		if(this.state.dept_code == 'sndg')
		{
			hText = 'Sales and Dispatch'
		}
		if(this.state.dept_code == 'log-tnp')
		{
			hText = 'Train and Production'
		}
	
		if(this.state.dept_code == 'log-prt')
		{
			hText = 'Spare Parts'
		}
    	const dStyles={
			width:'90%',
			marginLeft:'0px',
			paddingLeft:'0px',
			height:'500px'
		}
		const btstye={
			marginBottom:"10px"
		}
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icon-map cus-i"></i> <span>Trucks Within Geofence ( {hText} ) </span>
				       				{/*<span className="float-right">
										<img className="mapico2" src={require('../../assets/icons/truck_overspeed.png')} /> - Overspeed
										&nbsp;&nbsp;<img className="mapico" src={require('../../assets/icons/truck-end.png')} /> - Night Driving
									
									</span> */}
									<span className="col-xl-12 col-lg-12 pull-right" style={{fontSize:"12px",marginBottom:"20px"}}>
										{/*<img className="mapico1" src={require('../../assets/icons/truck_na_32.png')} /> - N/A
										&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_inside_32.png')} /> - Inside Plant
										&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_going_32.png')} /> - Going to Dealer
										&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_atdealer_32.png')} /> - At Dealer Location
										&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_leftdealer_32.png')} /> - Left Dealer Location
										&nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_return_32.png')} /> - Return to Plant
										&nbsp;&nbsp;<img className="mapico" src={require('../../assets/icons/truck_empty_32.png')} /> - Empty Truck Outside Plant
										*/}
									</span>									
								</h5>
				   			</div>
				   			<div className="card-body row">
							   <form className={" theme-form col-xl-12 col-lg-12 "+ (this.state.showform)} onSubmit={this.formHandler}>
	                    			<div className="row">
					               		<div className="form-group  col-xl-3 col-lg-3">
					               			<label className="c-lbl">Radius *:</label>
					               			<input type="text" name="radius" id="radius" value={this.state.radius} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Radius" required />
					               		</div>
										<div className="form-group col-xl-3 col-lg-3">
					               			<label className="c-lbl">Latitude *:</label>
					               			<input type="text" name="latitude" id="latitude" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Latitude" required />
					               		</div>
										<div className="form-group col-xl-3 col-lg-3">
					               			<label className="c-lbl">Longitude *:</label>
					               			<input type="text" name="longitude" id="longitude" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Longitude" required />
					               		</div>
										<div className="form-group col-xl-3 col-lg-3">
					               			<label className="c-lbl"></label>
											<button type="submit" style={{marginTop:"35px"}} className="btn btn-success">Submit</button>
										</div>
									</div>
									
				
								</form>
								<div className={"col-xl-12 col-lg-12 "+(this.state.showrefresh)}>
										<button type="button" style={{marginRight:"30px"}} className={"btn btn-danger float-right"} onClick={this.goBack} >Back</button>
									</div>
								<div className="row col-xl-12 col-lg-12">
								    
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
									<input type="text" name="search" className="col-xl-6 col-lg-6 form-control mt-5p" style={{marginTop:"12px", height:"37px"}} id="search" placeholder="Enter Address to search" />
				
									<div id="map_canvas" className={this.state.mwidth} style={dStyles}></div>
									{
									(this.state.coordinates.length > 0)?
										<div className="col-xl-5 col-lg-5 sidediv">
											

											<div id="myGrid" style={{ height: "480px",width: "100%"}}  className="ag-theme-balham">
												<AgGridReact
													modules={this.state.modules}
													columnDefs={this.state.columnDefs}
													defaultColDef={this.state.defaultColDef}
													rowData={this.state.coordinates}
													enableCharts={false}
													enableRangeSelection={false}
													autoGroupColumnDef={this.state.autoGroupColumnDef}
													onGridReady={this.onGridReady}
													onGridState={this.onGridState}
													statusBar={this.state.statusBar}
													sideBar={this.state.sideBar}
													paginationPageSize={this.state.paginationPageSize}
													pagination={true}
													floatingFilter={true}
													//onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
												    onCellClicked={this.popmarker}
												/>
											</div>
										</div>
										: ""
									}
								</div>
							   		
                            </div>
				   		</div>	
				   	</div>
				 </div>

				 
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					
				 	<div className="slide-r-body" style={{position:"relative"}}>
					 <div className={"dataLoadpage " +(this.state.loadshow)}>
					</div>
					<div className={"dataLoadpageimg " +(this.state.loadshow)}>
						<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
					</div>
				 		<div className="col-xl-12 col-lg-12">
				 			 {this.displayData}
						</div>
					</div>
				 </div>


            </div>
              
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function getPolygonCoords()
{
	
	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 var coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);
	 
}
function initOMS(){
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
      //markersWontMove: true,
      //keepSpiderfied: true,
      //nearbyDistance: 10,
      //circleFootSeparation: 60,
	  //legWeight: 1.5
	  markersWontMove: true,
	markersWontHide: true,
	keepSpiderfied: true,
	basicFormatEvents: true
    });
  }