import React, { Component } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

class DashboardGrid extends Component {
  constructor(props) {
    super(props);

    this.containerStyle = { width: "100%", height: "100%" };
    this.DashboardStyle = { height: "100%", width: "100%" };

    this.state = {
      rowData: [],
      paginationPageSize: 50,
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        enablePivot: true,
        pivot: true,
        enableValue: true,
        // enableRowGroup: true,
        filter: true,
        resizable: true,
      },
    };
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onGridState = () => {
    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns,
     * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();

    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
  };

  componentWillReceiveProps(newProps) {
    this.setState({
      rowData: newProps.rowData,
    });
  }

  // componentDidUpdate() {
  //   if (this.props.gridFor === "loading") {
  //     this.props.columnDefs.splice(2, 0, );
  //   } else if (this.props.gridFor === "unloading") {
  //     this.props.columnDefs.splice(2, 0, {
  //       headerName: "Plant Name",
  //       field: "consignee_name",
  //       width: 100,
  //     });
  //   } else if (this.props.gridFor === "trip") {
  //     this.props.columnDefs.splice(2, 0, {
  //       headerName: "Trip Name",
  //       field: "consigner_name",
  //       width: 100,
  //     });
  //   }
  // }

  render() {
    return (
      <div
        id="myGrid"
        style={{ height: "440px", width: "100%" }}
        className="ag-theme-balham px-3"
      >
        <AgGridReact
          modules={this.state.modules}
          defaultColDef={this.state.defaultColDef}
          columnDefs={this.props.columnDefs}
          rowData={this.state.rowData}
          enableCharts={true}
          enableRangeSelection={true}
          onGridReady={this.onGridReady}
          onGridState={this.props.onGridState}
          frameworkComponents={this.props.frameworkComponents}
          statusBar={this.state.statusBar}
          gridOptions={this.props.gridOptions}
          sideBar={this.state.sideBar}
          paginationPageSize={this.state.paginationPageSize}
          pagination={true}
        />
      </div>
    );
  }
}

export default DashboardGrid;
