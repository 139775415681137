import React, { Component } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_exporting from "highcharts/modules/exporting"; // For exporting options
import drilldown from "highcharts-drilldown";

export default class DrilldownBarGraph extends Component {
  constructor(props) {
    HC_exporting(Highcharts);
    super(props);

    this.state = {};
  }
  render() {
    console.log("DrilldownBarGraph", this.props);
    drilldown(Highcharts);
    let highchartoptions = {
      chart: {
        type: "column",
        width: this.props.width,
        height: 400,
      },
      title: {
        align: "center",
        text: this.props.title,
      },
      subtitle: {
        align: "center",
        text: this.props.subtitle,
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      xAxis: {
        type: "category",
      },
      yAxis: {
        title: {
          text: this.props.yaxistitle,
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: `{point.y} ${this.props.unit}`,
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: `<span style="color:{point.color}">{point.name}</span>: <b>{point.y} ${this.props.unit}</b><br/>`,
      },
      series: [
        {
          name: this.props.seriesHeading,
          colorByPoint: true,
          data: this.props.parentData,
        },
      ],
      drilldown: {
        breadcrumbs: {
          position: {
            align: "right",
          },
        },
        series: this.props.childData,
      },
    };

    return (
      <HighchartsReact
        containerProps={{ style: { width: "100%", height: "100%" } }}
        highcharts={Highcharts}
        options={highchartoptions}
      />
    );
  }
}
