import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import CountUp from "react-countup";
import redirectURL from "../redirectURL";
import DashboardGrid from "./DashboardGrid";

export default class TruckScoreDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadshow: "show-n",
      overly: "show-n",
      show: false,
      basicType: "default",
      basicTitle: "",
      fromDate: moment
        .parseZone()
        .subtract(7, "days")
        .format("YYYY-MM-DD"),
      toDate: moment.parseZone().format("YYYY-MM-DD"),
      scoreCard: [],
      shortRestTicketsCount: 0,
      nightDrivingTicketsCount: 0,
      overspeedingTicketsCount: 0,
      enrouteStoppageTicketsCount: 0,
      currentTruck: "",
      columnDefs: [
        {
          headerName: "Ticket Number",
          field: "ticket_number",
          width: 130,
        },
        {
          headerName: "Consignment Number",
          field: "consignment_code",
          width: 150,
        },
        {
          headerName: "Ticket Type",
          field: "ticket_category",
          width: 150,
          valueGetter: function(params) {
            if (params.data.ticket_category) {
              const words = params.data.ticket_category.split("_");
              const capitalizedWords = words.map(
                (word) => word.charAt(0).toUpperCase() + word.slice(1)
              );
              const result = capitalizedWords.join(" ");
              return result;
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Truck No",
          field: "truck_no",
          width: 100,
        },
        {
          headerName: "Driver Name",
          field: "driver_name",
          width: 120,
          valueGetter: function(params) {
            if (params.data.driver_name) {
              const words = params.data.driver_name.split("_");
              const capitalizedWords = words.map(
                (word) => word.charAt(0).toUpperCase() + word.slice(1)
              );
              const result = capitalizedWords.join(" ");
              return result;
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Driver Mobile No.",
          field: "driver_mobile_no",
          width: 140,
        },

        {
          headerName: "Transporter Name",
          field: "transporter_name",
          width: 150,
          valueGetter: function(params) {
            if (params.data.transporter_name) {
              const words = params.data.transporter_name.split("_");
              const capitalizedWords = words.map(
                (word) => word.charAt(0).toUpperCase() + word.slice(1)
              );
              const result = capitalizedWords.join(" ");
              return result;
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Plant Name",
          field: "consignee_name",
          width: 150,
          valueGetter: function(params) {
            if (params.data.consignee_name) {
              const words = params.data.consignee_name.split("_");
              const capitalizedWords = words.map(
                (word) => word.charAt(0).toUpperCase() + word.slice(1)
              );
              const result = capitalizedWords.join(" ");
              return result;
            } else {
              return "";
            }
          },
        },

        {
          headerName: "Port In",
          field: "port_in_timestamp",
          width: 150,
          valueGetter: function(params) {
            if (params.data.port_in_timestamp) {
              return moment.parseZone(params.data.port_in_timestamp).format(
                "YYYY-MM-DD HH:mm"
              );
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Exception Start Time",
          field: "exception_start_time",
          width: 160,
          valueGetter: function(params) {
            if (params.data.exception_start_time) {
              return moment.parseZone(params.data.exception_start_time).format(
                "YYYY-MM-DD HH:mm"
              );
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Exception End Time",
          field: "exception_end_time",
          width: 150,
          valueGetter: function(params) {
            if (params.data.exception_end_time) {
              return moment.parseZone(params.data.exception_end_time).format(
                "YYYY-MM-DD HH:mm"
              );
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Ticket Text",
          field: "ticket_text",
          width: 250,
        },
        {
          headerName: "Ticket Creation Date",
          field: "ticket_creation_date",
          width: 200,
          valueGetter: function(params) {
            if (params.data.ticket_creation_date) {
              return moment.parseZone(params.data.ticket_creation_date).format(
                "YYYY-MM-DD HH:mm"
              );
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Ticket Status",
          field: "ticket_status",
          width: 100,
          valueGetter: function(params) {
            if (params.data.ticket_status) {
              if (params.data.ticket_status === 1) {
                return "Open";
              } else {
                return "";
              }
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Exception Duration (in Hrs)",
          field: "total_exception_duration",
          width: 150,
          valueGetter: function(params) {
            if (params.data.total_exception_duration) {
              return (
                Number(params.data.total_exception_duration) / 3600
              ).toFixed(2);
            } else {
              return "";
            }
          },
        },
      ],
      rowData: [],
      truckwiseViolationData: [],
      truckViolationCounts: [],
      transportScoreCard: [],
    };
  }

  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  isValidDate = (current) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to midnight to compare only dates

    return current.isSameOrBefore(today, 'day');
    // return current.isBefore(new Date(), 'day');
  };

  getTrucksScore = () => {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    let payload = {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
    };
    redirectURL
      .post("/dashboard/getTrucksScore", payload)
      .then((resp) => {
        console.log("getTrucksScore", resp.data);
        let {
          truckViolationCounts,
          violationDataByTruckWise,
          transporterViolationCount,
        } = resp.data;
        let scoreCard = truckViolationCounts;
        let transportScoreCard = transporterViolationCount;
        if (scoreCard.length > 0 && transportScoreCard.length > 0) {
          let {
            enroute_stoppage_count,
            night_driving_count,
            over_speeding_count,
            short_rest_count,
            truck_no,
          } = scoreCard[0];

          for (let eachTruckDetails of violationDataByTruckWise) {
            if (eachTruckDetails.truck_no === truck_no) {
              this.setState({
                rowData: eachTruckDetails.documents,
              });
              break;
            }
          }

          this.setState({
            currentTruck: truck_no,
            scoreCard: scoreCard,
            shortRestTicketsCount: short_rest_count,
            enrouteStoppageTicketsCount: enroute_stoppage_count,
            nightDrivingTicketsCount: night_driving_count,
            overspeedingTicketsCount: over_speeding_count,
            truckwiseViolationData: violationDataByTruckWise,
            truckViolationCounts: truckViolationCounts,
            loadshow: "show-n",
            overly: "show-n",
            transportScoreCard: transportScoreCard,
          });
        }else{
          this.setState({
            currentTruck: "",
            scoreCard: [],
            shortRestTicketsCount: 0,
            enrouteStoppageTicketsCount: 0,
            nightDrivingTicketsCount: 0,
            overspeedingTicketsCount: 0,
            truckwiseViolationData: [],
            truckViolationCounts: [],
            loadshow: "show-n",
            overly: "show-n",
            transportScoreCard: [],
            rowData: []
          });
        }
      })
      .catch((e) => {
        console.log("getTrucksScore err", e);
      });
  };

  showViolationCountersData = (truckNo) => {
    console.log("showViolationCountersData", truckNo);
    let scoreCard = this.state.truckViolationCounts;
    if (scoreCard.length > 0) {
      for (let each of scoreCard) {
        if (each.truck_no === truckNo) {
          this.setState({
            shortRestTicketsCount: each.short_rest_count,
            enrouteStoppageTicketsCount: each.enroute_stoppage_count,
            nightDrivingTicketsCount: each.night_driving_count,
            overspeedingTicketsCount: each.over_speeding_count,
          });
          break;
        }
      }

      for (let eachTruckDetails of this.state.truckwiseViolationData) {
        if (eachTruckDetails.truck_no == truckNo) {
          this.setState({
            rowData: eachTruckDetails.documents,
          });
          break;
        }
      }
    }
    this.setState({
      currentTruck: truckNo,
    });
  };

  showGridDataOnViolationCounterClick = (truckNo, counterType) => {
    console.log("showGridDataOnViolationCounterClick", truckNo);
    console.log("showGridDataOnViolationCounterClick", counterType);

    for (let eachTruckDetails of this.state.truckwiseViolationData) {
      if (eachTruckDetails.truck_no == truckNo) {
        let filteredData = eachTruckDetails.documents.filter((each) => {
          if (each.ticket_category === counterType) {
            return each;
          }
        });
        this.setState({
          rowData: filteredData,
        });
        break;
      }
    }
  };

  changeNameStyling = (string) => {
    const words = string.split("_");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const result = capitalizedWords.join(" ");
    return result;
  };

  componentDidMount() {
    this.getTrucksScore();
  }

  onClickHideAll = () => {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
    });
  };

  handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var mm = d.getMonth() + 1;
    if (mm < 10) {
      var month = "0" + mm;
    } else {
      var month = mm;
    }

    var startdate =
      d.getFullYear() +
      "-" +
      month +
      "-" +
      (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

      let fromDate = new Date(startdate);
      let toDate = new Date(this.state.toDate)

      let previousStartDate = this.state.fromDate;

      if (fromDate > toDate) {
        // fromDate.setDate(toDate.getDate()); // Subtract 1 day from fromDate
        // const formattedFromDate = fromDate.toISOString().split('T')[0];
        this.setState({
          fromDate: previousStartDate,
          show: true,
          basicType: "danger",
          basicTitle: "From Date shouldn't be greater than To Date"
        })
      }else{
        this.setState({
          fromDate: startdate,
        });
      }

    
    //let value = event.target.value;
    //this.setState({'startDate':value});
  };

  handlerEndDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var mm = d.getMonth() + 1;
    if (mm < 10) {
      var month = "0" + mm;
    } else {
      var month = mm;
    }

    var edate =
      d.getFullYear() +
      "-" +
      month +
      "-" +
      (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

      
      let fromDate = new Date(this.state.fromDate)
      let toDate = new Date(edate);

      let previousEndDate = this.state.toDate;

      if (toDate < fromDate) {
        // toDate.setDate(fromDate.getDate()); // Subtract 1 day from fromDate
        // const formattedToDate = toDate.toISOString().split('T')[0];
        this.setState({
          toDate: previousEndDate,
          show: true,
          basicType: "danger",
          basicTitle: "To Date shouldn't be lesser than From Date"
        })
      }else{
        this.setState({
          toDate: edate,
        });
      }
      
    // this.setState({
    //   toDate: edate,
    // },()=>{
    //   console.log("startDate", this.state.fromDate);
    //   console.log("startDate", this.state.toDate);
    // });
  };

  getFilterData = () => {
    // e.preventDefault();
    this.getTrucksScore();
  };

  render() {
    return (
      <div className="container-fluid">
        <div className={"dataLoadpage " + this.state.loadshow}></div>
        <div className={"dataLoadpageimg " + this.state.loadshow}>
          <div className="loader-box">
            <div className="loader-box">
              <div className="rotate dashed colored"></div>
            </div>
          </div>
        </div>
        <div
          className={"overlay-part " + this.state.overly}
          onClick={this.onClickHideAll}
        ></div>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        />
        <div
          className="col-xl-12 col-lg-12 mt-2 p-2 mb-4"
          style={{ minHeight: "100vh" }}
        >
          {/* Filter card */}
          <div
            className="card shadow pt-3 mt-2 mb-3"
            style={{ minHeight: "100px" }}
          >
            <h5 style={{ marginLeft: "3%" }}>
              <i className="icofont icofont-vehicle-delivery-van"></i>
              <span className="pl-2">Truck Score Dashboard</span>
            </h5>
            <div className="col-lg-12 col-xl-12 mx-0 row justify-content-between">
              <div
                className="col-xl-3 col-lg-3 form-group"
                style={{ zIndex: "12" }}
              >
                <label>From Date</label>
                <Datetime
                  value={this.state.fromDate}
                  disableCloseOnClickOutside={false}
                  closeOnSelect={true}
                  inputProps={{
                    placeholder: "Start Date",
                    name: "startDate",
                    autoComplete: "off",
                    readOnly: 'true'
                  }}
                  dateFormat="YYYY-MM-DD"
                  name="fromDate"
                  onChange={this.handlerStartDateTime.bind(this)}
                  isValidDate={this.isValidDate}
                />
              </div>

              <div
                className="col-xl-3 col-lg-3 form-group"
                style={{ zIndex: "12" }}
              >
                <label>To Date</label>
                <Datetime
                  value={this.state.toDate}
                  disableCloseOnClickOutside={false}
                  closeOnSelect={true}
                  isValidDate={this.isValidDate}
                  inputProps={{
                    placeholder: "To Date",
                    name: "toDate",
                    autoComplete: "off",
                    readOnly: 'true'
                  }}
                  dateFormat="YYYY-MM-DD"
                  name="endDate"
                  onChange={this.handlerEndDateTime.bind(this)}
                />
              </div>
              <div className={"col-xl-3 col-lg-3 form-group"}>
                <label htmlFor=""></label>
                <br />
                <button
                  type="button"
                  className="btn btn-info shadow mt-2"
                  onClick={this.getFilterData}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>

          {/* Transporter Score card */}
          <div className="card shadow mt-2 mb-3">
            <span className="pl-5 pt-2 card-heading">Transporter Score Card</span>
            <div className="row mx-0">
              {
                this.state.transportScoreCard.length > 0 ? this.state.transportScoreCard.map((each, index) => {
                  var textColor = "green";
                  if (parseFloat(each.normalizedScore) < 5) {
                    textColor = "red";
                  } else if (
                    parseFloat(each.normalizedScore) >= 5 &&
                    parseFloat(each.normalizedScore) < 8
                  ) {
                    textColor = "#cfcb06";
                  }
                  return (
                    <div
                      key={each.transporter_name}
                      className="col cirlce-d pt-26px counterBorder"
                      // onClick={this.showViolationCountersData.bind(
                      //   this,
                      //   each.truck_no
                      // )}
                    >
                      <span className="f13 countClass">
                        {this.changeNameStyling(each.transporter_name)}
                      </span>
                      <h4 className="txt-pink-medium f30 txt-center">
                        <span className="counter">
                          <span style={{ color: textColor }}>
                            {each.normalizedScore}
                          </span>
                        </span>
                      </h4>
                    </div>
                  );
                }): <div className="no-data-found">No Data Found</div>
              }
            </div>
          </div>

          {/* Truck Score Card */}
          <div className="card shadow mt-2 mb-3">
            <span className="pl-5 pt-2 card-heading">Truck Score Card</span>
            <div className="row mx-0">
              {
                this.state.scoreCard.length > 0 ? this.state.scoreCard.map((each, index) => {
                  var textColor = "green";
                  if (parseFloat(each.normalizedScore) < 5) {
                    textColor = "red";
                  } else if (
                    parseFloat(each.normalizedScore) >= 5 &&
                    parseFloat(each.normalizedScore) < 8
                  ) {
                    textColor = "#cfcb06";
                  }
                  return (
                    <div
                      key={each.truck_no}
                      className="col cpointer cirlce-d pt-26px counterBorder"
                      onClick={this.showViolationCountersData.bind(
                        this,
                        each.truck_no
                      )}
                    >
                      <span className="f13 countClass">{each.truck_no}</span>
                      <h4 className="txt-pink-medium f30 txt-center">
                        <span className="counter">
                          <span style={{ color: textColor }}>
                            {each.normalizedScore}
                          </span>
                        </span>
                      </h4>
                    </div>
                  );
                }) : <div className="no-data-found">No Data Found</div>
              }
            </div>
          </div>

          {/* Violation Counters */}
          <div className="card shadow mt-2 mb-3">
          <span className="pl-5 pt-2 card-heading">Violation Count of Truck No: <span style={{color: "red"}}>{this.state.currentTruck}</span>  </span>
            <div className="row mx-0">
              <div
                className="col cpointer pt-26px counterBorder"
                onClick={this.showGridDataOnViolationCounterClick.bind(
                  this,
                  this.state.currentTruck,
                  "short_rest"
                )}
              >
                <span className="f13 countClass">Short Rest</span>
                <h4 className="f30 txt-center txt-secondary txt-center">
                  <span className="counter">
                    <CountUp end={this.state.shortRestTicketsCount} />
                  </span>
                </h4>
              </div>

              <div
                className="col cpointer cirlce-d pt-26px counterBorder"
                onClick={this.showGridDataOnViolationCounterClick.bind(
                  this,
                  this.state.currentTruck,
                  "night_driving"
                )}
              >
                <span className="f13 countClass">Night Driving</span>
                <h4 className="txt-pink-medium f30 txt-center">
                  <span className="counter">
                    <CountUp end={this.state.nightDrivingTicketsCount} />
                  </span>
                </h4>
              </div>

              <div
                className="col cpointer cirlce-d pt-26px counterBorder"
                onClick={this.showGridDataOnViolationCounterClick.bind(
                  this,
                  this.state.currentTruck,
                  "over_speeding"
                )}
              >
                <span className="f13 countClass">Over Speeding</span>
                <h4 className="txt-pink-medium f30 txt-center">
                  <span className="counter">
                    <CountUp end={this.state.overspeedingTicketsCount} />
                  </span>
                </h4>
              </div>

              <div
                className="col cpointer cirlce-d pt-26px"
                onClick={this.showGridDataOnViolationCounterClick.bind(
                  this,
                  this.state.currentTruck,
                  "enroute_stoppage"
                )}
              >
                <span className="f13 countClass">Enroute Stoppage</span>
                <h4 className="txt-pink-medium f30 txt-center">
                  <span className="counter">
                    <CountUp end={this.state.enrouteStoppageTicketsCount} />
                  </span>
                </h4>
              </div>
            </div>
          </div>
          {/* Counters */}

          {/* Grid-Data */}
          <div className="card shadow mt-2 mb-3">
            <span className="pt-2 pl-5 card-heading pb-2">
              Violation Details of Truck No: <span style={{color: "red"}}>{this.state.currentTruck}</span>
            </span>
            <DashboardGrid
              columnDefs={this.state.columnDefs}
              rowData={this.state.rowData}
            />
          </div>
          {/* Grid-Data */}
        </div>
      </div>
    );
  }
}
