/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import _ from "lodash";
import $ from 'jquery';
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import Modal from 'react-responsive-modal';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';
import CountUp from 'react-countup';
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import GridButton from './gridButton';

var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");



export default class TEC extends Component {

	constructor(props){
		super(props);
		this.state={
			dmodules: AllModules,
			defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: null,
			contactrowData:null,
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
                GridButton:GridButton
			},
      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			rowSelection:'multiple',
			detailCellRendererParams:{},
			uploadDivWidth:'0%',
			sliderTranslate:'',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            confirmShow: false,
            basicTitle:'',
            basicType:"default",
			pagetitle:"",
			usermanualmodal:false,
			overly: "show-n",
			sliderTrucksTranslate: "",
			loadshow:'show-n',	
			teamCategory:"",
			teamCategoryOptions:[],
			l1EndTime:"",
			endTimeOptions:[],
			l1Group:"",
			emailGroupsOptions:[],
			l2EndTime:"",
			l2Group:"",
			emailGroup:"",
			showTextBox:'show-n'
		}
	}

	componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

		}
		
	    this.loadPageData()
	};

	loadPageData = () => {
		
		redirectURL.post('/tms/getemailgroups').then(response => {
		  if(response.data.status == 'success'){
			let emailGroupsOptions = response.data.records.map(group => {return {"label":group, "value":group}});
			this.setState({
				emailGroupsOptions: emailGroupsOptions
			}, () => {
				this.setState({
					emailGroupsOptions: [...this.state.emailGroupsOptions,{"label":"+ Add New Group","value":"other"}]
				})
			})
		  }
		}).catch(function (error) {
		  console.log(error);
		});

		redirectURL.post('/tms/getticketsemailconfigurations').then(response => {
		  if(response.data.status == 'success'){
			this.setState({
				rowData:response.data.email_configurations
			})
		  }
		}).catch(function (error) {
		  console.log(error);
		});
	}
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

	onClickShowSliderForTicketConfigAddition = () =>{
		this.setState({
			sliderTranslate : "slider-translate-30p",
			loadshow:'show-n',
			overly:'show-m',
			showDiv:'show-m',
		})

	}

	addEmailConfigDetails = e =>{
		e.preventDefault()
		if(this.state.emailGroup && $("#inputEmail").val()){
			var validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
			if ($("#inputEmail").val().match(validEmailRegex)) {

				let params = {
					email: $("#inputEmail").val(),
					created_date : moment.parseZone().utcOffset("+05:30")._d
				}
	
				if(this.state.emailGroup.value !== 'other'){
					params.group_name = this.state.emailGroup.value;
				}else{
					params.group_name = $("#groupName").val();
				}
				
				redirectURL.post("/tms/saveticketsemailconfiguration",params).then(response =>{
					let data = response.data
					if(data.status == "success"){
						this.setState({
							basicTitle: "Inserted Successfully.",
							basicType: "success",
							show: true
						})
						this.loadPageData()
					}else{
						this.setState({
							basicTitle: data.message,
							basicType: "warning",
							show: true
						})
					}
	
				})
			}else{
				this.setState({
					basicTitle: "Invalid Email Address",
					basicType: "warning",
					show: true
				})			
			}
		}else{
			this.setState({
				basicTitle: "All Fields are Manditory",
				basicType: "warning",
				show: true
			})
		}
	}

	onClickHideAll = () =>{
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderTranslate : "",
			showDiv:'show-n',
			show: false
		});
	}
	
	closeAlert = () => {
		this.setState({
			show: false,
			confirmShow:false
		});
	}

	newFilter = (filterType, selOption) => {
		this.setState({ [filterType]: selOption });
		if(selOption.value === "other"){
			this.setState({showTextBox:'show-m'});
		}else{
			$("#groupName").val('')
			this.setState({showTextBox:'show-n'});
		}
	}

	showDeleteEmailTicketModal = (propData) => {
		this.setState({
			deleteRowData : propData.data,
			confirmShow: true
		})
	}
	

	deleteTicketsEmailConfiguration = () => {
		this.setState({
			confirmShow: false
		})
		let data = this.state.deleteRowData
		let params = {
			delete_id: data._id
		}
		redirectURL.post('/tms/deleteTicketsEmailConfiguration', params)
			.then(response => {
				if (response.data.status == 'success') {
					this.loadPageData()
				} else {
					this.setState({
						show: true,
						basicTitle: "Failed to delete the data",
						basicType: "danger"
					})
				}
			})
	}
	
	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

		//    ChartMenu.prototype.saveChart = function (e) {
		// 	   var event = {
		// 	      data: this.gridOptionsWrapper.getApi(),
		// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
		// 	    };
		// 	   //console.log("Testing", event);
		// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
		// 	   this.dispatchEvent(event);
		// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;

		//    };


	};


	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);


	}

    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		
		const {usermanualmodal} = this.state;
		var hideChilds = true;
		// commented this code as this condition was from the msil screen on 27-07-2022
		// if(this.state.departmentCode == "LOG-PRT")
		// {
		// 	hideChilds = false
		// }
		// console.log("hello Checking")
		const columnwithDefs = [
				// {
				// 	headerName:"Actions",
				// 	field:"cluster",
				// 	width:"80",
				// 	pinned:'left',
				// 	params:{buttonName:'Edit',
				// 			onClickFunction:},
				// 	cellRendererSelector:function(params){
						
				// 		var rendComponent = {
				// 			component: "GridButton"
				// 		};
				// 		return rendComponent
					
				// 	},
				// },
				{
					headerName:"",
					field:"cluster",
					width:"100",
					pinned:'left',
					params:{buttonName:'Delete',iconName:"fa fa-trash",gButton:"btn btn-outline-danger",
							onClickFunction:this.showDeleteEmailTicketModal},
					cellRendererSelector:function(params){
						
						var rendComponent = {
							component: "GridButton"
						};
						return rendComponent
					
					},
				},
	  	        {
					headerName: "Group Name",
					field: "group_name",
					width: 500,
					hide:false,
					filter: "agSetColumnFilter",
					cellRenderer: '', 
					resizable: true
		        },
		        {
		          headerName: "Email",
                  field: "email",
				  width: 500,
				  hide : false,
                  filter: "agSetColumnFilter",
				  cellRenderer: '', 
				  resizable: true	    	          
				},	    
	    ];
		return(
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				> 
				</SweetAlert>
				<SweetAlert
					warning
					show={this.state.confirmShow}
					showCancel
					confirmBtnText="Yes, delete it!"
					confirmBtnBsStyle="danger"
					title="Are you sure?"
					onConfirm={this.deleteTicketsEmailConfiguration}
					onCancel={this.closeAlert}
					focusCancelBtn
					>
					You will not be able to recover this data!
				</SweetAlert>
				{this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icon-email"></i> <span>Manage Tickets Email Configuration {this.state.currentDepartmentTitle} </span>
									<div>
										<form method="POST" className="theme-form row" onSubmit={this.addEmailConfigDetails}>   
											<div className="form-group mt-20p col-4">
												<label className="">Email Group</label>
												<Select 
													closeMenuOnSelect={true} 
													name="emailGroup"
													className="border-radius-0" 
													isMulti={false}
													onChange={this.newFilter.bind(this, "emailGroup")}
													options={this.state.emailGroupsOptions}  
													value={this.state.emailGroup}
													required
												/> 
												<input className={"form-control mt-10p "+(this.state.showTextBox)} type="text" name="groupName" autoComplete="off" id="groupName" placeholder="Enter New Group Name" />
											</div>                         
											<div className="form-group mt-20p col-4">
												<label className="">Email</label> 
												<input type="text" name="email" autoComplete="off" class="form-control" id="inputEmail" />
											</div>    
											<div className="col-4 form-group" style={{marginTop: "3rem"}}>
												<button type="submit" className="btn btn-success">Add</button>
											</div>
										</form>
									</div>
                                </h5>
				   			</div>				   			
							<div className="row card-body pt-15px" >
								<div id="myGrid" style={{ height: "550px", width: "100%" }} className="col-xl-12 col-lg-12 ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										rowHeight = {35}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										//floatingFilter={true}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										//onCellClicked={this.onRowClicked.bind(this)}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										rowSelection={this.state.rowSelection}
										//onRowSelected={this.onRowSelected.bind(this)}
										suppressRowClickSelection={true}
									/>
								</div>
							</div>
				   		</div>	
				   	</div>
				 </div>
            </div>
              	
		);
	}
}

$(document).on(".num-cls", function() {
    this.value = this.value.replace(/\D\./g,'');
    // this.value = this.value.replace(/[!#$%^*()+\=;'|<>\?a-zA-Z]+/g,"")

});

// const regex = /^[-\w\s]+$/;
// $(document).on(".txt-cls", function() {
//     this.value = this.value.replace(/^[-\w\s]+$/g,'');
// });