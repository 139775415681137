import {
	TICKET_CATEGORIES,
	TEAM_CATEGORIES,
	TIME
} from '../../constants/ticketConfigurations'



/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import _ from "lodash";
import $ from 'jquery';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import Modal from 'react-responsive-modal';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';
import GridButton from './gridButton';

var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");



export default class TCC extends Component {

	constructor(props) {
		super(props);
		this.state = {
			
			currentDepartmentTitle: null,
			modules: AllModules,
			defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true

      	    },
      	    rowData: null,
			contactrowData:null,
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
                GridButton:GridButton
			},
      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,
				
			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			rowSelection:'multiple',
			detailCellRendererParams:{},
			uploadDivWidth: '0%',
			sliderTranslate: '',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			alerterrshow: 'fade',
			alert: null,
			show: false,
			confirmShow: false,
			basicTitle: '',
			basicType: "default",
			pagetitle: "",
			usermanualmodal: false,
			overly: "show-n",
			sliderTrucksTranslate: "",
			loadshow: 'show-n',
			manualTicketType: "",
			manualTicketTypeOptions: [],
			teamCategory: "",
			teamCategoryOptions: [],
			l1EndTime: "",
			endTimeOptions: [],
			l1Group: "",
			emailGroupsOptions: [],
			l2EndTime: "",
			l2Group: "",
			l3Group: "",
		}
	}

	componentDidMount() {
		if (googleAnalytics.page.enableGA) {
			this.logPageView(); // Log page view to GA
			let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
			this.setState({ eventAction: eventAction });

		}

		this.loadPageData()
	};

	loadPageData = () => {
		let manualTicketTypeOptions = TICKET_CATEGORIES.map(ticket_type => { return { "label": ticket_type, "value": ticket_type } });
		let teamCategoryOptions = TEAM_CATEGORIES.map(team_cat => { return { "label": team_cat, "value": team_cat } });
		let endTimeOptions = TIME.map(time => { return { "label": time, "value": time } });
		this.setState({
			manualTicketTypeOptions: manualTicketTypeOptions,
			teamCategoryOptions: teamCategoryOptions,
			endTimeOptions: endTimeOptions,
			manualTicketType: '',
			teamCategory: '',
			l1EndTime: '',
			l1Group: [],
			l2EndTime: '',
			l2Group: [],
			l3Group: []

		});
		redirectURL.post('/tms/getemailgroups').then(response => {
			if (response.data.status == 'success') {
				let emailGroupsOptions = response.data.records.map(group => { return { "label": group, "value": group } })
				this.setState({
					emailGroupsOptions: emailGroupsOptions
				})
			}
		}).catch(function (error) {
			console.log(error);
		});

		redirectURL.post('/tms/getticketconfigurations').then(response => {
			if (response.data.status == 'success') {
				this.setState({
					rowData: response.data.ticket_configurations
				}, () => {
					console.log("this.state.ticket_configurations===>", this.state.emailGroupsOptions)
				})
			}
		}).catch(function (error) {
			console.log(error);
		});
	}
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
	}

	onCloseUserManualModal = () => {
		this.setState({ usermanualmodal: false });
	};

	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};

	onClickShowSliderForTicketConfigAddition = () => {
		this.setState({
			sliderTranslate: "slider-translate-30p",
			loadshow: 'show-n',
			overly: 'show-m',
			showDiv: 'show-m',
		})

	}

	addTicketConfigDetails = e => {
		e.preventDefault()
		if (this.state.manualTicketType && this.state.teamCategory && this.state.l1EndTime && this.state.l1Group && this.state.l2Group) {
			let params = {
				ticket_type: this.state.manualTicketType.value,
				team_category: this.state.teamCategory.value,
				l1_endtime: this.state.l1EndTime.value,
				l1_group: this.state.l1Group.map(e => e.value),
				l2_endtime: this.state.l2EndTime.value,
				l2_group: this.state.l2Group.map(e => e.value),
				l3_group: this.state.l3Group.map(e => e.value),
				created_date: moment.parseZone().utcOffset("+05:30")._d
			}
			redirectURL.post("/tms/saveticketconfiguration", params).then(response => {
				let data = response.data
				console.log("data", data)
				if (data.status == "success") {
					this.setState({
						basicTitle: "Inserted Successfully.",
						basicType: "success",
						show: true,
						loadshow: 'show-n',
						overly: 'show-n',
						sliderTranslate: "",
					})
					this.loadPageData()
				} else {
					this.setState({
						basicTitle: data.message,
						basicType: "warning",
						show: true
					})
				}

			})
		} else {
			this.setState({
				basicTitle: "Fields Marked with '*' are Manditory",
				basicType: "warning",
				show: true
			})
		}
	}

	onClickHideAll = () => {
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			sliderTranslate: "",
			showDiv: 'show-n',
			show: false
		});
	}

	closeAlert = () => {
		this.setState({
			show: false,
			confirmShow: false
		});
	}

	newFilter = (filterType, selOption) => {
		// console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });
	}

	showDeleteTicketModal = (propData) => {
		this.setState({
			deleteRowData : propData.data,
			confirmShow: true
		})
	}
	

	deleteTicketConfiguration = () => {
		this.setState({
			confirmShow: false
		})
		let data = this.state.deleteRowData
		let data_type = this.state.data_type

		let params = {
			delete_id: data._id
		}
		redirectURL.post('/tms/deleteticketconfiguration', params)
			.then(response => {
				if (response.data.status == 'success') {
					this.loadPageData()
				} else {
					this.setState({
						show: true,
						basicTitle: "Failed to delete the data",
						basicType: "danger"
					})
				}
			})
	}
	
	editTicketConfiguration = (propData) => {
		let data = propData.data
		let l1Group = data.l1_group.map(e => {return {label:e,value:e}});
		let l2Group = data.l2_group.map(e => {return {label:e,value:e}});
		let l3Group = data.l3_group.map(e => {return {label:e,value:e}});
		this.setState({
			manualTicketType:{label:data.ticket_type,value:data.ticket_type},
			teamCategory:{label:data.team_category,value:data.team_category},
			l1EndTime:{label:data.l1_endtime,value:data.l1_endtime},
			l2EndTime:{label:data.l2_endtime,value:data.l2_endtime},
			l1Group:l1Group,
			l2Group:l2Group,
			l3Group:l3Group,
			sliderTranslate: "slider-translate-30p",
			loadshow: 'show-n',
			overly: 'show-m',
			showDiv: 'show-m',
		})
	}

	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

		//    ChartMenu.prototype.saveChart = function (e) {
		// 	   var event = {
		// 	      data: this.gridOptionsWrapper.getApi(),
		// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
		// 	    };
		// 	   //console.log("Testing", event);
		// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
		// 	   this.dispatchEvent(event);
		// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;

		//    };


	};


	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns, 
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);


	}
	render() {
		const modalStyles = {
			width: '1300px !important',
		}

		const { usermanualmodal } = this.state;
		const columnwithDefs = [
			{
				headerName: "",
				field: "cluster",
				width: "90",
				pinned: 'left',
				params: {
					buttonName: 'Edit',
					onClickFunction: this.editTicketConfiguration
				},
				cellRendererSelector: function (params) {

					var rendComponent = {
						component: "GridButton"
					};
					return rendComponent

				},
			},
			{
				headerName: "",
				field: "cluster",
				width: "100",
				pinned: 'left',
				params: {
					buttonName: 'Delete', iconName: "fa fa-trash",gButton:"btn btn-outline-danger",
					onClickFunction: this.showDeleteTicketModal
				},
				cellRendererSelector: function (params) {

					var rendComponent = {
						component: "GridButton"
					};
					return rendComponent

				},
			},
			{
				headerName: "Ticket Type",
				field: "ticket_type",
				width: 150,
				hide: false,
				filter: "agSetColumnFilter",
				cellRenderer: '',
				resizable: true
			},
			{
				headerName: "Team Category",
				field: "team_category",
				width: 150,
				hide: false,
				filter: "agSetColumnFilter",
				cellRenderer: '',
				resizable: true
			},
			{
				headerName: "L1 End Time(Hrs)",
				field: "l1_endtime",
				width: 150,
				hide: false,
				filter: "agSetColumnFilter",
				cellRenderer: '',
				resizable: true
			},
			{
				headerName: "L2 End Time(Hrs)",
				field: "l2_endtime",
				width: 150,
				hide: false,
				filter: "agSetColumnFilter",
				cellRenderer: '',
				resizable: true
			},
			{
				headerName: "L1 Group Name",
				field: "l1_group",
				width: 150,
				hide: false,
				filter: "agSetColumnFilter",
				cellRenderer: '',
				resizable: true
			},
			{
				headerName: "L2 Group Name",
				field: "l2_group",
				width: 150,
				hide: false,
				filter: "agSetColumnFilter",
				cellRenderer: '',
				resizable: true
			},
			{
				headerName: "L3 Group Name",
				field: "l3_group",
				width: 150,
				hide: false,
				filter: "agSetColumnFilter",
				cellRenderer: '',
				resizable: true
			},

		];
		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				<SweetAlert
					warning
					show={this.state.confirmShow}
					showCancel
					confirmBtnText="Yes, delete it!"
					confirmBtnBsStyle="danger"
					title="Are you sure?"
					onConfirm={this.deleteTicketConfiguration}
					onCancel={this.closeAlert}
					focusCancelBtn
					>
					You will not be able to recover this data!
				</SweetAlert>
				{this.state.alert}
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h5>
									<i className="icon-ticket"></i> <span>Manage Tickets Configuration {this.state.currentDepartmentTitle} </span>
									<button onClick={this.onClickShowSliderForTicketConfigAddition} className={"btn btn-success float-right"}>Add Ticket Configuration</button>
								</h5>
							</div>
							<div className="row card-body pt-15px" >
								<div id="myGrid" style={{ height: "550px", width: "100%" }} className="col-xl-12 col-lg-12 ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										rowHeight = {35}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										//floatingFilter={true}
										enableCellChangeFlash={true}
										suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										//onCellClicked={this.onRowClicked.bind(this)}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										rowSelection={this.state.rowSelection}
										//onRowSelected={this.onRowSelected.bind(this)}
										suppressRowClickSelection={true}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={"slide-r " + (this.state.sliderTranslate)} >
					<div className="slide-r-title">
						<h4>
							Add Ticket Configuration
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.addTicketConfigDetails}>
								<div className="form-group mt-20p">
									<label className="">Ticket Type <span className='text-danger'>*</span></label>
									<Select
										closeMenuOnSelect={true}
										name="manualTicketType"
										className="border-radius-0"
										onChange={this.newFilter.bind(this, "manualTicketType")}
										options={this.state.manualTicketTypeOptions}
										value={this.state.manualTicketType}
										required
									/>
								</div>
								<div className="form-group mt-20p">
									<label className="">Team Category <span className='text-danger'>*</span></label>
									<Select
										closeMenuOnSelect={true}
										name="teamCategory"
										className="border-radius-0"
										onChange={this.newFilter.bind(this, "teamCategory")}
										options={this.state.teamCategoryOptions}
										value={this.state.teamCategory}
										required
									/>
								</div>
								<div className="row">
									<div className="form-group mt-20p col-6">
										<label className="">L1 End Time(hrs) <span className='text-danger'>*</span></label>
										<Select
											closeMenuOnSelect={true}
											name="l1EndTime"
											className="border-radius-0"
											onChange={this.newFilter.bind(this, "l1EndTime")}
											options={this.state.endTimeOptions}
											value={this.state.l1EndTime}
											required
										/>
									</div>
									<div className="form-group mt-20p col-6">
										<label className="">L2 End Time(hrs)</label>
										<Select
											closeMenuOnSelect={true}
											name="l2EndTime"
											className="border-radius-0"
											onChange={this.newFilter.bind(this, "l2EndTime")}
											options={this.state.endTimeOptions}
											value={this.state.l2EndTime}
											required
										/>
									</div>
								</div>
								<div className="row">
									<div className="form-group mt-20p col-12">
										<label className="">L1 Group <span className='text-danger'>*</span></label>
										<Select
											closeMenuOnSelect={true}
											name="l1Group"
											isMulti={true}
											className="border-radius-0"
											onChange={this.newFilter.bind(this, "l1Group")}
											options={this.state.emailGroupsOptions}
											value={this.state.l1Group}
											required
										/>
									</div>
									<div className="form-group mt-20p col-12">
										<label className="">L2 Group <span className='text-danger'>*</span></label>
										<Select
											closeMenuOnSelect={true}
											name="l2Group"
											isMulti={true}
											className="border-radius-0"
											onChange={this.newFilter.bind(this, "l2Group")}
											options={this.state.emailGroupsOptions}
											value={this.state.l2Group}
											required
										/>
									</div>
								</div>
								<div className="row">
									<div className="form-group mt-20p col-12">
										<label className="">L3 Group</label>
										<Select
											closeMenuOnSelect={true}
											name="l3Group"
											isMulti={true}
											className="border-radius-0"
											onChange={this.newFilter.bind(this, "l3Group")}
											options={this.state.emailGroupsOptions}
											value={this.state.l3Group}
											required
										/>
									</div>
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
				<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
						<h5 className="model-title">Transporter</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This screen consists details regarding to all the transporters, its MSIL code and currently actively taking consignments or not.
							</div>

							<div className="col-xl-12 col-lg-12">
								Image Here
							</div>
							<div className="col-xl-12 col-lg-12">
								For PRT department there is additional column present which is known as child codes which consists of subcodes of transporters through which they pick the consignments from various point of Origin
							</div>

							<div className="col-xl-12 col-lg-12">
								Image Here
							</div>
						</div>
					</div>
				</Modal>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>
			</div>

		);
	}
}

$(document).on(".num-cls", function () {
	this.value = this.value.replace(/\D\./g, '');
	// this.value = this.value.replace(/[!#$%^*()+\=;'|<>\?a-zA-Z]+/g,"")

});

// const regex = /^[-\w\s]+$/;
// $(document).on(".txt-cls", function() {
//     this.value = this.value.replace(/^[-\w\s]+$/g,'');
// });