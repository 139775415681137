
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const KpiEnrouteStoppagesActions = (props) => {
   
    const handleEnrouteStoppagesClick = (e) => {
        e.stopPropagation();
        
       props.context.componentParent.onShowEnrouteStoppagesKpi(props);
    };
    const handleNoEnrouteStoppagesClick = (e) => {
        e.stopPropagation();
        
       props.context.componentParent.onShowEnrouteStoppagesKpi(0);
    }; 

    return (
        <div>
            {(props.data.enroute_exception == 1)?
            <button onClick={handleEnrouteStoppagesClick} className="custom-btn f22 label label-success" title="Enroute Stoppages"><i className="icofont icofont-pause maroon"></i> &nbsp;</button>
            
            :
            <button onClick={handleNoEnrouteStoppagesClick} className="custom-btn f22 label label-success" title="Enroute Stoppages" style={{color:"rgb(204, 204, 204)"}}><i className="icofont icofont-pause"></i> &nbsp;</button>
            
            }
       
			
        </div>
    );
}; 
 
export default KpiEnrouteStoppagesActions;
