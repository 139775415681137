import React, { Component, Fragment } from "react";
import {Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Polyline} from 'google-maps-react';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import $ from 'jquery'; 
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getDDMMYYYYHHMM } from '../common/utils'; 
// import Tooltip from "rc-tooltip";
import Slider, { Range } from "rc-slider";
import { PlayCircle, PauseCircle } from "react-feather";
import "rc-slider/assets/index.css";
import Constant from "../common/googlemapkey";

const Handle = Slider.Handle;
// var infoBox = require('./google-infowindow');
var moment = require('moment');
var infoBox = require('../common/google-infowindow');

var animateTimer;
var maxMarkerss;
var amarkers=0;
var marker;
var map1;
var mapmarker;
var allmarkers = []
var cmarkers = []

class RoutePlayPause extends Component {
	constructor(props){
		super(props);
		this.state = {
			mapinfo:'',
			defTransitCoords:'',
			activeMarker: {},
			selectedPlace: {},
			dealerCode:'',
			timelinesmarkers:[],
			viewtimelinemarkers:true,
			viewgoogleroutes:true,
			googleroutepath:[],
			loadshow:'show-n',
            open: false,
            animatespeed:3,
            animatespeedtext : "Default"
			
        };
        this.playRoute = this.playRoute.bind(this);
        this.drawRoute = this.drawRoute.bind(this);
        this.addMarkers = this.addMarkers.bind(this);
        this.redrawRouting = this.redrawRouting.bind(this);
        this.pauseRoute = this.pauseRoute.bind(this);
		this.clearMarkers = this.clearMarkers.bind(this);
	}
	componentDidMount = async() =>{	
        var arr = this.props.coords;
        console.log("Coordinates ", arr)	
        this.playRoute()
         await this.renderMap()

        //  loadOmsScript("https://enalytics.in/prod/assets/js/oms.min.js")
        
	}
	// componentWillReceiveProps(){
	// 	this.renderMap()
	// }
    renderMap = () => {   
		loadScript("https://maps.googleapis.com/maps/api/js?key="+Constant.GOOGLE_KEY+"&libraries=places,drawing&callback=initMap");
        
		window.initMap = this.initMap
	}
	
	onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;
	
	//    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   //console.log("Deptcode " ,this.state.deptcode)
	    if(this.state.deptcode == 'LOG-PRT')
	    {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], false);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], false);
		}
		if(this.state.deptcode == 'SNDG')
		{
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
		}

		
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
		   
	}

    
	initMap = () => {
		var currentwindow;
		var dealer='';
		var lt, ln;
        var arr=[];
          
        var jsondata = this.props.coords;
        console.log("jsondata ",jsondata)
        var locations = this.props.coords.breaks;
        // console.log(locations,"break locations")
        var arr = jsondata.coords;
        
        arr = arr.filter((d) => d.speed > 0);
        if(arr.length > 0)
        {
            lt = arr[0].lat;
            ln = arr[0].lng;
            var mapOptions = {
                zoom: 14,
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                labels:true,
                mapTypeControlOptions: {
                    mapTypeIds: ['hybrid', 'roadmap'],
                },
                center: new window.google.maps.LatLng(17.67264,75.311256),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP
            };
                    
            map1 = new window.google.maps.Map(document.getElementById('animatemap'), mapOptions);
        
            // marker = new window.google.maps.Marker({
            //     map:map,
            //     icon:require('../assets/icons/truck-end.png')
            // });

            //  var mapOptions = {
            // 	zoom: 13,
            // 	zoomControl: true,
            // 	mapTypeControl: true,
            // 	scaleControl: true,
            // 	streetViewControl: true,
            // 	rotateControl: true,
            // 	fullscreenControl: true,
            // 	labels:true,
            // 	mapTypeControlOptions: {
            // 		mapTypeIds: ['hybrid', 'roadmap'],
            // 	},
            // 	center: new window.google.maps.LatLng(lt,ln),
            // 	mapTypeId: window.google.maps.MapTypeId.ROADMAP
            // };
                    
            // var map = new window.google.maps.Map(document.getElementById('animatemap'),
            // 	mapOptions);

            var bounds = new window.google.maps.LatLngBounds();
            bounds.extend(new window.google.maps.LatLng(lt,ln));
                // Create our info window content
            var currentinfowindow = null;
            var line=new window.google.maps.Polyline(
            {
                map:map1,
                strokeColor: '#157254',
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
                icons: [{
                        icon: {
                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                strokeColor:'#ff8c52',
                                fillColor:'#ff8c52',
                                fillOpacity:1,
                                strokeWeight: 2
                        },
                        repeat:'100px',
                        path:[]
                    }]
            });
            
            
        var linereturn=new window.google.maps.Polyline(
                {
                    map:map1,
                                    strokeColor: '#0000FF',
                                    strokeOpacity: 1.0,
                                    strokeWeight: 2.5,
                            icons: [{
                            icon: {
                                    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                    strokeColor:'#ff8c52',
                                    fillColor:'#ff8c52',
                                    fillOpacity:1,
                                    strokeWeight: 2
                                    },
                            repeat:'100px',
                            path:[]
                            }]
                    });

            if(arr.length > 0)
            {

                var arr1=[];
                //console.log("Total ",ratio);
                if(this.state.timelinesmarkers.length > 0)
                {
                    arr1 = this.state.timelinesmarkers.filter(function (value, index, arr) {
                        return (index % ratio == 0);
                    } );
                }
                
                var arr2=[];
                var darr=[];
                if(arr.length > 0)
                {
                    darr = arr.filter(function (value, index, arr) {
                        //console.log("Vale ",value)
                        return (value.dist_from_prev_point > 0);
                    } );
                    if(darr.length < 2000)
                    {
                        var ratio = 1;
                    }
                    else{
                        var ratio = 20;
                    }
                    //console.log("darr.length ", darr.length)
                    arr2 = darr.filter(function (value, index, darr) {
                        return (index % ratio == 0);
                    } );
                    

                    //var arr1 = this.state.timelinesmarkers;
                }
                arr = arr2;
                for (let i = 0; i < arr.length; i++) {
                    allmarkers.push(arr[i]);
                    var linecolor=0;
                    var path=line.getPath().getArray();
                    var pathreturn=linereturn.getPath().getArray();
                            
                    let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
                    path.push(latLng);
                    if (linecolor == 1)
                    {
                        pathreturn.push(latLng);
                        linereturn.setPath(pathreturn);
                    }
                    else
                    {
                        path.push(latLng);
                        line.setPath(path);
                    }
                    // line.setPath(path);
                        
                    //Change line color based on map type
                    window.google.maps.event.addListener( map1, 'maptypeid_changed', function() {
                        if (map1.getMapTypeId() =='hybrid' || map1.getMapTypeId() =='satellite')
                        {
                            var line=new window.google.maps.Polyline(
                            {
                                map:map1,
                                strokeColor: '#FFFFFF',
                                strokeOpacity: 1.0,
                                strokeWeight: 2.5,
                                icons: [{
                                        icon: {
                                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                                strokeColor:'#ff8c52',
                                                fillColor:'#ff8c52',
                                                fillOpacity:1,
                                                strokeWeight: 2
                                                },
                                        repeat:'100px',
                                        path:[]
                                    }]
                                });                
                        }
                        else
                        {
                            var line=new window.google.maps.Polyline(
                            {
                                map:map1,
                                strokeColor: '#157254',
                                strokeOpacity: 1.0,
                                strokeWeight: 2.5,
                                icons: [{
                                        icon: {
                                                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                                strokeColor:'#ff8c52',
                                                fillColor:'#ff8c52',
                                                fillOpacity:1,
                                                strokeWeight: 2
                                                },
                                        repeat:'100px',
                                        path:[]
                                    }]
                                });

                        }

                        for (i = 0; i < arr.length; i++) {
                                var path=line.getPath().getArray();
                                let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
                                path.push(latLng);
                                line.setPath(path);
                                        //map.setCenter(latLng);
                            }
                                        
                        var x = map1.getZoom();
                        var c = map1.getCenter();
                        window.google.maps.event.trigger(map1, 'resize');
                        map1.setZoom(x);
                        map1.setCenter(c);
                    } );
                    bounds.extend(new window.google.maps.LatLng(arr[i].lat,arr[i].lng));
                    
                }

                var infowindow = new window.google.maps.InfoWindow();
                var l;
                
                for (l = 0; l < locations.length; l++) {
                    // console.log(locations[l].lat, locations[l].lng,"locations[l].lat, locations[l].lng")
                marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
                    icon:require('../../assets/icons/cf.png'),
                    map: mapmarker,
                });
                window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
                        return function() {
                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
                        var contentarr = []
                        var header = "Break - "+(l+1)
                        contentarr.push({"key":"Start time", "value":getDDMMYYYYHHMMSS(locations[l].break_start)})
                        contentarr.push({"key":"End time", "value":getDDMMYYYYHHMMSS(locations[l].break_end)})
                        contentarr.push({"key":"Break Duration", "value":timeConvert(locations[l].break_time_seconds)})
                        var contentString = infoBox(marker.icon, header, contentarr)
                        infowindow.setContent(contentString);
                        currentwindow = infowindow;
                        infowindow.open(mapmarker, marker);
                        //console.log(marker.position.toJSON());
                        //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                        }
                    })(marker, l));
                }
                
                
            }
		    map1.fitBounds(bounds)
        }
	}

    playRoute()
    {
        this.renderMap()
        var jsondata = this.props.coords;
        var arr = jsondata.coords;
        arr = arr.filter((d) => d.speed > 0);
        // console.log(arr,"arr")
        var lt = arr[0].lat;
        var ln = arr[0].lng;
        var bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(lt,ln));
        for (var i = 0; i < arr.length; i++) 
        {
            if(arr[i].speed > 0)
            {
                bounds.extend({lat:parseFloat(arr[i].lat),lng:parseFloat(arr[i].lng)});
            }			
        }
        // map.fitBounds(bounds);
        this.drawRoute(arr)
    }
    drawRoute = async(markers) =>{
        // console.log(markers,"markesrs data")
        // console.log(amarkers,"amarkers data")
        if(amarkers <= markers.length) {
            await this.addMarkers(amarkers)
            // await this.redrawRouting(markers)
            var animatespeed = 300
            if(this.state.animatespeed == 1)
            {
                animatespeed = 1000
            }
            else if(this.state.animatespeed == 2)
            {
                animatespeed = 500 
            }
            else if(this.state.animatespeed == 4)
            {
                animatespeed = 100 
            }
            else if(this.state.animatespeed == 5)
            {
                animatespeed = 50 
            }
            else
            {
                animatespeed = 300 
            }
            animateTimer = setTimeout(
                async function() {
                    this.clearMarkers(); 
                    this.drawRoute(markers)  
                }
                .bind(this),
                animatespeed
            );
			amarkers=amarkers+1
			// console.log("amarkers ",amarkers)
	    }
		else{
			amarkers=0;
			 window.clearTimeout(animateTimer);
			 this.renderMap()
		}
    }
    redrawRouting(markers){
        this.drawRoute(markers)
    }
    clearMarkers(){
        // var jsondata = this.props.coords;
        // var allmarkers = jsondata.coords;
        // console.log("cmarkers", cmarkers)
        
        if(cmarkers.length > 0)
        {
            for (var i = 0; i < (cmarkers.length-1); i++) {
               //console.log("cmarkers[i] ",cmarkers[i])
                cmarkers[i].setMap(null);
            }
        }
    }
    addMarkers(amarkers)
	{
        var jsondata = this.props.coords;
        // console.log(jsondata,"jsondata add markers")
        var allmarkers = jsondata.coords;
        var locations = this.props.coords.breaks;
        var currentwindow;
        // console.log(locations,"allmarkers add markers")
        allmarkers = allmarkers.filter((d) => d.speed > 0);
        // console.log("amarkers ", allmarkers[amarkers].lat)
        try{
            marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(allmarkers[amarkers].lat, allmarkers[amarkers].lng),
                map:map1,
                icon:require('../../assets/icons/truck_null.png'),
                // draggable: false,
                // animation: window.google.maps.Animation.DROP,
                // clickable: true
            });
            // console.log(marker,"try marker")
            cmarkers.push(marker);
            var infowindow = new window.google.maps.InfoWindow();
            var l;
            
            for (l = 0; l < locations.length; l++) {
                    // console.log(locations[l].lat, locations[l].lng,"locations[l].lat, locations[l].lng")
                    // console.log("breakicon1")
                    var dist = calcCrow(allmarkers[amarkers].lat,allmarkers[amarkers].lng,locations[l].lat,locations[l].lng)
                    // console.log(dist, "distance from break")

                    if(dist < 0.5)
                    {
                        // console.log("breakicon")
                        marker = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
                            icon:require('../../assets/icons/cf.png'),
                            map: map1,
                        });
                   
                        // window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
                        //     return function() {
                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
                            var contentarr = []
                            var header = "Break - "+(l+1)
                            contentarr.push({"key":"Start time", "value":getDDMMYYYYHHMMSS(locations[l].break_start)})
                            contentarr.push({"key":"End time", "value":getDDMMYYYYHHMMSS(locations[l].break_end)})
                            contentarr.push({"key":"Break Duration", "value":timeConvert(locations[l].break_time_seconds)})
                            contentarr.push({"key":"Break Address", "value":locations[l].break_address})
                            contentarr.push({"key":"Break City", "value":locations[l].city})
                            contentarr.push({"key":"Break state", "value":locations[l].break_state})
                            var contentString = infoBox(marker.icon, header, contentarr)
                            console.log(infowindow.map,"infowindow")
                            if(infowindow.map != null && infowindow.map != undefined)
                            {

                            }
                            else
                            {
                                infowindow.setContent(contentString);
                                currentwindow = infowindow;
                                infowindow.open(map1, marker);
                            }
                            //console.log(marker.position.toJSON());
                            //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}'); 
                        //     }
                        // })(marker, l));
                    }
                    else
                    {
                        setTimeout(
                            () => {                                
                                if(infowindow != null && infowindow != undefined)
                                {
                                    // console.log("breakicon closed")
                                    infowindow.close();
                                }
                            }, 
                            2000
                          );
                    }
                }
                
            $("#timeroute").html(allmarkers[amarkers].stime)
            var vehspeed=allmarkers[amarkers].speed;
            $("#speed").html(allmarkers[amarkers].speed)
            // console.log("vehspeed ",vehspeed)
            if(parseInt(vehspeed) >= 60)
            {
                $(".spd-button").css("color","red")
            }
            else
            {
                $(".spd-button").css("color","#666")
            }
            
        }
        catch(e){
            // console.log(e,"catch data")
        }
        
		
	}
    pauseRoute()
	{
		window.clearTimeout(animateTimer);
    }
    applySpeed(val){
        console.log("val", val)
        var animatespeedtext = "Default"
        if(val == 1)
        {
            animatespeedtext = "Very Slow"
        }
        else if(val == 2)
        {
            animatespeedtext = "Slow"
        }
        else if(val == 4)
        {
            animatespeedtext = "Fast"
        }
        else if(val == 5)
        {
            animatespeedtext = "Very Fast"
        }
        else if(val == 3)
        {
            animatespeedtext = "Default"
        }
        this.setState({
            animatespeed:val,
            animatespeedtext:animatespeedtext
        })
    }
    render () {
        const modalStyles  = {
            width:'500px !important',
        }

        const wrapperStyle = { width: 200, marginLeft: 10, marginTop:0, marginRight:0 };
        
        const handle = props => {
            const { value, dragging, index, ...restProps } = props;
            return  ""
            // (
            // <Tooltip
            //     prefixCls="rc-slider-tooltip"
            //     overlay={value}
            //     visible={dragging}
            //     placement="top"
            //     key={index}
            // >
            //     <Handle value={value} {...restProps} />
            // </Tooltip>
            // );
        };
        // console.log(this.props)
        return (
           <div className="row" style={{width:"100vw"}}>
                <div className="col-xl-12 col-md-12">
                    
                    <a href="javascript:;" onClick={this.pauseRoute} className="float-right">
                        Pause:<br /> <PauseCircle size={24} className="mr-4" />
                    </a>
                    <a href="javascript:;" onClick={this.playRoute} className="float-right" style={{marginLeft:"15px"}}>
                        Play:<br /> <PlayCircle size={24} className="mr-4" />
                    </a>
                    <button type="button" className="btn btn-sm btn-default spd-button" style={{width:"120px", paddingLeft:"10px"}} >Speed:<br /><span id="speed">0</span> km/h</button>
                    <button type="button" className="btn btn-sm btn-default spd-button" style={{width:"160px", paddingLeft:"10px"}} >Time:<br /><span id="timeroute">0</span></button>
                    
                </div>
                <div className="col-xl-12 col-md-12">
                    <span className="float-left">
                        Animate Speed: <span style={{fontWeight:"600"}}>{this.state.animatespeedtext}</span>
                    </span>
                    <span className="float-right"  style={wrapperStyle}>
                        <Slider min={1} max={5} onChange={this.applySpeed.bind(this)} defaultValue={this.state.animatespeed} handle={handle} />
                    </span>
                </div>
                <div className="col-xl-12 col-md-12">
                    <div id="animatemap" className="" style={{width:'100%',height:"70vh", position:"relative",overflow:"hidden"}}></div>		
                </div>
            </div>
        )
    }
}

function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
// function loadOmsScript(url){
//     var index  =window.document.getElementsByTagName("script")[0]
//     var script = window.document.createElement("script")
//     script.src=url
//     script.async=true
//     script.defer = true
//     index.parentNode.insertBefore(script,index)
// }

function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = parseInt(hours);
	var minutes = (num-(rhours*3600)) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}   

function intervals(startString, endString) {
    var start = moment(startString, 'DD-MM-YYYY HH:mm:ss');
    var end = moment(endString, 'DD-MM-YYYY HH:mm:ss');

    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    var result = [];

    var current = moment(start);

    while (current <= end) {
        result.push(current.format('DD-MM-YYYY HH:mm'));
        current.add(15, 'minutes');
    }

    return result;
}


function secondsToString(seconds)
{
var numdays = Math.floor(seconds / 86400);
var numhours = Math.floor((seconds % 86400) / 3600);
var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
var numseconds = ((seconds % 86400) % 3600) % 60;
return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}
function calcCrow(lat1, lon1, lat2, lon2) 
    {
      var R = 6371; // km
      var dLat = toRad(lat2-lat1);
      var dLon = toRad(lon2-lon1);
      var lat1 = toRad(lat1);
      var lat2 = toRad(lat2);

      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c;
      return d;
    }

    // Converts numeric degrees to radians
    function toRad(Value) 
    {
        return Value * Math.PI / 180;
    }

export default RoutePlayPause;