import React, { Component } from "react";
import DashboardGrid from "./DashboardGrid";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Select from "react-select";
import CountUp from "react-countup";
import redirectURL from "../redirectURL";
// import PiechartDrilldownComponent from "../layouts/PiechartDrilldownComponent";
// import ColumnChartLoadingDashboard from "../layouts/columnchartloadingdashboard";
import ScatterChartLoadingDashboard from "../layouts/scatterchartloadingdashboard";
import DrilldownBarGraph from "../layouts/DrilldownBarGraph";
import DriverFatigueBottomCard from "./DriverFatigueBottomCard";
import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault
} from '../common/utils';

export default class DriverFatigueDashboardChanged extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dummyData: {
        seriesNames: ["Driver-3", "Driver-4"],
      },
      // 1 continuousDrive
      continuousDriveGraph: "show-m",
      continuousDriveGrid: "show-n",
      continuousDriveButtonText: "Show Data",

      continuousDriveGridData: [],
      continuousDriveHeading: "Avg. Continuous driving time (Hrs)",
      // continuousDriveSubHeading: "Inside plant >20km/hr for 10 sec and outside plant > 60km/hr for 10 sec",
      continuousDriveSubHeading: "",

      // 2 shortRest

      shortRestGraph: "show-m",
      shortRestGrid: "show-n",
      shortRestButtonText: "Show Data",

      shortRestGridData: [],
      shortRestHeading: "Avg. Short rest time",
      shortRestSubHeading:
        "Avg. short rest time taken by drivers between 2 trips (< 4 hours)",

      // 3 longRest

      longRestGraph: "show-m",
      longRestGrid: "show-n",
      longRestButtonText: "Show Data",

      longRestGridData: [],
      longRestHeading: "Avg. Long rest time",
      longRestSubHeading: "Long rest time: 8 hours in the night (10pm-6am)",

      // 4 nightTime

      nightTimeGraph: "show-m",
      nightDataFound: "show-m",
      nightTimeGrid: "show-n",
      nightTimeButtonText: "Show Data",

      nightTimeGridData: [],
      nightTimeHeading: "Avg. Night driving (Hrs)",
      nightTimeSubHeading: "Night Driving is driving in between 11 PM - 5 AM",

      // 5 overSpeed
      overSpeedGraph: "show-m",
      overspeedDataFound: "show-m",
      overSpeedGrid: "show-n",
      overSpeedButtonText: "Show Data",

      overSpeedGridData: [],
      overSpeedHeading: "No. of Instances of overspeeding",
      overSpeedSubHeading:
        "Inside plant >20km/hr for 10 sec and outside plant > 60km/hr for 10 sec",

      //6 harshBraking
      enrouteStoppageGraph: "show-m",
      enrouteStoppageGrid: "show-n",
      enrouteStoppageButtonText: "Show Data",

      harshBrakingGridData: [],
      enrouteStoppageHeading: "Enroute Stoppage Analysis",
      enrouteStoppageSubHeading:
        "Avg. stoppage time of truck from Port-Plant per trip in corresponding time",

      fromDate: moment
        .parseZone()
        .subtract(7, "days")
        .format("YYYY-MM-DD"),
      toDate: moment.parseZone().format("YYYY-MM-DD"),
      show: false,
      basicType: "default",
      basicTitle: "",
      loadshow: "show-n",
      overly: "show-n",
      portNames: [],
      plantNames: [],

      transporternames: [],
      allVehicles: {},
      allDrivers: {},

      inplanttransportname: [],
      inplantVehicles: {},
      inplantalldrivers: {},

      vehicleNumbers: [],
      inBoundBtn:"btn-info",
			inPlantBtn:"btn-default",
      driverNames: [],
      portname: [],
      plantname: [],
      vehiclenumber: [],
      transportername: [],
      drivername: "",
      shortRestData: { data: [] },
      shortRestChildData: [],
      nightTimeSeriesData: [],
      nightTimeSeriesCategories: [],
      overSpeedSeriesData: [],
      overSpeedSeriesCategories: [],
      harshBrakingParentData: [],
      harshBrakingChildData: [],
      enrouteStoppageData: { data: [] },
      shortRestTicketsCount: 0,
      shortRestTicketsData: [],
      nightDrivingTicketsCount: 0,
      nightDrivingTicketsData: [],
      overspeedingTicketsCount: 0,
      overspeedingTicketsCountInPlant:0,
      overspeedingTicketsData: [],
      overspeedingTicketsDataInPlant:[],
      enrouteStoppageTicketsCount: 0,
      idlingTicketsCount: 0,
      idlingTicketsCountInPlant:0,
      harshBreakingTicketsCount: 0,
      enrouteStoppageTicketsData: [],
      columnDefs: [
        {
          headerName: "Ticket Number",
          field: "ticket_number",
          width: 100,
        },
        {
          headerName: "Truck No",
          field: "truck_no",
          width: 100,
        },
        {
          headerName: "Transporter Name",
          field: "transporter_name",
          width: 150,
          valueGetter: function(params) {
            if (params.data.transporter_name) {
              const words = params.data.transporter_name.split("_");
              const capitalizedWords = words.map(
                (word) => word.charAt(0).toUpperCase() + word.slice(1)
              );
              const result = capitalizedWords.join(" ");
              return result;
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Plant Name",
          field: "consignee_name",
          width: 150,
          valueGetter: function(params) {
            if (params.data.consignee_name) {
              const words = params.data.consignee_name.split("_");
              const capitalizedWords = words.map(
                (word) => word.charAt(0).toUpperCase() + word.slice(1)
              );
              const result = capitalizedWords.join(" ");
              return result;
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Consignment Number",
          field: "consignment_code",
          width: 200,
        },
        {
          headerName: "Port In",
          field: "port_in_timestamp",
          width: 200,
          valueGetter: function(params) {
            if (params.data.port_in_timestamp) {
              return moment(params.data.port_in_timestamp).format(
                "YYYY-MM-DD HH:mm"
              );
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Exception Start Time",
          field: "exception_start_time",
          width: 200,
          valueGetter: function(params) {
            if (params.data.exception_start_time) {
              return getHyphenDDMMYYYYHHMMSS(params.data.exception_start_time);
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Exception End Time",
          field: "exception_end_time",
          width: 200,
          valueGetter: function(params) {
            if (params.data.exception_end_time) {
              return getHyphenDDMMYYYYHHMMSS(params.data.exception_end_time);
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Driver Name",
          field: "driver_name",
          width: 200,
        },
        {
          headerName: "Driver Mobile Number",
          field: "driver_mobile_no",
          width: 200,
        },
        {
          headerName: "Ticket Type",
          field: "ticket_category",
          width: 200,
        },
        {
          headerName: "Ticket Text",
          field: "ticket_text",
          width: 200,
        },
        {
          headerName: "Ticket Creation Date",
          field: "ticket_creation_date",
          width: 200,
          valueGetter: function(params) {
            if (params.data.ticket_creation_date) {
              return moment(params.data.ticket_creation_date).format(
                "YYYY-MM-DD HH:mm"
              );
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Ticket Status",
          field: "ticket_status",
          width: 200,
          valueGetter: function(params) {
            if (params.data.ticket_status) {
              if (params.data.ticket_status === 1) {
                return "Open";
              } else {
                return "";
              }
            } else {
              return "";
            }
          },
        },
      ],
      nightDriveChildData: [],
      nightDriveParentData: [],
      overspeedChildData: [],
      overspeedParentData: [],
      harshBreakingSeriesData: {
        name: "Truck No.",
        colorByPoint: true,
        data: [
          {
            name: "GJ16AV8513",
            y: 2,
          },
          {
            name: "GJ16AV8597",
            y: 3,
          },
          {
            name: "GJ16AV8547",
            y: 2,
          },
          {
            name: "GJ16AV8455",
            y: 1,
          },
          {
            name: "GJ16AV8422",
            y: 4,
          },
          {
            name: "GJ16AV8857",
            y: 5,
          },
          {
            name: "GJ16AW0469",
            y: 7,
          },
          {
            name: "GJ15AT6443",
            y: 4,
          },
        ],
      },
      harshBreakingSeriesCategories: [
        "GJ16AV8513",
        "GJ16AV8597",
        "GJ16AV8547",
        "GJ16AV8455",
        "GJ16AV8422",
        "GJ16AV8857",
        "GJ16AW0469",
        "GJ15AT6443",
      ],
      harshBreakingGraph: "show-m",
      harshBreakingGrid: "show-n",
      harshBreakingButtonText: "Show Data",
      harshBreakingHeading: "Harsh Breaking",
      harshBreakingSubHeading: "",
      idlingData: { data: [] },
      idlingTicketData: [],
      harshBrakingTicketsData: [],
    };
  }

  harshBreakingBoxVisibility = () => {
    if (this.state.harshBreakingButtonText === "Show Data") {
      this.setState({
        harshBreakingGraph: "show-n",
        harshBreakingGrid: "show-m",
        harshBreakingButtonText: "Show Chart",
      });
    } else {
      this.setState({
        harshBreakingGraph: "show-m",
        harshBreakingGrid: "show-n",
        harshBreakingButtonText: "Show Data",
      });
    }
  };

  transformString(input) {
    const words = input.split("_");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const result = capitalizedWords.join(" ");

    return result;
  }
  inBoundData = async () => {
    this.setState({
      inBoundBtn:"btn-info",
      inPlantBtn:"btn-default",
    })
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    await redirectURL
      .get("/dashboard/getalltransportertrucks")
      .then((resp) => {
        console.log("InBound resp", resp.data);
        let { allTransporters, allVehicles, allDrivers } = resp.data;
    
        let transporterOptions = [];
        allTransporters.forEach((each) => {
          let changedName = this.transformString(each);
          let option = { label: changedName, value: each };
          transporterOptions.push(option);
        });

        this.setState(
          {
            transporternames: transporterOptions,
            allVehicles: allVehicles,
            allDrivers: allDrivers,
            loadshow: "show-n",
            overly: "show-n",
          },
          () => {
            let payload = {
              fromDate: this.state.fromDate,
              toDate: this.state.toDate,
              transporters: [],
              vehicles: [],
            };
            this.getDriverFatigueData(payload);
          }
        );
      })
      .catch((err) => {
        console.log("getalltransportertrucks err", err);
      });
    
  }
  inPlantData = async () => {
    this.setState({
      inBoundBtn:"btn-default",
      inPlantBtn:"btn-info",
      nightTimeSubHeading: "Night Driving is driving in between 6 PM - 6 AM"
    })
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    
    let params = {
      in_plant_vehicle: 1,
    }
    await redirectURL
      .post("/dashboard/getalltransportertrucks", params)
      .then((resp) => {
        console.log("inPlant resp", resp.data);
        let { allTransporters, allVehicles, allDrivers } = resp.data;
    
        let transporterOptions = [];
        allTransporters.forEach((each) => {
          let changedName = this.transformString(each);
          let option = { label: changedName, value: each };
          transporterOptions.push(option);
        });

        this.setState(
          {
            inplanttransportname: transporterOptions,
            inplantVehicles: allVehicles,
            inplantalldrivers: allDrivers,
            loadshow: "show-n",
            overly: "show-n",
          },
          () => {
            let payload = {
              fromDate: this.state.fromDate,
              toDate: this.state.toDate,
              transporters: [],
              vehicles: [],
              in_plant_vehicle: 1
            };
            this.getDriverFatigueData(payload);
          }
        );
      })
      .catch((err) => {
        console.log("getalltransportertrucks err", err);
      });
    
  }
  getDriverFatigueData = (payload) => {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    redirectURL
      .post("/dashboard/getDriverFatigueData", payload)
      .then(async (resp) => {
        console.log("getDriverFatigueData data", resp.data);

        this.setState({
          shortRestTicketsCount: 0,
          shortRestTicketsData: [],
          nightDrivingTicketsCount: 0,
          nightDrivingTicketsData: [],
          overspeedingTicketsCount: 0,
          overspeedingTicketsCountInPlant:0,
          overspeedingTicketsData: [],
          overspeedingTicketsDataInPlant:[],
          enrouteStoppageTicketsCount: 0,
          enrouteStoppageTicketsData: [],
          idlingTicketsCount: 0,
          idlingTicketsCountInPlant:0,
          idlingTicketData: [],
          harshBreakingTicketsCount: 0,
          harshBrakingTicketsData: [],
          shortRestData: { data: [] },
          enrouteStoppageData: { data: [] },
        });

        let ticketsData = resp.data.ticketsData;
        console.log(ticketsData,"ticketsData")
        if (ticketsData.length > 0) {
          ticketsData.forEach((each) => {

            if (each.ticket === "over_speeding") {

              let out_plant_trucks = []
              let in_plant_trucks = []
              if(each.documents.length > 0){
                each.documents.forEach((each)=>{
                  if(!each.hasOwnProperty("in_plant_vehicle")){
                    out_plant_trucks.push(each)
                  } else{
                    in_plant_trucks.push(each)
                  }
                })
              }

              this.setState({
                overspeedingTicketsCount: out_plant_trucks.length,
                overspeedingTicketsCountInPlant: in_plant_trucks.length,
                overspeedingTicketsDataInPlant: in_plant_trucks,
                overspeedingTicketsData: out_plant_trucks,
              });
            }

            if (each.ticket === "enroute_stoppage") {
              this.setState({
                enrouteStoppageTicketsCount: each.count,
                enrouteStoppageTicketsData: each.documents,
              });
            }

            if (each.ticket === "night_driving") {
              this.setState({
                nightDrivingTicketsCount: each.count,
                nightDrivingTicketsData: each.documents,
              });
            }

            if (each.ticket === "short_rest") {
              this.setState({
                shortRestTicketsCount: each.count,
                shortRestTicketsData: each.documents,
              });
            }
          });
        } else {
          this.setState({
            shortRestTicketsCount: 0,
            shortRestTicketsData: [],
            nightDrivingTicketsCount: 0,
            nightDrivingTicketsData: [],
            overspeedingTicketsCount: 0,
            overspeedingTicketsCountInPlant:0,
            overspeedingTicketsData: [],
            overspeedingTicketsDataInPlant:[],
            enrouteStoppageTicketsCount: 0,
            enrouteStoppageTicketsData: [],
          });
        }

        let overspeedData =
          resp.data.overspeedData.length > 0 ? resp.data.overspeedData : [];

        // let overSpeedSeriesData = {
        //   name: "Truck No.",
        //   colorByPoint: true,
        //   data: overspeedData,
        // };

        // let overSpeedSeriesCategories = [];

        // overspeedData.forEach((each) => {
        //   overSpeedSeriesCategories.push(each.name);
        // });

        // console.log("overSpeedSeriesDataCategories", overSpeedSeriesCategories);

        ///////////////////////////
        let overspeedParentData = [];
        let overspeedChildData = [];
        if (resp.data.overspeedData.length > 0) {
          overspeedParentData = resp.data.overspeedData[0].parentData;
          overspeedChildData = resp.data.overspeedData[0].childData;
        }

        // let overspeedSeriesCategories = [];

        // await overspeedParentData.forEach(async (each) => {
        //   await overspeedSeriesCategories.push(each.name);
        // });

        ///////////////////

        let nightDriveParentData = [];
        let nightDriveChildData = [];
        if (
          resp.data.nightDriveData.length > 0 &&
          resp.data.nightDriveData[0].parentData.length > 0
        ) {
          nightDriveParentData =
            resp.data.nightDriveData[0].parentData[0].parentData;
          //
        } else {
          nightDriveParentData = [];
        }

        if (
          resp.data.nightDriveData.length > 0 &&
          resp.data.nightDriveData[0].childData.length > 0
        ) {
          nightDriveChildData = resp.data.nightDriveData[0].childData;
        } else {
          nightDriveChildData = [];
        }

        // else{
        //   nightDriveParentData = [];
        //   nightDriveChildData = [];
        // }

        let nightTimeSeriesCategories = [];

        await nightDriveParentData.forEach((each) => {
          nightTimeSeriesCategories.push(each.name);
        });

        this.setState(
          {
            // overSpeedSeriesData: overSpeedSeriesData,
            // overSpeedSeriesCategories: overSpeedSeriesCategories,

            nightTimeSeriesCategories: nightTimeSeriesCategories,
            nightDriveParentData: nightDriveParentData,
            nightDriveChildData: resp.data.nightDriveData[0].childData,
            overspeedParentData: overspeedParentData,
            overspeedChildData: overspeedChildData,
            shortRestData: {
              data: resp.data.shortRestData,
            },

            idlingData: {
              data: resp.data.idlingData,
            },

            idlingTicketData: resp.data.idlingTicketData,
            idlingTicketsCount: resp.data.idlingTicketData.length,
            enrouteStoppageData: {
              data: resp.data.enrouteStoppageData,
            },
          },
          () => {
            if (
              this.state.nightDriveParentData.length === 0 &&
              this.state.nightDriveChildData.length === 0
            ) {
              this.setState({
                nightDataFound: "show-n",
              });
            } else {
              this.setState({
                nightDataFound: "show-m",
              });
            }

            if (
              this.state.overspeedParentData.length === 0 &&
              this.state.overspeedChildData.length === 0
            ) {
              this.setState({
                overspeedDataFound: "show-n",
              });
            } else {
              this.setState({
                overspeedDataFound: "show-m",
              });
            }
          }
        );

        this.setState({
          loadshow: "show-n",
          overly: "show-n",
        });
      })
      .catch((err) => {
        console.log("getDriverFatigueData err", err);
      });
  };

  componentDidMount = async () => {
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    await redirectURL
      .get("/dashboard/getalltransportertrucks")
      .then((resp) => {
        console.log("getalltransportertrucks resp", resp.data);
        let { allTransporters, allVehicles, allDrivers } = resp.data;
    
        let transporterOptions = [];
        allTransporters.forEach((each) => {
          let changedName = this.transformString(each);
          let option = { label: changedName, value: each };
          transporterOptions.push(option);
        });

        this.setState(
          {
            transporternames: transporterOptions,
            allVehicles: allVehicles,
            allDrivers: allDrivers,
            loadshow: "show-n",
            overly: "show-n",
          },
          () => {
            let payload = {
              fromDate: this.state.fromDate,
              toDate: this.state.toDate,
              transporters: [],
              vehicles: [],
            };
            this.getDriverFatigueData(payload);
          }
        );
      })
      .catch((err) => {
        console.log("getalltransportertrucks err", err);
      });
  };

  newFilter = (filterType, selOption) => {
    console.log("filterType", filterType);
    console.log("filterType", selOption);
    this.setState({ [filterType]: selOption }, () => {
      if (selOption && filterType === "transportername") {
        let allVehicles = [];
        selOption.forEach((each) => {
          let vehicles = this.state.allVehicles[each.value];
          vehicles.forEach((veh) => {
            allVehicles.push({ label: veh, value: veh });
          });
        });
        this.setState({
          vehicleNumbers: allVehicles,
          vehiclenumber: [],
          // drivername: "",
        });
      }
      if (filterType === "transportername" && selOption === null) {
        this.setState({
          vehiclenumber: [],
          transportername: [],
        });
      }

      if (filterType === "vehiclenumber" && selOption === null) {
        this.setState({
          vehiclenumber: [],
        });
      }

      // if (selOption && filterType === "vehiclenumber") {
      //   let allDrivers = [];
      //   // console.log("resp.data.allDrivers",this.state.allDrivers);
      //   selOption.forEach((each) => {
      //     let drivers = this.state.allDrivers[each.value];
      //     drivers.forEach((driver) => {
      //       if (driver) {
      //         allDrivers.push({
      //           label: this.transformString(driver),
      //           value: driver,
      //         });
      //       }
      //     });
      //   });
      //   this.setState({
      //     driverNames: allDrivers,
      //     drivername: "",
      //   });
      // }
    });
  };

  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  onClickHideAll = () => {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
    });
  };

  getFilterData = async (e) => {
    this.setState({
      overly: "show-m",
      loadshow: "show-m",
    });
    e.preventDefault();
    if (this.state.fromDate && this.state.toDate) {
      let transporterNames = [];
      this.state.transportername.forEach((each) => {
        transporterNames.push(each.value);
      });

      let vehicleNumbers = [];
      this.state.vehiclenumber.forEach((each) => {
        vehicleNumbers.push(each.value);
      });

      let payload = {
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        transporters: transporterNames,
        vehicles: vehicleNumbers,
      };
      var cpayload={};
      if(this.state.inPlantBtn === "btn-info"){
        cpayload = {...payload, in_plant_vehicle:1}
      } else if(this.state.inBoundBtn === "btn-info"){
        cpayload = {...payload}
      }

      console.log("cpayload", cpayload);

      this.getDriverFatigueData(cpayload);
      // await this.getAvgLoadUnloadTimePerTripData(payload, "loading");
      // await this.getAvgLoadUnloadTimePerTripData(payload, "unloading");
      // this.getTrucksTripsCountData(payload);
      // this.emptyTruckAnalysisData(payload);
      // this.getDedicatedTruckDashboardGridData(payload);
      // this.setState({
      //   overly: "show-n",
      //   loadshow: "show-n",
      // });
    } else {
      this.setState({
        show: true,
        basicType: "danger",
        basicTitle: "Dates shouldn't be empty",
      });
    }
  };

  continuousDriveBoxVisibility = () => {
    if (this.state.continuousDriveButtonText === "Show Data") {
      this.setState({
        continuousDriveGraph: "show-n",
        continuousDriveGrid: "show-m",
        continuousDriveButtonText: "Show Chart",
      });
    } else {
      this.setState({
        continuousDriveGraph: "show-m",
        continuousDriveGrid: "show-n",
        continuousDriveButtonText: "Show Data",
      });
    }
  };

  shortRestBoxVisibility = () => {
    if (this.state.shortRestButtonText === "Show Data") {
      this.setState({
        shortRestGraph: "show-n",
        shortRestGrid: "show-m",
        shortRestButtonText: "Show Chart",
      });
    } else {
      this.setState({
        shortRestGraph: "show-m",
        shortRestGrid: "show-n",
        shortRestButtonText: "Show Data",
      });
    }
  };

  longRestBoxVisibility = () => {
    if (this.state.longRestButtonText === "Show Data") {
      this.setState({
        longRestGraph: "show-n",
        longRestGrid: "show-m",
        longRestButtonText: "Show Chart",
      });
    } else {
      this.setState({
        longRestGraph: "show-m",
        longRestGrid: "show-n",
        longRestButtonText: "Show Data",
      });
    }
  };

  nightTimeBoxVisibility = () => {
    if (this.state.nightTimeButtonText === "Show Data") {
      this.setState({
        nightTimeGraph: "show-n",
        nightTimeGrid: "show-m",
        nightTimeButtonText: "Show Chart",
      });
    } else {
      this.setState({
        nightTimeGraph: "show-m",
        nightTimeGrid: "show-n",
        nightTimeButtonText: "Show Data",
      });
    }
  };

  isValidDate = (current) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to midnight to compare only dates

    return current.isSameOrBefore(today, "day");
    // return current.isBefore(new Date(), 'day');
  };

  handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var mm = d.getMonth() + 1;
    if (mm < 10) {
      var month = "0" + mm;
    } else {
      var month = mm;
    }

    var startdate =
      d.getFullYear() +
      "-" +
      month +
      "-" +
      (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

    let fromDate = new Date(startdate);
    let toDate = new Date(this.state.toDate);

    let previousStartDate = this.state.fromDate;

    if (fromDate > toDate) {
      // fromDate.setDate(toDate.getDate()); // Subtract 1 day from fromDate
      // const formattedFromDate = fromDate.toISOString().split('T')[0];
      this.setState({
        fromDate: previousStartDate,
        show: true,
        basicType: "danger",
        basicTitle: "From Date shouldn't be greater than To Date",
      });
    } else {
      this.setState({
        fromDate: startdate,
      });
    }

    //let value = event.target.value;
    //this.setState({'startDate':value});
  };

  handlerEndDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var mm = d.getMonth() + 1;
    if (mm < 10) {
      var month = "0" + mm;
    } else {
      var month = mm;
    }

    var edate =
      d.getFullYear() +
      "-" +
      month +
      "-" +
      (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

    let fromDate = new Date(this.state.fromDate);
    let toDate = new Date(edate);

    let previousEndDate = this.state.toDate;

    if (toDate < fromDate) {
      // toDate.setDate(fromDate.getDate()); // Subtract 1 day from fromDate
      // const formattedToDate = toDate.toISOString().split('T')[0];
      this.setState({
        toDate: previousEndDate,
        show: true,
        basicType: "danger",
        basicTitle: "To Date shouldn't be lesser than From Date",
      });
    } else {
      this.setState({
        toDate: edate,
      });
    }

    // this.setState({
    //   toDate: edate,
    // },()=>{
    //   console.log("startDate", this.state.fromDate);
    //   console.log("startDate", this.state.toDate);
    // });
  };

  overSpeedBoxVisibility = () => {
    if (this.state.overSpeedButtonText === "Show Data") {
      this.setState({
        overSpeedGraph: "show-n",
        overSpeedGrid: "show-m",
        overSpeedButtonText: "Show Chart",
      });
    } else {
      this.setState({
        overSpeedGraph: "show-m",
        overSpeedGrid: "show-n",
        overSpeedButtonText: "Show Data",
      });
    }
  };

  enrouteStoppageBoxVisibility = () => {
    if (this.state.enrouteStoppageButtonText === "Show Data") {
      this.setState({
        enrouteStoppageGraph: "show-n",
        enrouteStoppageGrid: "show-m",
        enrouteStoppageButtonText: "Show Chart",
      });
    } else {
      this.setState({
        enrouteStoppageGraph: "show-m",
        enrouteStoppageGrid: "show-n",
        enrouteStoppageButtonText: "Show Data",
      });
    }
  };
  render() {
    let durationColDef = [
      {
        headerName: "Exception Duration (Hrs)",
        field: "total_exception_duration",
        width: 150,
        valueGetter: function(params) {
          console.log(
            "params.data.total_exception_duration",
            params.data.total_exception_duration
          );
          if (params.data.total_exception_duration) {
            return (
              Number(params.data.total_exception_duration) /
              (60 * 60)
            ).toFixed(2);
          } else {
            return "";
          }
        },
      },
    ];

    let idlingColumnDefs = [
      {
        headerName: "Ticket Number",
        field: "ticket_number",
        width: 100,
      },
      {
        headerName: "Truck No",
        field: "truck_no",
        width: 100,
      },
      {
        headerName: "Transporter Name",
        field: "transporter_name",
        width: 150,
        valueGetter: function(params) {
          if (params.data.transporter_name) {
            const words = params.data.transporter_name.split("_");
            const capitalizedWords = words.map(
              (word) => word.charAt(0).toUpperCase() + word.slice(1)
            );
            const result = capitalizedWords.join(" ");
            return result;
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Plant Name",
        field: "consignee_name",
        width: 150,
        valueGetter: function(params) {
          if (params.data.consignee_name) {
            const words = params.data.consignee_name.split("_");
            const capitalizedWords = words.map(
              (word) => word.charAt(0).toUpperCase() + word.slice(1)
            );
            const result = capitalizedWords.join(" ");
            return result;
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Consignment Number",
        field: "consignment_code",
        width: 200,
      },
      {
        headerName: "Exception Start Time",
        field: "exception_start_time",
        width: 200,
        valueGetter: function(params) {
          if (params.data.exception_start_time) {
            return getHyphenDDMMYYYYHHMMSS(params.data.exception_start_time);;
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Exception End Time",
        field: "exception_end_time",
        width: 200,
        valueGetter: function(params) {
          if (params.data.exception_end_time) {
            return getHyphenDDMMYYYYHHMMSS(params.data.exception_end_time);;
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Exception Duration (Min)",
        field: "total_exception_duration",
        width: 150,
        valueGetter: function(params) {
          console.log(
            "params.data.total_exception_duration",
            params.data.total_exception_duration
          );
          if (params.data.total_exception_duration) {
            return (Number(params.data.total_exception_duration) / 60).toFixed(
              2
            );
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Driver Name",
        field: "driver_name",
        width: 200,
      },
      {
        headerName: "Driver Mobile Number",
        field: "driver_mobile_no",
        width: 200,
      },
      {
        headerName: "Ticket Type",
        field: "ticket_category",
        width: 200,
      },
      {
        headerName: "Ticket Text",
        field: "ticket_text",
        width: 200,
      },
      {
        headerName: "Ticket Creation Date",
        field: "ticket_creation_date",
        width: 200,
        valueGetter: function(params) {
          if (params.data.ticket_creation_date) {
            return moment(params.data.ticket_creation_date).format(
              "YYYY-MM-DD HH:mm"
            );
          } else {
            return "";
          }
        },
      },
      {
        headerName: "Ticket Status",
        field: "ticket_status",
        width: 200,
        valueGetter: function(params) {
          if (params.data.ticket_status) {
            if (params.data.ticket_status === 1) {
              return "Open";
            } else {
              return "";
            }
          } else {
            return "";
          }
        },
      },
    ];

    let shortRestColumnDefs = [
      ...this.state.columnDefs,
      {
        headerName: "Short Rest Score",
        field: "short_rest_exceptions_count",
        width: 150,
      },
      ...durationColDef,
    ];

    let enrouteColumnDefs = [
      ...this.state.columnDefs,
      {
        headerName: "Enroute Stoppage Count",
        field: "enroute_stoppage_exceptions_count",
        width: 150,
      },
      ...durationColDef,
    ];

    let nightDrivingColDefs = [
      ...this.state.columnDefs,
      {
        headerName: "Night-driving Count",
        field: "night_driving_exceptions_count",
        width: 150,
      },
      ...durationColDef,
    ];

    let overspeedColDefs = [
      ...this.state.columnDefs,
      {
        headerName: "Overspeed Score",
        field: "overspeed_exceptions_count",
        width: 150,
      },
    ];

    return (
      <div className="container-fluid">
        <h5 style={{ marginBottom:"15px" }}>
          <i className="icofont icofont-vehicle-delivery-van"></i>
          <span className="pl-2">Driver Fatigue Dashboard</span>
        </h5>
        <div className={"dataLoadpage " + this.state.loadshow}></div>
        <div className={"dataLoadpageimg " + this.state.loadshow}>
          <div className="loader-box">
            <div className="loader-box">
              <div className="rotate dashed colored"></div>
            </div>
          </div>
        </div>
        <div
          className={"overlay-part " + this.state.overly}
          onClick={this.onClickHideAll}
        ></div>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        />
        <div className="row">
				   <div className="col-xl-12 col-lg-12">
              <button onClick={this.inBoundData.bind(this,"inBound")} className={"btn "+this.state.inBoundBtn}>In Bound</button>
              <button onClick={this.inPlantData.bind(this, "inPlant")} className={"btn "+this.state.inPlantBtn}>In Plant</button>
					</div>
				</div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 mt-2 p-2 mb-4" style={{ minHeight: "100vh" }}>
            {/* Filter card */}
            <div className="card shadow pt-3 mt-2 mb-3" style={{ minHeight: "100px" }}>
              
              <div className="col-lg-12 col-xl-12 mx-0 row">
                <div
                  className="col-xl-2 col-lg-2 form-group"
                  style={{ zIndex: "12" }}
                >
                  <label>From Date</label>
                  <Datetime
                    value={this.state.fromDate}
                    disableCloseOnClickOutside={false}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "Start Date",
                      name: "startDate",
                      autoComplete: "off",
                      readOnly: 'true'
                    }}
                    dateFormat="YYYY-MM-DD"
                    name="fromDate"
                    onChange={this.handlerStartDateTime.bind(this)}
                    isValidDate={this.isValidDate}
                  />
                </div>

                <div
                  className="col-xl-2 col-lg-2 form-group"
                  style={{ zIndex: "12" }}
                >
                  <label>To Date</label>
                  {/* <Datetime
                    timeFormat={false}
                    value={this.state.toDate}
                    disableCloseOnClickOutside={false}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "To",
                      name: "toDate",
                      autoComplete: "off",
                    }}
                    dateFormat="YYYY-MM-DD"
                    name="toDate"
                    onChange={this.handleToDate}
                    required
                  /> */}

                  <Datetime
                    value={this.state.toDate}
                    disableCloseOnClickOutside={false}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "To Date",
                      name: "toDate",
                      autoComplete: "off",
                      readOnly: 'true'
                    }}
                    dateFormat="YYYY-MM-DD"
                    name="endDate"
                    onChange={this.handlerEndDateTime.bind(this)}
                    isValidDate={this.isValidDate}
                  />
                </div>
                {/* Transporter Name */}
                <div
                  className="col-xl-3 col-lg-3 form-group"
                  style={{ zIndex: "12" }}
                >
                  <label>Transporter Name</label>
                  <Select
                    closeMenuOnSelect={true}
                    name="transportername"
                    value={this.state.transportername}
                    className="border-radius-0"
                    onChange={this.newFilter.bind(this, "transportername")}
                    isMulti={true}
                    style={{ borderRadius: "0px", zIndex: "9999" }}
                    options={this.state.transporternames}
                    placeholder={"Select"}
                    required
                  />
                </div>
                {/* Vehicle Number */}
                <div
                  className="col-xl-2 col-lg-2 form-group"
                  style={{ zIndex: "12" }}
                >
                  <label>Vehicle Number</label>
                  <Select
                    closeMenuOnSelect={true}
                    name="vehiclenumber"
                    value={this.state.vehiclenumber}
                    className="border-radius-0"
                    onChange={this.newFilter.bind(this, "vehiclenumber")}
                    isMulti={true}
                    style={{ borderRadius: "0px", zIndex: "9999" }}
                    options={this.state.vehicleNumbers}
                    placeholder={"Select"}
                    required
                  />
                </div>
                {/* Driver Name */}
                {/* <div
                  className="col-xl-3 col-lg-3 form-group"
                  style={{ zIndex: "12" }}
                >
                  <label>Driver Name</label>
                  <Select
                    closeMenuOnSelect={true}
                    name="drivername"
                    value={this.state.drivername}
                    className="border-radius-0"
                    onChange={this.newFilter.bind(this, "drivername")}
                    isMulti={true}
                    style={{ borderRadius: "0px", zIndex: "9999" }}
                    options={this.state.driverNames}
                    placeholder={"Select"}
                    required
                  />
                </div> */}

                <div className={"col-xl-1 col-lg-1 form-group"}>
                  <label htmlFor=""></label>
                  <br />
                  <button
                    type="button"
                    className="btn btn-info shadow mt-2"
                    onClick={this.getFilterData}
                    // disabled={this.state.vehiclenumber ? null : "disabled"}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>

            {/* Counter Card */}
            <div className="card shadow mt-2 mb-3">
              <div className="row mx-0">
                {/* <div
                  className="col pt-26px counterBorder"
                  // onClick={this.onClickCounterShowData.bind(this, "open")}
                  // style={{
                  //   borderRight: "1px solid #dadada",
                  // }}
                >
                  <span className="f13 countClass">Continuous Driving</span>
                  <h4 className="txt-pink-medium f30 txt-center">
                    <span className="counter">
                      <CountUp end={0} />
                    </span>
                  </h4>
                </div> */}
                {this.state.inBoundBtn==="btn-info"? <div
                  className="col pt-26px counterBorder"
                  // onClick={this.onClickCounterShowData.bind(this, "workinprogress")}
                  // style={{ borderRight: "1px solid #dadada" }}
                >
                  <span className="f13 countClass">Short Rest</span>
                  <h4 className="f30 txt-center txt-secondary txt-center">
                    <span className="counter">
                      <CountUp end={this.state.shortRestTicketsCount} />
                    </span>
                  </h4>
                </div>:""}
                

                {/* <div
                  className="col cirlce-d counterBorder pt-26px"
                  // onClick={this.onClickCounterShowData.bind(this, "onhold")}
                  // style={{ borderRight: "1px solid #dadada" }}
                >
                  <span className="f13 countClass">Long Rest</span>
                  <h4 className="txt-info f30 txt-center">
                    <span className="counter">
                      <CountUp end={0} />
                    </span>
                  </h4>
                </div> */}

                <div
                  className="col cirlce-d pt-26px counterBorder"
                  // onClick={this.onClickCounterShowData.bind(this, "all")}
                >
                  <span className="f13 countClass">Night Driving</span>
                  <h4 className="txt-pink-medium f30 txt-center">
                    <span className="counter">
                      <CountUp end={this.state.nightDrivingTicketsCount} />
                    </span>
                  </h4>
                </div>

                <div
                  className="col cirlce-d pt-26px counterBorder"
                  // onClick={this.onClickCounterShowData.bind(this, "all")}
                >
                  <span className="f13 countClass">Over Speeding</span>
                  <h4 className="txt-pink-medium f30 txt-center">
                    <span className="counter">
                      {
                        this.state.inPlantBtn === "btn-info"? 
                        <CountUp end={this.state.overspeedingTicketsCountInPlant} />:
                        <CountUp end={this.state.overspeedingTicketsCount} />
                      }
                    </span>
                  </h4>
                </div>
                {this.state.inBoundBtn==="btn-info" ? 
                  <div
                  className="col cirlce-d pt-26px counterBorder"
                  // onClick={this.onClickCounterShowData.bind(this, "all")}
                  >
                    <span className="f13 countClass">Enroute Stoppage</span>
                    <h4 className="txt-pink-medium f30 txt-center">
                      <span className="counter">
                        <CountUp end={this.state.enrouteStoppageTicketsCount} />
                      </span>
                    </h4>
                </div>
                :""}
                
                {this.state.inBoundBtn==="btn-info"?
                  <div
                  className="col cirlce-d pt-26px counterBorder"
                  // onClick={this.onClickCounterShowData.bind(this, "all")}
                >
                  <span className="f13 countClass">Harsh Breaking</span>
                  <h4 className="txt-pink-medium f30 txt-center">
                    <span className="counter">
                      <CountUp end={0} />
                    </span>
                  </h4>
                </div>
                :""}
                

                <div
                  className="col cirlce-d pt-26px"
                  // onClick={this.onClickCounterShowData.bind(this, "all")}
                >
                  <span className="f13 countClass">Idling</span>
                  <h4 className="txt-pink-medium f30 txt-center">
                    <span className="counter">
                      <CountUp end={this.state.idlingTicketsCount} />
                    </span>
                  </h4>
                </div>
              </div>
            </div>

            {/* Data Card*/}
            <div className="card shadow p-4">
              {/*top data*/}
              <div className="row justify-content-around mx-0">

                {/* Overspeed */}
                <div className="col-xl-6 col-lg-6 graphCard">
                  <div className="dataChartButton">
                    <button
                      className="btn btn-danger"
                      onClick={this.overSpeedBoxVisibility}
                      style={{
                        zIndex: "11",
                        fontSize: "12px",
                        padding: "5px 15px",
                        borderRadius: "3px",
                        marginTop: "12px",
                      }}
                    >
                      {this.state.overSpeedButtonText}
                    </button>
                  </div>
                  <span
                    className={`${this.state.overSpeedGraph} ${this.state.overspeedDataFound}`}
                  >
                    <DrilldownBarGraph
                      width={520}
                      yaxistitle={"Count"}
                      title={this.state.overSpeedHeading}
                      subtitle={this.state.overSpeedSubHeading}
                      parentData={this.state.overspeedParentData}
                      childData={this.state.overspeedChildData}
                      context={this}
                      unit="  Time(s)"
                      seriesHeading="Overspeed"
                    />
                  </span>

                  {this.state.overspeedParentData.length > 0 &&
                  this.state.overspeedChildData.length > 0 ? (
                    <></>
                  ) : (
                    <>
                      <span className={this.state.overSpeedGraph}>
                        <div className="txt-center" style={{ marginTop: "8%" }}>
                          <h6>No. of Instances of overspeeding</h6>
                          <p>
                            Inside plant &gt;20km/hr for 10 sec and outside plant
                            &gt; 60km/hr for 10 sec
                          </p>
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "27%",
                            fontWeight: "bold",
                            marginBottom: "20%",
                          }}
                        >
                          <div style={{ marginBottom: "20px" }}>
                            <i className="icofont icofont-search icofont-3x"></i>
                          </div>
                          No Data Found
                        </div>
                      </span>
                    </>
                  )}

                  <div className={`${this.state.overSpeedGrid} txt-center p-2`}>
                    <div className="txt-center" style={{ marginTop: "1%" }}>
                      <h6>No. of Instances of overspeeding</h6>
                      <p>
                        Inside plant &gt;20km/hr for 10 sec and outside plant &gt;
                        60km/hr for 10 sec
                      </p>
                    </div>
                    <DashboardGrid
                      columnDefs={overspeedColDefs}
                      rowData={this.state.inBoundBtn==="btn-info"?this.state.overspeedingTicketsData: this.state.overspeedingTicketsDataInPlant}
                    />
                  </div>
                </div>                

                {/* Night Driving */}
                <div className="col-xl-6 col-lg-6 graphCard">
                  {/* <div className="txt-center pt-2">
                    <h6>{this.state.nightTimeHeading}</h6>
                    <label>{this.state.nightTimeSubHeading}</label>
                  </div> */}
                  <>
                    <div className="dataChartButton">
                      <button
                        className="btn btn-danger"
                        onClick={this.nightTimeBoxVisibility}
                        style={{
                          zIndex: "11",
                          fontSize: "12px",
                          padding: "5px 15px",
                          borderRadius: "3px",
                          marginTop: "12px",
                        }}
                      >
                        {this.state.nightTimeButtonText}
                      </button>
                    </div>

                    <span
                      className={`${this.state.nightTimeGraph} ${this.state.nightDataFound}`}
                    >
                      <DrilldownBarGraph
                        width={520}
                        categories={this.state.nightTimeSeriesCategories}
                        yaxistitle={"Time (in Hrs)"}
                        title={this.state.nightTimeHeading}
                        subtitle={this.state.nightTimeSubHeading}
                        parentData={this.state.nightDriveParentData}
                        childData={this.state.nightDriveChildData}
                        context={this}
                        unit="  Hrs"
                        seriesHeading="Night Driving"
                      />
                    </span>
                    {this.state.nightDriveParentData.length > 0 &&
                    this.state.nightDriveChildData.length > 0 ? (
                      <></>
                    ) : (
                      <>
                        <span className={this.state.nightTimeGraph}>
                          <div className="txt-center" style={{ marginTop: "8%" }}>
                            <h6>Avg. Night driving (Hrs)</h6>
                            <p>
                              Night Driving is driving in between 11 PM - 5 AM
                            </p>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "27%",
                              fontWeight: "bold",
                              marginBottom: "20%",
                            }}
                          >
                            <div style={{ marginBottom: "20px" }}>
                              <i className="icofont icofont-search icofont-3x"></i>
                            </div>
                            No Data Found
                          </div>
                        </span>
                      </>
                    )}

                    <div className={`${this.state.nightTimeGrid} txt-center p-2`}>
                      <div className="txt-center" style={{ marginTop: "1%" }}>
                        <h6>Avg. Night driving (Hrs)</h6>
                        <p>Night Driving is driving in between 11 PM - 5 AM</p>
                      </div>
                      <DashboardGrid
                        columnDefs={nightDrivingColDefs}
                        rowData={this.state.nightDrivingTicketsData}
                      />
                    </div>
                  </>
                </div>
              </div>

              {/*top second data*/}
              <div className="row justify-content-around mx-0 mt-3">

                {/* Short Rest*/}
                {this.state.inBoundBtn==="btn-info" ? 
                  <div className="col-xl-6 col-lg-6 graphCard">
                  <div className="dataChartButton">
                    <button
                      className="btn btn-danger"
                      onClick={this.shortRestBoxVisibility}
                      style={{
                        zIndex: "11",
                        fontSize: "12px",
                        padding: "5px 15px",
                        borderRadius: "3px",
                        marginTop: "12px",
                      }}
                    >
                      {this.state.shortRestButtonText}
                    </button>
                  </div>
                  {this.state.shortRestData.data.length > 0 ? (
                    <>
                      <span className={this.state.shortRestGraph}>
                        <ScatterChartLoadingDashboard
                          title={this.state.shortRestHeading}
                          subtitle={this.state.shortRestSubHeading}
                          context={this}
                          width={520}
                          series={{
                            ...this.state.shortRestData,
                            name: "Short-rest Data",
                            dataSorting: { enabled: true },
                          }}
                          //         name: "Short-rest Data",
                          // dataSorting: { enabled: true }
                          units=" Min"
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <span className={this.state.shortRestGraph}>
                        <div className="txt-center" style={{ marginTop: "8%" }}>
                          <h6>Avg. Short rest Time</h6>
                          <p>
                            Avg. short rest time taken by drivers between 2 trips
                            ( &lt; 4 hours)
                          </p>
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                            marginTop: "27%",
                            fontWeight: "bold",
                            marginBottom: "20%",
                          }}
                        >
                          <div style={{ marginBottom: "20px" }}>
                            <i className="icofont icofont-search icofont-3x"></i>
                          </div>
                          No Data Found
                        </div>
                      </span>
                    </>
                  )}

                  <div className={`${this.state.shortRestGrid} txt-center p-2`}>
                    <div className="txt-center" style={{ marginTop: "1%" }}>
                      <h6>Avg. Short rest Time</h6>
                      <p>
                        Avg. short rest time taken by drivers between 2 trips
                        (&lt; 4 hours)
                      </p>
                    </div>
                    <DashboardGrid
                      columnDefs={shortRestColumnDefs}
                      rowData={this.state.shortRestTicketsData}
                    />
                  </div>
                  </div>
                :""}


                {/* Enroute Stoppage */}
                {this.state.inBoundBtn==="btn-info"? 
                  <div className="col-xl-6 col-lg-6 graphCard">
                  {/* <div className="txt-center pt-2">
                    <h6>{this.state.enrouteStoppageHeading}</h6>
                    <label>{this.state.enrouteStoppageSubHeading}</label>
                  </div> */}
                  <>
                    <div className="dataChartButton">
                      <button
                        className="btn btn-danger"
                        onClick={this.enrouteStoppageBoxVisibility}
                        style={{
                          zIndex: "11",
                          fontSize: "12px",
                          padding: "5px 15px",
                          borderRadius: "3px",
                          marginTop: "12px",
                        }}
                      >
                        {this.state.enrouteStoppageButtonText}
                      </button>
                    </div>

                    {this.state.enrouteStoppageData.data.length > 0 ? (
                      <>
                        <span className={this.state.enrouteStoppageGraph}>
                          <ScatterChartLoadingDashboard
                            title={this.state.enrouteStoppageHeading}
                            subtitle={this.state.enrouteStoppageSubHeading}
                            width={520}
                            context={this}
                            series={{
                              ...this.state.enrouteStoppageData,
                              name: "enroutestoppage Data",
                              dataSorting: { enabled: true },
                            }}
                            units=" Hrs"
                          />
                        </span>
                      </>
                    ) : (
                      <>
                        <span className={this.state.enrouteStoppageGraph}>
                          <div className="txt-center" style={{ marginTop: "8%" }}>
                            <h6>Enroute Stoppage Analysis</h6>
                            <p>
                              Avg. stoppage time of truck from Port-Plant per trip
                              in corresponding time
                            </p>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "27%",
                              fontWeight: "bold",
                              marginBottom: "20%",
                            }}
                          >
                            <div style={{ marginBottom: "20px" }}>
                              <i className="icofont icofont-search icofont-3x"></i>
                            </div>
                            No Data Found
                          </div>
                        </span>
                      </>
                    )}

                    <div
                      className={`${this.state.enrouteStoppageGrid} txt-center p-2`}
                    >
                      <div className="txt-center" style={{ marginTop: "1%" }}>
                        <h6>Enroute Stoppage Analysis</h6>
                        <p style={{ fontSize: "12px" }}>
                          Avg. stoppage time of truck from Port-Plant per trip in
                          corresponding time
                        </p>
                      </div>
                      <DashboardGrid
                        columnDefs={enrouteColumnDefs}
                        rowData={this.state.enrouteStoppageTicketsData}
                      />
                    </div>
                  </>
                  </div>
                :""}
              </div>

              {/* Bottom Card */}
              <DriverFatigueBottomCard
                inPlantBtn={this.state.inPlantBtn}
                idlingData={this.state.idlingData}
                idlingColumnDefs={idlingColumnDefs}
                idlingTicketData={this.state.idlingTicketData}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
