/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import $ from 'jquery';
import axios from 'axios';
import Grid from '../layouts/transporterComponent';
import Modal from 'react-responsive-modal';
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");


export default class ManageTransporters extends Component {

	constructor(props){
		super(props);
		this.state={
			departmentCode: null,
			currentDepartmentTitle: null,
			rowData:null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			pagetitle:"",
			usermanualmodal:false,
			overly: "show-n",
			sliderTrucksTranslate: "",
			loadshow:'show-n',
		}
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		switch(pathToMatch) {
			case "/manage/sndtransporters":
				department='SNDG';
				departmentName = " (Sales and Dispatch) ";
				break;
			case "/manage/prttransporters":
				department='LOG-PRT';
				departmentName = " (Spare Parts) ";
				break;
			case "/manage/tnptransporters":
				department='LOG-TNP';
				departmentName = " (Train and Production) ";
				break;
			case "/manage/sctransporters":
				department='LOG-SC';
				departmentName = " (Supply Chain) ";
				break;
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentName
		});
		return department;
	}
	componentDidMount(){
		if (googleAnalytics.page.enableGA) {
			this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

		}
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		this.setState({
			departmentCode:currentDepartmentCode
		});
		 /*Consignments List*/	
	    this.loadPageData()
	};

	loadPageData = () => {
		redirectURL.post('/transporters/list')    
		.then((response) => {
		  var records = JSON.parse(JSON.stringify(response)).data;
		  
		  if (this.state.departmentCode) {
			  records = records.filter(rec => rec.dept_code === this.state.departmentCode);
			}
			console.log("/transporters/list, records = ", records);
		  this.setState({
			  rowData:records
		  });
		})
		.catch(function (error) {
		  console.log(error);
		  });
	}
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

	onClickShowSliderForTransporterAddition = () =>{
		this.setState({
			sliderTranslate : "slider-translate-30p",
			loadshow:'show-n',
			overly:'show-m',
			showDiv:'show-m',
		})

	}

	addTransporterDetails = e =>{
		e.preventDefault()
		let tCode = $("#transporter_Code").val()
		let tName = $("#transporter_name").val()
		// let created_date = new Date().toISOString()
		let params = {
			is_active : 1,
			transporter_code:tCode,
			transporter_name:tName,
			dept_code: this.state.departmentCode,
			created_date : moment.parseZone().utcOffset("+05:30")._d
		}
		// console.log("param",params)
		redirectURL.post("/transporters/insertNewTransporter",params).then(response =>{
			let data = response.data
			console.log("data",data)
			if(data.message == "Success"){
				$("#transporter_Code").val("")
				$("#transporter_name").val("")
				this.setState({
					basicTitle: "Inserted Successfully.",
					basicType: "success",
					show: true
				})
				this.loadPageData()
			}else{
				this.setState({
					basicTitle: data.message,
					basicType: "warning",
					show: true
				})
			}

		})
	}

	onClickHideAll = () =>{
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderTranslate : "",
			showDiv:'show-n',
			show: false
		});
	}
	
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		
		const {usermanualmodal} = this.state;
		var hideChilds = true;
		// commented this code as this condition was from the msil screen on 27-07-2022
		// if(this.state.departmentCode == "LOG-PRT")
		// {
		// 	hideChilds = false
		// }
		// console.log("hello Checking")
		const columnwithDefs = [
	  	        {
	  	          headerName: "Transporter Code",
	  	          field: "transporter_code",
	  	          width: 300,
                  editable:false,
	    	      filter: "agSetColumnFilter"
		        },
		        {
		          headerName: "Transporter Name",
                  field: "transporter_name",
				  width: 300,
				  editable : false,
                  filter: "agSetColumnFilter"	    	          
				},
				{
					headerName: "Child Codes",
					field: "child_codes",
					width: 200,
					cellStyle: {'white-space': 'normal'},
					editable : false,
					autoHeight:true,
					hide : hideChilds,
					valueGetter:function(params)
					{
						if(params.data.child_code.length > 0)
						{
							var child_codes = ''
							params.data.child_code.map(function(e){
								if(child_codes != "")
								{
									child_codes = child_codes+", "+e
								}
								else{
									child_codes = e
								}
							});
							return child_codes
						}
						else
						{
							return ""
						}
					}	    	          
				  },
		        {
		            headerName: "Status",
		            field: "is_active",
		            width: 90,
					filter: false,
		            //singleClickEdit:true,
		            editable: true,
		            cellRenderer: function(params){
		            	if(params.value == 1 || params.value == 'Active')
	            		{
		            		return 'Active';
	            		}
		            	else
	            		{
		            		return 'Inactive';
	            		}
		            },
		            cellEditor: 'agRichSelectCellEditor',
		            cellEditorParams: {
		                cellRenderer: 'genderCellRenderer',
		                values: ['Active', 'Inactive']
		            }
		        },
		        // {
		        //   headerName: "Action",
		        //   field: "_id",
		        //   width: 100,
				//   filter: false,
		        //   cellRenderer:'updateItem',
		        //   cellRendererParams: {
			    //         actionCall: "transporters",
			    //         actionMethod:"updateTransporter"
			    //     },
			    //     editable:false
		        // }
	    ];
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Manage Transporters {this.state.currentDepartmentTitle} </span>
									   <button onClick={this.onClickShowSliderForTransporterAddition} className={"btn btn-warning float-right"}>Add Transporter</button>
                                </h5>
				   			</div>				   			
		            		<div className="card-body">
				   				   <Grid 
								   ashow={this.state.show} 
								   atype={this.state.basicType} 
								   atitle={this.state.basicTitle}
								   actionCall={"transporters"}
									actionMethod={"updateTransporter"}  
									//table={"trucklocation"} 
									rowData={this.state.rowData} 
									columnwithDefs={columnwithDefs} 

									/>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} >				 	
					 <div className="slide-r-title" style={{overflow:"hidden",marginTop: "48px"}}>
				 		<h4>
                            Add Transporter
							<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.addTransporterDetails}>   
							<div className="form-group mt-20p">
								<label className="">Transporter Code</label> 
								<input type="number" name="text" placeholder="Transporter Code" id="transporter_Code" className="num-cls form-control" required  />
							</div>                         
                            <div className="form-group mt-20p">
								<label className="">Transporter Name</label> 
								<input type="text" name="text" placeholder="Transporter Name" id="transporter_name" className="form-control" pattern="[a-zA-Z'-'\s]*" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>	
                        </div>
					</div>
				 </div>
				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
				<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<h5 className="model-title">Transporter</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This screen consists details regarding to all the transporters, its MSIL code and currently actively taking consignments or not.
							</div>
							
							<div className="col-xl-12 col-lg-12">	
								Image Here
							</div>
							<div className="col-xl-12 col-lg-12">
								For PRT department there is additional column present which is known as child codes which consists of subcodes of transporters through which they pick the consignments from various point of Origin
							</div>
							
							<div className="col-xl-12 col-lg-12">	
								Image Here
							</div>
						</div>
					</div>
				</Modal>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll}></div>
            </div>
              	
		);
	}
}

$(document).on(".num-cls", function() {
    this.value = this.value.replace(/\D\./g,'');
    // this.value = this.value.replace(/[!#$%^*()+\=;'|<>\?a-zA-Z]+/g,"")

});

// const regex = /^[-\w\s]+$/;
// $(document).on(".txt-cls", function() {
//     this.value = this.value.replace(/^[-\w\s]+$/g,'');
// });