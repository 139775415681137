import React, { Component } from "react";

const ManualActionComponent = (props) => {
  let { value } = props;
  let hoverText = "";
  let iconClass = "";
  let iconColor = "";
  let duelFuelTypeShow = "d-none";

  if (props.data.hasOwnProperty("ticket_category")) {
    if (props.data.ticket_category === "Manual") {

      if (value === "Delay") {
        iconClass = "icofont-clock-time";
        hoverText = "Delay";
        iconColor = "txt-pink-dark";
      }

      if (value === "Breakdown") {
        iconClass = "icofont-repair";
        hoverText = "Breakdown";
        iconColor = "txt-secondary";
      }

      if (value === "Accident") {
        iconClass = "icofont-skull-danger";
        hoverText = "Accident";
        iconColor = "txt-danger";
      }

      if (value === "GPS") {
        iconClass = "icofont-map";
        hoverText = "GPS";
        iconColor = "txt-linkedin";
      }

      if (value === "E-Lock") {
        iconClass = "icofont-lock";
        hoverText = "E-Lock";
        iconColor = "txt-info";
      }

      if (value === "Overload") {
        iconClass = "icofont-fast-delivery";
        hoverText = "Overload";
        iconColor = "txt-secondary";
      }

      

      if(value === "Fuel"){
        iconClass = "icofont-water-drop";
        hoverText = "Fuel";
        iconColor = "txt-warning-dark";
      }


      if (value === "Fastag") {
        iconClass = "icofont-ui-tag";
        hoverText = "Fastag";
        iconColor = "txt-info";
      }

      if (value === "Documents") {
        iconClass = "icofont-attachment";
        hoverText = "Documents";
        iconColor = "txt-primary";
      }

      if (value === "Tyre") {
        iconClass = "icofont-drill";
        hoverText = "Tyre";
        iconColor = "txt-primary";
      }

      if (value === "Driver") {
        iconClass = "icofont-engineer";
        hoverText = "Driver";
        iconColor = "txt-primary";
      }

      if (value === "Others") {
        iconClass = "icofont-listine-dots";
        hoverText = "Others";
        iconColor = "txt-danger";
      }



      if (value === "Ready to Close") {
        iconClass = "icofont-safety";
        hoverText = "Ready to close";
        iconColor = "txt-secondary-dark";
      }
      
    }
  }
  return (
    <div>
      <button
        className="custom-btn f22 label label-success"
        title={`${hoverText}`}
      >
        <i className={`icofont ${iconClass} ${iconColor}`}></i>
        <i
          class={`${duelFuelTypeShow} icofont icofont-water-drop txt-warning`}
          style={{ position: "relative", right: "14px" }}
        ></i>
        &nbsp;
      </button>
    </div>
  );
};

export default ManualActionComponent;
