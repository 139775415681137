import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_exporting from "highcharts/modules/exporting"; // For exporting options
import drilldown from "highcharts-drilldown";

export default class PiechartDrilldownComponent extends Component {
  constructor(props) {
    HC_exporting(Highcharts);
    drilldown(Highcharts);
    super(props);
  }

  render() {
    let options = {
      chart: {
        type: "pie",
        width: this.props.width,
        height: 400
      },
        title: {
          text: "",
          align: "",
        },
  
      accessibility: {
        announceNewData: {
          enabled: true,
        },
        point: {
          valueSuffix: this.props.units,
        },
      },
  
      plotOptions: {
        series: {
          borderRadius: 5,
          dataLabels: {
            enabled: true,
            format: `{point.name}: {point.y:.1f} (${this.props.units})`,
          },
        },
      },

      
			title: {
				text: this.props.title
			},
			subtitle: {
				text: this.props.subtitle
			},
  
      tooltip: {
        headerFormat: `<span style="font-size:11px">{series.name} (${this.props.units})</span><br>`,
        pointFormat:
          `<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b> (${this.props.units})<br/>`,
      },
  
      series: [
        {
          name: this.props.backButtonText,
          colorByPoint: true,
          data: this.props.parentData
          // data: [
          //   {
          //     name: "0-3",
          //     y: 61.04,
          //     drilldown: "0-1",
          //     color: "#2e8f0b",
          //   },
          //   {
          //     name: "3-6",
          //     y: 9.47,
          //     drilldown: "1-2",
          //     color: "yellow",
          //   },
          //   {
          //     name: ">6",
          //     y: 9.32,
          //     drilldown: ">2",
          //     color: "red",
          //   },
          // ],
        },
      ],
      drilldown: {
        series: this.props.drilldowData
  
        // series: [
        //   {
        //     name: "0-3",
        //     id: "0-3",
        //     data: [
        //       ["Veh1", 2],
        //       ["Veh2", 1],
        //     ],
        //   },
        //   {
        //     name: "3-6",
        //     id: "3-6",
        //     data: [
        //       ["v15.3", 0.1],
        //       ["v15.2", 2.01],
        //       ["v15.1", 2.29],
        //       ["v15.0", 0.49],
        //       ["v14.1", 2.48],
        //       ["v14.0", 0.64],
        //       ["v13.1", 1.17],
        //       ["v13.0", 0.13],
        //       ["v12.1", 0.16],
        //     ],
        //   },
        //   {
        //     name: ">6",
        //     id: ">6",
        //     data: [
        //       ["v97", 6.62],
        //       ["v96", 2.55],
        //       ["v95", 0.15],
        //     ],
        //   },
        // ],
      },
    };
    return (
        <HighchartsReact
        highcharts={Highcharts}
        containerProps={{ style: { width: "100%", height: "40%" } }}
        options={options}
      />
    );
  }
}
