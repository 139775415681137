/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';


import _ from "lodash";
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';

import CountUp from 'react-countup';

import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM } from '../common/utils';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

var redirectURL = require('../redirectURL');
var moment = require('moment');

export default class FleetSummaryStatus extends Component {
    state = {
            show: false,
            basicTitle: '',
            basicType: "default",
            loadshow: "show-n",
            overly: "show-n",
            dept_code: "",
            totalTrucks: [],
            truckscnt: 0,
            uniqueActiveConsignments: [],
            totalActiveConsignmentsCount: 0,
            ldata: [],
            ondata: [],
            parkdata: [],
            rowDataOntrips: [],
            rowDataPark: [],
            rowDataOff: [],
            rowData: [],
            pivotmodeis: false,
            pivotcols: [],

            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true

            },
            frameworkComponents: {
            },
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: true,
            suppressAggFuncInHeader: true,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            intransit: [],
            inreturn: [],
            inplant: [],
            inparkinglist: [],
            devicenrd: [],
            atdealership: [],
            unloaded: [],
            total: [],
            usergridstate: [],
            usergridstate1: [],
            allData: [],
            offTrip :[],
            apiData : [],
            sliderCriticalTranslate: '',
            sliderTitle : '',
            apiFleetSummary : [],
            allBtn : "btn-danger",
            dedicatedBtn : "btn-default",
            tab: 1
        };



    componentDidMount() {
        this.setState({
            loadshow: "show-m",
            overly : 'show-m'
        });
        this.getUserSavedGridState()
        this.loadData();
    }

    getUserSavedGridState = () =>{
        // console.log("location",window.location.pathname)

        let params = { userId: localStorage.getItem("userid"), screenurl: `${window.location.pathname}-${this.state.tab}`}

        redirectURL.post("/consignments/usergridstates", params)
            .then(async (resp) => {
                //console.log("resp ", resp)
                if(this.state.tab == 1){
                    this.setState({
                    usergridstate: resp.data
                 });
                }else{
                    this.setState({
                        usergridstate1: resp.data
                     });
                }
                
                this.restoreGridStates();
            })
    }
    
    loadData = () => {
        var deptcode = "";
        if (this.props.match.path == "/sndconsolidate") {
            deptcode = "SNDG";
        }
        this.setState({
            dept_code: deptcode
        })

        redirectURL.post("/dashboard/fleetstatussummary")
            .then((response) => {
                // let result = JSON.parse(JSON.stringify(response)).data;
                let result = response.data.fleetSummary
                // let apiData = JSON.parse(response.data.apiData)
                // let fleetStatus = JSON.parse(apiData.fleet_status)
                // console.log("fleetStatusapi ", fleetStatus)
                // let fleetSummary = JSON.parse(apiData.fleet_summary)
                // console.log("fleetSummaryapi ", fleetSummary)
                let isDedicatedData = result.filter(e => e.is_dedicated === 1)
                var intransit = []
                var inreturn = []
                var inplant = []
                var inparkinglist = []
                var devicenrd = []
                var atdealership = []
                var unloaded = []
                var total = []
                var offTrip =[]
                var intransit_dedicated = []
                var inreturn_dedicated = []
                var inplant_dedicated = []
                var inparkinglist_dedicated = []
                var devicenrd_dedicated = []
                var atdealership_dedicated = []
                var unloaded_dedicated = []
                var total_dedicated = []
                var offTrip_dedicated =[]

                if (result.length > 0) {
                    result.map((item) => {
                        if (item.travel_status == "In Transit") {
                            intransit.push(item)
                            if(item.is_dedicated == 1){
                                intransit_dedicated.push(item)
                            }
                        }
                        else if (item.travel_status == "In Return") {
                            // inreturn.push(item)
                            offTrip.push(item)
                            if(item.is_dedicated == 1){
                                offTrip_dedicated.push(item)
                            }
                        }
                        else if (item.travel_status == "In Plant") {
                            inplant.push(item)
                            if(item.is_dedicated == 1){
                                inplant_dedicated.push(item)
                            }
                        }
                        else if (item.travel_status == "In Parking List") {
                            inparkinglist.push(item)
                            if(item.is_dedicated == 1){
                                inparkinglist_dedicated.push(item)
                            }
                        }
                        else if (item.travel_status == "Device NRD") {
                            devicenrd.push(item)
                            if(item.is_dedicated == 1){
                                devicenrd_dedicated.push(item)
                            }
                        }
                        else if (item.travel_status == "At Dealership") {
                            atdealership.push(item)
                            if(item.is_dedicated == 1){
                                atdealership_dedicated.push(item)
                            }
                        }
                        else if (item.travel_status == "Unloaded") {
                            offTrip.push(item)
                            // unloaded.push(item)
                            if(item.is_dedicated == 1){
                                unloaded_dedicated.push(item)
                            }
                        }
                        else if (item.travel_status == "Total") {
                            total.push(item)
                            if(item.is_dedicated == 1){
                                total_dedicated.push(item)
                            }
                        }
                    })
                }
                // console.log("totalTrucks ", totalTrucks)
                this.setState({
                    loadshow : 'show-n',
                    overly: 'show-n',
                    rowDataPark: result,
                    allData: result,
                    allData_counter : result.length,
                    intransit: intransit,
                    inreturn: inreturn,
                    inplant: inplant,
                    inparkinglist: inparkinglist,
                    devicenrd: devicenrd,
                    intransit_counter : intransit.length,
                    inreturn_counter : inreturn.length,
                    inplant_counter : inplant.length,
                    inparkinglist_counter : inparkinglist.length,
                    devicenrd_counter : devicenrd.length,
                    atdealership: atdealership,
                    atdealership_counter : atdealership.length,
                    offTrip_counter : offTrip.length,
                    total: total,
                    unloaded: unloaded,
                    offTrip: offTrip,
                    isDedicatedData,
                    intransit_dedicated,
                    total_dedicated,
                    unloaded_dedicated,
                    atdealership_dedicated,
                    devicenrd_dedicated,
                    inparkinglist_dedicated,
                    inplant_dedicated,
                    offTrip_dedicated,                    
                    // apiData : fleetStatus,
                    // apiFleetSummary : fleetSummary
                });
            })
    }

    restoreGridStates() {
        if(this.state.tab == 1){
            if (this.state.usergridstate.length > 0) {
                var windowstates = this.state.usergridstate;
                this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
                this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
                this.gridApi.setSortModel(windowstates[0].gridcolsort);
                this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
            }        
        }else{
            if (this.state.usergridstate1.length > 0) {
                windowstates = this.state.usergridstate1;
                this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
                this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
                this.gridApi.setSortModel(windowstates[0].gridcolsort);
                this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
            }
        }
    }

    onClickSaveGridState = () => {
        window.colState = this.gridColumnApi.getColumnState();
        window.groupState = this.gridColumnApi.getColumnGroupState();
        window.sortState = this.gridApi.getSortModel();
        window.filterState = this.gridApi.getFilterModel();

        let screenpage = '';

        if (this.props.match.path == "/fleetsummary") {
            screenpage = 'fleet summary';
        }
        // else if (this.props.match.path == "/prtforceclosure") {
        //     screenpage = 'prt force closure';
        // }
        // else if (this.props.match.path == "/tnpforceclosure") {
        //     screenpage = 'tnp force closure';
        // }
        let reqparams = {
            gridcolumns: window.colState,
            gridgroup: window.groupState,
            gridcolsort: window.sortState,
            gridcolfilter: window.filterState,
            userId: localStorage.getItem("userid"),
            // screenurl: window.location.pathname,
            screenurl: `${window.location.pathname}-${this.state.tab}`,
            screentitle: screenpage
        }
        console.log("reqparams ", reqparams)
        redirectURL.post("/consignments/saveGridStates", reqparams)
            .then((response) => {
                //console.log("State response ",response.data)
                this.setState({
                    show: true,
                    basicTitle: "Successfully saved grid layout",
                    basicType: "success",
                    screenurl: window.location.pathname,
                    screentitle: screenpage
                })
            })
            .catch(function (e) {
                console.log("Error ", e)
            })
    }
    onClickHideAll = () =>{
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
        });
    }

    resetState = () => {
        this.gridColumnApi.resetColumnState();
        this.gridColumnApi.resetColumnGroupState();
        this.gridApi.setSortModel(null);
        this.gridApi.setFilterModel(null);
        //console.log('column state reset');
        var reqparams = {
            userId: localStorage.getItem("userid"),
            // screenurl: window.location.pathname,
            screenurl: `${window.location.pathname}-${this.state.tab}`,
            screentitle: this.state.screenpage
        }

        redirectURL.post("/consignments/removeUserGridState", reqparams)
            .then((response) => {
                this.setState({
                    show: true,
                    basicTitle: "Successfully reset default grid layout",
                    basicType: "success"
                })
            })
    }

    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    }

    onGridReady1 = params =>{
        this.gridApi1 = params.api;
        //console.log("Grid is: ", params);
        this.gridApi1.sizeColumnsToFit();
        this.gridColumnApi1 = params.columnApi;
    }

    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    onClickCounter(countertype) {
        let { allData, intransit, inreturn, inplant, inparkinglist, devicenrd, atdealership, unloaded, total,offTrip, isDedicatedData,
            intransit_dedicated,
            total_dedicated,
            unloaded_dedicated,
            atdealership_dedicated,
            devicenrd_dedicated,
            inparkinglist_dedicated,
            inplant_dedicated,
            offTrip_dedicated, } = this.state

        if (countertype == "all") {
            if(this.state.allBtn == 'btn-danger'){
                this.setState({
                    allData_counter : allData.length,
                    rowDataPark: allData,
                    sliderCriticalTranslate : "slider-translate-75p",
                    sliderTitle : 'Total Data'
                })
            }else{
                this.setState({
                    allData_counter : isDedicatedData.length,
                    rowDataPark: isDedicatedData,
                    sliderCriticalTranslate : "slider-translate-75p",
                    sliderTitle : 'Dedicated Data'
                })

            }
           
        }
        else if (countertype == "inplant") {
            if(this.state.allBtn == 'btn-danger'){
                this.setState({
                    rowDataPark: inplant,
                    sliderCriticalTranslate : "slider-translate-75p",
                    sliderTitle : 'In Plant'
                })
            }else{
                this.setState({
                    rowDataPark: inplant_dedicated,
                    sliderCriticalTranslate : "slider-translate-75p",
                    sliderTitle : 'In Plant'
                })

            }
           

        }
        else if (countertype == "intransit") {
            if(this.state.allBtn == 'btn-danger'){
                this.setState({
                    rowDataPark: intransit,
                    sliderCriticalTranslate : "slider-translate-75p",
                    sliderTitle : 'In Transit'
                })
            }else{
                this.setState({
                    rowDataPark: intransit_dedicated,
                    sliderCriticalTranslate : "slider-translate-75p",
                    sliderTitle : 'In Transit'
                })
            }
           

        }
        else if (countertype == "inparkinglist") {
            if(this.state.allBtn == 'btn-danger'){
                this.setState({
                    rowDataPark: inparkinglist,
                    sliderCriticalTranslate : "slider-translate-75p",
                    sliderTitle : 'In Parking List'
                })    
            }else{
                this.setState({
                    rowDataPark: inparkinglist_dedicated,
                    sliderCriticalTranslate : "slider-translate-75p",
                    sliderTitle : 'In Parking List'
                })
            }
          
        }
        else if (countertype == "devicenrd") {
            if(this.state.allBtn == 'btn-danger'){
                this.setState({
                    rowDataPark: devicenrd,
                    sliderCriticalTranslate : "slider-translate-75p",
                    sliderTitle : 'Device Nrd'
                })
            }else{
                this.setState({
                    rowDataPark: devicenrd_dedicated,
                    sliderCriticalTranslate : "slider-translate-75p",
                    sliderTitle : 'Device Nrd'
                })
                
            }
          

        }
        else if (countertype == "atdealership") {
            if(this.state.allBtn == 'btn-danger'){
                this.setState({
                    rowDataPark: atdealership,
                    sliderCriticalTranslate : "slider-translate-75p",
                    sliderTitle : 'At Dealership'
                })
            }else{
                
                this.setState({
                    rowDataPark: atdealership_dedicated,
                    sliderCriticalTranslate : "slider-translate-75p",
                    sliderTitle : 'At Dealership'
                })
            }
           

        }
        // else if (countertype == "unloaded") {
        //     this.setState({
        //         rowDataPark: unloaded
        //     })

        // }
        // else if (countertype == "inreturn") {
        //     this.setState({
        //         rowDataPark: inreturn
        //     })

        // }
        else if(countertype == 'offTrip'){
            if(this.state.allBtn == 'btn-danger'){
                this.setState({
                    rowDataPark:offTrip,
                    sliderCriticalTranslate : "slider-translate-75p",
                    sliderTitle : 'Off Trip'
                })
            }else{
                
                this.setState({
                    rowDataPark:offTrip_dedicated,
                    sliderCriticalTranslate : "slider-translate-75p",
                    sliderTitle : 'Off Trip'
                })
            }
           
        }
    }

    onCloseSliderDiv = () => {
        this.setState({
            sliderZoneTranslate: "",
            sliderCriticalTranslate: "",
            showDiv: "show-n",
            overly: "show-n",
            loadshow: "show-n",
        });
    };

    onClickTab = (param) =>{
        console.log("param", param)
        const { allData,intransit,inreturn,inplant,inparkinglist,devicenrd,atdealership,unloaded,total,offTrip,isDedicatedData,
            intransit_dedicated,total_dedicated, unloaded_dedicated, atdealership_dedicated, devicenrd_dedicated,inparkinglist_dedicated,
            inplant_dedicated,offTrip_dedicated,allData_counter} = this.state
        if(param == 1){
            this.setState({                
                allBtn : "btn-danger",
                dedicatedBtn : "btn-default",
                rowDataPark : allData,
                allData_counter : allData.length,
                intransit_counter : intransit.length,
                // inreturn_counter : inreturn.length,
                inplant_counter : inplant.length,
                inparkinglist_counter : inparkinglist.length,
                devicenrd_counter : devicenrd.length,
                atdealership_counter : atdealership.length,
                offTrip_counter : offTrip.length,
                tab : 1

            }, () => this.getUserSavedGridState())
        }
        else if (param == 2){
            this.setState({
                allBtn : 'btn-default',
                dedicatedBtn : "btn-danger",
                rowDataPark : isDedicatedData,
                allData_counter : isDedicatedData.length,
                intransit_counter : intransit_dedicated.length,
                // inreturn_counter : inreturn.length,
                inplant_counter : inplant_dedicated.length,
                inparkinglist_counter : inparkinglist_dedicated.length,
                devicenrd_counter : devicenrd_dedicated.length,
                atdealership_counter : atdealership_dedicated.length,
                offTrip_counter : offTrip_dedicated.length,  
                tab :2          
            }, () => this.getUserSavedGridState())
        }
    }

    render() {
        var columnDefontrip = [

            {
                headerName: "Truck No",
                field: "vehicle_no",
                width: 200,
            },
            {
                headerName: "Transporter code",
                field: "transporter_code",
                width: 200,
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 200,
            },
            {
                headerName: "Today Distance (KM)",
                field: "current_day_distance",
                width: 140,

            },
            {
                headerName: "Yesterday Distance (KM) ",
                field: "previous_day_distance",
                width: 200,
            },
            {
                headerName: "Last Active On",
                field: "last_location_date",
                width: 200,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.last_location_date)
                }

            },
            {
                headerName: "Last Location",
                field: "last_location",
                width: 500,
            },
            {
                headerName: "Dispatch Date",
                field: "dispatch_date",
                width: 200,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.dispatch_date)
                }

            },
            {
                headerName: "Remaining Distance (KM)",
                field: "remaining_distance",
                width: 200,
            },
            {
                headerName: "Speed (KMPH)",
                field: "speed",
                width: 200,
            },
            {
                headerName: "Stoppage Since",
                field: "stopped_since",
                width: 200,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.stopped_since)
                }
            },
            {
                headerName: "Stoppage Duration",
                field: "stoppage_duration",
                width: 200,
            },
            {
                headerName: "Consigner Code",
                field: "consigner_code",
                width: 200,

            },
            {
                headerName: "Dealer Name",
                field: "consignee_name",
                width: 250,

            },
            {
                headerName: "Expected Trip End",
                field: "expected_eta",
                width: 200,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.expected_eta)
                }
            },
            {
                headerName: "Planned Distance (KM)",
                field: "planned_distance",
                width: 200,
            },
            {
                headerName: "Delay Time",
                field: "delay_time",
                width: 200

            },
            {
                headerName: "Consignment code",
                field: "lr_no",
                width: 200,
            },

            {
                headerName: "Dealer City",
                field: "consignee_city",
                width: 120,


            },
            {
                headerName: "Dealer Code",
                field: "consignee_code",
                width: 120,

            },
            {
                headerName: "Vehicle Status",
                field: "travel_status",
                width: 200,
            },
            {
                headerName: "Travel Status",
                field: "running_status",
                width: 200,
            },
            // {
            //     headerName: "GPS Status",
            //     field: "gps_status",
            //     width: 100,
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.gps_status != "" && params.data.gps_status != undefined) {
            //                 if (params.data.gps_status == "Inactive") {
            //                     return "Inactive";
            //                 }
            //                 else {
            //                     return "Active";
            //                 }

            //             }
            //             else {
            //                 if (params.data.truckloc.length > 0) {
            //                     if (params.data.truckloc[0].elaped_time_from_last_moved_seconds == "" || params.data.truckloc[0].elaped_time_from_last_moved_seconds == undefined) {
            //                         return "Inactive";
            //                     }
            //                     else {
            //                         return "Active";
            //                     }
            //                 }
            //                 else {
            //                     return "";
            //                 }
            //             }

            //         }
            //         catch (e) {
            //             return "";
            //         }
            //     },
            //     cellClass: function (params) {
            //         try {
            //             if (params.data.gps_status != "" && params.data.gps_status != undefined) {
            //                 if (params.data.gps_status == "Inactive") {
            //                     return ["maroonbg", "fbold"];
            //                 }
            //                 else {
            //                     return ["greenbg", "white", "fbold"];
            //                 }

            //             }
            //             else {
            //                 if (params.data.truckloc.length > 0) {
            //                     if (params.data.truckloc[0].elaped_time_from_last_moved_seconds == "" || params.data.truckloc[0].elaped_time_from_last_moved_seconds == undefined) {
            //                         return ["maroonbg", "fbold"];
            //                     }
            //                     else {
            //                         return ["greenbg", "white", "fbold"];
            //                     }
            //                 }
            //                 else {
            //                     return "";
            //                 }
            //             }

            //         }
            //         catch (e) {
            //             return "";
            //         }
            //     }

            // },
        ]

        const gpsColDef = [
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 200,
            },
            {
                headerName: "Not Reported  30-45 Days",
                field: "Non Reporting 30-45 days",
                width: 140,
            },
            {
                headerName: "Not Reported  46-90 Days",
                field: "Non Reporting 46-90 days",
                width: 140,
            },
            {
                headerName: "Not Reported  >90 Days",
                field: "Non Reporting > 90 days",
                width: 140,
            },
            {
                headerName: "In Transit",
                field: "In Transit",
                width: 140,
            },
            {
                headerName: "At Dealership",
                field: "At Dealership",
                width: 140,
            },
            {
                headerName: "Reported In Parking",
                field: "Parking",
                width: 140,
            },
            {
                headerName: "In Return To Plant",
                field: "Return To Plant",
                width: 140,
            },
            {
                headerName: "Device NRD",
                field: "Device NRD",
                width: 140,
            },
            {
                headerName: "Waiting For Oem Unloading",
                field: "OEM Unloading",
                width: 140,
            },
            {
                headerName: "OEM (0-10 KM)",
                field: "(0-10km)OEM",
                width: 140,
            },
        ]

        const apiFleetSummaryColDef = [
            {
                headerName: "Away From Plant",
                headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 155,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "Plants",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "consigner_name",
                        width: 150,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: "0 - 100",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "0-100",
                        width: 150,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: "101 - 200",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "101-200",
                        width: 150,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: "201  - 300",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: "201-300",
                        width: 150,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                    {
                        headerName: "> 300",
                        headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                        field: ">300",
                        width: 150,
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        chartDataType: "series"
                    },
                ]
            },
        ]

        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className="button-group">
                    <button className={"btn "+this.state.allBtn} onClick={() => this.onClickTab(1)}>Complete Fleet Data</button>
                    <button className={"btn "+this.state.dedicatedBtn} onClick={() => this.onClickTab(2)}>Dedicated Fleet Data</button>
                </div>

                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                        <div className="card">
                            <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cirlce-d cursorPointer" onClick={this.onClickCounter.bind(this, "all")}>
                                            <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Total</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.allData_counter > 0) ? this.state.allData_counter : 0} /></span></h4>

                                        </div>

                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "devicenrd")}>
                                            <span className="f13"><i className="icofont icofont-clock-time f22 maroon"></i><br />Device NRD</span>
                                            <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.devicenrd_counter > 0) ? this.state.devicenrd_counter : 0} /></span></h4>

                                        </div>

                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "inparkinglist")}>
                                            <span className="f13"><i className="icofont icofont-clock-time f22 txt-primary"></i><br /> In Parking List</span>
                                            <h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.inparkinglist_counter > 0) ? this.state.inparkinglist_counter : 0} /></span></h4>

                                        </div>

                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "inplant")}>
                                            <span className="f13"><i className="icofont icofont-clock-time f22 bluefont"></i><br /> In Plant</span>
                                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.inplant_counter > 0) ? this.state.inplant_counter : 0} /></span></h4>

                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "intransit")}>
                                            <span className="f13"><i className="icofont icofont-clock-time f22 txt-success"></i><br /> In Transit</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.intransit_counter > 0) ? this.state.intransit_counter : 0} /></span></h4>
                                        </div>                                        
                                        
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "atdealership")}>
                                            <span className="f13"><i className="icofont icofont-clock-time f22 txt-success"></i><br /> At Dealership</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.atdealership_counter > 0) ? this.state.atdealership_counter : 0} /></span></h4>

                                        </div>
                                        {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "unloaded")}>
                                            <span className="f13"><i className="icofont icofont-clock-time f22 txt-warning"></i><br />Unloaded</span>
                                            <h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.unloaded.length > 0) ? this.state.unloaded.length : 0} /></span></h4>

                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "inreturn")}>
                                            <span className="f13"><i className="icofont icofont-clock-time f22 txt-primary"></i><br /> In Return</span>
                                            <h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.inreturn.length > 0) ? this.state.inreturn.length : 0} /></span></h4>

                                        </div> */}
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "offTrip")}>
                                            <span className="f13"><i className="icofont icofont-clock-time f22 txt-primary"></i><br /> offTrip</span>
                                            <h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.offTrip_counter > 0) ? this.state.offTrip_counter : 0} /></span></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-xl-12 col-lg-12">
                        <div className="card height-equal equal-height-lg">
                            <div className="card-header" style={{ padding: '10px' }}>
                                <h5>Fleet Summary </h5>
                            </div>
                            <div className="col-xl-12">
                                <span className="layoutbtns float-right">
                                    <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState}>
                                        <i className="icofont icofont-save"></i> Save Grid Layout
                                    </button>
                                    <button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState}>
                                        <i className="icofont icofont-refresh"></i> Reset Default Layout
                                    </button>
                                    
                                </span>
                            </div>

                            {/* node data */}
                            <div className="card-body" style={{ padding: '10px' }}>
                                <div id="myGrid" style={{ height: "600px", width: "100%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnDefontrip}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowDataPark}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        frameworkComponents={this.state.frameworkComponents} 
                                        // rowHeight={this.state.rowHeight}
                                        // onCellEditingStopped={this.onCellUpdateData}
                                    />
                                </div>
                            </div>



                            {/* api data */}
                            {/* <div className="card-body" style={{ padding: '10px' }}>
                            <div className="card-header" style={{ padding: '10px' }}>
                                <h5>GPS FLeet Status </h5>
                            </div>
                                <div id="myGrid" style={{ height: "600px", width: "100%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={gpsColDef}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.apiData}
                                        enableCharts={false}
                                        enableRangeSelection={true}                                                                              
                                        statusBar={this.state.statusBar}
                                        pagination={true}
                                        //sideBar={this.state.sideBar}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                    />
                                </div>
                            </div> */}

                            {/* fleet summary */}
                            {/* <div className="card-body" style={{ padding: '10px' }}>
                            <div className="card-header" style={{ padding: '10px' }}>
                                <h5>GPS FLeet Status Summary</h5>
                            </div>
                                <div id="myGrid" style={{ height: "500px", width: "60%" }} className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={apiFleetSummaryColDef}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.apiFleetSummary}
                                        enableCharts={false}
                                        enableRangeSelection={true}                                                                              
                                        statusBar={this.state.statusBar}
                                        pagination={true}
                                        groupHeaderHeight={50}
                                        headerHeight={70}
                                        floatingFiltersHeight={50}
                                        pivotGroupHeaderHeight={30}
                                        pivotHeaderHeight={50}
                                        rowHeight={50}
                                        onGridReady={this.onGridReady1}
                                        // onGridState={this.onGridState1}
                                        //sideBar={this.state.sideBar}
                                        // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                    />
                                </div>
                            </div> */}

                        </div>
                    </div>                  

                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>    </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll}></div>

            </div>
        );
    }
}

function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
        try {
            ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
        }
        catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
}

function saveToLS(key, value) {
    if (global.localStorage) {
        global.localStorage.setItem(
            "rgl-8",
            JSON.stringify({
                [key]: value
            })
        );
    }
}

function refActions() {
    alert("helloo")
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}








