import React, { Component } from 'react';
import CountUp from 'react-countup';


export default class DashboardCountersComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            totalTrucksCount: 0,
            trucksWithGpsDataCount: 0,
            trucksWithNoTransportersCount: 0,
            gpsDataNotReportedTodayCount: 0,
            totalActiveConsignmentsCount: 0,
            gpsNotTodayActiveConsignmentsCount: 0,
            totalInactiveConsignmentsCount: 0,
            ontripwithgps: 0,
            ontripwithnogps24hrs: 0,
            israil: 0,
            ontripgpsna: 0,
            railtotlaontrip: 0,
            atdealertruckcnt: 0,
            atdealernogps: 0,
            primaryontrips: 0,
            secondaryontrips: 0,
            deptcode: '',
            inactiveenmovil: 0,
            inactiveaxes: 0,
            activeenmovil: 0,
            activeaxes: 0,
            totalaxes: 0,
            totalenmovil: 0,
            totalactive: 0,
            totalinactive: 0,
            totalactiveontrips: 0,
            totalactiveofftrips: 0,
            totalinactiveontrips: 0,
            totalinactiveofftrips: 0,
            totaltruckgps: 0,
            totaltruckgpsontrips: 0,
            totaltruckgpsofftrips: 0,

            inactiveaxesontrips: 0,
            inactiveenmovilontrips: 0,
            inactiveaxesofftrips: 0,
            inactiveenmovilofftrips: 0
        }

    }
    componentWillReceiveProps(newprops) {
        let gpsDataNotReportedToday = newprops.tottrucks - newprops.truckgps;
        let gpsDataNotReportedTodayCount = (gpsDataNotReportedToday) ? gpsDataNotReportedToday : 0;
        let totalInactiveConsignmentsCount = newprops.tottrucks - newprops.totalActiveConsignmentsCount;
        totalInactiveConsignmentsCount = (totalInactiveConsignmentsCount >= 0) ? totalInactiveConsignmentsCount : 0;
        var ontripwithnogps24hrs = (newprops.totalActiveConsignmentsCount - newprops.ontripwithgps)
        //console.log("israil ", newprops.israil)
        if (newprops.israil == 1) {
            var ontripgpsna = newprops.ontripgpsna
            var railtotlaontrip = parseInt(newprops.ontripwithgps) + parseInt(newprops.ontripgpsna)
        }
        else {
            var ontripgpsna = 0
            var railtotlaontrip = 0
        }
        this.setState({
            totalTrucksCount: newprops.tottrucks,
            trucksWithGpsDataCount: newprops.truckgps,
            gpsDataNotReportedTodayCount: gpsDataNotReportedTodayCount,
            trucksWithNoTransportersCount: newprops.notruckgps,
            totalActiveConsignmentsCount: newprops.totalActiveConsignmentsCount,
            gpsNotTodayActiveConsignmentsCount: newprops.gpsNotTodayActiveConsignmentsCount,
            totalInactiveConsignmentsCount: totalInactiveConsignmentsCount,
            ontripwithgps: newprops.ontripwithgps,
            ontripwithnogps24hrs: ontripwithnogps24hrs,
            ontripgpsna: ontripgpsna,
            railtotlaontrip: railtotlaontrip,
            israil: newprops.israil,
            atdealertruckcnt: newprops.atdealertruckcnt,
            atdealernogps: newprops.atdealernogps,
            primaryontrips: newprops.primaryontrips,
            secondaryontrips: newprops.secondaryontrips,
            deptcode: newprops.deptcode,
            inactiveenmovil: newprops.inactiveenmovil,
            inactiveaxes: newprops.inactiveaxes,
            activeenmovil: newprops.activeenmovil,
            activeaxes: newprops.activeaxes,
            totalaxes: newprops.inactiveaxes + newprops.activeaxes,
            totalenmovil: newprops.inactiveenmovil + newprops.activeenmovil,
            totaltruckgps: newprops.totalactive + newprops.totalinactive,
            totalactive: newprops.totalactive,
            totalinactive: newprops.totalinactive,
            totalactiveontrips: newprops.totalactiveontrips,
            totalactiveofftrips: newprops.totalactiveofftrips,
            totalinactiveontrips: newprops.totalinactiveontrips,
            totalinactiveofftrips: newprops.totalinactiveofftrips,
            totaltruckgpsontrips: newprops.totalactiveontrips + newprops.totalinactiveontrips,
            totaltruckgpsofftrips: newprops.totalactiveofftrips + newprops.totalinactiveofftrips,

            inactiveaxesontrips: newprops.inactiveaxesontrips,
            inactiveenmovilontrips: newprops.inactiveenmovilontrips,
            inactiveaxesofftrips: newprops.inactiveaxesofftrips,
            inactiveenmovilofftrips: newprops.inactiveenmovilofftrips,

            totalGpsRecords : newprops.totalGpsRecords,
            active_trucks: newprops.active_trucks,
            inactive_trucks:newprops.inactive_trucks,

        })
    }
    onClickCounter(data) {
        this.props.context.onClickCounterShowData(data)
    }
    render() {
        const isPrt = this.props.context.props.match.path === "/dashboard/prtdefault" ? true : false

        return (
            <div className="row">

                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="card">
                        <div className="card-body" id="dashboardCounterCardBody">
                            <div className="crm-numbers pb-0">
                                <div className="row">
                                    {/*
                                <div className="col cirlce-d " >
                                    <span className="f13">
                                        <i className="icofont icofont-truck cus-i txt-info f24"></i>
                                        <br /> Total Trucks
                                    </span>
                                    <h4 className="txt-info f50">
                                        <span className="counter">
                                            <CountUp end={(this.state.totalTrucksCount != '')?this.state.totalTrucksCount:0}/>
                                        </span>
                                    </h4>
                                     <div className=" f20">
                                        <div className="counter txt-success-medium">
                                        On Trip Trucks: &nbsp;
                                            <CountUp end={(this.state.totalActiveConsignmentsCount != '')?this.state.totalActiveConsignmentsCount:0}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col ">
                                    <span className="f13">
                                        <i className="icofont icofont-truck-loaded f22 txt-primary-dark"></i>
                                        <br /> Trucks with GPS Data in 24hrs
                                    </span>
                                    <h4 className="txt-primary f50">
                                        <span className="counter">
                                            <CountUp end= {(this.state.trucksWithGpsDataCount) ? this.state.trucksWithGpsDataCount : 0}/>
                                        </span>
                                    </h4>
                                    <div className=" f20">
                                        <div className="counter txt-success-medium">
                                        On Trip Trucks: &nbsp;
                                            <CountUp end={(this.state.ontripwithgps != '')?this.state.ontripwithgps:0}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col ">
                                    <span className="f13">
                                        <i className="icofont icofont-social-google-map  f24 txt-pink-light"></i>
                                        <br /> GPS Data Not Reported in 24hrs
                                    </span>
                                    <h4 className="txt-pink-medium f50">
                                        <span className="counter">
                                            <CountUp end={(this.state.gpsDataNotReportedTodayCount) ? this.state.gpsDataNotReportedTodayCount : 0}/>
                                        </span>
                                    </h4>
                                    <div className=" f20">
                                        <div className="counter txt-success-medium">
                                        On Trip Trucks: &nbsp;
                                           
                                            <CountUp end={(this.state.ontripwithnogps24hrs != '')?this.state.ontripwithnogps24hrs:0}/>

                                        </div>
                                    </div>
                                    {(this.state.deptcode != '' && this.state.deptcode == "SNDG")?
                                    <div className=" f16">
                                        <span className="counter txt-secondary-medium" style={{color:"#0077ff"}}>
                                        Primary: &nbsp;
                                            <CountUp end={(this.state.primaryontrips != '')?this.state.primaryontrips:0}/>
                                        </span>&nbsp;&nbsp;
                                        <span className="counter txt-danger-medium" style={{color:"#fd2f52"}}>
                                            Secondary: &nbsp;
                                            <CountUp end={(this.state.secondaryontrips != '')?this.state.secondaryontrips:0}/>
                                        </span>
                                    </div>
                                    :""}

                                </div>
                                    */}

                                    <div className="col cirlce-d " style={{ cursor: "pointer" }}>
                                        <span className="f13">
                                            <i className="icofont icofont-truck cus-i txt-info f24"></i>
                                            <br /> Total Trucks with GPS Installed
                                        </span>
                                        <h4 className="txt-info f50" onClick={this.onClickCounter.bind(this, "0")}>
                                            <span className="counter">
                                                <CountUp end={(this.state.totalGpsRecords) ? this.state.totalGpsRecords : 0} />
                                            </span>
                                        </h4>
                                        {/* <div className=" f16">
                                            <div className="counter txt-success-medium" onClick={this.onClickCounter.bind(this, "1")}>
                                                Ontrips : &nbsp;
                                                <CountUp end={(this.state.totaltruckgpsontrips != '') ? this.state.totaltruckgpsontrips : 0} />
                                            </div>

                                            <div className="counter txt-danger-medium" onClick={this.onClickCounter.bind(this, "2")}>
                                                Off Trips : &nbsp;

                                                <CountUp end={(this.state.totaltruckgpsofftrips != '') ? this.state.totaltruckgpsofftrips : 0} />

                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="col " style={{ cursor: "pointer" }}>
                                        <span className="f13">
                                            <i className="icofont icofont-truck-loaded f22 txt-primary-dark"></i>
                                            <br />  Total Active Trucks
                                        </span>
                                        <h4 className="txt-primary f50" onClick={this.onClickCounter.bind(this, "3")}>
                                            <span className="counter">
                                                <CountUp end={(this.state.active_trucks) ? this.state.active_trucks : 0} />
                                            </span>
                                        </h4>
                                        {/* <div className=" f16">
                                            <div className="counter txt-success-medium" onClick={this.onClickCounter.bind(this, "4")}>
                                                Ontrips: &nbsp;
                                                <CountUp end={(this.state.totalactiveontrips != '') ? this.state.totalactiveontrips : 0} />
                                            </div>

                                            <div className="counter txt-danger-medium" onClick={this.onClickCounter.bind(this, "5")}>
                                                Off Trips : &nbsp;

                                                <CountUp end={(this.state.totalactiveofftrips != '') ? this.state.totalactiveofftrips : 0} />

                                            </div>
                                        </div> */}
                                    </div>

                                    <div className="col " style={{ cursor: "pointer" }}>
                                        <span className="f13">
                                            <i className="icofont icofont-social-google-map  f24 txt-pink-light"></i>
                                            <br /> Total Inactive Trucks
                                        </span>
                                        <h4 className="txt-pink-medium f50" onClick={this.onClickCounter.bind(this, "6")}>
                                            <span className="counter">
                                                <CountUp end={(this.state.inactive_trucks) ? this.state.inactive_trucks : 0} />
                                            </span>
                                        </h4>
                                        <div className=" f16">
                                            {/* <div className="counter txt-success-medium" onClick={this.onClickCounter.bind(this, "7")}>
                                                Ontrips: &nbsp;

                                                <CountUp end={(this.state.totalinactiveontrips != '') ? this.state.totalinactiveontrips : 0} />

                                            </div>

                                            <div className="counter txt-danger-medium" onClick={this.onClickCounter.bind(this, "8")}>
                                                Off Trips: &nbsp;

                                                <CountUp end={(this.state.totalinactiveofftrips != '') ? this.state.totalinactiveofftrips : 0} />

                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col d-none">
                                        <span className="f13">
                                            <i className="icofont icofont-ui-home  f24 txt-warning-light"></i>
                                            <br /> At Dealer
                                        </span>
                                        <h4 className="txt-warning-medium f50" style={{ cursor: "pointer" }} onClick={this.onClickCounter.bind(this, "9")}>
                                            <span className="counter">
                                                <CountUp end={(this.state.atdealertruckcnt) ? this.state.atdealertruckcnt : 0} />
                                            </span>
                                        </h4>
                                        <div className=" f18">
                                            <div className="counter txt-success-medium">
                                                GPS No Data in 24hrs: &nbsp;
                                                {/* <CountUp end={(this.state.gpsNotTodayActiveConsignmentsCount != '')?this.state.gpsNotTodayActiveConsignmentsCount:0}/> */}

                                                <CountUp end={(this.state.atdealernogps != '') ? this.state.atdealernogps : 0} />

                                            </div>
                                            {/* <div className="counter txt-danger-medium">
                                            Inactive: &nbsp;
                                            <CountUp end={(this.state.totalInactiveConsignmentsCount != '')?this.state.totalInactiveConsignmentsCount:0}/>
                                        </div> */}
                                        </div>
                                    </div>

                                    {/* <div className="col " onClick={this.onClickCounter.bind(this,4)}>
                                    <span className="f13">
                                        <i className="icofont icofont-truck-alt f22 txt-info-medium"></i>
                                        <br />Trucks with No Transporters
                                    </span>
                                    <h4 className="txt-info-dark f50">
                                        <span className="counter">
                                            <CountUp end={(this.state.trucksWithNoTransportersCount) ? this.state.trucksWithNoTransportersCount : 0}/>
                                        </span>
                                    </h4>
                                </div> */}


                                </div>

                            </div>

                        </div>
                    </div>
                </div>


               {/* {isPrt === false ? 
                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <h5>ONMOVE:</h5>
                    <div className="card">
                        <div className="card-body" id="dashboardCounterCardBody">
                            <div className="crm-numbers pb-0">
                                <div className="row">
                                    <div className="col cirlce-d ">
                                        <span className="f13">
                                            <i className="icofont icofont-truck cus-i txt-info f24"></i>
                                            <br /> Total Trucks
                                        </span>
                                        <h4 className="txt-info f50" style={{ cursor: "pointer" }} onClick={this.onClickCounter.bind(this, "totaxes")}>
                                            <span className="counter">
                                                <CountUp end={this.state.totalaxes} />
                                            </span>
                                        </h4>
                                    </div>

                                    <div className="col cirlce-d ">
                                        <span className="f13">
                                            <i className="icofont icofont-truck cus-i txt-success f24"></i>
                                            <br /> Active Trucks
                                        </span>
                                        <h4 className="txt-success f50" style={{ cursor: "pointer" }} onClick={this.onClickCounter.bind(this, "actaxes")}>
                                            <span className="counter">
                                                <CountUp end={this.state.activeaxes} />
                                            </span>
                                        </h4>
                                    </div>
                                    <div className="col cirlce-d " style={{ cursor: "pointer" }}>
                                        <span className="f13">
                                            <i className="icofont icofont-truck cus-i txt-danger f24"></i>
                                            <br /> Inactive Trucks
                                        </span>
                                        <h4 className="txt-danger f50" onClick={this.onClickCounter.bind(this, "axesinactivetrucks")}>
                                            <span className="counter">
                                                <CountUp end={this.state.inactiveaxes} />
                                            </span>
                                        </h4>
                                        <div className=" f16">
                                            <div className="counter txt-success-medium" onClick={this.onClickCounter.bind(this, "10")}>
                                                Ontrips: &nbsp;

                                                <CountUp end={(this.state.inactiveaxesontrips != '') ? this.state.inactiveaxesontrips : 0} />

                                            </div>

                                            <div className="counter txt-danger-medium" onClick={this.onClickCounter.bind(this, "11")}>
                                                Off Trips : &nbsp;

                                                <CountUp end={(this.state.inactiveaxesofftrips != '') ? this.state.inactiveaxesofftrips : 0} />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> :""} */}

                {/* {isPrt === false ? <div className="col-xl-6 col-lg-6 col-sm-6 beffect">
                    <h5>ENMOVIL:</h5>
                    <div className="card">
                        <div className="card-body" id="dashboardCounterCardBody">
                            <div className="crm-numbers pb-0">
                                <div className="row">
                                    <div className="col cirlce-d ">
                                        <span className="f13">
                                            <i className="icofont icofont-truck cus-i txt-info f24"></i>
                                            <br /> Total Trucks
                                        </span>
                                        <h4 className="txt-info f50" style={{ cursor: "pointer" }} onClick={this.onClickCounter.bind(this, "totenv")}>
                                            <span className="counter">
                                                <CountUp end={this.state.totalenmovil} />
                                            </span>
                                        </h4>
                                    </div>

                                    <div className="col cirlce-d ">
                                        <span className="f13">
                                            <i className="icofont icofont-truck cus-i txt-success f24"></i>
                                            <br /> Active Trucks
                                        </span>
                                        <h4 className="txt-success f50" style={{ cursor: "pointer" }} onClick={this.onClickCounter.bind(this, "actenv")}>
                                            <span className="counter">
                                                <CountUp end={this.state.activeenmovil} />
                                            </span>
                                        </h4>
                                    </div>
                                    <div className="col cirlce-d " style={{ cursor: "pointer" }}>
                                        <span className="f13">
                                            <i className="icofont icofont-truck cus-i txt-danger f24"></i>
                                            <br /> Inactive Trucks
                                        </span>
                                        <h4 className="txt-danger f50" onClick={this.onClickCounter.bind(this, "enmoilinactivetrucks")}>
                                            <span className="counter">
                                                <CountUp end={this.state.inactiveenmovil} />
                                            </span>
                                        </h4>
                                        <div className=" f16">
                                            <div className="counter txt-success-medium" onClick={this.onClickCounter.bind(this, "12")}>
                                                Ontrips: &nbsp;

                                                <CountUp end={(this.state.inactiveenmovilontrips != '') ? this.state.inactiveenmovilontrips : 0} />

                                            </div>

                                            <div className="counter txt-danger-medium" onClick={this.onClickCounter.bind(this, "13")}>
                                                Off Trips: &nbsp;

                                                <CountUp end={(this.state.inactiveenmovilofftrips != '') ? this.state.inactiveenmovilofftrips : 0} />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : ""} */}
            </div>
        );
    }
}
