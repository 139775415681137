/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import SweetAlert from "react-bootstrap-sweetalert";

import Select from "react-select";
import _ from "lodash";

import MarkerClusterer from "@google/markerclusterer";
import Modal from "react-responsive-modal";
import axios from "axios";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import {
    getDDMMYYYY,
    getYYYYMMDD,
    getDDMMYYYYHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenDDMMMYYYY,
} from "../common/utils";
import "react-vertical-timeline-component/style.min.css";
import DrawMap from "../common/drawmap";
import ConsignmentActions from "../manage/ConsignmentActionsComponent";
import ParkingoutCarrier from "./parkingcarrierbtn";
import $ from "jquery";
// import "jquery-ui/ui/core";
import "jquery-ui/ui/widgets/datepicker";
var infoBox = require("../common/google-infowindow");
var googleAnalytics = require("../common/googleanalytics");

var redirectURL = require("../redirectURL");
var moment = require("moment");

var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng;
var allcords = [];
var map;
var rad = function (x) {
    return (x * Math.PI) / 180;
};
var rlatitude;
var rlongitude;

var fencingLength = 0;
var geoFencingArea;

const decipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) =>
        textToChars(salt).reduce((a, b) => a ^ b, code);
    return (encoded) =>
        encoded
            .match(/.{1,2}/g)
            .map((hex) => parseInt(hex, 16))
            .map(applySaltToChar)
            .map((charCode) => String.fromCharCode(charCode))
            .join("");
};
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
const cipher = (salt) => {
        const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
        const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = (code) =>
            textToChars(salt).reduce((a, b) => a ^ b, code);
        try {
            return (text) =>
                text
                    .split("")
                    .map(textToChars)
                    .map(applySaltToChar)
                    .map(byteHex)
                    .join("");
            // eslint-disable-next-line no-unreachable
        } catch (e) { }
    };

var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class NightDrivingReport extends Component {
    state = {
            pageTitle: "",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            uploadDivWidth: "0%",
            sliderTranslate: "",
            sliderRouteTranslate: "",
            tabsliderTranslate: "",
            loadshow: "show-n",
            showDiv: "show-n",
            alertshow: "fade",
            alertmg: "show-n",
            alerterrmg: "show-n",
            overly: "show-n",
            alerterrshow: "fade",
            alert: null,
            show: false,
            basicTitle: "",
            basicType: "default",
            show1: false,
            basicTitle1: "",
            basicType1: "default",
            modules: AllModules,
            sidebarSubHeader: "",
            defTransitCoords: "",
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            rowData: [],
            rowModelhighchartoptionsType: "enterprise",
            rowGroupPanelShow: "always",
            overlayNoRowsTemplate: "",
            frameworkComponents: {
                consignmentActions: ConsignmentActions,
                customLoadingCellRenderer: CustomLoadingCellRenderer,
            },
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,

            childconfs: [],
            childrow: [],
            rowModelType: "serverSide",
            paginationPageSize: 50,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left",
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center",
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" },
                ],
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel",
                    },
                ],
            },
            detailCellRendererParams: {},
            rowClassRules: {
                // "highlitRow": "data.transit_delay == 1",
                // "green":"data.status >= 3"
            },
            startDate: "",
            endDate: "",
            mapinfo: "",
            defaultsdate: "",
            defaultedate: "",
            withdrawmodal: false,
            parkingyard: "show-m",
            truckinfo: "show-n",
            pact: "btn-warning",
            tact: "btn-defaultc",
            truckslist: [],
            truck_no: { value: "", label: "ALL" },
            dept_code: "",
            transporters: [],
            transporter_code: { value: "", label: "Select Transporter" },
            struckslist: [],
            struck_no: { value: "", label: "Select Truck" },
            radius: "5000",
            rlatitude: "",
            rlongitude: "",
            latitude: "",
            longitude: "",
            defaultradius: 0,
            coordinates: [],
            alltrucks: [],
            search_truck_no: "",
            search_transporter_name: "",
            search_gps_provider: "",
            search_speed: "",
            search_last_packet_time: "",
            search_gps_status: "",
            search_address: "",
            search_city: "",
            search_state: "",
            search_carrier_reported: "",
            reporttruck: 0,
            plants: [],
            plant_code: { value: "", label: "Select Parking Yard" },
            withdrawdata: "",
            withdraw_reason: "",
            gpsplant: "",
            gpsradius: 15,
            mwidth: "col-xl-5 col-lg-5",
            rowData1: [],
            nsearch: "show-n",
            selectedTabLat: "",
            loginplant: "",
            filteredData: [],
            consignment_code: '',
            showTrucksList: "show-n",
            manual_parking_out: "",
            parkingdata: "",
            slideerParkingOut: "",
    };

    

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction =
                    googleAnalytics.page.actionType.gridAction +
                    " - " +
                    window.location.pathname;
                let eventCounterAction =
                    googleAnalytics.page.actionType.counterAction +
                    " - " +
                    window.location.pathname;
                let eventFormAction =
                    googleAnalytics.page.actionType.formAction +
                    " - " +
                    window.location.pathname;
                this.setState({
                    eventGridAction: eventGridAction,
                    eventCounterAction: eventCounterAction,
                    eventFormAction: eventFormAction,
                });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    };

    async componentDidMount() {
        loadDateTimeScript();
        loadDatepicker();
        // var loguser = decode(localStorage.getItem("m"));
        // console.log("LOGUSER ", JSON.parse(loguser));
        // var loginplant = "";
        // try {
        //     var luser = JSON.parse(loguser);
        //     loginplant = luser.is_plant_code;
        //     // console.log("logplant", loginplant)
        //     this.setState({
        //         loginplant: loginplant,
        //         // consigner:[{"value":luser.is_plant_code,"label":luser.is_plant_name}]
        //     });
        // } catch (e) { }

        var sdate = moment
            .parseZone()
            .subtract(5, "days")
            .format("YYYY-MM-DD");
        var edate = moment.parseZone().format("YYYY-MM-DD");
        //console.log("Props ", this.props.match)
        // this.logPageView(); // Log page view to GA
        var urlpath;
        var hText = "(All)";
        this.setState({
            loadshow: "show-m",
        });
        // if (this.props.match.path == "/overspeedreport") {
        //     urlpath = "/dashboard/getNightDrivingReport";
        //     var urlpath1 = "/dashboard/sndparkingslist";
        //     var urlpath2 = "/dashboard/snddeptplants";
        //     dpt = "SNDG";
        //     hText = "( Sales and Dispatch )";
        // } else if (this.props.match.path == "/prtparkingreport") {
        //     urlpath = "/dashboard/prtparkingreport";
        //     urlpath1 = "/dashboard/prtparkingslist";
        //     urlpath2 = "/dashboard/prtdeptplants";
        //     dpt = "LOG-PRT";
        //     hText = "( Spare Parts )";
        // } else {
        //     urlpath = "/dashboard/parkingreports";
        //     urlpath1 = "/dashboard/parkingslist";
        //     dpt = "all";
        // }
        // let pageTitle = "OverSpeed Report " + hText + "";
        let pageTitle = "Night Driving Report";
        let dpt = localStorage.getItem("dept_code")
        //console.log(dpt);
        this.setState({
            dept_code: dpt,
            pageTitle: pageTitle,
            startDate: sdate,
            endDate: edate,
        });
        await redirectURL.post("/dashboard/getDistictTrucks").then(response => {
            var truckslist = [{value: "", label: "ALL"}]
            response.data.records.map(e => truckslist.push({label : e , value : e}))

            this.setState({
                truckslist:truckslist
            })
        })
        var reqparams = {
            dept_code: encode(dpt),
            startDate: sdate,
            endDate: edate,
        };
        this.onLoadData(reqparams);
    }

    onLoadData = (reqparams) => {
        let urlpath = "/dashboard/getTicketLogsData";
        reqparams.ticket_category = "night_driving"
        redirectURL
            .post(urlpath, reqparams)
            .then((response) => {
                var records = response.data.records;
                // console.log("rcords ", records);
                // let dropList = response.data.trucksDropList;
                
                var trucks = [];
                var recordsarr = [];
                if (records.length !== 0) {
                    // trucks.push({ value: "", label: "ALL" });
                    // dropList.map(e =>{
                    //     trucks.push({ value: e, label: e });
                    // })
                    records.map((item) => {
                        // if (
                        //     this.state.loginplant != "" &&
                        //     this.state.loginplant != undefined
                        // ) {
                        //     if (item.plant_code == this.state.loginplant) {
                        //         trucks.push({ value: item.truck_no, label: item.truck_no });
                        //     }
                        // } else {
                            // trucks.push({ value: item.truck_no, label: item.truck_no });
                        // }

                        // var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss");
                        // var d2 = moment
                        //     .parseZone(item.truckloc[0].timestamp)
                        //     .format("YYYY-MM-DD HH:mm:ss");
                        // var ms = moment(d1, "YYYY-MM-DD HH:mm:ss").diff(
                        //     moment(d2, "YYYY-MM-DD HH:mm:ss")
                        // );
                        // var d = moment.duration(ms);
                        // var s = Math.floor(d.asHours());
                        // console.log("d1 ", d1)
                        // console.log("d2 ", d2)
                        // console.log("Hrs ", s)
                        // if (
                        //     this.state.loginplant != "" &&
                        //     this.state.loginplant != undefined
                        // ) {
                        //     if (item.plant_code == this.state.loginplant) {
                        //         recordsarr.push(item);
                        //     }
                        // } else {
                            recordsarr.push(item);
                        // }
                    });
                }
                // console.log("recordsarr", recordsarr);
                this.setState({
                    rowData: recordsarr,
                    // truckslist: trucks,
                    loadshow: "show-n",
                    overly: "show-n",
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    onLoadFilterData = (reqparams) => {
        // if (this.props.match.path == "/sndparkingreport") {
        //     var urlpath = "/dashboard/sndparkingreport";
        // } else if (this.props.match.path == "/prtparkingreport") {
        //     urlpath = "/dashboard/prtparkingreport";
        // } else {
        //     urlpath = "/dashboard/parkingreports";
        // }
        let urlpath = "/dashboard/getTicketLogsData"
        reqparams.ticket_category = "night_driving"
        if(this.state.truck_no.value!=""){
            reqparams.truck_no = this.state.truck_no.value
        }
        redirectURL.post(urlpath, reqparams).then((response) => {
                var records = response.data.records;
                // console.log("rcords ", records)
                var trucks = [];
                var recordsarr = [];
                var transporters = [];
                if (records.length > 0) {
                    records.map((item) => {
                        // var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss");
                        // var d2 = moment.parseZone(item.truckloc[0].timestamp).format("YYYY-MM-DD HH:mm:ss");
                        // var ms = moment(d1, "YYYY-MM-DD HH:mm:ss").diff(moment(d2, "YYYY-MM-DD HH:mm:ss"));
                        // var d = moment.duration(ms);
                        // var s = Math.floor(d.asHours());
                        // console.log("d1 ", d1)
                        // console.log("d2 ", d2)
                        // console.log("Hrs ", s)
                        // item.travelstatus = s;
                        // if (s >= 24) {
                        //     item.gps_status = "Inactive";
                        // } else {
                        //     item.gps_status = "";
                        // }
                        // if (
                        //     this.state.loginplant != "" &&
                        //     this.state.loginplant != undefined
                        // ) {
                        //     if (item.plant_code == this.state.loginplant) {
                        //         recordsarr.push(item);
                        //     }
                        // } else {
                            recordsarr.push(item);
                        // }
                    });
                }
                this.setState({
                    rowData: recordsarr,
                    loadshow: "show-n",
                    overly: "show-n",
                    // transporters:transporterlist
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };

    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    };

    onClickHideAll() {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
            sliderRouteTranslate: "",
            tabsliderTranslate: "",
            slideAddManualForm: "",
            slideerParkingOut: "",
        });
    }
    closeAlert = async () => {
        await this.setState({
            show: false,
        });
        //await window.location.reload();
    };

    closeAlert1 = () => {
        this.setState({
            show1: false,
        });
    };
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
		if(this.state.endDate !=undefined && this.state.endDate !="")
		{
			// console.log(startdate,"startdate")
			// console.log(this.state.endDate,"endDate")
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.endDate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if(startDate > endDate)
			{
				this.setState({
					startDate:""
				});
				alert("To Date should be greater than From Date");
			}
			else
			{ 	
				this.setState({
					startDate:startdate
				});
			}
		}
		else
		{
			this.setState({
				startDate:startdate
			});
		}
    };

    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);    	
        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())		
		if(this.state.endDate !=undefined && this.state.endDate !=""){
			// console.log(this.state.startDate,"startdate")
			// console.log(edate,"endDate")
			var startDate = new Date(this.state.startDate);
			var endDate = new Date(edate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if(startDate > endDate){
				this.setState({
					endDate:""
				});
				alert("To Date should be greater than From Date");
			}
			else{ 
				this.setState({
					endDate:edate
				});
			}
		}
		else{
			this.setState({
				endDate:edate
			});
		}
    };
    formHandler = (event) => {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                category: pageTitle,
                action: this.state.eventFormAction,
                label: googleAnalytics.page.action.formSubmittedGetData,
            };
            googleAnalytics.logEvent(eventOptions);
        }
        this.setState({
            loadshow: "show-m",
        });
        //console.log("code ", this.state.dept_code)
        if (this.state.startDate != "" && this.state.endDate != "") {
            // var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
            var sdate = $("#startDate").val();
            var edate = $("#endDate").val();
            let chkStartDate = new Date(sdate)
            let chkEndDate = new Date(edate)
            if(chkStartDate > chkEndDate){
                alert("To Date should be greater than From Date");      
                this.setState({
                    loadshow: "show-n",
                });          
            }else{
                if (this.state.truck_no.value != "") {
                    var truck_no = this.state.truck_no.value;
                } else {
                    truck_no = "";
                }
                // if (this.state.geofence_name.value != "") {
                //     var geofence_name = this.state.geofence_name.value;
                // } else {
                //     geofence_name = "";
                // }
                var parameters = {
                    dept_code: encode(this.state.dept_code),
                    startDate: sdate,
                    endDate: edate,
                    truck_no: truck_no,
                };
                // console.log("Params ", parameters);
                this.onLoadFilterData(parameters);
            }
        } else {
            this.setState({
                show1: true,
                basicTitle1: "Start date and end Date should  not be empty",
                basicType1: "danger",
            });
        }
    };

    handlerForm = (event) => {
        // console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    };
    onCloseUploadDiv = () => {
        this.setState({
            uploadDivWidth: "0%",
            sliderRouteTranslate: "",
            tabsliderTranslate: "",
            slideerParkingOut: "",
            showDiv: "show-n",
            overly: "show-n",
        });
    };
    onCloseRouteDiv = () => {
        this.setState({
            uploadDivWidth: "0%",
            sliderRouteTranslate: "",
            slideAddManualForm: "",
            slideerParkingOut: "",
            showDiv: "show-n",
            overly: "show-n",
        });
    };

    onShowRouteDiv = (params) => {
        // console.log("Params ", params);
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                category: pageTitle,
                action: this.state.eventGridAction,
                label: googleAnalytics.page.action.viewRoute,
            };
            googleAnalytics.logEvent(eventOptions);
        }
        this.setState({
            loadshow: "show-m",
        });
        var fdate = moment
            .parseZone(params.exception_start_time)
            .format("YYYY-MM-DD HH:mm:ss");
        // if (
        //     params.reporting_out_date == undefined ||
        //     params.reporting_out_date == ""
        // ) {
            var edate = moment.parseZone(params.exception_end_time).format("YYYY-MM-DD HH:mm:ss");
        // } else {
        //     edate = moment
        //         .parseZone(params.reporting_out_date)
        //         .format("YYYY-MM-DD HH:mm:ss");
        // }
        var reqData = {
            truck_no: params.truck_no,
            startDate: fdate,
            endDate: edate,
            screen: "nightdrivingreport",
        };
        //console.log("reqData: ", reqData);
        redirectURL
            .post("/gmap", reqData, {
                headers: {
                    "content-type": "application/json",
                },
            })
            .then((response) => {
                var records = response.data;
                let responseData = JSON.parse(records.data)
                console.log("responseData",responseData)
                if(response.data.status == 'failure'){
                    this.setState({
                        show : true,
                        basicType : "warning",
                        basicTitle : response.data.message,
                        loadshow: "show-n"
                    })
                }else{
                    this.setState({
                        sliderRouteTranslate: "slider-translate-65p",
                        overly: "show-m",
                        loadshow: "show-n",
                        maptruckno: params.truck_no,
                        mapinfo: responseData,
                        sidebarSubHeader: "Truck Information",
                        routeTruck: {
                            truck_no: params.truck_no,
                            startTime: responseData.route_details.start_time,
                            endTime: responseData.route_details.end_time,
                        },
                    });
                }
                // console.log("end Time", typeof(response.data.route_details.end_time), response.data.route_details.end_time)
            })
            .catch(function (e) {
                console.log("Error ", e);
            });
    };
    onCloseModal = () => {
        this.setState({ withdrawmodal: false });
    };

    onChangeTruckItem = (truck_no) => {
        this.setState({ truck_no }, () =>
            console.log(`Name  Option selected:`, this.state.truck_no)
        );

        this.setState({
            errorMessage: "",
            successMessage: "",
        });
    }
    

    onChangeSearchTruckItem(struck_no) {
        this.setState({ struck_no }, () =>
            console.log(`Name  Option selected:`, this.state.struck_no)
        );

        this.setState({
            errorMessage: "",
            successMessage: "",
        });
    }
    

    onClickTab(params) {
        if (params == "parking") {
            this.setState({
                pact: "btn-warning",
                tact: "btn-defaultc",
                parkingyard: "show-m",
                truckinfo: "show-n",
            });
        }
        if (params == "truck") {
            this.setState({
                pact: "btn-defaultc",
                tact: "btn-warning",
                parkingyard: "show-n",
                truckinfo: "show-m",
            });
        }
    }

    renderMap = () => {
        loadScript(
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyBI9tCwOL8bO4iIFRCWucbzeWfJOEX-y34&libraries=places,drawing&callback=initMap"
        );
        window.initMap = this.initMap;
    };

    initMap = () => {
        allcords = [];
        this.state.coordinates.map((marker) => {
            allcords.push(marker);
        });
        //	console.log("allcords ", this.state.coordinates)
        var contentdata = this.state.contentString;

        var lt = 21.144644112601775;
        var ln = 79.08860126768066;

        try {
            map = new window.google.maps.Map(document.getElementById("search_map"), {
                zoom: 11,
                center: new window.google.maps.LatLng(lt, ln),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                /*mapTypeControl: true,
                                gestureHandling: 'greedy',
                                mapTypeControlOptions: {
                                    style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                                    position: window.google.maps.ControlPosition.TOP_RIGHT
                                  },
                                disableDefaultUI: true,
                                zoomControl: true*/
            });
        } catch (e) {
            // map = new window.google.maps.Map(document.getElementById('search_map'), {
        }

        circle = new window.google.maps.Circle({
            map: map,
        });

        marker = new window.google.maps.Marker({
            map: map,
            draggable: true,
            animation: window.google.maps.Animation.DROP,
            //position: {lat: 28.48, lng: 77.06}
        });

        marker.addListener("click", function () {
            marker.setAnimation(window.google.maps.Animation.BOUNCE);
        });

        window.google.maps.event.addListener(map, "click", function (event) {
            //document.getElementById("latitude").value = event.latLng.lat();
            //document.getElementById("longitude").value = event.latLng.lng();
            marker.setPosition(event.latLng);
        });
        circle.bindTo("center", marker, "position");
        var markerLatlng1;
        //console.log("Radius ", this.state.radius)
        // console.log(allcords);
        var bounds = new window.google.maps.LatLngBounds();
        var markers = allcords.map((marker) => {
            var image = require("../../assets/icons/truck_na_24.png");
            var contentarr = [];
            var headerTitle = marker.truck_no;
            contentarr.push({ key: "Truck No", value: marker.truck_no });
            contentarr.push({
                key: "Transporter Name",
                value: marker.transporter_name,
            });
            //contentarr.push({"key":"Distance from geofence", "value":marker.distance});
            //contentarr.push({"key":"GPS Provider", "value":marker.actual_lspuser});
            contentarr.push({ key: "GPS Provider", value: marker.gps_provider });
            contentarr.push({ key: "Speed (km/h)", value: Math.ceil(marker.speed) });
            contentarr.push({
                key: "Last Packet Time",
                value: getHyphenDDMMMYYYYHHMM(marker.timestamp),
            });
            // truck_travelling_status
            if (marker.truck_travelling_status == 0) {
                var travelstat = "Active";
            } else {
                travelstat = "Active";
            }
            contentarr.push({ key: "GPS Status", value: travelstat });
            contentarr.push({ key: "Address", value: marker.device_address });
            contentarr.push({ key: "City", value: marker.device_city });
            contentarr.push({ key: "State", value: marker.device_state });
            var contentString = infoBox(image, headerTitle, contentarr, "");

            var infowindow = new window.google.maps.InfoWindow({
                content: contentString,
            });
            //console.log("parseFloat(marker.latitude) ", parseFloat(marker.latitude))
            var markerLatlng = new window.google.maps.LatLng(
                parseFloat(marker.latitude),
                parseFloat(marker.longitude)
            );

            image = require("../../assets/icons/truck_na_24.png");

            var mark = new window.google.maps.Marker({
                position: markerLatlng,
                map: map,
                title: "Truck No: " + marker.truck_no,
                icon: image,
            });
            mark.addListener("click", function () {
                infowindow.open(map, mark);
            });
            if (this.state.rowData1.length == 0) {
                infowindow.open(map, mark);
            }
            //
            //return mark
            // mark.setMap(map);

            bounds.extend(
                new window.google.maps.LatLng(
                    parseFloat(marker.latitude),
                    parseFloat(marker.longitude)
                )
            );
        });

        if (this.state.selectedTabLat != "" && this.state.selectedTabLng != "") {
            //console.log(" this.state.selectedTabLat ",parseFloat(this.state.selectedTabLat.toFixed(6)))
            //console.log(" this.state.selectedTabLng ",parseFloat(this.state.selectedTabLng.toFixed(6)))
            markerLatlng1 = new window.google.maps.LatLng(
                this.state.selectedTabLat,
                this.state.selectedTabLng
            );
            //console.log("markerLatlng", markerLatlng1)
            circle = new window.google.maps.Circle({
                strokeColor: "#71caab",
                strokeOpacity: 0.7,
                strokeWeight: 1,
                fillColor: "#71caab",
                fillOpacity: 0.25,
                map: map,
                center: markerLatlng1,
                radius: Math.sqrt(this.state.radius) * 8000,
            });
            bounds.extend(
                new window.google.maps.LatLng(
                    parseFloat(this.state.selectedTabLat),
                    parseFloat(this.state.selectedTabLng)
                )
            );
            var mainmark = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(
                    parseFloat(this.state.selectedTabLat),
                    parseFloat(this.state.selectedTabLng)
                ),
                map: map,
                //title:item.plant_code
            });

            mainmark.setMap(map);
            map.setZoom(8);
            console.log("mainmark.getPosition() ", mainmark.getPosition());
            //	map.setCenter(mainmark.getPosition());
            //map.setZoom(10)
            //map.fitBounds(bounds)
        } else {
            // console.log("Here")
            if (this.state.tabplants.length > 0) {
                this.state.tabplants.forEach((item) => {
                    // console.log("Item Marker ", item)
                    markerLatlng1 = new window.google.maps.LatLng(item.lat, item.lng);
                    //console.log("markerLatlng", markerLatlng1)
                    circle = new window.google.maps.Circle({
                        strokeColor: "#71caab",
                        strokeOpacity: 0.7,
                        strokeWeight: 1,
                        fillColor: "#71caab",
                        fillOpacity: 0.25,
                        map: map,
                        center: markerLatlng1,
                        radius: Math.sqrt(this.state.gpsradius) * 8000,
                    });
                    bounds.extend(
                        new window.google.maps.LatLng(
                            parseFloat(item.lat),
                            parseFloat(item.lng)
                        )
                    );
                    var mainmark = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(
                            parseFloat(item.lat),
                            parseFloat(item.lng)
                        ),
                        map: map,
                        title: item.plant_code,
                    });
                    mainmark.addListener(
                        "click",
                        (function () {
                            var contentarr = [];
                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                            // var header = "Plant"
                            // contentarr.push({"key":"Code", "value":item.plant_code})
                            //
                            // var contentString = infoBox("", header, contentarr,'')
                            //
                            // infowindow.setContent(contentString);
                            // currentwindow = infowindow;
                            // infowindow.open(map, marker);
                        })(marker)
                    );
                    mainmark.setMap(map);
                });
            }
        }

        map.fitBounds(bounds);
        var eventtrigger = document.getElementsByClassName("truck");
        var markerCluster = new MarkerClusterer(map, markers, {
            imagePath:
                "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        });
    };

    toggleBounce() {
        if (marker.getAnimation() !== null) {
            marker.setAnimation(null);
        } else {
            marker.setAnimation(window.google.maps.Animation.BOUNCE);
        }
    }

    getDistance = (p1, p2) => {
        //console.log("p1",p1);
        //console.log("p2",p2);
        var R = 6378137; // Earth’s mean radius in meter
        var dLat = rad(p2.lat - p1.lat);
        var dLong = rad(p2.lng - p1.lng);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(rad(p1.lat)) *
            Math.cos(rad(p2.lat)) *
            Math.sin(dLong / 2) *
            Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d / 1000; // returns the distance in meter
    };

    onShowInfo = (e) => {
        //	console.log(JSON.parse(e.target.id));
        this.setState({
            contentString: "Here Success",
        });
        var ourMarker = e.target.id;
        window.google.maps.event.trigger(ourMarker, "click");
        var infowindow = new window.google.maps.InfoWindow({
            content: "Here Success",
        });
        //	infowindow.open(map, marker);
    };
    popmarker = (e) => {
        var truckNo = e.data.truck_no;
        var truck = [];
        var trucks = this.state.alltrucks;
        console.log(trucks, "input truck");
        var filteredValue = trucks.filter(function (e) {
            return e.truck_no == truckNo;
        });
        if (filteredValue.length > 0) {
            // console.log("Matched")
            var lat = parseFloat(filteredValue[0].latitude);
            var lng = parseFloat(filteredValue[0].longitude);
            var data = filteredValue[0];
            map.setCenter(new window.google.maps.LatLng(lat, lng));
            map.setZoom(22);
            var markerLatlng = new window.google.maps.LatLng(lat, lng);

            if (filteredValue[0].status == 1) {
                var truckText = "Inside Plant";
                var color = "#333333";
            } else if (filteredValue[0].status == 2) {
                truckText = "Going to Dealer";
                color = "#333333";
            } else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
                truckText = "At Dealer Location";
                color = "#333333";
            } else if (filteredValue[0].status == 5) {
                truckText = "Left Destination Location";
                color = "#333333";
            } else if (filteredValue[0].status == 6) {
                truckText = "Return to Plant";
                color = "#333333";
            } else if (filteredValue[0].status == 7) {
                truckText = "Empty Truck Outside Plant";
                color = "#333333";
            } else {
                truckText = "N/A";
                color = "#333333";
            }

            if (filteredValue[0].status == 1) {
                var image = require("../../assets/icons/truck_inside_24.png");
            } else if (filteredValue[0].status == 2) {
                image = require("../../assets/icons/truck_going_24.png");
            } else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
                image = require("../../assets/icons/truck_atdealer_24.png");
            } else if (filteredValue[0].status == 5) {
                image = require("../../assets/icons/truck_leftdealer_24.png");
            } else if (filteredValue[0].status == 6) {
                image = require("../../assets/icons/truck_return_24.png");
            } else if (filteredValue[0].status == 7) {
                image = require("../../assets/icons/truck_empty_24.png");
            } else {
                image = require("../../assets/icons/truck_na_24.png");
            }
            image = require("../../assets/icons/truck_na_24.png");
            var marker = new window.google.maps.Marker({
                position: markerLatlng,
                map: map,
                title: data.truck_no,
                icon: image,
            });

            var contentarr = [];
            var headerTitle = filteredValue[0].truck_no;
            contentarr.push({ key: "Truck No", value: filteredValue[0].truck_no });
            contentarr.push({
                key: "Transporter Name",
                value: filteredValue[0].transporter_name,
            });
            //contentarr.push({"key":"Distance from geofence", "value":trucks[i].distance});
            //contentarr.push({"key":"GPS Provider", "value":filteredValue[0].actual_lspuser});
            contentarr.push({
                key: "GPS Provider",
                value: filteredValue[0].gps_provider,
            });
            contentarr.push({ key: "Speed (KMPH)", value: filteredValue[0].speed });
            contentarr.push({
                key: "Last Packet Time",
                value: getHyphenDDMMMYYYYHHMM(filteredValue[0].timestamp),
            });
            // contentarr.push({"key":"Address", "value":filteredValue[0].area+", "+filteredValue[0].state});
            if (filteredValue[0].truck_travelling_status == 1) {
                var travelstat = "Active";
            } else {
                travelstat = "Active";
            }
            contentarr.push({ key: "GPS Status", value: travelstat });
            contentarr.push({
                key: "Address",
                value: filteredValue[0].device_address,
            });
            contentarr.push({ key: "City", value: filteredValue[0].device_city });
            contentarr.push({ key: "State", value: filteredValue[0].device_state });

            var contentString = infoBox(image, headerTitle, contentarr, "");
            //console.log(contentarr,"contentarr")
            var infowindow = new window.google.maps.InfoWindow({
                content: contentString,
            });

            marker.setAnimation(window.google.maps.Animation.DROP);
            marker.addListener("click", function () {
                infowindow.open(map, marker);
            });

            marker.setMap(map);
        } else {
            this.setState({
                show: true,
                basicType: "warning",
                basicTitle: "No Data found.",
                loadshow: "show-n",
                showform: "show-m",
            });
        }
        console.log(filteredValue);
    };

    truckFormHandler =() => {
        //e.preventDefault();

        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                category: pageTitle,
                action: this.state.eventFormAction,
                label: googleAnalytics.page.action.formSubmittedSearchByTruckNo,
            };
            googleAnalytics.logEvent(eventOptions);
        }
        var truck = [];
        var trucks = this.state.alltrucks;
        var struck = this.state.truckno;
        var filteredValue = trucks.filter(function (e) {
            return e.truck_no == struck;
        });
        // console.log(filteredValue,"filteredValue")
        if (filteredValue.length > 0) {
            // console.log("Matched")
            var lat = parseFloat(filteredValue[0].latitude);
            var lng = parseFloat(filteredValue[0].longitude);
            var data = filteredValue[0];
            // map.setCenter(new window.google.maps.LatLng(lat,lng));
            // map.setZoom(22);
            var markerLatlng = new window.google.maps.LatLng(lat, lng);

            var image = require("../../assets/icons/truck_na_24.png");
            var marker = new window.google.maps.Marker({
                position: markerLatlng,
                map: map,
                title: data.truck_no,
                icon: image,
            });

            var contentarr = [];
            var headerTitle = filteredValue[0].truck_no;
            // contentarr.push({"key":"Truck No", "value":filteredValue[0].truck_no});
            contentarr.push({
                key: "Transporter Name",
                value: filteredValue[0].transporter_name,
            });
            //contentarr.push({"key":"Distance from geofence", "value":trucks[i].distance});
            //contentarr.push({"key":"GPS Provider", "value":filteredValue[0].actual_lspuser});
            contentarr.push({ key: "GPS Provider", value: "Enmovil" });
            contentarr.push({ key: "Speed (KMPH)", value: filteredValue[0].speed });
            contentarr.push({
                key: "Last Packet Time",
                value: getHyphenDDMMMYYYYHHMM(filteredValue[0].timestamp),
            });
            // contentarr.push({"key":"Address", "value":filteredValue[0].area+", "+filteredValue[0].state});
            contentarr.push({
                key: "Address",
                value: filteredValue[0].device_address,
            });
            contentarr.push({ key: "City", value: filteredValue[0].device_city });
            contentarr.push({ key: "State", value: filteredValue[0].device_state });

            var contentString = infoBox(image, headerTitle, contentarr, "");

            var infowindow = new window.google.maps.InfoWindow({
                content: contentString,
            });

            marker.setAnimation(window.google.maps.Animation.DROP);
            marker.addListener("click", function () {
                infowindow.open(map, marker);
            });

            marker.setMap(map);
        } else {
            this.setState({
                show: true,
                basicType: "warning",
                basicTitle: "No Data found.",
                loadshow: "show-n",
                showform: "show-m",
            });
        }
    }
    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    };
    onChangePlantCode = async (gpsplant) => {
        //console.log("Select", event.target.value);
        await this.setState({ gpsplant }, () =>
            console.log(`Option selected:`, this.state.gpsplant)
        );
    }; 
    
    onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();

		let screenpage = '';

		if(this.props.match.path == "/overspeedreport")
		{
			screenpage='Saftey Reports - OverSpeed Report';
		}
		else if(this.props.match.path == "/nightdrivingreport")
		{
			screenpage='Night Driving Report';
		}
		else if(this.props.match.path == "/idlingreport")
		{
			screenpage='Idling Report';
		}


		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		// console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

    resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};

    onClickExportBtn(){
		this.gridApi.exportDataAsExcel();
	}

    render() {
        var listData = [];
        try {
            this.state.filteredData.map(function (e) {
                listData.push(<li className="dropdown-truck-no">{e}</li>);
            });
        } catch (e) {
            console.log(e);
        }

        const modalStyles = {
            width: "600px !important",
        };

        const { show,basicType, basicTitle,alert, pageTitle,parkingyard,truck_no,truckslist,startDate,endDate,modules,defaultColDef,rowData,autoGroupColumnDef,statusBar,sideBar,paginationPageSize,frameworkComponents,detailCellRendererParams,rowClassRules,overlayNoRowsTemplate,sliderRouteTranslate,editType} = this.state;
        const columnwithDefs = [          
            {
                headerName: "",
                field: "",
                width: 50,
                cellRenderer: "consignmentActions",
            },
            {
                headerName: "Truck no",
                field: "truck_no",
                width: 100,
                filter: true,
                resizable: true,
            },
            // {
            //     headerName: "Consignment Codes",
            //     field: "consignment_codes",
            //     width: 140,
            //     filter: true,
            //     Routeresizable: true,
            // },
            {
                headerName: "Start Time",
                field: "exception_start_time",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.exception_start_time);
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
            },            
            {
                headerName: "End Time",
                field: "exception_end_time",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.exception_end_time);
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
            },
            {
                headerName: "Distance (KM)",
                field: "distance_travelled",
                width: 120,
                filter: true,
                Routeresizable: true,
            },    
            {
                headerName: "Duration (HH:MM)",
                field: "total_exception_duration",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    let firstInstance = (params.data.exception_start_time)
                    let recentInstance = (params.data.exception_end_time)
                    var curentdate = moment.parseZone(recentInstance).format("YYYY-MM-DD HH:mm:ss");
                    var inv = moment.parseZone(firstInstance).format("YYYY-MM-DD HH:mm:ss");
                    var diffreetime = moment.duration(moment(curentdate).diff(inv));
                    var delaydays =Math.round(diffreetime.asSeconds());
                    return secondsToHM(delaydays);
                },
                //filter: "agDateColumnFilter",
                // comparator: dateComparator,
            },
            {
                headerName: "Start Address",
                field: "exception_start_time_address",
                width: 200,
                filter: true,
                resizable: true,
            },
            {
                headerName: "End Address",
                field: "exception_end_time_address",
                width: 200,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Department Code",
                field: "dept_code",
                width: 140,
                filter: true,
                Routeresizable: true,
            },
            {
                headerName: "Vehicle Type",
                field: "vehicle_type",
                width: 140,
                filter: true,
                Routeresizable: true,
            },
            {
                headerName: "Vehicle Tracking Type",
                field: "vehicle_tracking_type",
                width: 140,
                filter: true,
                Routeresizable: true,
            },
                    
            // {
            //     headerName: "Distance From End Time (KM)",
            //     field: "distance_from_recent_instance",
            //     width: 120,
            //     filter: true,
            //     Routeresizable: true,
            // },
            // {
            //     headerName: "Ready To Notify",
            //     field: "ready_to_notify",
            //     width: 140,
            //     filter: true,
            //     Routeresizable: true,
            // },
            
        ];
        const dStyles = {
            width: this.props.width,
            height: "500px",
            marginLeft: this.props.marginLeft,
        };
        var showonlysnd = true;
        var showonlyprt = true;
        var hidecol = true;
      

        return (
            <div className="container-fluid">
                <SweetAlert
                    show={show}
                    type={basicType}
                    title={basicTitle}
                    onConfirm={this.closeAlert}
                ></SweetAlert>               
                {alert}
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i>{" "}
                                    <span>{pageTitle} </span>
                                </h5>
                            </div>
                            <div className="row mr-3">
                                <span className="col-xl-12 col-lg-12">
                                    {/* <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
                                        <i className="icofont icofont-save"></i> Save Grid Layout
                                    </button>
                                    <button className="float-right custom-btn white btn-info" style={{ marginRight: "10px" }} onClick={this.resetState.bind(this)}>
                                        <i className="icofont icofont-refresh"></i> Reset Default Layout
                                    </button> */}
                                    <button onClick={this.onClickExportBtn.bind(this)} className="float-right custom-btn white btn-success" style={{marginRight: "10px" , float: "right" }}>Export To PDF</button>
                                </span>
                            </div>
                            <div className="card-body">
                                <div
                                    className={
                                        "row col-xl-12 col-lg-12 " + parkingyard
                                    }
                                >
                                    <form
                                        className="row theme-form col-xl-10 col-lg-10"
                                        onSubmit={this.formHandler}
                                    >
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <label for="" className="ml-3">Trucks</label>
                                            <Select
                                                placeholder={"Select Truck"}
                                                closeMenuOnSelect={true}
                                                onChange={this.onChangeTruckItem}
                                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                                style={{ borderRadius: "0px" }}
                                                name="truck_no"
                                                value={truck_no}
                                                options={truckslist}
                                            />
                                        </div>
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <label for="startDate">From Date</label>
                                            <Datetime value={this.state.startDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'Start Date', name: 'startDate', id:"startDate", autoComplete:'off', required:true ,readOnly:'true'}} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"  name="startDate" onChange={this.handlerStartDateTime.bind(this)} />
                                            {/* <input
                                                type="text"
                                                name="startDate"
                                                autoComplete="Off"
                                                id="startDate"
                                                placeholder="Start Date"
                                                className="datepicker form-control"
                                                onChange={this.handlerStartDateTime}
                                                value={startDate}
                                            /> */}
                                        </div>

                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <label for="endDate">To Date</label>
                                            <Datetime value={endDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'End Date', name: 'endDate', id:"endDate", autoComplete:'off', required:true ,readOnly:'true'}} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" onChange={this.handlerEndDateTime.bind(this)} />
                                            {/* <input
                                                type="text"
                                                name="endDate"
                                                autoComplete="Off"
                                                id="endDate"
                                                placeholder="End Date"
                                                className="datepicker form-control"
                                                onChange={this.handlerEndDateTime}
                                                value={endDate}
                                            /> */}
                                        </div>

                                        <div className="col-xl-2 col-lg-2 form-group">
                                            <button type="submit" className="btn btn-success mt-4">
                                                Get Data
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div
                                    id="myGrid"
                                    style={{ height: "580px", width: "100%" }}
                                    className={"ag-theme-balham " + parkingyard}
                                >
                                    <AgGridReact
                                        modules={modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={defaultColDef}
                                        rowData={rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={statusBar}
                                        sideBar={sideBar}
                                        paginationPageSize={paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={frameworkComponents}
                                        detailCellRendererParams={
                                            detailCellRendererParams
                                        }
                                        //editType={editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        //floatingFilter={true}
                                        //enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        rowClassRules={rowClassRules}
                                        //onCellClicked={this.onRowClicked.bind(this)}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                        masterDetail={true}
                                        overlayNoRowsTemplate={overlayNoRowsTemplate}
                                    />
                                </div>
                                {/* <div className="col-xl-12 col-lg-12" style={{color:"#FF0000", fontSize:"12px"}}>
                    	Note: Night driving alert is raised if truck has continuously travelled for more than 10 kms during 00:00 hrs to 05:00 hrs
					</div> */}
                            </div>
                        </div>
                    </div>
                </div>

                {sliderRouteTranslate != "" ? (
                    <div
                        className={"slide-r " + sliderRouteTranslate}
                        style={{ overflow: "auto" }}
                    >
                        <div className="slide-r-title"  style={{overflow:"auto",marginTop: "48px"}}>
                            <h4>
                                View Route
                                <span
                                    className="float-right closebtn"
                                    style={{ marginRight: "25px" }}
                                    onClick={this.onCloseRouteDiv.bind(this)}
                                >
                                    X
                                </span>
                            </h4>
                        </div>
                        <div className="slide-r-body" style={{ position: "relative" }}>
                            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                <DrawMap
                                    mapFor={"geofence"}
                                    truckno={this.state.maptruckno}
                                    mapinfo={this.state.mapinfo}
                                    defTransitCoords={this.state.defTransitCoords}
                                />
                                <div className="col-xl-12 col-lg-12 n-p-0">
                                    <div className="crm-numbers pb-0">
                                        <h3 className="subH">{this.state.sidebarSubHeader}</h3>
                                        <div className="col-xl-12 col-lg-12 row">
                                            <div className="col route-block">
                                                <label className="sidebar-label">Truck No</label>
                                                <div>{this.state.routeTruck.truck_no}</div>
                                            </div>
                                            {/* <div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div> */}
                                            <div className="col route-block">
                                                <label className="sidebar-label">Start Time</label>
                                                <div>
                                                    {this.state.routeTruck.startTime != ""
                                                        ? getDDMMYYYYHHMMSS(this.state.routeTruck.startTime)
                                                        : "NA"}
                                                </div>
                                            </div>
                                            <div className="col route-block">
                                                <label className="sidebar-label">End Time</label>
                                                <div>
                                                    {this.state.routeTruck.endTime != ""
                                                        ? getDDMMYYYYHHMMSS(this.state.routeTruck.endTime)
                                                        : "NA"}
                                                </div>
                                            </div>

                                            <div className="col route-block">
                                                <label className="sidebar-label">Distance</label>
                                                <div>
                                                    {!isEmpty(this.state.mapinfo.route_details)
                                                        ? Math.round(
                                                            this.state.mapinfo.route_details.distance / 1000
                                                        )
                                                        : "0"}{" "}
                                                    Kms
                                                </div>
                                            </div>
                                            {/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}

                <div
                    className={"overlay-part " + this.state.overly}
                    onClick={this.onClickHideAll.bind(this)}
                ></div>
                <div className={"dataLoadpage " + this.state.loadshow}></div>
                <div className={"dataLoadpageimg " + this.state.loadshow}>
                    <div class="loader-box">
                        <div class="loader-box">
                            <div class="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0];
    var script = window.document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
}

function getPolygonCoords() {
    var len = geoFencingArea.getPath().getLength();
    console.log(len);
    var htmlStr = "";
    var coordinates = [];
    for (var i = 0; i < len; i++) {
        // var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
        // coordinates.push()
        var strlatlng = geoFencingArea
            .getPath()
            .getAt(i)
            .toUrlValue(5);
        var spliting = strlatlng.split(",");
        var latlngd = {
            lat: parseFloat(spliting[0]),
            lng: parseFloat(spliting[1]),
        };
        coordinates.push(latlngd);
        // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
        //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
        //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
    }
    // console.log("htmlStr", coordinates);
}
function ConvertSeconds(n) {
    var days = Math.round(parseInt(n / (24 * 3600)));

    n = n % (24 * 3600);
    var hours = Math.round(parseInt(n / 3600));

    n %= 3600;
    var minutes = Math.round(n / 60);

    n %= 60;
    var seconds = Math.round(n);

    return (
        days + " Day(s) " + hours + " Hrs " + minutes + " mins " + seconds + " secs"
    );
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}
function timeConvert(n) {
    var num = n;
    var hours = num / 3600;
    var rhours = parseInt(hours);
    var minutes = (num - rhours * 3600) / 60;
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
function loadDatepicker() {
    $(".datepicker").datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
    });
}

window.onload = function () {
    var today = new Date();
    var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    $("#manual_reported_on").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });
    $("#manual_parking_out").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });

    $(".styles_modal__gNwvD").css({ width: "450px" });
};
document.addEventListener("turbolinks:before-render", () => {
    loadDateTimeScript();
});

function loadDateTimeScript() {
    var today = new Date();
    var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    $("#manual_reported_on").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });
    $("#manual_parking_out").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });

    var index = window.document.getElementsByTagName("script")[1];
    var script = window.document.createElement("script");
    script.src =
        "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js";
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

$(document).ready(function () {
    $(document).on("click", ".dropdown-truck-no", function () {
        var vals = $(this).text();
        $("#manual_truck").val(vals);
        $(".trucks-dropdown").removeClass("show-m");
        $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#manual_truck");
        if (!container.is(e.target)) {
            try {
                if (
                    $(".trucks-dropdown")
                        .attr("class")
                        .includes("show-m") ||
                    $(".trucks-dropdown").attr("class") == "trucks-dropdown"
                ) {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            } catch (e) { }
        }
    });
});

function secondsToHM(seconds) {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600*24));
    // var h = Math.floor(seconds % (3600*24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);
    
    // var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    // var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    // var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return dDisplay + hDisplay + mDisplay;
    
    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh*3600));
    var mm = Math.floor(hrr/60)
    if(mm < 10)
    {
        var mins = "0"+mm;
    }
    else
    {
        mins = mm;
    }

    if(hh < 10)
    {
        var hrs = "0"+hh;
    }
    else
    {
        hrs = hh;
    }
    return hrs+":"+mins;
}