/* eslint-disable no-redeclare */
/* eslint-disable no-loop-func */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import CountUp from "react-countup";
import './inPlantAnalysisDashboard.css';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { WidthProvider, Responsive } from "react-grid-layout";
import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Autosuggest from 'react-autosuggest';
// import MarkerClusterer from "@google/markerclusterer";
import Breadcrumb from '../common/breadcrumb.component';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import Counters from '../layouts/consignmentkpicountersComponent';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import $ from "jquery";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import DrawMap from '../common/drawmap';
import Trucklocaction from './trucklocaction';
var infoBox = require('../common/google-infowindow');
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`)
const ResponsiveReactGridLayout = WidthProvider(Responsive);
var map;
var viewMarkersArr = [];
var showmarkers = 0;
var rlatitude;
var rlongitude;
var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];

const decipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBI9tCwOL8bO4iIFRCWucbzeWfJOEX-y34&libraries=places,drawing&callback=initMap");
export default class TrucksGPSDataComponent extends Component {

    constructor(props) {
        super(props);

        this.state =
        {
            pageTitle: "Truck GPS Data",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            routemap: '',
            allmarkers: '',
            trucks: [],
            truck_no: '',
            inPlantTruckList: [],
            selected_truck_no: "",
            startDate: '',
            endDate: '',
            activeTab:"gps",
            mapinfo: '',
            usergridstate: [],
            violationInPlantData: [],
            mapinfoclick: '',
            modules: AllModules,
            defTransitCoords: '',
            activeMarker: {},
            selectedPlace: {},
            dealerCode: '',
            mwidth:"col-xl-12 col-lg-12",
			isCounterDisplay: 1,
            timelinesmarkers: [],
            viewtimelinemarkers: true,
            viewgoogleroutes: true,
            googleroutepath: [],
            loadshow: 'show-n',
            open: false,
            alert: null,
            show: false,
            basicTitle: '',
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true

            },
            gridshow: 'show-m',
            gpsCardShow: '',
            filteredData: [],
            mapshow: 'show-m',
            actgridbtn: "btn-success",
            actmapbtn: "btn-default",
            rowData: null,
            showTrucksList: "show-n",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            overlayNoRowsTemplate: "",
            frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer,
                trucklocaction: Trucklocaction
            },

            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,

            childconfs: [],
            childrow: [],
            rowModelType: "serverSide",
            paginationPageSize: 50,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            truckNoList: [],
            overly: "show-n",
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            detailCellRendererParams: {},
            rowClassRules: {
                "highlitRow": "data.transit_delay == 1",
                "green": "data.status >= 3"

            },
            tolls: [],
            frequency: "",
            showmarkers: 0,
            loginplant: "",
            maptruck: "",
            tottalDistance: [],
            totaldistanceval: 0,
            overspeedCount: 0,
            nightdrivingCount: 0,
            idlingCount: 0,
            plantLocations:[],
            coordinates:[],
            topCardShow: "",
            totalVoilationsCount :0,
            toatalOverSpeedingCount : 0,
            totalNightDrivingCount :0,
            totalIdlingCount : 0,
            voilation_records:[],
            current_fitler_truck:'',
            hideforgps:"d-show",

        };
        this.setTruckno = this.setTruckno.bind(this);
        this.onClickShowTruckLocation = this.onClickShowTruckLocation.bind(this);
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    async componentDidMount() {
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        //console.log("Box ",localStorage.getItem('token'));
        var loguser = decode(localStorage.getItem("m"));
        // console.log("LOGUSER ", JSON.parse(loguser));
        var loginplant = "";
        try {
            var luser = JSON.parse(loguser);
            loginplant = luser.is_plant_code;
            // console.log("logplant", loginplant)
            this.setState({
                loginplant: loginplant,
                // consigner:[{"value":luser.is_plant_code,"label":luser.is_plant_name}]

            })
        }
        catch (e) {

        }
        let dpt = JSON.parse(localStorage.getItem("dept_code"))[0]
        // console.log("dpt",dpt)
        this.logPageView(); // Log page view to GA
        await redirectURL.post("consignments/getAllTrucksData").then((response) => {
            if (response.data.length > 0) {
                var trucks = []
                response.data.map(  (e) => {
                    if (e.truck_no != "" && e.dept_code === dpt) {
                        trucks.push(e.truck_no) 
                    }
                })
                // console.log("trucks",trucks)
                this.setState({
                    truckNoList: trucks
                })
            }

        })
		await redirectURL.post("consignments/getAllTrucksDataInPlant").then((response) => {
            if (response.data.length > 0) {
                var inPlantTrucks = []
                response.data.map(  (e) => {
                    if (e.truck_no != "") {
                        inPlantTrucks.push(e.truck_no) 
                    }
                })
                // console.log("trucks",trucks)
                this.setState({
                    inPlantTruckList: inPlantTrucks
                }, () => {
                    console.log("inPlantTruckList", this.state.inPlantTruckList)
                })
            }

        })
        this.setState({
            gridDataTitle: "Overspeeding"
        })
        // this.renderinitmap(markers)

        var formdata = {
            radius: 3000,
            latitude: "20.73568784876339",
            longitude: "79.38999444931353",
            dept_code: "IBL",
            transporter_code: this.state.transportervalue,
            status: this.state.truckstatus,
            trip_type: 1
        }
        rlatitude = "20.73568784876339";
        rlongitude = "79.38999444931353";
        // http://autometrics.in:5000/getNearByTrucks
        await redirectURL.post('/vicinity/getPlantdashboard', formdata, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
                'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

            }
        })
            .then(
                (response) => {
                    console.log("stringified",response.data.body.length)
                    console.log("dataaa",JSON.parse(response.data.body))
                    // console.log("response", JSON.parse(response.data.body));
                    // console.log(response.data.body)
                    var markers = JSON.parse(JSON.parse(response.data.body).report_data);
                    // These trucks have been filter on 16-02-2024 suggested by Rahul (["GJ05SP0066", "GJ16BS1053", "GJ16CH5326"])
                    markers = markers.filter(e => !["GJ05SP0066", "GJ16BS1053", "GJ16CH5326"].includes(e.truck_no))
                    console.log("markers 282", markers)
                    //	console.log(markers);
                    // if(markers.length > 0)
                    // {
                    // 	markers.map((item) => {
                    // 		// console.log("Plant ", item);
                    // 	})
                    // }
                    // console.log(JSON.parse(response.data.apiData.body).gps_trucks);
                    if (markers.length > 0) {
                        this.setState({
                            // weatherData: response.data.weatherData,
                            // defaultradius:formdata.radius,
                            coordinates: markers,
                            totalTrucksCount: markers.length,
                            mwidth: "col-xl-12 col-lg-12",
                            // rowData: markers,
                            radius: 5000,
                            loadshow: 'show-n',
                            showform: 'show-n',
                            showrefresh: 'show-m',
                            rlatitude: "21.144644112601775",
                            rlongitude: "79.08860126768066",
                            // sortable: false,
                            // filter: false,
                            // resizable: true
            
                            // alltrucks: markers,
                            // onTripCounter : JSON.parse(response.body).gps_trucks,
                            // 'gridData': markers
                        },()=>{
                            console.log(this.state.coordinates,"rowData 327")
                        });
                    }
                    else {
                        this.setState({
                            show: true, basicType: 'warning', basicTitle: "No Data found.",
                            loadshow: 'show-n',
                            showform: 'show-m',
                        });

                    }
                    this.renderinitmap(markers)
                })
            .catch(function (error) {
                console.log(error);
            });
        var trucks = []
        if(this.state.coordinates.length > 0){
            this.state.coordinates.map(e => trucks.push(e.truck_no))
        }
        var params = {
            truck_no:trucks,
            startDate:moment.parseZone(new Date()).subtract(1,'days').format("YYYY-MM-DD HH:mm:ss"),
            endDate:moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss"),

        }

    
        await redirectURL.post("/dashboard/getTicketLogsDataForInPlant" ,params ).then(response =>{
            var records = response.data.records
            var totalVoilations = []
            var toatalOverSpeeding = []
            var totalNightDriving = []
            var totalIdling = []
            if(records.length > 0) {
                totalVoilations = records
                toatalOverSpeeding = records.filter(e =>e.ticket_category=="over_speeding")
                totalNightDriving = records.filter(e =>e.ticket_category=="night_driving")
                totalIdling = records.filter(e =>e.ticket_category=="idling")
            }
            console.log(totalVoilations , toatalOverSpeeding , totalNightDriving ,totalIdling , "voilations_373")
            this.setState({
                voilation_records : records,
                totalVoilationsCount :totalVoilations.length,
                toatalOverSpeedingCount : toatalOverSpeeding.length,
                totalNightDrivingCount : totalNightDriving.length,
                totalIdlingCount : totalIdling.length,
                loadshow:"show-n",
                overly:"show-n"
            })
        })

    }

    onCellClicked = (e) => {
        if(e.colDef.field == "truck_no"){
            var records = this.state.voilation_records.filter(f =>f.truck_no == e.value)
            var totalVoilations = []
            var toatalOverSpeeding = []
            var totalNightDriving = []
            var totalIdling = []
            if(records.length > 0) {
                totalVoilations = records
                toatalOverSpeeding = records.filter(e =>e.ticket_category=="over_speeding")
                totalNightDriving = records.filter(e =>e.ticket_category=="night_driving")
                totalIdling = records.filter(e =>e.ticket_category=="idling")
            }
            this.setState({
                totalVoilationsCount :totalVoilations.length,
                toatalOverSpeedingCount : toatalOverSpeeding.length,
                totalNightDrivingCount : totalNightDriving.length,
                totalIdlingCount : totalIdling.length,
                current_fitler_truck :e.value,
            })
        }
    }

    onClickResetFilters = () => {
        var records = this.state.voilation_records
        var totalVoilations = []
        var toatalOverSpeeding = []
        var totalNightDriving = []
        var totalIdling = []
        if(records.length > 0) {
            totalVoilations = records
            toatalOverSpeeding = records.filter(e =>e.ticket_category=="over_speeding")
            totalNightDriving = records.filter(e =>e.ticket_category=="night_driving")
            totalIdling = records.filter(e =>e.ticket_category=="idling")
        }
        console.log(totalVoilations , toatalOverSpeeding , totalNightDriving ,totalIdling , "voilations_373")
        this.setState({
            totalVoilationsCount :totalVoilations.length,
            toatalOverSpeedingCount : toatalOverSpeeding.length,
            totalNightDrivingCount : totalNightDriving.length,
            totalIdlingCount : totalIdling.length,
            current_fitler_truck:"",
        })
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
    
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    
        //    ChartMenu.prototype.saveChart = function (e) {
        // 	   var event = {
        // 	      data: this.gridOptionsWrapper.getApi(),
        // 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
        // 	    };
        // 	   //console.log("Testing", event);
        // 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
        // 	   this.dispatchEvent(event);
        // 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
    
        //    };
    };
    onGridState = () => {
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
    
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
    
        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
    
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    };
    selectOptionsItems() {
        let items = [];
        //console.log(this.state.trucks);
        this.state.trucks.forEach(item => {
            if (item != '' && item != null) {
                items.push(<option key={item.truck_no} value={item.truck_no}>{item.truck_no}</option>);
            }

        });


        return items;
    }
    restoreGridStates() {
        if (this.state.usergridstate.length > 0) {
          try {
            var windowstates = this.state.usergridstate;
            this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
            this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
            this.gridApi.setSortModel(windowstates[0].gridcolsort);
            this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
          } catch (e) {}
        }
      }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        // var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());
        var startdate = getYYYYMMDDHHMMSS(d)
        if(this.state.endDate != "" && startdate > this.state.endDate){
            this.setState({
                show: true,
                basicTitle: 'Start date should be less than End date',
                basicType: "danger",
                loadshow: 'show-n',
                startDate: "",
                // rowData: null,
                // mapinfo: '',
            })
        }
        else{
        this.setState({
            startDate: startdate
        });
    }
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    // onClickTicketTypeCounterShowData(params, ticketCategory) {
    //     // console.log("Counter Params: ", params);
    //     let filterInstance = null;
    //     let filterInstance2 = null;
    //     let filterActivated = false;
    //     let gridTitle = "Active";
    //     try {
    //       this.gridApi.setFilterModel(null);
    //       this.gridApi.onFilterChanged();
    //       this.setState({
    //         showMailBtn: "show-n",
    //         showCommentBtn: "show-n",
    //         activeTab:"violation"
    //       });
    //       filterInstance = this.gridApi.getFilterInstance("ticket_category");
    //       filterActivated = true;
    //       console.log(filterActivated,"outside")
    //       // if(params == 'all')
    //       // {
    //       // 	filterActivated = false;
    //       // }
    //       try {
    //         console.log("filterActivated ",filterActivated)
    //         var isFilterDataAvailable = [];
    //         if (filterActivated) {
    //           // if(params != "slow_trucks_data" && params != "ready_to_close_data" && params != "ready_to_close_manual_data")
    //           if ( params != "ready_to_close_data" && params != "ready_to_close_manual_data" ) {
    //             console.log(params, "counter type");
    
    //             // here now params can be slow_trucks_data, food_stoppages, fuel_stoppage, duel_fuel_stoppage
    //             console.log(this.state.violationsRowData, "rowData")
    //             isFilterDataAvailable = this.state.rowData.filter((rec) => {
    //               // if(params == 'transitdelay')
    //               // {
    //               // 	return rec["ticket_type"] == "transit_delay";
    //               // }
    //               if (params == "overspeeding") {
    //                 gridTitle = "Overspeeding";
    //                 return rec["ticket_category"] == "over_speeding";
    //               }
    //             //   if (params == "enroute") {
    //             //     gridTitle = "Enroute Stoppage";
    //             //     return rec["ticket_category"] == "enroute_stoppage";
    //             //   }
    //               if (params == "nightdrive") {
    //                 gridTitle = "Night Driving";
    //                 return rec["ticket_category"] == "night_driving";
    //               }
    //             //   if (params == "detour") {
    //             //     gridTitle = "Deviation";
    //             //     return rec["ticket_category"] == "detour";
    //             //   }
    //             //   if (params == "shortrest") {
    //             //     gridTitle = "No Short Rest";
    //             //     return rec["ticket_category"] == "short_rest";
    //             //   }
    //               if (params == "idling") {
    //                 gridTitle = "Idling";
    //                 return rec["ticket_category"] == "idling";
    //               }
    //             //   if (params == "all") {
    //             //     gridTitle = "Active";
    //             //     return rec["ticket_type"] != "Manual";
    //             //   }
    //             });
    //             console.log(isFilterDataAvailable, "isFilterDataAvailable");
    //           } else {
    //             isFilterDataAvailable = this.state[params];
    //           }
    //           console.log(isFilterDataAvailable, "isFilterDataAvailable");
    //           if (isFilterDataAvailable.length) {
    //             this.gridApi.setRowData(isFilterDataAvailable);
    //             this.gridApi.onFilterChanged();
    //           } else {
    //             this.gridApi.setRowData([]);
    //             this.gridApi.onFilterChanged();
    //           }
    //         } else {
    //           console.log("Here", this.state.rowData);
    //           this.gridApi.setRowData(this.state.rowData);
    //           this.gridApi.onFilterChanged();
    //         }
    //         this.setState({
    //           gridDataTitle: gridTitle,
    //         })
    //       } catch (e) {
    //         console.log("Counter Filter Error ", e);
    //       }
    //     } catch (e) {
    //       console.log("Counter Filter Error ", e);
    //     }
    //   }
    onClickTicketTypeCounterShowData(params, ticketCategory) {
        console.log("Counter Params: ", params);

        let filterInstance = null;
        let filterInstance2 = null;
        let filterActivated = false;
        let gridTitle = "Active";
        try {
          this.gridApi.setFilterModel(null);
          this.gridApi.onFilterChanged();
          this.setState({
            showMailBtn: "show-n",
            showCommentBtn: "show-n",
          });
          filterInstance = this.gridApi.getFilterInstance("ticket_category");
          filterActivated = true;
          // if(params == 'all')
          // {
          // 	filterActivated = false;
          // }
          try {
            //console.log("filterActivated ",filterActivated)
            var isFilterDataAvailable = [];
            if (filterActivated) {
              console.log(filterActivated,"filterActivated")
              // if(params != "slow_trucks_data" && params != "ready_to_close_data" && params != "ready_to_close_manual_data")
              if (
                params != "ready_to_close_data" &&
                params != "ready_to_close_manual_data"
              ) {
                console.log(params, "counter type");
                console.log(this.state.violationInPlantData,"violation rowData")
                // here now params can be slow_trucks_data, food_stoppages, fuel_stoppage, duel_fuel_stoppage
    
                isFilterDataAvailable = this.state.violationInPlantData.filter((rec) => {
                  // if(params == 'transitdelay')
                  // {
                  // 	return rec["ticket_type"] == "transit_delay";
                  // }
                  if (params == "overspeeding") {
                    gridTitle = "Overspeeding";
                    return rec["ticket_category"] == "over_speeding";
                  }
                //   if (params == "enroute") {
                //     gridTitle = "Enroute Stoppage";
                //     return rec["ticket_category"] == "enroute_stoppage";
                //   }
                  if (params == "nightdrive") {
                    gridTitle = "Night Driving";
                    return rec["ticket_category"] == "night_driving";
                  }
                //   if (params == "detour") {
                //     gridTitle = "Deviation";
                //     return rec["ticket_category"] == "detour";
                //   }
                //   if (params == "shortrest") {
                //     gridTitle = "No Short Rest";
                //     return rec["ticket_category"] == "short_rest";
                //   }
                  if (params == "idling") {
                    gridTitle = "Idling";
                    return rec["ticket_category"] == "idling";
                  }
                //   if (params == "all") {
                //     gridTitle = "Active";
                //     return rec["ticket_type"] != "Manual";
                //   }
                });
                console.log(isFilterDataAvailable, "isFilterDataAvailable");
              } else {
                isFilterDataAvailable = this.state[params];
              }
              console.log(isFilterDataAvailable, "isFilterDataAvailable");
              if (isFilterDataAvailable.length) {
                // filterInstance.selectNothing();
    
                // if(params == 'transitdelay')
                // {
                // 	filterInstance.selectValue("transit_delay");
                // }
                // if(params == 'nogps')
                // {
                // 	filterInstance.selectValue("no_gps");
                // }
                // if(params == 'overspeeding')
                // {
                // 	filterInstance.selectValue("overspeeding");
                // }
                // if(params == 'enroute')
                // {
                // 	filterInstance.selectValue("enroute_stoppage");
                // }
                // if(params == 'nightdrive')
                // {
                // 	filterInstance.selectValue("night_driving");
    
                // }
                // if(params == 'loadingdelay')
                // {
                // 	filterInstance.selectValue("loading_delay");
                // }
                // if(params == 'unloadingdelay')
                // {
                // 	filterInstance.selectValue("unloading_delay");
                // }
    
                // filterInstance.applyModel();
                this.gridApi.setRowData(isFilterDataAvailable);
                this.gridApi.onFilterChanged();
              } else {
                // filterInstance.selectNothing();
                //filterInstance.selectValue("");
                // filterInstance.applyModel();
                this.gridApi.setRowData([]);
                this.gridApi.onFilterChanged();
              }
            } else {
              console.log("Here");
              this.gridApi.setRowData(this.state.rowData);
              this.gridApi.onFilterChanged();
            }
            this.setState({
              gridDataTitle: gridTitle,
            },()=>{
                console.log(this.state.gridDataTitle,"counter type")
            })
          } catch (e) {
            console.log("Counter Filter Error ", e);
          }
        } catch (e) {
          console.log("Counter Filter Error ", e);
        }
      }
    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        // var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes()) + ":" + ((d.getSeconds() < 10) ? "0" + d.getSeconds() : d.getSeconds());
        var edate = getYYYYMMDDHHMMSS(d)
        console.log(edate,this.state.startDate,"changes123")
        if(this.state.startDate != "" && edate<this.state.startDate){
            this.setState({
                show: true,
                basicTitle: 'End date should be greater than Start date',
                basicType: "danger",
                loadshow: 'show-n',
                endDate: "",
                // rowData: null,
                // mapinfo: '',
            })
        }
        else{
        this.setState({
            endDate: edate
        });
    }

        //let value = event.target.value;
        //this.setState({'startDate':value});
    }
    initalList = (event) => {
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.inPlantTruckList;
        console.log("dataset", dataset)

        dataset = dataset.slice(0, 100);
        this.setState({
            filteredData: dataset,
            showTrucksList: "show-m",
        })
    }
    handlerForm = (event) => {
        //console.log("Select", event.target.value);
        // let name = event.target.name;
        // let value = event.target.value;
        // this.setState({[name]:value});
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.inPlantTruckList;
        var filteredResult = dataset.filter(function (e) {
            if (e != "" && e != null) {
                return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
            }

        });
        filteredResult = filteredResult.slice(0, 100);
        this.setState({
            filteredData: filteredResult,
            showTrucksList: "show-m",
        })
    }
    onClickTruck(event) {
        $("#inputTruck").val($(this).text());
    }
    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          },()=> {
            console.log(this.state.activeTab,"activeTab")
          });
        }
      };
    formHandler = async (event) => {
        // console.log(document.getElementById("view_markers_check").checked, "checked");
        $("#view_markers_check").prop("checked", false);
        // $("#topCard").addClass("d-none")
        this.setState({
            topCardShow: "d-none",
            gpsCardShow : "d-show",
        })
        showmarkers = 0;
        this.setState({
            loadshow: 'show-m',
        })
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.formSubmittedGenerate,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        //var formdata = new FormData(event.target);
        // console.log($("#inputTruck").val())
        if(this.state.startDate == "" || this.state.endDate == ""){
            this.setState({
                show: true,
                basicTitle: 'Either or both the start date and end date fields are empty',
                basicType: "danger",
                loadshow: 'show-n',
                topCardShow : 'd-show',
                // rowData: null,
                // mapinfo: '',
            })
        }
        else{
        var payload = {
            truck_no: $("#inputTruck").val().toUpperCase(),
            startDate: getHyphenYYYYMMDDHHMMSS(this.state.startDate),
            endDate: getHyphenYYYYMMDDHHMMSS(this.state.endDate)
        }
        await redirectURL.post("/tms/getInPlantViolations", payload).then((res) => {
            console.log("res inplantviolations", res.data)
            let violations= res.data;
            this.setState({
                violationInPlantData: violations
            })
            let overRowData = violations.filter(e => e.ticket_category === "over_speeding");
            console.log(overRowData, "overRowData")
            let overCount = violations.filter(e => e.ticket_category === "over_speeding").length;

            let nightRowData = violations.filter(e => e.ticket_category === "night_driving");
            console.log(nightRowData, "nightRowData");
            let nightCount = nightRowData.length;

            let idleRowData = violations.filter(e => e.ticket_category === "idling");
            console.log(idleRowData,"idleRowData");
            let idleCount = idleRowData.length;


            this.setState({
                overspeedCount: overCount,
                violationsRowData: overRowData,
                nightdrivingCount: nightCount,
                nightRowData: nightRowData,
                idlingRowData: idleRowData,
                idlingCount: idleCount
            
            })
        })
        var formdata = {
            truck_no: $("#inputTruck").val().toUpperCase(),
            startDate: getHyphenYYYYMMDDHHMMSS(this.state.startDate),
            endDate: getHyphenYYYYMMDDHHMMSS(this.state.endDate),
            frequency: this.state.frequency
        }
        console.log("formdata ", formdata)
        await redirectURL.post("/gmap/inplant", formdata, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': "*",
                'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, TOKEN',
                'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
            }
        })
            //.then(res => res.json())
            .then(
                async (result) => {
                    // console.log("result ", result.data.status);
                    if (result.data.status == 'failure') {
                        this.setState({
                            show: true,
                            basicTitle: 'No GPS data available for this period',
                            basicType: "danger",
                            loadshow: 'show-n',
                            topCardShow : 'd-show',
                            gpsCardShow : 'd-none',
                            startDate : "",
                            endDate: "",
                            hideforgps:"d-show",
                            // rowData: null,
                            // mapinfo: '',
                        })
                    }
                    else if(result.data.status === 'truckno'){
                        this.setState({
                            show: true,
                            basicTitle: 'Enter Valid Truck No',
                            basicType: "danger",
                            loadshow: 'show-n',
                            topCardShow : 'd-show',
                            gpsCardShow : 'd-none',
                            hideforgps:"d-show",
                            // rowData: null,
                            // mapinfo: '',
                        })
                    }
                    else {

                        var jsondata = result.data;
                        // console.log("mapinfodata", JSON.parse(jsondata.data))
                        var coordinates = JSON.parse(jsondata.data);
                        //console.log("truckno ", this.props.truckno)
                        //console.log("coordinates ", coordinates)
                        var dataarr = []
                        var tottalDistance = [];
                        //console.log("coordinates ", coordinates)
                        coordinates.coords.map((item) => {

                            tottalDistance.push(parseFloat(item.cumm_distance));
                            dataarr.push({
                                "truck_no": item.truck_no,
                                "lat": item.lat,
                                "lng": item.lng,
                                "speed": item.speed,
                                "timestamp": item.stime,
                                //"state":item.state,
                                //"area":item.area,
                                //"city":item.city,
                                //"location_type":item.location_type,
                                "time_from_prev_point": item.time_from_prev_point,
                                "dist_from_prev_point": item.dist_from_prev_point,
                                "received_on": item.received_on,
                                "area": item.area,
                                "state": item.state,
                                "cumm_distance": item.cumm_distance
                            })
                        });
                        //console.log("Modified ", dataarr)
                        var pinnedBottomRowData = {}
                        pinnedBottomRowData['_id'] = "total";
                        pinnedBottomRowData['truck_no'] = "";
                        pinnedBottomRowData['lat'] = "";
                        pinnedBottomRowData['lng'] = "";
                        pinnedBottomRowData['speed'] = "";
                        pinnedBottomRowData['timestamp'] = "";
                        pinnedBottomRowData['dist_from_prev_point'] = "Total";
                        pinnedBottomRowData['created_date'] = "";
                        pinnedBottomRowData['time_from_prev_point'] = "Total";
                        pinnedBottomRowData['area'] = "";
                        pinnedBottomRowData['district'] = "";
                        pinnedBottomRowData['state'] = "";
                        pinnedBottomRowData['cumm_distance'] = arrayMax(tottalDistance);
                        pinnedBottomRowData['total_travel_time_mins'] = "";
                        this.setState({
                            rowData: dataarr,
                            mapinfo: JSON.parse(jsondata.data),
                            loadshow: 'show-n',
                            tolls: JSON.parse(jsondata.data).tolls,
                            maptruck: formdata.truck_no,
                            tottalDistance: [pinnedBottomRowData],
                            totaldistanceval: arrayMax(tottalDistance),
                            hideforgps:"d-none"
                        })
                        this.renderMap()

                    }

                }
            )
            .catch(function (error) {
                console.log(error);
            });
    }}

    onClickShowGrid() {
        this.setState({
            gridshow: 'show-m',
            mapshow: 'show-n',
            mapinfoclick: "",
            actgridbtn: "btn-success",
            actmapbtn: "btn-default"
        });
    }

    onClickShowMap() {
        this.setState({
            gridshow: 'show-n',
            mapshow: 'show-m',
            mapinfoclick: this.state.mapinfo,
            actgridbtn: "btn-default",
            actmapbtn: "btn-success"
        });
    }
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            showTrucksList: "show-n",
        });

    }
    hideList() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            showTrucksList: "show-n",
        });
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    setTruckno = (event) => {
        this.setState({
            selected_truck_no: event.target.text
        })
        // console.log(event.target.text)
    }

    checkHandler = (event) => {

        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    }
    renderMap = () => {
        window.initMap = this.initMap
    }
    renderinitmap = () => {
        loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBI9tCwOL8bO4iIFRCWucbzeWfJOEX-y34&libraries=places,drawing&callback=initMap");
        window.initMap = this.initMapdata
    }
    initMap = () => {
        //console.log("arr ",arr)
        var currentwindow;
        var dealer = '';
        var tolls = this.state.tolls;

        var arr = [];
        var jsondata = this.state.mapinfo;
        var locations = jsondata.breaks;
        var arr = jsondata.coords;
        if (arr.length > 0 && typeof arr.length != undefined) {
            var lt = arr[0].lat;
            var ln = arr[0].lng;
        }
        else {
            var lt = 28.4519751;
            var ln = 77.0310713;
        }
        var routeinfo;
        try {
            var mapOptions = {
                zoom: 13,
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                labels: true,
                mapTypeControlOptions: {
                    mapTypeIds: ['satellite', 'roadmap'],
                },
                center: new window.google.maps.LatLng(lt, ln),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP
            };

        }
        catch (e) {
            var mapOptions = {
                zoom: 13,
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
                labels: true,
                mapTypeControlOptions: {
                    mapTypeIds: ['satellite', 'roadmap'],
                },
                center: new window.google.maps.LatLng(28.4519751, 77.0310713),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP
            };

        }
        try {
            map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
        } catch (e) { }

        var bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng(lt, ln));
        // Create our info window content
        var currentinfowindow = null;
        var line = new window.google.maps.Polyline(
            {
                map: map,
                strokeColor: '#157254',
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
                // icons: [{
                // 		icon: {
                // 				path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                // 				strokeColor:'#ff8c52',
                // 				fillColor:'#ff8c52',
                // 				fillOpacity:1,
                // 				strokeWeight: 2
                // 		},
                // 		repeat:'100px',
                // 		path:[]
                // 	}]
            });
        if (arr.length > 0) {
            var allpoints = [];

            var arr1 = [];
            //console.log("Total ",ratio);
            if (this.state.timelinesmarkers.length > 0) {
                arr1 = this.state.timelinesmarkers.filter(function (value, index, arr) {
                    return (index % ratio == 0);
                });


                //var arr1 = this.state.timelinesmarkers;
            }

            var arr2 = [];
            var darr = [];
            if (arr.length > 0) {
                darr = arr.filter(function (value, index, arr) {
                    //console.log("Vale ",value)
                    return (value.dist_from_prev_point > 0);
                });
                if (darr.length < 2000) {
                    var ratio = 1;
                }
                else {
                    var ratio = 20;
                }
                //console.log("darr.length ", darr.length)
                arr2 = darr.filter(function (value, index, darr) {
                    return (index % ratio == 0);
                });


                //var arr1 = this.state.timelinesmarkers;
            }
            // view markers code
            if (arr2.length > 0) {
                // console.log("allpoints ", arr2)

                for (var a = 0; a < arr2.length; a++) {
                    //console.log(arr2[a],"arr2[a]");
                    var marker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(arr2[a].lat, arr2[a].lng),
                        icon: {
                            path: window.google.maps.SymbolPath.CIRCLE,
                            strokeColor: '#157254',
                            fillColor: '#157254',
                            fillOpacity: 1,
                            strokeWeight: 5,
                            scale: 1,
                        },
                        map: map,
                        content: arr2[a]
                    });
                    marker.setVisible(false);
                    // 	//console.log("Arr ",arr1[a])
                    window.google.maps.event.addListener(marker, 'mouseover', (function (marker) {
                        return function () {
                            //console.log("Arr ",marker)
                            var contentarr = []
                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                            var header = "Current Status"
                            contentarr.push({ "key": "Time ", "value": getDDMMYYYYHHMMSS(marker.content.timestamp) })
                            contentarr.push({ "key": "Speed ", "value": marker.content.speed + " KMPH" })

                            var contentString = infoBox("", header, contentarr, '')


                            infowindow.setContent(contentString);
                            currentwindow = infowindow;
                            infowindow.open(map, marker);
                            //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
                        }
                    })(marker));
                    window.google.maps.event.addListener(marker, 'mouseout', function () {
                        infowindow.close();
                    });

                    viewMarkersArr.push(marker);

                }
            }
            var routeinfo = jsondata.route_details;
            var icon_cnt = 100
            for (var i = 0; i < arr2.length - 1; i++) {
                var locationLatLng = [];
                locationLatLng.push(new window.google.maps.LatLng(arr2[i].lat, arr2[i].lng));
                locationLatLng.push(new window.google.maps.LatLng(arr2[i + 1].lat, arr2[i + 1].lng));
                if (icon_cnt % 10 == 0) {
                    var lineicon = [{
                        // icon: {
                        //         path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        //         strokeColor:'#FF0000',
                        //         fillColor:'#FF0000',
                        //         fillOpacity:1,
                        //         strokeWeight: 2
                        // },
                        repeat: '100px',
                        path: []
                    }];
                } else { lineicon = [] }
                icon_cnt = icon_cnt + 1
                var consignments_missing_route_line = new window.google.maps.Polyline({
                    map: map,
                    path: locationLatLng,
                    strokeColor: '#157254',
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    icons: lineicon
                });
                // console.log(arr2[i].msgtype)
                if (arr2[i].msgtype == "G") {

                    consignments_missing_route_line.setOptions({
                        strokeColor: "#452a68",
                        strokeWeight: 5.5,
                    })
                }
                bounds.extend(new window.google.maps.LatLng(arr2[i].lat, arr2[i].lng));
            }

            // for (let i = 0; i < arr2.length; i++) {
            //     //if(arr2[i].speed > 0)
            //     //{
            //         var linecolr = "#157254";
            //         if(arr2[i].set  == 2)
            //         {
            //             linecolr = "#0000ff";
            //         }
            //         if(arr2[i].set  == 3)
            //         {
            //             linecolr = "#980000"
            //         }
            //         line.setOptions({strokeColor: linecolr});
            //     var path=line.getPath().getArray();

            //     let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
            //     path.push(latLng);
            //     line.setPath(path);

            //     //Change line color based on map type
            //     window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
            //         if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
            //         {
            //             var line=new window.google.maps.Polyline(
            //             {
            //                 map:map,
            //                 strokeColor: '#FFFFFF',
            //                 strokeOpacity: 1.0,
            //                 strokeWeight: 2.5,
            //                 icons: [{
            //                         icon: {
            //                                 path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            //                                 strokeColor:'#ff8c52',
            //                                 fillColor:'#ff8c52',
            //                                 fillOpacity:1,
            //                                 strokeWeight: 2
            //                                 },
            //                         repeat:'100px',
            //                         path:[]
            //                     }]
            //                 });
            //         }
            //         else
            //         {
            //             var line=new window.google.maps.Polyline(
            //             {
            //                 map:map,
            //                 strokeColor: '#157254',
            //                 strokeOpacity: 1.0,
            //                 strokeWeight: 2.5,
            //                 icons: [{
            //                         icon: {
            //                                 path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            //                                 strokeColor:'#ff8c52',
            //                                 fillColor:'#ff8c52',
            //                                 fillOpacity:1,
            //                                 strokeWeight: 2
            //                                 },
            //                         repeat:'100px',
            //                         path:[]
            //                     }]
            //                 });

            //         }

            //         for (i = 0; i < arr2.length; i++) {
            //                 var path=line.getPath().getArray();
            //                 let latLng=new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng);
            //                 path.push(latLng);
            //                 line.setPath(path);
            //                         //map.setCenter(latLng);
            //             }

            //         var x = map.getZoom();
            //         var c = map.getCenter();
            //         window.google.maps.event.trigger(map, 'resize');
            //         map.setZoom(x);
            //         map.setCenter(c);
            //     } );
            //     bounds.extend(new window.google.maps.LatLng(arr2[i].lat,arr2[i].lng));
            //     //}
            // }

            var infowindow = new window.google.maps.InfoWindow();
            var marker, l;

            for (l = 0; l < locations.length; l++) {
                marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
                    icon: require('../../assets/icons/cf.png'),
                    map: map,
                });

                window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
                    return function () {
                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time)+")</p>";

                        var contentarr = []
                        //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                        var header = "Break";
                        try {
                            if (typeof this.props.mapFor != 'undefined') {
                                var timeinsecs = moment.parseZone(locations[l].break_start).format("x") - moment.parseZone(locations[l].break_start).format("x")
                                contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
                                contentarr.push({ "key": "Break End", "value": locations[l].break_end })
                                contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
                            }
                            else {
                                contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
                                contentarr.push({ "key": "Break End", "value": locations[l].break_end })
                                contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
                            }
                        }
                        catch (e) {
                            contentarr.push({ "key": "Break Start", "value": locations[l].break_start })
                            contentarr.push({ "key": "Break End", "value": locations[l].break_end })
                            contentarr.push({ "key": "Break time", "value": timeConvert(locations[l].break_time_seconds) })
                        }


                        var contentString = infoBox(marker.icon, header, contentarr, '')

                        infowindow.setContent(contentString);
                        currentwindow = infowindow;
                        infowindow.open(map, marker);
                        //console.log(marker.position.toJSON());
                        // console.log('{"lat":' + marker.position.lat() + ',"lng":' + marker.position.lng() + '}');
                    }
                })(marker, l));
            }
            marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
                icon: require('../../assets/icons/track_start.png'),
                map: map,
            });
            window.google.maps.event.addListener(marker, 'click', (function (marker) {
                return function () {
                    //	console.log(clusters[0])

                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                    var contentarr = []
                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                    var header = "Starting Point"
                    contentarr.push({ "key": "Started at", "value": routeinfo.start_time })

                    var contentString = infoBox(marker.icon, header, contentarr, '')

                    infowindow.setContent(contentString);
                    currentwindow = infowindow;
                    infowindow.open(map, marker);
                    // console.log('{"lat":' + marker.position.lat() + ',"lng":' + marker.position.lng() + '}');
                }
            })(marker));
            marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
                icon: require('../../assets/icons/truck-end.png'),
                map: map,
            });
            window.google.maps.event.addListener(marker, 'click', (function (marker) {
                return function () {
                    //	console.log(clusters[0])

                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
                    var contentarr = []
                    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                    var header = "End Point"
                    contentarr.push({ "key": "End at", "value": routeinfo.end_time })

                    var contentString = infoBox(marker.icon, header, contentarr, '')

                    infowindow.setContent(contentString);
                    infowindow.setContent(contentString);
                    currentwindow = infowindow;
                    infowindow.open(map, marker);
                    // console.log('{"lat":' + marker.position.lat() + ',"lng":' + marker.position.lng() + '}');
                }
            })(marker));

        }
        try {
            if (tolls.length > 0) {
                var infowindow = new window.google.maps.InfoWindow();
                var l;
                tolls.map(function (e, index) {
                    var tollMarker = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(e.lat, e.lon),
                        icon: require('../../assets/icons/barrier.png'),
                        map: map,
                    });
                    window.google.maps.event.addListener(tollMarker, 'mouseover', (function (tollMarker, index) {
                        return function () {
                            var contentarr = []
                            var header = "Toll - " + e.name
                            contentarr.push({ "key": "Address", "value": e.location })
                            var contentString = infoBox(tollMarker.icon, header, contentarr)
                            infowindow.setContent(contentString);
                            var currentwindow = infowindow;
                            infowindow.open(map, tollMarker);
                        }
                    })(tollMarker, index));
                    window.google.maps.event.addListener(tollMarker, 'mouseout', function () {
                        infowindow.close();
                    });
                })

            }
        } catch (e) { }


        map.fitBounds(bounds)

    }

    initMapdata = () => {
		allcords=[];
		console.log(222222,this.state.coordinates)
		this.state.coordinates.map((marker) => {
			allcords.push(marker);
		});
       

		var contentdata = this.state.contentString;
		if(this.state.rlatitude == '' && this.state.rlongitude == '')
		{
			var lt=21.144644112601775;
			var ln=79.08860126768066;
		}
		else{
			var lt=this.state.rlatitude;
			var ln=this.state.rlongitude;
		}
		try{
			map = new window.google.maps.Map(document.getElementById('map_canvas'), {
				zoom: 15,
				center: new window.google.maps.LatLng(21.7774477778,72.8959194444),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				/*mapTypeControl: true,
				gestureHandling: 'greedy',
				mapTypeControlOptions: {
					style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: window.google.maps.ControlPosition.TOP_RIGHT
				  },
				disableDefaultUI: true,
				zoomControl: true*/
			});
		}
		catch(e){
			map = new window.google.maps.Map(document.getElementById('map_canvas'), {
				zoom: 15,
				center: new window.google.maps.LatLng(parseFloat(21.144644112601775),parseFloat(79.08860126768066)),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				/*mapTypeControl: true,
				gestureHandling: 'greedy',
				mapTypeControlOptions: {
					style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: window.google.maps.ControlPosition.TOP_RIGHT
				  },
				disableDefaultUI: true,
				zoomControl: true*/
			});
		}

		circle = new window.google.maps.Circle({
            map: map
		});
console.log(2222,ln,lt)
		marker = new window.google.maps.Marker({
			map: map,
			draggable: true,
			animation: window.google.maps.Animation.DROP,
			//position: {lat: 28.48, lng: 77.06}
		});

		marker.addListener('click', function(){
			marker.setAnimation(window.google.maps.Animation.BOUNCE)
		});

  		window.google.maps.event.addListener(map, 'click', function(event) {

			//document.getElementById("latitude").value = event.latLng.lat();
			//document.getElementById("longitude").value = event.latLng.lng();
			marker.setPosition(event.latLng);

		});
		circle.bindTo('center', marker, 'position');
		var markerLatlng1;
		//console.log("Radius ", this.state.radius)
		// console.log(allcords);
		var markers = allcords.map((marker) => {
			// console.log("marker ",marker);
			if(marker.status == 1)
			{
				var truckText =  "Inside Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_inside_24.png');
			}
			else if(marker.status == 2)
			{
				var truckText =  "Going to Dealer";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_going_24.png');
			}
			else if(marker.status == 3 || marker.status == 4)
			{
				var truckText =  "At Dealer Location";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_atdealer_24.png');
			}
			else if(marker.status == 5)
			{
				var truckText =  "Left Destination Location";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_leftdealer_24.png');
			}
			else if(marker.status == 6)
			{
				var truckText =  "Return to Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_return_24.png');
			}
			else if(marker.status == 7)
			{
				var truckText =  "Empty Truck Outside Plant";
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_empty_24.png');
			}
			else{
				var truckText =  marker.truck_no;
				var color =  "#333333";
				var image =  require('../../assets/icons/truck_na_24.png');
			}
			var image =  require('../../assets/icons/truck_na_24.png');
			var contentarr = []
			var headerTitle = truckText
			//contentarr.push({"key":"Truck No", "value":marker.truck_no});
			contentarr.push({"key":"Transporter Name", "value":marker.truck_no});
			//contentarr.push({"key":"Distance from geofence", "value":marker.distance});
			contentarr.push({"key":"Latitude", "value":marker.latitude});
			contentarr.push({"key":"Longitude", "value":marker.longitude});
            contentarr.push({"key":"State", "value":marker.state});
            contentarr.push({"key":"Pin Code", "value":marker.pincode});
			// contentarr.push({"key":"Speed (km/h)", "value":Math.ceil(marker.speed)});
			// contentarr.push({"key":"Address", "value":marker.area+", "+marker.state});

			var contentString = infoBox(image, headerTitle, contentarr, '')


			// var contentString = "<table><tr><th>Status: </th><td style='color:"+color+";font-weight:600;text-align: right'>"+truckText+"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>";
			// contentString = contentString + marker.distance+"</td></tr><th>Truck No: </th><td style='text-align: right'>"+marker.truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
			// contentString = contentString + marker.transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+marker.lsp_name+"</td></tr><tr><th>Address:</th><td style='text-align: right'>";
			// contentString = contentString  +marker.address+"</td></tr></table>";

			//var contentString = "Here "+marker.truck_no;

			//var contentString = infoBox(require('../../assets/icons/truck_inside_24.png'), header, contentarr)

			var infowindow = new window.google.maps.InfoWindow({
				content: contentString
			});

			var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));

			if(marker.status == 1)
			{
				var image =  require('../../assets/icons/truck_inside_24.png');
			}
			else if(marker.status == 2)
			{
				var image =  require('../../assets/icons/truck_going_24.png');
			}
			else if(marker.status == 3 || marker.status == 4)
			{
				var image =  require('../../assets/icons/truck_atdealer_24.png');
			}
			else if(marker.status == 5)
			{
				var image =  require('../../assets/icons/truck_leftdealer_24.png');
			}
			else if(marker.status == 6)
			{
				var image =  require('../../assets/icons/truck_return_24.png');
			}
			else if(marker.status == 7)
			{
				var image =  require('../../assets/icons/truck_empty_24.png');
			}
			else{
				var image =  require('../../assets/icons/truck_na_24.png');
			}
			var image =  require('../../assets/icons/truck_na_24.png');
			/*
			if(marker.overspeeding_exception == 1)
			{
				var image =  require('../../assets/icons/truck_overspeed.png');
			}
			if(marker.nightdriving_exception == 1)
			{
				var image =  require('../../assets/icons/truck-end.png');
			}
			*/


			var mark = new window.google.maps.Marker({
                position: markerLatlng,
				map: map,
				title:"Status: "+truckText+" #"+marker.truck_no,
				icon: image
            });
			mark.addListener('click', function() {
				infowindow.open(map, mark);
			  });
			return mark
            mark.setMap(map);
		});
		var eventtrigger = document.getElementsByClassName('truck');
		// var markerCluster = new MarkerClusterer(map, markers,
        //     {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
		  // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
		  //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
		if(this.state.plantLocations.length > 0){
			this.state.plantLocations.map(function(params){
				var plant = {
				url: require("../../assets/icons/"+params.icon), // url
				scaledSize: new window.google.maps.Size(32,32), // scaled size
				origin: new window.google.maps.Point(0,0), // origin
				anchor: new window.google.maps.Point(0, 0) // anchor
				};
				var coords = JSON.parse(params.coordinates);
				var plantmarker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(coords.lat,coords.lng),
					map: map,
					icon: plant,
					title : params.plant_name
				});
				// var cityCircle = new window.google.maps.Circle({
				// strokeColor: '#9fc9b9',
				// strokeOpacity: 0.3,
				// strokeWeight: 2,
				// fillColor: '#9fc9b9',
				// fillOpacity: 0.6,
				// map: map,
				// center:coords,
				// radius: 50 * 1000
				// });
			})
		}
	}

    onClickShowTruckLocation(e) {
        // console.log("CLiclable ", e)
        var lat = parseFloat(e.data.latitude == undefined?e.data.lat:e.data.latitude);
        var lng = parseFloat(e.data.longitude == undefined?e.data.lng:e.data.longitude);
        var data = e.data;
        map.setCenter(new window.google.maps.LatLng(lat, lng));
        map.setZoom(22);
        var markerLatlng = new window.google.maps.LatLng(lat, lng);
        var image =  require('../../assets/icons/truck_na_24.png');
        // var image = require('../../assets/icons/gmarker.png');

        var marker = new window.google.maps.Marker({
            position: markerLatlng,
            map: map,
            title: data.truck_no,
            icon: image
        });


        var contentarr = []
        var header = data.truck_no
        //contentarr.push({"key":"Reached On", "value":getDDMMYYYYHHMMSS(data.reached_on)})
        contentarr.push({ "key": "City/Area", "value": data.city != undefined?data.city:data.area})
        contentarr.push({ "key": "State", "value": data.state })

        var contentString = infoBox(marker.icon, header, contentarr, '')

        var infowindow = new window.google.maps.InfoWindow({
            content: contentString
        });

        marker.setAnimation(window.google.maps.Animation.DROP)
        marker.addListener('click', function () {
            infowindow.open(map, marker);
        });

        marker.setMap(map);

    }
    showmarkers(event) {
        // console.log("checkbox", event.target.value);
        // console.log("markers", this.state.showmarkers)
        if (showmarkers == 0) {
            viewMarkersArr.map(function (e) {
                e.setVisible(true);
            });
            showmarkers = 1
            // this.setState({
            //     showmarkers: 1
            // })
        }
        else {
            viewMarkersArr.map(function (e) {
                e.setVisible(false);
            });
            showmarkers = 0
            // this.setState({
            //     showmarkers: 0
            // })
        }
    }
    render() {
        let order_cnt = [];

        const dStyles = {
            width:'100%',
			marginRight:'0px',
			paddingRight:'0px',
			height:'700px'
        }
        const modalStyles = {
            width: '500px !important',
        }
        const { open } = this.state;
        const columnwithDefs = [
            {
                headerName: "",
                field: "",
                width: 50,
                //   cellRenderer:'trucklocaction'
                cellRendererSelector: function (params) {
                    if (params.data._id != "total") {
                        return {
                            component: "trucklocaction"
                        }
                    }
                }
            },
            {
                headerName: "Truck no",
                field: "truck_no",
                width: 100,
                filter: true,
                resizable: true

            },
            {
                headerName: "Latitude",
                field: "lat",
                width: 120,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    return params.data.lat;
                }

            },
            {
                headerName: "Longitude",
                field: "lng",
                width: 120,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    return params.data.lng;
                }

            },
            //   {
            //       headerName: "Last Known State",
            //       field: "state",
            //       width: 140,
            //       filter: true,
            //       resizable: true

            //   },
            //   {
            //       headerName: "Last Know City",
            //       field: "city",
            //       width: 140,
            //       filter: true,
            //       resizable: true

            //   },

            //   {
            //     headerName: "Last Know Area",
            //     field: "area",
            //     width: 140,
            //     filter: true,
            //     resizable: true

            // },
            //   {
            //       headerName: "Location Type",
            //       field: "location_type",
            //       width: 140,
            //       filter: true,
            //       resizable: true

            //   },
            {
                headerName: "Area / City",
                field: "area",
                width: 130,
                filter: true,
                resizable: true,
            },
            {
                headerName: "State",
                field: "state",
                width: 130,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Speed (Kms)",
                field: "speed",
                width: 80,
                filter: true,
                resizable: true

            },
            {
                headerName: "Packet Time",
                field: "timestamp",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    //console.log(params);
                    if (params.data.timestamp != "") {
                        return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
                    }
                    else {
                        return "";
                    }

                },
                // valueGetter:function(params){
                // 	console.log(params);
                // 	return params.data.stime;
                // }
                //   // filter: "agDateColumnFilter",
                comparator: dateComparator,

            },
            // {
            //     headerName: "Received On",
            //     field: "re",
            //     width: 140,
            //     filter: true,
            //     resizable: true,
            //     valueGetter: function (params) {
            //         // console.log(params.data.received_on);
            //         if (params.data.created_date != "NaT") {
            //             return getHyphenDDMMMYYYYHHMM(params.data.created_date);
            //         }
            //         else {
            //             return "";
            //         }

            //     },
            //     // // filter: "agDateColumnFilter",
            //     comparator: dateComparator,


            // },
            {
                headerName: "Time from previous point",
                field: "time_from_prev_point",
                width: 240,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    // return ConvertSeconds(params.data.time_from_prev_point);
                    if (params.data.time_from_prev_point != 'Total') {
                        return secondsToDhms(params.data.time_from_prev_point);
                    }
                    else {
                        return "";
                    }
                }

            },
            {
                headerName: "Distance from previous point (Meters)",
                field: "dist_from_prev_point",
                width: 240,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    // return  convertMeters(params.data.dist_from_prev_point);
                    if (params.data.dist_from_prev_point != 'Total') {
                        return convertMeters(params.data.dist_from_prev_point);
                    }
                    else {
                        return "Total";
                    }
                }

            },
            {
                headerName: "Cummulative Distance (KM)",
                field: "cumm_distance",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    return convertMeters(params.data.cumm_distance);
                }

            },

        ]

        const columnwithcoordinates = [
            {
                headerName: "",
                field: "",
                width: 50,
                //   cellRenderer:'trucklocaction'
                cellRendererSelector: function (params) {
                    if (params.data._id != "total") {
                        return {
                            component: "trucklocaction"
                        }
                    }
                }
            },
            {
                headerName: "Truck no",
                field: "truck_no",
                width: 100,
                filter: true,
                resizable: true

            },
            {
                headerName: "Latitude",
                field: "latitude",
                width: 120,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    return params.data.latitude;
                }

            },
            {
                headerName: "Longitude",
                field: "longitude",
                width: 120,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    return params.data.longitude;
                }

            },
            //   {
            //       headerName: "Last Known State",
            //       field: "state",
            //       width: 140,
            //       filter: true,
            //       resizable: true

            //   },
            //   {
            //       headerName: "Last Know City",
            //       field: "city",
            //       width: 140,
            //       filter: true,
            //       resizable: true

            //   },

            //   {
            //     headerName: "Last Know Area",
            //     field: "area",
            //     width: 140,
            //     filter: true,
            //     resizable: true

            // },
            //   {
            //       headerName: "Location Type",
            //       field: "location_type",
            //       width: 140,
            //       filter: true,
            //       resizable: true

            //   },
            {
                headerName: "Area / City",
                field: "city",
                width: 130,
                filter: true,
                resizable: true,
            },
            {
                headerName: "State",
                field: "state",
                width: 130,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Speed (Kms)",
                field: "speed",
                width: 80,
                filter: true,
                resizable: true

            },
            {
                headerName: "Packet Time",
                field: "timestamp",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    //console.log(params);
                    if (params.data.timestamp != "") {
                        return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
                    }
                    else {
                        return "";
                    }

                },
                // valueGetter:function(params){
                // 	console.log(params);
                // 	return params.data.stime;
                // }
                //   // filter: "agDateColumnFilter",
                comparator: dateComparator,

            },
            // {
            //     headerName: "Received On",
            //     field: "created_date",
            //     width: 140,
            //     filter: true,
            //     resizable: true,
            //     valueGetter: function (params) {
            //         // console.log(params.data.received_on);
            //         if (params.data.created_date != "NaT") {
            //             return getHyphenDDMMMYYYYHHMM(params.data.created_date);
            //         }
            //         else {
            //             return "";
            //         }

            //     },
            //     // // filter: "agDateColumnFilter",
            //     comparator: dateComparator,


            // },
            {
                headerName: "Department Code",
                field: "dept_code",
                width: 140,
                filter: true,
                Routeresizable: true,
            },
            {
                headerName: "Vehicle Type",
                field: "vehicle_type",
                width: 140,
                filter: true,
                Routeresizable: true,
            },
            {
                headerName: "Vehicle Tracking Type",
                field: "vehicle_tracking_type",
                width: 140,
                filter: true,
                Routeresizable: true,
            },
            // {
            //     headerName: "Time from previous point",
            //     field: "time_from_prev_point",
            //     width: 240,
            //     filter: true,
            //     resizable: true,
            //     valueGetter: function (params) {
            //         // return ConvertSeconds(params.data.time_from_prev_point);
            //         if (params.data.time_from_prev_point != 'Total') {
            //             return secondsToDhms(params.data.time_from_prev_point);
            //         }
            //         else {
            //             return "";
            //         }
            //     }

            // },
            // {
            //     headerName: "Distance from previous point (Meters)",
            //     field: "dist_from_prev_point",
            //     width: 240,
            //     filter: true,
            //     resizable: true,
            //     valueGetter: function (params) {
            //         // return  convertMeters(params.data.dist_from_prev_point);
            //         if (params.data.dist_from_prev_point != 'Total') {
            //             return convertMeters(params.data.dist_from_prev_point);
            //         }
            //         else {
            //             return "Total";
            //         }
            //     }

            // },
            // {
            //     headerName: "Cummulative Distance (KM)",
            //     field: "cumm_distance",
            //     width: 140,
            //     filter: true,
            //     resizable: true,
            //     valueGetter: function (params) {
            //         return convertMeters(params.data.cumm_distance);
            //     }

            // },

        ]

        const violationColumnwithDefs = [
            {
                headerName: "Truck no",
                field: "truck_no",
                width: 100,
                filter: true,
                resizable: true

            },
            {
                headerName: "Ticket Number",
                field: "ticket_number",
                width: 100,
                filter: true,
                resizable: true

            },
            {
                headerName: "Ticket Category",
                field: "ticket_category",
                width: 120,
                filter: true,
                resizable: true

            },
            {
                headerName: "Overspeed Exceptions Count",
                field: "overspeed_exceptions_count",
                width: 160,
                filter: true,
                resizable: true

            },
            {
                headerName: "Ticket Text",
                field: "ticket_text",
                width: 200,
                filter: true,
                resizable: true

            },
            {
                headerName: "Exception Start Time",
                field: "exception_start_time",
                width: 170,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    // console.log(params.data.received_on);
                    if (params.data.exception_start_time != "NaT") {
                        return getHyphenDDMMMYYYYHHMM(params.data.exception_start_time);
                    }
                    else {
                        return "";
                    }

                }
            },
            {
                headerName: "Exception End Time",
                field: "exception_end_time",
                width: 170,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    // console.log(params.data.received_on);
                    if (params.data.exception_end_time != "NaT") {
                        return getHyphenDDMMMYYYYHHMM(params.data.exception_end_time);
                    }
                    else {
                        return "";
                    }

                }
            },
            {
                headerName: "Ticket Creation Date",
                field: "ticket_creation_date",
                width: 160,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    // console.log(params.data.received_on);
                    if (params.data.ticket_creation_date != "NaT") {
                        return getHyphenDDMMMYYYYHHMM(params.data.ticket_creation_date);
                    }
                    else {
                        return "";
                    }

                }
            }
        ]
        if(this.state.gridDataTitle ==="Overspeeding"? violationColumnwithDefs: violationColumnwithDefs.splice(3,1))
        var listData = []
        this.state.filteredData.map(function (e) {
            listData.push(<li className="dropdown-truck-no">{e}</li>)
        })
        return (
            <div >
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                {/*Container-fluid starts*/}
                <div className="container-fluid">
                    <div className="row col-xl-12 col-lg-12">
                        {/* <div className="card" style={{width:"100%", paddingTop:"20px"}}> */}
                            <div className={"trucks-dropdown " + this.state.showTrucksList}>
                                <ul>
                                    {listData}
                                </ul>
                            </div>
                            <form className="row theme-form col-xl-12 col-lg-12" onSubmit={this.formHandler}>
                                <div className="col-xl-3 col-lg-3 form-group">
                                    {/*<select name="truck_no" className="form-control" onChange={this.handlerForm.bind(this)}>
                                        <option value="">Truck No</option>
                                        {this.selectOptionsItems()}
                                    </select>
                                    */}
                                    <input 
                                        type="text" 
                                        name="truck_no" 
                                        id="inputTruck" 
                                        placeholder="Truck No" 
                                        autoComplete="off" 
                                        className="form-control" 
                                        onFocus={this.initalList.bind(this)} 
                                        // options={this.state.inPlantTruckList} 
                                        onChange={this.handlerForm.bind(this)}
                                    />
                                </div>
                                <div className="col-xl-3 col-lg-3 form-group">
                                    <Datetime
                                        inputProps={{ placeholder: 'Start Date', name: 'startDate', value : this.state.startDate, autoComplete: 'off', readOnly: 'true' }}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat="HH:mm:ss"
                                        name="startDate"
                                        onChange={this.handlerStartDateTime.bind(this)} />
                                </div>

                                <div className="col-xl-2 col-lg-2 form-group">
                                    <Datetime inputProps={{ placeholder: 'End Date', name: 'endDate', value : this.state.endDate, autoComplete: 'off', readOnly: 'true' }}
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat="HH:mm:ss"
                                        onChange={this.handlerEndDateTime.bind(this)} />
                                </div>
                                <div className="col-xl-2 col-lg-2 form-group">
                                    <input type="number" name="frequency" id="frequency" value={this.state.frequency} placeholder="Data Interval (Mins)" className="validateNumber form-control" onChange={this.checkHandler.bind(this)} />
                                </div>
                                <div className="col-xl-2 col-lg-2 form-group">
                                    <button type="submit" className="btn btn-success">Generate</button>
                                </div>
                            </form>
                        {/* </div> */}
                    </div  >
                    <div className={`col-xl-12 col-lg-12 ${this.state.hideforgps}`} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <p style={{ float: "right", marginBottom: "-20px", fontSize: "16px",color:"#e54c34"  }}>
                            {this.state.current_fitler_truck !== "" ? `Violation Data of ${this.state.current_fitler_truck} shown for Last 24hrs` : "Violation Data shown for Last 24hrs"}
                        </p>
                        {this.state.current_fitler_truck !== "" ? <button className='btn' style={{ float: "right", marginBottom: "-20px", marginLeft: "10px", backgroundColor:"#0d7eff" , color:"#ffffff"}} onClick={this.onClickResetFilters}>Reset</button> : ""}
                    </div>
                    <div className={`col-xl-12 col-lg-12 col-sm-6 ${this.state.hideforgps}`}>
                        <div className="card mt-4 shadow">
                        <div className="card-body p-10px">
                            <div className="crm-numbers pb-0">
                            <div className="row mx-0">
                                {/* <div className="col-12">
                                <h6>Tickets: </h6>
                                </div> */}

                                {/* Overspeed */}
                                <div
                                className="col cpointer counterBorder"
                                // onClick={this.onClickTicketTypeCounterShowData.bind(
                                //     this,
                                //     "overspeeding",
                                //     "automatic"
                                // )}
                                >
                                    <span className="f13 countClass">Total Violations</span>
                                    <h4 className="f30 txt-center txt-success">
                                        <span className="counter">
                                        <CountUp end={this.state.totalVoilationsCount} />
                                        </span>
                                    </h4>
                                </div>
                                <div
                                className="col cpointer counterBorder"
                                // onClick={this.onClickTicketTypeCounterShowData.bind(
                                //     this,
                                //     "overspeeding",
                                //     "automatic"
                                // )}
                                >
                                    <span className="f13 countClass">Overspeed</span>
                                    <h4 className="f30 txt-center txt-danger">
                                        <span className="counter">
                                        <CountUp end={this.state.toatalOverSpeedingCount} />
                                        </span>
                                    </h4>
                                </div>

                                {/* Night Driving */}
                                <div
                                className="col cpointer counterBorder"
                                // onClick={this.onClickTicketTypeCounterShowData.bind(
                                //     this,
                                //     "nightdrive",
                                //     "automatic"
                                // )}
                                >
                                <span className="f13 countClass">Night Driving</span>
                                <h4 className="f30 txt-center txt-fb">
                                    <span className="counter">
                                    <CountUp end={this.state.totalNightDrivingCount} />
                                    </span>
                                </h4>
                                </div>

                                {/* Short Rest */}
                                {/* <div
                                className="col cpointer counterBorder"
                                onClick={this.onClickTicketTypeCounterShowData.bind(
                                    this,
                                    "shortrest",
                                    "automatic"
                                )}
                                >
                                <span className="f13 countClass">No Short Rest</span>
                                <h4 className="f30 txt-center txt-primary-dark">
                                    <span className="counter">
                                    <CountUp end={this.state.shortrest} />
                                    </span>
                                </h4>
                                </div> */}

                                {/* On-Route Stoppage */}
                                {/* <div
                                className="col cpointer counterBorder"
                                onClick={this.onClickTicketTypeCounterShowData.bind(
                                    this,
                                    "enroute",
                                    "automatic"
                                )}
                                >
                                <span className="f13 countClass">Enroute Stoppage</span>
                                <h4 className="f30 txt-center txt-black">
                                    <span className="counter">
                                    <CountUp end={this.state.enroute} />
                                    </span>
                                </h4>
                                </div> */}

                                {/* Harsh Braking */}
                                {/* <div
                                className="col cpointer counterBorder"
                                onClick={this.onClickCounterShowData.bind(
                                    this,
                                    "harshBraking"
                                )}
                                >
                                <span className="f13 countClass">Harsh Braking</span>
                                <h4 className="f30 txt-center txt-danger-dark">
                                    <span className="counter">
                                    <CountUp end={3} />
                                    </span>
                                </h4>
                                </div> */}

                                {/* Detour */}
                                {/* <div
                                className="col cpointer counterBorder"
                                onClick={this.onClickTicketTypeCounterShowData.bind(
                                    this,
                                    "detour",
                                    "automatic"
                                )}
                                >
                                <span className="f13 countClass">Deviation</span>
                                <h4 className="f30 txt-center txt-secondary">
                                    <span className="counter">
                                    <CountUp end={this.state.detourCount} />
                                    </span>
                                </h4>
                                </div> */}

                                {/* Idling */}
                                <div
                                className="col cpointer"
                                // onClick={this.onClickTicketTypeCounterShowData.bind(this, "idling")}
                                >
                                <span className="f13 countClass">Idling</span>
                                <h4 className="f30 txt-center txt-warning">
                                    <span className="counter">
                                    <CountUp end={this.state.totalIdlingCount} />
                                    </span>
                                </h4>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div> 
                    {/* {(this.state.rowData != null && this.state.activeTab==="gps")? */}
                        <div className={`row col-xl-12 col-lg-12 ${this.state.topCardShow}`}>
                        <div className="col-xl-6 col-lg-6">
                                <div id="myGrid" style={{ height: "700px", width: "105%", marginBottom: '10px' }} className={"ag-theme-balham " + (this.state.gridshow)}>
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithcoordinates}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.coordinates}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this },
                                            getRowStyle: function (params) {
                                                if (params.node.rowPinned) {
                                                    return { 'font-weight': 'bold', 'font-size': '16px' };
                                                }
                                            },
                                        }}
                                        pinnedBottomRowData={this.state.tottalDistance}
                                        onCellClicked={this.onCellClicked}
                                    />


                                </div>
                                
                            </div>
                            <div className='col-xl-6 col-lg-6'>

                                <div id="map_canvas" className={this.state.mwidth}  style={dStyles} ></div>
                            </div>
                            
                            
                            {/* {(this.state.mapinfoclick != '')?this.state.mapshow */}
                           
                         {/* :""} */}
                        </div>
                        {/* //  : ""}  */}
                    {/* <div id="map_canvas" className={this.state.mwidth} style={dStyles}></div> */}
                    {(this.state.rowData != null) ? 
                    <div className={`col-xl-12 col-lg-12 col-sm-6 ${this.state.gpsCardShow}`}>
                        <div className="card mt-4 shadow">
                        <div className="card-body p-10px">
                            <div className="crm-numbers pb-0">
                            <div className="row mx-0">
                                {/* <div className="col-12">
                                <h6>Tickets: </h6>
                                </div> */}

                                {/* Overspeed */}
                                <div
                                className="col cpointer counterBorder"
                                onClick={this.onClickTicketTypeCounterShowData.bind(
                                    this,
                                    "overspeeding",
                                    "automatic"
                                )}
                                >
                                    <span className="f13 countClass">Overspeed</span>
                                    <h4 className="f30 txt-center txt-danger">
                                        <span className="counter">
                                        <CountUp end={this.state.overspeedCount} />
                                        </span>
                                    </h4>
                                </div>

                                {/* Night Driving */}
                                <div
                                className="col cpointer counterBorder"
                                onClick={this.onClickTicketTypeCounterShowData.bind(
                                    this,
                                    "nightdrive",
                                    "automatic"
                                )}
                                >
                                <span className="f13 countClass">Night Driving</span>
                                <h4 className="f30 txt-center txt-fb">
                                    <span className="counter">
                                    <CountUp end={this.state.nightdrivingCount} />
                                    </span>
                                </h4>
                                </div>

                                {/* Short Rest */}
                                {/* <div
                                className="col cpointer counterBorder"
                                onClick={this.onClickTicketTypeCounterShowData.bind(
                                    this,
                                    "shortrest",
                                    "automatic"
                                )}
                                >
                                <span className="f13 countClass">No Short Rest</span>
                                <h4 className="f30 txt-center txt-primary-dark">
                                    <span className="counter">
                                    <CountUp end={this.state.shortrest} />
                                    </span>
                                </h4>
                                </div> */}

                                {/* On-Route Stoppage */}
                                {/* <div
                                className="col cpointer counterBorder"
                                onClick={this.onClickTicketTypeCounterShowData.bind(
                                    this,
                                    "enroute",
                                    "automatic"
                                )}
                                >
                                <span className="f13 countClass">Enroute Stoppage</span>
                                <h4 className="f30 txt-center txt-black">
                                    <span className="counter">
                                    <CountUp end={this.state.enroute} />
                                    </span>
                                </h4>
                                </div> */}

                                {/* Harsh Braking */}
                                {/* <div
                                className="col cpointer counterBorder"
                                onClick={this.onClickCounterShowData.bind(
                                    this,
                                    "harshBraking"
                                )}
                                >
                                <span className="f13 countClass">Harsh Braking</span>
                                <h4 className="f30 txt-center txt-danger-dark">
                                    <span className="counter">
                                    <CountUp end={3} />
                                    </span>
                                </h4>
                                </div> */}

                                {/* Detour */}
                                {/* <div
                                className="col cpointer counterBorder"
                                onClick={this.onClickTicketTypeCounterShowData.bind(
                                    this,
                                    "detour",
                                    "automatic"
                                )}
                                >
                                <span className="f13 countClass">Deviation</span>
                                <h4 className="f30 txt-center txt-secondary">
                                    <span className="counter">
                                    <CountUp end={this.state.detourCount} />
                                    </span>
                                </h4>
                                </div> */}

                                {/* Idling */}
                                <div
                                className="col cpointer"
                                onClick={this.onClickTicketTypeCounterShowData.bind(this, "idling")}
                                >
                                <span className="f13 countClass">Idling</span>
                                <h4 className="f30 txt-center txt-warning">
                                    <span className="counter">
                                    <CountUp end={this.state.idlingCount} />
                                    </span>
                                </h4>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div> : "" }

                    {(this.state.rowData != null) ? 
                        <div className={`row col-md-12 ${this.state.gpsCardShow}`} style={{paddingLeft:"30px"}}>
                            <ul className="nav nav-tabs">
                                <li className={`nav-item ${this.state.activeTab === "gps" ? "active-tab" : ""} tablistitem`}>
                                <a
                                    className="nav-link"
                                    onClick={this.toggleTab.bind(this,"gps")}
                                >
                                    GPS Data
                                </a>
                                </li>
                                <li className={`nav-item ${this.state.activeTab === "violation" ? "active-tab" : ""} tablistitem`}>
                                <a
                                    className="nav-link"
                                    onClick={this.toggleTab.bind(this,"violation")}
                                >
                                    Violation Data
                                </a>
                                </li>
                            </ul>
                        </div>
                    : ""}

                    {(this.state.rowData != null && this.state.activeTab==="gps")?
                        <div className={`row col-xl-12 col-lg-12 ${this.state.gpsCardShow}`}>
                            <div className="col-xl-6 col-lg-6">
                                <div id="myGrid" style={{ height: "700px", width: "100%", marginTop: '10px' }} className={"ag-theme-balham " + (this.state.gridshow)}>
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this },
                                            getRowStyle: function (params) {
                                                if (params.node.rowPinned) {
                                                    return { 'font-weight': 'bold', 'font-size': '16px' };
                                                }
                                            },
                                        }}
                                        pinnedBottomRowData={this.state.tottalDistance}
                                    />


                                </div>
                            </div>
                            {/* {(this.state.mapinfoclick != '')?this.state.mapshow */}
                            <div className={"col-xl-6 col-lg-6 "} style={{ padding: "10px", backgroundColor: "#FFFFFF" }}>
                                <input type="checkbox" style={{ marginLeft: "2em", marginBottom: "1em", }} id="view_markers_check" value={showmarkers} onClick={this.showmarkers.bind(this)} /> view markers
                                <DrawMap
                                    context={this}
                                    mapFor={"truckgps"}
                                    tolls={this.state.tolls}
                                    mapinfo={this.state.mapinfo}
                                    defTransitCoords={""} />


                                <div className="col-xl-12 col-lg-12 n-p-0">
                                    <div className="crm-numbers pb-0">
                                        <h3 className="subH">View Details:</h3>
                                        <div className="col-xl-12 col-lg-12 row">
                                            <div className="col route-block">
                                                <label className="sidebar-label">Truck No</label>
                                                <div>{this.state.maptruck}</div>
                                            </div>
                                            <div className="col route-block">
                                                <label className="sidebar-label">Start Time</label>
                                                <div>{(this.state.mapinfo.route_details.start_time != '') ? getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time) : "NA"}</div>
                                            </div>
                                            <div className="col route-block">
                                                <label className="sidebar-label">End Time</label>
                                                <div>{(this.state.mapinfo.route_details.end_time != '') ? getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time) : "NA"}</div>
                                            </div>

                                            <div className="col route-block">
                                                <label className="sidebar-label">Distance</label>
                                                <div>{(this.state.totaldistanceval / 1000).toFixed(2)} Kms</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* :""} */}
                        </div>
                    : ""}
                    
                    {(this.state.activeTab ==="violation") && (this.state.violationsRowData != null) ? 
                        <div className="row col-xl-12 col-lg-12">
                        <div className="col-xl-6 col-lg-6">
                            <div id="myGrid" style={{ height: "700px", width: "100%", marginTop: '10px' }} className={"ag-theme-balham " + (this.state.gridshow)}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={violationColumnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.violationsRowData}
                                    enableCharts={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    frameworkComponents={this.state.frameworkComponents}
                                    enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    gridOptions={{
                                        context: { componentParent: this },
                                        getRowStyle: function (params) {
                                            if (params.node.rowPinned) {
                                                return { 'font-weight': 'bold', 'font-size': '16px' };
                                            }
                                        },
                                    }}
                                    pinnedBottomRowData={this.state.tottalDistance}
                                />
                            </div>
                        </div>
                        {/* {(this.state.mapinfoclick != '')?this.state.mapshow */}
                        <div className={"col-xl-6 col-lg-6 "} style={{ padding: "10px", backgroundColor: "#FFFFFF" }}>
                            <input type="checkbox" style={{ marginLeft: "2em", marginBottom: "1em", }} id="view_markers_check" value={showmarkers} onClick={this.showmarkers.bind(this)} /> view markers
                            <DrawMap
                                context={this}
                                mapFor={"truckgps"}
                                tolls={this.state.tolls}
                                mapinfo={this.state.mapinfo}
                                defTransitCoords={""} />


                            <div className="col-xl-12 col-lg-12 n-p-0">
                                <div className="crm-numbers pb-0">
                                    <h3 className="subH">View Details:</h3>
                                    <div className="col-xl-12 col-lg-12 row">
                                        <div className="col route-block">
                                            <label className="sidebar-label">Truck No</label>
                                            <div>{this.state.maptruck}</div>
                                        </div>
                                        <div className="col route-block">
                                            <label className="sidebar-label">Start Time</label>
                                            <div>{(this.state.mapinfo.route_details.start_time != '') ? getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time) : "NA"}</div>
                                        </div>
                                        <div className="col route-block">
                                            <label className="sidebar-label">End Time</label>
                                            <div>{(this.state.mapinfo.route_details.end_time != '') ? getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time) : "NA"}</div>
                                        </div>

                                        <div className="col route-block">
                                            <label className="sidebar-label">Distance</label>
                                            <div>{(this.state.totaldistanceval / 1000).toFixed(2)} Kms</div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* :""} */}
                        </div>
                    : ""}
                    <div className={"dataLoadpage " + (this.state.loadshow)}>
                    </div>
                    <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                    </div>
                </div>
                {/*Container-fluid Ends*/}

                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>
        );
    }
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
$(document).ready(function () {
    $(document).on("click", ".dropdown-truck-no", function () {
        var vals = $(this).text();
        $("#inputTruck").val(vals);
        $(".trucks-dropdown").removeClass("show-m");
        $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#inputTruck");
        if (!container.is(e.target)) {
            try {
                if ($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown") {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            } catch (e) { }

        }


    })
})

function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src = url
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function initOMS() {
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
        //markersWontMove: true,
        //keepSpiderfied: true,
        //nearbyDistance: 10,
        //circleFootSeparation: 60,
        //legWeight: 1.5
        markersWontMove: true,
        markersWontHide: true,
        keepSpiderfied: true,
        basicFormatEvents: true
    });
}
function timeConvert(n) {
    var num = n;
    var hours = (num / (3600));
    var rhours = parseInt(hours);
    var minutes = (num - (rhours * 3600)) / (60);
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}

function ConvertMinutes(num) {
    var d = Math.floor(num / 1440); // 60*24
    var h = Math.floor((num - (d * 1440)) / 60);
    var m = Math.round(num % 60);
    return d + " Day(s) " + h + " Hrs " + m + " mins"
}

function ConvertSeconds(totalSeconds) {
    // var days = Math.floor(num/86400);
    // var hours = Math.floor(num/3600)
    // var minutes = Math.floor(num/60);
    var minutes = Math.round((totalSeconds % 3600) / 60);
    var hours = Math.round((totalSeconds % 86400) / 3600);
    var days = Math.round((totalSeconds % (86400 * 30)) / 86400);
    // let d = (new Date(t0)) - (new Date(t1));
    // let weekdays     = Math.floor(d/1000/60/60/24/7);
    // let days         = Math.floor(d/1000/60/60/24 - weekdays*7);
    // let hours        = Math.floor(d/1000/60/60    - weekdays*7*24            - days*24);
    // let minutes      = Math.ceil(d/1000/60       - weekdays*7*24*60         - days*24*60         - hours*60);
    return days + " Day(s) " + hours + " Hrs " + minutes + " mins"
}

function convertMeters(metersValue) {
    return (metersValue / 1000).toFixed(2);
}

$(document).ready(function () {
    $(document).on('keyup', ".validateNumber", function () {
        // alert("click");
        if (isNaN($(this).val()) == true) {
            $(this).val($(this).val().slice(0, -1));
        }
    });
});
function arrayMax(arr) {
    return arr.reduce(function (p, v) {
        return (p > v ? p : v);
    });
}


function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
