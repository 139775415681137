import React, { Component } from "react";
import DashboardGrid from "./DashboardGrid";
import moment from "moment";
import ScatterChartLoadingDashboard from "../layouts/scatterchartloadingdashboard";
import ColumnChartLoadingDashboard from "../layouts/columnchartloadingdashboard";

export default class DriverFatigueBottomCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dummyData: {
        seriesNames: ["Driver-3", "Driver-4"],
      },
      // 1 continuousDrive
      continuousDriveGraph: "show-m",
      continuousDriveGrid: "show-n",
      continuousDriveButtonText: "Show Data",

      continuousDriveGridData: [],
      continuousDriveHeading: "Avg. Continuous driving time (Hrs)",
      // continuousDriveSubHeading: "Inside plant >20km/hr for 10 sec and outside plant > 60km/hr for 10 sec",
      continuousDriveSubHeading: "",

      // 2 shortRest

      shortRestGraph: "show-m",
      shortRestGrid: "show-n",
      shortRestButtonText: "Show Data",

      shortRestGridData: [],
      shortRestHeading: "Avg. Short rest time",
      shortRestSubHeading:
        "Avg. short rest time taken by drivers between 2 trips (< 4 hours)",

      // 3 longRest

      longRestGraph: "show-m",
      longRestGrid: "show-n",
      longRestButtonText: "Show Data",

      longRestGridData: [],
      longRestHeading: "Avg. Long rest time",
      longRestSubHeading: "Long rest time: 8 hours in the night (10pm-6am)",

      // 4 nightTime

      nightTimeGraph: "show-m",
      nightTimeGrid: "show-n",
      nightTimeButtonText: "Show Data",

      nightTimeGridData: [],
      nightTimeHeading: "Avg. Night driving (Hrs)",
      nightTimeSubHeading: "Night Driving is driving in between 11 PM -5 AM",

      // 5 harshBraking
      harshBrakingGraph: "show-m",
      harshBrakingGrid: "show-n",
      harshBrakingButtonText: "Show Data",

      harshBrakingGridData: [],
      harshBrakingHeading: "No. of Instances of overspeeding",
      harshBrakingSubHeading:
        "Inside plant >20km/hr for 10 sec and outside plant > 60km/hr for 10 sec",

      //6 harshBraking
      idlingGraph: "show-m",
      idlingGrid: "show-n",
      idlingButtonText: "Show Data",

      harshBrakingGridData: [],
      idlingHeading: "Enroute Stoppage Analysis",
      idlingSubHeading:
        "Avg. stoppage time of truck from Port-Plant per trip in corresponding time",

      fromDate: moment
        .parseZone()
        .subtract(7, "days")
        .format("YYYY-MM-DD"),
      toDate: moment.parseZone().format("YYYY-MM-DD"),
      show: false,
      basicType: "default",
      basicTitle: "",
      loadshow: "show-n",
      overly: "show-n",
      portNames: [],
      plantNames: [],
      transporternames: [],
      vehicleNumbers: [],

      driverNames: [],
      portname: [],
      plantname: [],
      vehiclenumber: [],
      transportername: [],
      drivername: "",
      allVehicles: {},
      allDrivers: {},
      shortRestData: {},
      shortRestChildData: [],
      nightTimeSeriesData: [],
      nightTimeSeriesCategories: [],
      harshBrakingSeriesData: [],
      harshBrakingSeriesCategories: [],
      harshBrakingParentData: [],
      harshBrakingChildData: [],
      idlingData: [],
      shortRestTicketsCount: 0,
      shortRestTicketsData: [],
      nightDrivingTicketsCount: 0,
      nightDrivingTicketsData: [],
      overspeedingTicketsCount: 0,
      overspeedingTicketsData: [],
      idlingTicketsCount: 0,
      idlingTicketsData: [],
      idlingData: [],
      columnDefs: [
        {
          headerName: "Ticket Number",
          field: "ticket_number",
          width: 100,
        },
        {
          headerName: "Truck No",
          field: "truck_no",
          width: 100,
        },
        {
          headerName: "Transporter Name",
          field: "transporter_name",
          width: 150,
          valueGetter: function(params) {
            if (params.data.transporter_name) {
              const words = params.data.transporter_name.split("_");
              const capitalizedWords = words.map(
                (word) => word.charAt(0).toUpperCase() + word.slice(1)
              );
              const result = capitalizedWords.join(" ");
              return result;
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Plant Name",
          field: "consignee_name",
          width: 150,
          valueGetter: function(params) {
            if (params.data.consignee_name) {
              const words = params.data.consignee_name.split("_");
              const capitalizedWords = words.map(
                (word) => word.charAt(0).toUpperCase() + word.slice(1)
              );
              const result = capitalizedWords.join(" ");
              return result;
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Consignment Number",
          field: "consignment_code",
          width: 200,
        },
        {
          headerName: "Port In",
          field: "port_in_timestamp",
          width: 200,
          valueGetter: function(params) {
            if (params.data.port_in_timestamp) {
              return moment(params.data.port_in_timestamp).format(
                "YYYY-MM-DD HH:mm"
              );
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Exception Start Time",
          field: "exception_start_time",
          width: 200,
          valueGetter: function(params) {
            if (params.data.exception_start_time) {
              return moment(params.data.exception_start_time).format(
                "YYYY-MM-DD HH:mm"
              );
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Exception End Time",
          field: "exception_end_time",
          width: 200,
          valueGetter: function(params) {
            if (params.data.exception_end_time) {
              return moment(params.data.exception_end_time).format(
                "YYYY-MM-DD HH:mm"
              );
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Driver Name",
          field: "driver_name",
          width: 200,
        },
        {
          headerName: "Driver Mobile Number",
          field: "driver_mobile_no",
          width: 200,
        },
        {
          headerName: "Ticket Type",
          field: "ticket_category",
          width: 200,
        },
        {
          headerName: "Ticket Text",
          field: "ticket_text",
          width: 200,
        },
        {
          headerName: "Ticket Creation Date",
          field: "ticket_creation_date",
          width: 200,
          valueGetter: function(params) {
            if (params.data.ticket_creation_date) {
              return moment(params.data.ticket_creation_date).format(
                "YYYY-MM-DD HH:mm"
              );
            } else {
              return "";
            }
          },
        },
        {
          headerName: "Ticket Status",
          field: "ticket_status",
          width: 200,
          valueGetter: function(params) {
            if (params.data.ticket_status) {
              if (params.data.ticket_status === 1) {
                return "Open";
              } else {
                return "";
              }
            } else {
              return "";
            }
          },
        },
      ],
      nightDriveChildData: [],
      nightDriveParentData: [],
      overspeedChildData: [],
      overspeedParentData: [],

      harshBreakingGraph: "show-m",
      harshBreakingGrid: "show-n",
      harshBreakingButtonText: "Show Data",
      harshBreakingHeading: "Harsh Breaking",
      harshBreakingSubHeading: "",
      harshBreakingSeriesData: [],
      harshBreakingSeriesCategories: [],
    };
  }
  idlingBoxVisibility = () => {
    if (this.state.idlingButtonText === "Show Data") {
      this.setState({
        idlingGraph: "show-n",
        idlingGrid: "show-m",
        idlingButtonText: "Show Chart",
      });
    } else {
      this.setState({
        idlingGraph: "show-m",
        idlingGrid: "show-n",
        idlingButtonText: "Show Data",
      });
    }
  };

  harshBrakingBoxVisibility = () => {
    if (this.state.harshBrakingButtonText === "Show Data") {
      this.setState({
        harshBrakingGraph: "show-n",
        harshBrakingGrid: "show-m",
        harshBrakingButtonText: "Show Chart",
      });
    } else {
      this.setState({
        harshBrakingGraph: "show-m",
        harshBrakingGrid: "show-n",
        harshBrakingButtonText: "Show Data",
      });
    }
  };

  componentDidMount = () => {
    this.setState({
      harshBreakingSeriesData: {
        name: "Truck No.",
        colorByPoint: true,
        data: [
          {
            name: "GJ16AV8513",
            y: 2,
          },
          {
            name: "GJ16AV8597",
            y: 1,
          },
          // {
          //   name: "GJ16AV8547",
          //   y: 2,
          // },
          // {
          //   name: "GJ16AV8455",
          //   y: 1,
          // },
          // {
          //   name: "GJ16AV8422",
          //   y: 4,
          // },
          // {
          //   name: "GJ16AV8857",
          //   y: 1,
          // },
          // {
          //   name: "GJ16AW0469",
          //   y: 3,
          // },
          // {
          //   name: "GJ15AT6443",
          //   y: 1,
          // },
        ],
      },
      harshBreakingSeriesCategories: [
        "GJ16AV8513",
        "GJ16AV8597",
        "GJ16AV8547",
        "GJ16AV8455",
        "GJ16AV8422",
        "GJ16AV8857",
        "GJ16AW0469",
        "GJ15AT6443",
      ],
      idlingData: {
        data: [
          {
            name: "GJ16AV8513",
            y: 4.03,
            index: 0,
            x: 3,
          },
          {
            name: "GJ16AW0469",
            y: 2.62,
            index: 1,
            x: 6,
          },
          {
            name: "GJ16AV8422",
            y: 6.91,
            index: 2,
            x: 1,
          },
          {
            name: "GJ16AV8857",
            y: 2.86,
            index: 3,
            x: 5,
          },
          {
            name: "GJ15AT6443",
            y: 3.66,
            index: 4,
            x: 4,
          },
          {
            name: "GJ16AV8547",
            y: 4.48,
            index: 5,
            x: 2,
          },
          {
            name: "GJ16AV8455",
            y: 0.26,
            index: 6,
            x: 7,
          },
          {
            name: "GJ16AV8597",
            y: 4.48,
            index: 7,
            x: 0,
          },
        ],
        name: "enroutestoppage Data",
        dataSorting: {
          enabled: true,
        },
      },
    });
  };
  render() {
    return (
      <>
        <div className="row justify-content-around mx-0 mt-3">
          {/* Harsh Breaking */}
          {this.props.inPlantBtn === "btn-default" ? 
          <>
            {/* Harsh Breaking */}
            <div className="col-xl-6 col-lg-6 graphCard">
            <div className="dataChartButton">
              <button
                className="btn btn-danger"
                onClick={this.harshBrakingBoxVisibility}
                style={{
                  zIndex: "11",
                  fontSize: "12px",
                  padding: "5px 15px",
                  borderRadius: "3px",
                  marginTop: "12px",
                }}
              >
                {this.state.harshBrakingButtonText}
              </button>
            </div>
            {/* <span className={this.state.harshBrakingGraph}>
              <ColumnChartLoadingDashboard
                title={"Harsh Breaking"}
                subtitle={"No. of Instances of harsh breaking"}
                // subtitle={"Avg. Time taken inside the plant from Gate in to Gate out"}
                context={this}
                width={520}
                series={this.state.harshBreakingSeriesData}
                categories={this.state.harshBreakingSeriesCategories}
                yaxistitle={"Count"}
                dept_code={"IBL"}
                unit=" time(s)"
              />
            </span> */}
            <span className={this.state.harshBrakingGraph}>
              <div className="txt-center" style={{ marginTop: "8%" }}>
                <h6>Harsh Breaking</h6>
                <p>No. of Instances of harsh breaking</p>
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "27%",
                  fontWeight: "bold",
                  marginBottom: "20%",
                }}
              >
                <div style={{ marginBottom: "20px" }}>
                  <i className="icofont icofont-search icofont-3x"></i>
                </div>
                No Data Found
              </div>
            </span>
            <div className={`${this.state.harshBrakingGrid} txt-center p-2`}>
              <div className="txt-center" style={{ marginTop: "1%" }}>
                <h6>Harsh Breaking</h6>
                <p>No. of Instances of harsh breaking</p>
              </div>
              {/* <DashboardGrid
                columnDefs={overspeedColDefs}
                rowData={this.state.overspeedingTicketsData}
              /> */}
            </div>
            </div>
            {/* idling */}
            <div className="col-xl-6 col-lg-6 graphCard">
            {/* <div className="txt-center pt-2">
                  <h6>{this.state.idlingHeading}</h6>
                  <label>{this.state.idlingSubHeading}</label>
                </div> */}
            <>
              <div className="dataChartButton">
                <button
                  className="btn btn-danger"
                  onClick={this.idlingBoxVisibility}
                  style={{
                    zIndex: "11",
                    fontSize: "12px",
                    padding: "5px 15px",
                    borderRadius: "3px",
                    marginTop: "12px",
                  }}
                >
                  {this.state.idlingButtonText}
                </button>
              </div>
              {this.props.idlingData.data.length > 0 ? (
                <>
                  <span className={`${this.state.idlingGraph}`}>
                    <ScatterChartLoadingDashboard
                      title={"Idling"}
                      subtitle={"Avg. time of Idling"}
                      width={520}
                      context={this}
                      series={{
                        ...this.props.idlingData,
                        name: "Short-rest Data",
                        dataSorting: {
                          enabled: true,
                        },
                      }}
                      units=" Min"
                    />
                  </span>
                </>
              ) : (
                <>
                  <span className={this.state.idlingGraph}>
                    <div className="txt-center" style={{ marginTop: "8%" }}>
                      <h6>Idling</h6>
                      <p>Avg. time of Idling</p>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "27%",
                        fontWeight: "bold",
                        marginBottom: "20%",
                      }}
                    >
                      <div style={{ marginBottom: "20px" }}>
                        <i className="icofont icofont-search icofont-3x"></i>
                      </div>
                      No Data Found
                    </div>
                  </span>
                </>
              )}

              <></>

              <div className={`${this.state.idlingGrid} txt-center p-2`}>
                <div className="txt-center" style={{ marginTop: "1%" }}>
                  <h6>Idling</h6>
                  <p style={{ fontSize: "12px" }}>Avg. time of Idling</p>
                </div>
                <DashboardGrid
                  columnDefs={this.props.idlingColumnDefs}
                  rowData={this.props.idlingTicketData}
                />
              </div>
            </>
            </div>
          </>
          :
          // idling
          <>
          <div className="col-xl-6 col-lg-6 graphCard">
          {/* <div className="txt-center pt-2">
                <h6>{this.state.idlingHeading}</h6>
                <label>{this.state.idlingSubHeading}</label>
              </div> */}
          <>
            <div className="dataChartButton">
              <button
                className="btn btn-danger"
                onClick={this.idlingBoxVisibility}
                style={{
                  zIndex: "11",
                  fontSize: "12px",
                  padding: "5px 15px",
                  borderRadius: "3px",
                  marginTop: "12px",
                }}
              >
                {this.state.idlingButtonText}
              </button>
            </div>
            {this.props.idlingData.data.length > 0 ? (
              <>
                <span className={`${this.state.idlingGraph}`}>
                  <ScatterChartLoadingDashboard
                    title={"Idling"}
                    subtitle={"Avg. time of Idling"}
                    width={520}
                    context={this}
                    series={{
                      ...this.props.idlingData,
                      name: "Short-rest Data",
                      dataSorting: {
                        enabled: true,
                      },
                    }}
                    units=" Min"
                  />
                </span>
              </>
            ) : (
              <>
                <span className={this.state.idlingGraph}>
                  <div className="txt-center" style={{ marginTop: "8%" }}>
                    <h6>Idling</h6>
                    <p>Avg. time of Idling</p>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "27%",
                      fontWeight: "bold",
                      marginBottom: "20%",
                    }}
                  >
                    <div style={{ marginBottom: "20px" }}>
                      <i className="icofont icofont-search icofont-3x"></i>
                    </div>
                    No Data Found
                  </div>
                </span>
              </>
            )}

            <></>

            <div className={`${this.state.idlingGrid} txt-center p-2`}>
              <div className="txt-center" style={{ marginTop: "1%" }}>
                <h6>Idling</h6>
                <p style={{ fontSize: "12px" }}>Avg. time of Idling</p>
              </div>
              <DashboardGrid
                columnDefs={this.props.idlingColumnDefs}
                rowData={this.props.idlingTicketData}
              />
            </div>
          </>
          </div>
          <div className="col-xl-6 col-lg-6"></div>
          </>
          }          
        </div>
      </>
    );
  }
}
